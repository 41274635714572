import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  itemListTimeStampState,
  windowSizeState,
} from "../../../common/state/state";
import columnData from "../../../common/json/columns.json";
import { Box, Button, InputBase, Typography } from "@mui/material";
import MainMenu from "../../../common/components/main_menu";
import { TailSpin } from "react-loader-spinner";
import { lightgrey, primary } from "../../../common/style/styles";
import SearchHeader from "../components/search_header";
import DataTable from "../components/data_table";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { useMutation } from "react-query";
import { borderColor } from "@mui/system";
import { convertDateTime } from "../../../common/js/common";
import { SignalCellularNullSharp } from "@mui/icons-material";

const Index = () => {
  const { pathname, state } = useLocation();
  const { submenu } = useParams();
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // url 파라미터
  const [params, setParams] = useState({
    limit: 15,
    offset: 0,
    filter: state ? { has_reply: state.status } : null,
  });
  // 검색한 데이터의 총 갯수
  const [count, setCount] = useState(0);
  // 현재페이지, 마지막페이지 정보
  const [page, setPage] = useState({ total: 1, current: 1 });
  // 조회조건 배열
  const [condition, setCondition] = useState({
    has_reply: state ? state.status : null,
    qeury: "",
  });

  // 조회조건 추가, 삭제
  const handleChangeInquiry = (event) => {
    const { name, value } = event.target;

    setCondition({ ...condition, [name]: value });
  };

  // 조회하기 버튼 클릭했을때 조회조건을 기준으로 필터링하기
  const handleClickInquiry = () => {
    if (condition.has_reply === null && condition.qeury === "") {
      alert("조회조건이 없습니다.");
      return;
    }

    setParams({ ...params, filter: condition });
  };

  // 특정 페이지 이동
  const handleChangeCurrentPage = () => {
    // 이동하려는 페이지 숫자가 전체 페이지를 넘어섰을 경우 리턴
    if (page.current > page.total) {
      return;
    }
    setParams({
      ...params,
      offset: params.limit * (page.current - 1),
    });
  };

  // 페이지 변경
  const handlePageInputChange = (event) => {
    // 페이지 입력이 숫자가 아니거나 0보다 작으면 리턴
    if (!event.target.value || event.target.value <= 0) {
      return;
    }
    setPage({ ...page, current: event.target.value });
  };

  // 서버로부터 문의 or 리뷰 데이터 갸져오기
  const Mutation = useMutation("replyItemListData", () => {
    return getDataFromServer(
      `/admin/reply/${submenu}?filter=${
        params.filter ? JSON.stringify(params.filter) : params.filter
      }&offset=${params.offset}&timestamp=${itemListTimeStamp}`
    );
  });

  // 답변 작성
  const handleReplyChange = (event, id) => {
    const { name, value } = event.target;

    const newRows = rows.map((data) => {
      if (data.id === id) {
        return { ...data, reply: value };
      }
      return data;
    });

    setRows([...newRows]);
  };

  // 답변 등록
  const handleSubmit = (id) => {
    if (processing) {
      return;
    }

    const isEmpty = rows.find((data) => data.id === id && !data.reply);
    console.log(isEmpty);

    if (isEmpty) {
      alert("답변을 입력해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);

    const data = rows.find((row) => row.id === id);
    putDataFromServer(`/admin/reply/${submenu}/${id}`, {
      reply: data.reply,
    })
      .then((response) => {
        const newRows = rows.map((row) => {
          if (row.id === id) {
            return { ...row, has_reply: true, reply: data.reply };
          }
          return row;
        });
        setRows([...newRows]);
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      });

    setLoading(false);
    setProcessing(false);
  };

  //  문의, 리뷰 내용 삭제
  const handleDeleteItem = (id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteDataFromServer(`/admin/reply/${submenu}/${id}`)
        .then((response) => {
          const newRows = rows.filter((row) => row.id !== id);
          setRows([...newRows]);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    }
  };

  // 조회조건 변경, 하위메뉴 변경시 서버로 부터 데이터 가져오기
  useEffect(() => {
    Mutation.mutate(params, {
      onSuccess: (data) => {
        setIsAdmin(true);
        setRows([...data.data]);
        setCount(data.count);
        setPage({
          ...page,
          total: Math.ceil(data.count / params.limit),
        });
      },
      onError: (error) => {
        setIsAdmin(false);
      },
    });
  }, [params]);

  // 하위 메뉴를 변경하는 경우 조회조건 초기화
  useEffect(() => {
    setParams({
      limit: 15,
      offset: 0,
      filter: state ? { has_reply: false } : null,
    });
    setCondition({ has_reply: state ? state.status : null, qeury: "" });
  }, [submenu]);

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <Box>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <SearchHeader
              condition={condition}
              handleChangeInquiry={handleChangeInquiry}
              handleClickInquiry={handleClickInquiry}
            />
            {count > 0 ? (
              <Box
                border={"1px solid " + borderColor}
                sx={{ overflow: "auto" }}
                maxHeight={(windowSize.height * 8) / 13}
              >
                <DataTable
                  submenu={submenu}
                  rows={rows}
                  handleReplyChange={handleReplyChange}
                  handleSubmit={handleSubmit}
                  handleDeleteItem={handleDeleteItem}
                />
                <Box
                  style={{
                    backgroundColor: `${lightgrey}`,
                    bottom: "0px",
                    left: "0px",
                    position: "sticky",
                    zIndex: 110,
                    padding: "8px",
                  }}
                >
                  {count}개 중 {params.offset + 1} -{" "}
                  {params.offset + rows.length} 데이터 표시중 ({" "}
                  <InputBase
                    type="number"
                    style={{
                      margin: "2px",
                      border: `2px solid ${primary}`,
                      borderRadius: "4px",
                      width: "50px",
                    }}
                    value={page.current}
                    onChange={handlePageInputChange}
                  />
                  / {page.total} )
                  <Button
                    variant="outlined"
                    style={{ borderRadius: "unset", margin: "0 8px" }}
                    onClick={handleChangeCurrentPage}
                  >
                    페이지 이동
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography textAlign="center" variant="h4" margin="32px 0">
                데이터가 없습니다.
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={Mutation.isLoading || loadling}
      />
    </Box>
  );
};

export { Index as ReplyManagement };
