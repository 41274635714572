import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import MenuTab from "../../../common/components/menu_tab";
import {
  calculateSizeOptionPrice,
  selectItemImages,
} from "../../../common/js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import Title from "../components/title";
import ItemOption from "../components/item_option";
import { Box, Button, Divider, Typography } from "@mui/material";
import ItemReview from "../components/item_review";
import ItemQnA from "../components/item_qna";
import PurchaseInfo from "../components/purchase_info";
import RecommendedItem from "../components/recommended_item";
import BottomButton from "../components/buy_button";
import { TailSpin } from "react-loader-spinner";
import { BREAK_POINT_PC, Column, primary } from "../../../common/style/styles";
import DeliveryInfo from "../components/delivery_info";
import PriceInfo from "../components/price_info";
import BenefitInfo from "../components/benefit_info";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import defaultImg from "../../../media_assets/image/product1.jpg";
import ItemInfo from "../components/item_info";
import BuyModal from "../../../common/modal/buy_modal";

export const Show = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  // 아이템 정보
  const [itemInfo, setItemInfo] = useState({});
  // 추천 아이템
  const [recommendedItem, setRecommendedItem] = useState({});
  // 선택된 메뉴 id
  const [menu, setMenu] = useState(1);
  // 찜 여부
  const [isLike, setIsLike] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);
  const [QnACount, setQnACount] = useState(0);
  const menuList = [
    { id: 1, name: "상품정보" },
    { id: 2, name: `리뷰(${reviewCount})` },
    { id: 3, name: `상품문의(${QnACount})` },
    { id: 4, name: "구매안내사항" },
  ];

  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 구매하기 모달 오픈여부
  const [isOpened, setIsOpened] = useState(false);

  const handleClickModal = () => {
    setIsOpened((prev) => !prev);
  };

  // prams가 변경되었을 때 서버로부터 상품 관련 데이터 가져오기
  const itemInfoMutation = useMutation("ItemData", () => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    return getDataFromServer(
      `/service${pathname}?token=${
        nonMemberToken == "undefined" ? null : nonMemberToken
      }`
    );
  });

  // prams가 변경되었을 때 서버로부터 상품 추천 관련 데이터 가져오기
  const recommendedItemMutation = useMutation("recommendedItemData", () => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");
    const categoryId = itemInfo.item_info.category_id;
    const color = itemInfo.item_info.color;
    const price = itemInfo.item_info.sale_price;

    return getDataFromServer(
      `/service/recommend?category=${categoryId}&color=${color}&price=${parseInt(
        price
      )}&token=${nonMemberToken == "undefined" ? null : nonMemberToken}`
    );
  });

  useEffect(() => {
    // 비회원 로그인으로 구매하기 시도한 재품인 경우 로그인으로 이 페이지에 넘어오면 저장된 데이터 삭제
    localStorage.setItem("nonmemberOrderItem", "null");

    // list 페이지에서 아이템 상세페이지로 넘어와서 state가 존재할 때 localStorage로 데이터 저장
    if (state) {
      localStorage.setItem(
        "itemListData",
        JSON.stringify({ ...state, currentItem: pathname })
      );
    }

    setLoading(true);
    // url 변경되었을 때 상품 데이터 요청

    itemInfoMutation.mutate(pathname, {
      onSuccess: (data, variables, context) => {
        const images = selectItemImages(
          data.item_info.images,
          data.item_info.color
        );
        data.item_info.images = images;
        data.item_info.sale_price = data.item_info.sale_price;

        // 사이즈별 추가 가격 계산
        data.size_list = calculateSizeOptionPrice(
          data.size_list,
          data.price_list,
          data.orderby_size
        );

        setItemInfo({ ...data });
        setIsLike(data.item_info.is_like);
        setReviewCount(data.review_count);
        setQnACount(data.qna_count);
        setMenu(1);
      },
      onError: (error, variables, context) => {
        // console.log(error.response.status);
        const statusCode = error.response.status;
        if (statusCode === 403) {
          alert("판매종료된 상품입니다.");
        } else {
          alert("존재하지 않는 상품입니다.");
        }
        navigate("/");
        return;
      },
    });

    setLoading(false);
  }, [pathname]);

  // itemInfo를 잘 가져왔을 때 상품추천 데이터 가져오기
  useEffect(() => {
    if (Object.keys(itemInfo).length !== 0) {
      recommendedItemMutation.mutate(pathname, {
        onSuccess: (data) => {
          setRecommendedItem({ ...data });
        },
      });
    }
  }, [itemInfo]);

  // 메뉴 탭 변경
  const handleChangeMenu = (event, newValue) => {
    setMenu(newValue);
  };

  // 찜하기 변경
  const handleChangeIsLike = (state) => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    if (state == "add") {
      postDataFromServer("/service/likes", {
        token: nonMemberToken === "undefined" ? null : nonMemberToken,
        table_name: "item_options",
        table_id: itemInfo.item_info.item_option_id.toString(),
      }).then((response) => {
        setIsLike((prev) => !prev);
        // 비회원 토큰 정보가 없을경우 토큰정보 로컬에 저장
        if (response["token"] !== "null") {
          localStorage.setItem("nonMemberToken", response["token"]);
        }
      });
    } else {
      deleteDataFromServer(
        `/service/likes/-1?token=${nonMemberToken}&table_name=item_options&table_id=${itemInfo.item_info.item_option_id}`
      ).then((response) => {
        setIsLike((prev) => !prev);
      });
    }
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {Object.keys(itemInfo).length !== 0 ? (
        <Box marginTop={windowsize.width >= BREAK_POINT_PC ? "1rem" : "0"}>
          {/* 상단 상품 정보 */}
          {windowsize.width >= BREAK_POINT_PC ? (
            <Box display="flex">
              <img
                style={{
                  backgroundImage: `url(${defaultImg})`,
                }}
                src={itemInfo.item_info.images[0]}
                alt="상품 대표이미지"
                width="50%"
                height={(((windowsize.width * 0.75) / 2) * 100) / 66}
              />
              <Box
                marginLeft="2rem"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Title
                    itemInfo={itemInfo.item_info}
                    handleChangeIsLike={handleChangeIsLike}
                  />
                  <ItemOption
                    itemInfo={itemInfo.item_info}
                    colorList={itemInfo.color_list}
                  />
                </Box>
                <DeliveryInfo itemInfo={itemInfo} />
                <Box>
                  <PriceInfo itemInfo={itemInfo} />
                  {/* <BenefitInfo itemInfo={itemInfo.item_info} /> */}
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    borderRadius: "0",
                    background: "black",
                    padding: "1.5rem 0",
                  }}
                  onClick={handleClickModal}
                >
                  <Typography variant="subtitle1" fontWeight="700">
                    구매하기
                  </Typography>
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <img
                style={{
                  backgroundImage: `url(${defaultImg})`,
                }}
                src={itemInfo.item_info.images[0]}
                alt="상품 대표이미지"
                width="100%"
                height="150%"
              />
              <Box>
                <Title
                  itemInfo={itemInfo.item_info}
                  handleChangeIsLike={handleChangeIsLike}
                />
                <PriceInfo itemInfo={itemInfo} />
                <ItemOption
                  itemInfo={itemInfo.item_info}
                  colorList={itemInfo.color_list}
                />
                <DeliveryInfo itemInfo={itemInfo} />
                <Box>{/* <BenefitInfo itemInfo={itemInfo.item_info} /> */}</Box>
              </Box>
            </Box>
          )}
          {/* 상품 추천 */}
          {Object.keys(recommendedItem).length !== 0 && (
            <RecommendedItem recommendedItem={recommendedItem.recommend_item} />
          )}

          {/* 메뉴탭 */}
          <Box>
            <Divider />
            <MenuTab
              data={menuList}
              menu={menu}
              handleChange={handleChangeMenu}
              style={{
                pc: { fontSize: "21pt" },
                mobile: { fontSize: "10.5pt" },
              }}
            />
            <Divider />
          </Box>

          {/* 메뉴 */}
          <Box
            marginBottom={
              windowsize.width >= BREAK_POINT_PC ? "4.3rem" : "1rem"
            }
          >
            {menu === 1 && <ItemInfo itemInfo={itemInfo} />}
            {menu === 2 && (
              <ItemReview itemInfo={itemInfo} reviewCount={reviewCount} />
            )}
            {menu === 3 && <ItemQnA itemInfo={itemInfo} QnACount={QnACount} />}
            {menu === 4 && (
              <PurchaseInfo itemInfo={itemInfo} QnACount={QnACount} />
            )}
          </Box>
          {windowsize.width >= BREAK_POINT_PC ? <Divider /> : null}

          {/* 이런상품은 어떠세요? */}
          {Object.keys(recommendedItem).length !== 0 && (
            <Box
              marginBottom={
                windowsize.width >= BREAK_POINT_PC ? "2rem" : "1rem"
              }
            >
              <RecommendedItem recommendedItem={recommendedItem.similar_item} />
            </Box>
          )}
          {/* 구매하기 버튼 */}
          {windowsize.width < BREAK_POINT_PC ? (
            <BottomButton
              sizeList={itemInfo.size_list}
              itemInfo={itemInfo.item_info}
              isLike={isLike}
              handleChangeIsLike={handleChangeIsLike}
            />
          ) : null}
        </Box>
      ) : null}

      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={loadling}
      />
      <BuyModal
        isOpened={isOpened}
        handleClickModal={handleClickModal}
        sizeList={itemInfo.size_list}
        itemInfo={itemInfo.item_info}
      />
    </Column>
  );
};
