import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { BREAK_POINT_PC } from "../../../common/style/styles";
import AddressModal from "../modal/address_modal";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import Address from "./address";

// 상품 주문시 배송지 정보
const AddressInfo = ({ handleChangeOrderInfo }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  // 주소 리스트
  const [addressList, setAddressList] = useState([]);
  // 선택한 주소
  const [selectedAddress, setSelectedAddress] = useState({});
  // 배송 메세지
  const [deliveryMessage, setDeliveryMessage] = useState("");
  // 사용자 정보
  const [userInfo, setUserInfo] = useState({});
  // 배송 메모 직접입력인지 확인
  const [isEditable, setIsEditable] = useState(false);
  // 배송지 선택 모달
  const [isOpened, setIsOpened] = useState(false);

  const handleClickModal = () => {
    setIsOpened((prev) => !prev);
  };

  // 서버에서 주소 리스트 가져오기
  useEffect(() => {
    getDataFromServer("/service/users").then((response) => {
      setAddressList([...response.address_info]);
      // 기본 베송지가 있을 때는 기본 배송지 보여주고 없을 때는 가장 최근 주소 보여주기.
      if (response.address_info.length) {
        const hasDefaultAddress = response.address_info.find(
          (value) => value.is_default
        );
        if (hasDefaultAddress) {
          setSelectedAddress({ ...hasDefaultAddress });
          handleChangeOrderInfo("userInfo", {
            address: hasDefaultAddress,
            info: response.user_info,
          });
        } else {
          setSelectedAddress({ ...response.address_info[0] });
          handleChangeOrderInfo("userInfo", {
            address: response.address_info[0],
            info: response.user_info,
          });
        }
      }

      setUserInfo({ ...response.user_info });
    });
  }, []);

  // 배송 메세지 입력
  const handleChangeDeliveryMessage = (event) => {
    const { name, value } = event.target;

    // 직접입력 선택
    if (name === "select" && value === "input") {
      setDeliveryMessage(value);
      setIsEditable(true);
      // 요청사항 선택
    } else if (name === "select") {
      setIsEditable(false);
      setDeliveryMessage(value);
      handleChangeOrderInfo("delivery_message", value);
      // 직접입력
    } else {
      setDeliveryMessage(value);
      handleChangeOrderInfo("delivery_message", value);
    }
  };

  // 배송지 선택 변경
  const handleChangeAddress = (id) => {
    const newSelectedAddress = addressList.find((value) => value.id === id);
    setSelectedAddress({ ...newSelectedAddress });
    handleChangeOrderInfo("userInfo", {
      address: newSelectedAddress,
      info: userInfo,
    });
    handleClickModal();
  };

  // 배송지 추가, 수정하기
  const handleEditAddress = (address) => {
    console.log(address);
    if (address.id) {
      putDataFromServer(`/service/address/${address.id}`, address)
        .then((response) => {
          const newAddressList = addressList.map((data) => {
            if (data.id === address.id) {
              return address;
            } else {
              if (address.is_default) {
                return { ...data, is_default: false };
              } else {
                return data;
              }
            }
          });
          setAddressList([...newAddressList]);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    } else {
      postDataFromServer("/service/address", address)
        .then((response) => {
          address.id = response.address.id;
          const newAddressList = addressList.map((data) => {
            if (address.is_default) {
              return { ...data, is_default: false };
            } else {
              return data;
            }
          });
          setAddressList([address, ...newAddressList]);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    }
  };
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" margin="0.8rem 1rem">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
          fontWeight="600"
        >
          배송지
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ borderColor: "black", color: "black" }}
          onClick={handleClickModal}
        >
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          >
            배송지 변경
          </Typography>
        </Button>
      </Box>
      <Divider />
      {!addressList.length ? (
        <Typography
          variant="subtitle2"
          component="p"
          textAlign="center"
          margin="8rem 0"
        >
          등록된 배송지가 없습니다.
        </Typography>
      ) : (
        // 기본 배송지
        <Box margin="0.8rem 1rem">
          <Address selectedAddress={selectedAddress} />
          <FormControl
            fullWidth
            size={windowsize.width >= BREAK_POINT_PC ? "medium" : "small"}
            style={{ marginBottom: "0.5rem" }}
          >
            <InputLabel
              style={{
                display: deliveryMessage ? "none" : "block",
                top: "-2px",
              }}
              shrink={false}
            >
              배송 메모를 선택해주세요
            </InputLabel>
            <Select
              name="select"
              size={windowsize.width >= BREAK_POINT_PC ? "medium" : "small"}
              value={deliveryMessage}
              onChange={handleChangeDeliveryMessage}
              renderValue={() => {
                if (isEditable) {
                  return (
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle1"
                          : "body2"
                      }
                    >
                      요청사항 직접 입력
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle1"
                          : "body2"
                      }
                    >
                      {deliveryMessage}
                    </Typography>
                  );
                }
              }}
            >
              <MenuItem value="input">
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                  }
                >
                  요청사항 직접 입력
                </Typography>
              </MenuItem>
              <MenuItem value="배송 전에 미리 연락해주세요">
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                  }
                >
                  배송 전에 미리 연락해주세요
                </Typography>
              </MenuItem>
              <MenuItem value="부재시 경비실에 맡겨주세요">
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                  }
                >
                  {" "}
                  부재시 경비실에 맡겨주세요
                </Typography>
              </MenuItem>
              <MenuItem value="부재시 전화 주시거나 문자 남겨주세요">
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                  }
                >
                  {" "}
                  부재시 전화 주시거나 문자 남겨주세요
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>

          {isEditable ? (
            <TextField
              variant="outlined"
              placeholder="요청사항을 입력해주세요"
              inputProps={{
                style: {
                  fontSize:
                    windowsize.width >= BREAK_POINT_PC ? "16px" : "12px",
                  padding: "0.5rem",
                },
              }}
              fullWidth={true}
              size="small"
              value={deliveryMessage === "input" ? "" : deliveryMessage}
              onChange={handleChangeDeliveryMessage}
            />
          ) : null}
        </Box>
      )}
      <AddressModal
        isOpened={isOpened}
        handleClickModal={handleClickModal}
        addressList={addressList}
        handleChangeAddress={handleChangeAddress}
        handleEditAddress={handleEditAddress}
      />
    </Box>
  );
};

export default AddressInfo;
