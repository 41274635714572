import { atom } from "recoil";
import { convertDateTime } from "../js/common";

// 메인 메뉴의 클릭된 메뉴 id 저장
export const selectedMenuState = atom({
  key: "selectedMenuState",
  default: null,
});

// 브랜드 메뉴의 클릭된 메뉴 id 저장
export const brandMenuState = atom({
  key: "brandMenuState",
  default: -1,
});

// 하단 탭 메뉴 저장
export const tabMenuState = atom({
  key: "tabMenuState",
  default: "",
});

// 윈도우 사이즈 저장
export const windowSizeState = atom({
  key: "windowSizeState",
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
});

// 장바구니 아이템 갯수 저장
export const cartItemNumState = atom({
  key: "cartItemNumState",
  default: 0,
});

// 이전페이지 정보 저장
export const beforePageState = atom({
  key: "beforePageState",
  default: "none",
});

// 리스트 처음 불러온 시간 저장
export const itemListTimeStampState = atom({
  key: "itemListTimeStampState",
  default: convertDateTime(new Date()),
});
