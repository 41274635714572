import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC } from "../../../common/style/styles";

export const Title = ({ titleName }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <Box paddingTop="1rem">
      <Typography
        variant={windowsize.width >= BREAK_POINT_PC ? "h4" : "subtitle2"}
        fontWeight="600"
        gutterBottom
        align={windowsize.width >= BREAK_POINT_PC ? "left" : "center"}
      >
        {titleName}
      </Typography>
    </Box>
  );
};
