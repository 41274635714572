import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { postDataFromServer } from "../../../common/network/network";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, Column } from "../../../common/style/styles";

// 임시 비밀번호 전송
const ForgetPassword = () => {
  const regEmail =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const handleChangeInput = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // 이메일 입력했는지 확인
    if (!email) {
      alert("이메일을 입력해주세요.");
      return;
    }
    // 이메일 형식이 맞는지 확인
    if (!regEmail.test(email)) {
      alert("올바른 이메일 형식으로 입력해주세요.");
      return;
    }

    postDataFromServer("/service/login/password", { email: email })
      .then((response) => {
        alert("임시비밀번호 전송이 완료되었습니다.");
        navigate("/login");
      })
      .catch((error) => {
        alert("가입된 이메일이 아닙니다.");
        return;
      });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box margin="16px" display="flex" justifyContent="center">
        <Box
          width="100%"
          height="100%"
          marginTop={windowSize.height / 8 + "px"}
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "h5" : "subtitle2"}
            padding={windowSize.width >= BREAK_POINT_PC ? "2rem" : "1rem"}
            component="p"
            textAlign="center"
          >
            비밀번호를 찾고자 하는 이메일을 입력해 주세요.
          </Typography>
          <TextField
            required
            label="이메일"
            value={email}
            name="email"
            variant="standard"
            sx={{ width: "100%" }}
            onChange={handleChangeInput}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              color: "white",
              margin: "32px 0",
              fontSize: "12pt",
            }}
            onClick={handleSubmit}
          >
            임시 비밀번호 전송
          </Button>
        </Box>
      </Box>
    </Column>
  );
};

export default ForgetPassword;
