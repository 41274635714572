import { Box, Typography } from "@mui/material";
import React from "react";
import ItemSlider from "./item_slider";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC } from "../../../common/style/styles";

const RecommendedItem = ({ recommendedItem }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  return (
    <Box margin={windowSize.width >= BREAK_POINT_PC ? "6rem 0" : "0.8rem 1rem"}>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
        fontWeight="600"
        margin="1rem 0"
      >
        {recommendedItem.title}
      </Typography>
      <ItemSlider
        items={recommendedItem.items}
        likeItems={recommendedItem.like_items}
      />
    </Box>
  );
};

export default RecommendedItem;
