import { Box, Checkbox, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC } from "../../../common/style/styles";

const PaymentInfo = ({ handleChangeOrderInfo, orderInfo }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [paymentMethod, setPaymetMethod] = useState("");

  const handleChange = (value) => {
    setPaymetMethod(value);
    handleChangeOrderInfo("payment", value);
  };

  return (
    <Box>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
        fontWeight="600"
        margin="0.8rem 1rem"
      >
        결제 수단
      </Typography>
      <Divider />
      <Box
        margin={
          windowSize.width >= BREAK_POINT_PC ? "1.5rem 1rem" : "0.8rem 1rem"
        }
        display={windowSize.width >= BREAK_POINT_PC ? "flex" : "block"}
      >
        <Box
          display="flex"
          alignItems="center"
          margin={windowSize.width >= BREAK_POINT_PC ? "0" : "1rem 0 "}
        >
          <Checkbox
            size="medium"
            color="primary"
            checked={paymentMethod === "kakaopay"}
            sx={{ padding: "0 4px 0 0" }}
            onChange={() => {
              handleChange("kakaopay");
            }}
          />
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          >
            카카오페이
          </Typography>
        </Box>
        {/* <Box
          display="flex"
          alignItems="center"
          margin={windowSize.width >= BREAK_POINT_PC ? "0 1.5rem" : "1rem 0"}
        >
          <Checkbox
            size="medium"
            color="primary"
            checked={paymentMethod === "tosspayments"}
            sx={{ padding: "0 4px 0 0" }}
            onChange={() => {
              handleChange("tosspayments");
            }}
          />
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          >
            신용/체크카드 결제
          </Typography>
        </Box> */}

        <Box display="flex" alignItems="center">
          <Checkbox
            size="medium"
            color="primary"
            checked={paymentMethod === "naverpay"}
            sx={{ padding: "0 4px 0 0" }}
            onChange={() => {
              handleChange("naverpay");
            }}
          />
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          >
            네이버페이
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentInfo;
