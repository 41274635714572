import {
  Box,
  Divider,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PurchaseInfo = () => {
  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">서비스 안내</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            밀리언픽은 유럽의 다양한 SPA, 명품, 키즈 브랜드를 모아서 쇼핑할 수
            있는 온라인 플랫폼입니다. 영국, 프랑스, 네덜란드에서 현지법인을
            운영중이며 주문시 유럽 현지에서 상품을 소싱하여 고객님에게 보내
            드립니다.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">정품</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            당사가 판매하는 모든 상품은 브랜드 정품입니다.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">배송프로세스</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            상품을 주문하신 후 밀리언픽에서 브랜드 홈페이지 또는 매장을 통하여
            상품을 바잉한 후 유럽 현지에서 출고 해드립니다.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">배송기간</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            본 상품은 해외배송 상품으로 결제 후 약 9~15영업일 이내 배송됩니다.
            다만, 상품 일시품절시 배송기간은 이보다 지연될 수 있으며, 개별적으로
            안내 드립니다.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">배송비</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            기본배송비는 1만원이며 밀리언픽의 모든 상품은 묶음배송이 가능합니다.
            다만, 관부가세 미포함 상품을 다수 구매하여 150달러가 초과하는 경우
            고객의 동의하에 주문을 분할배송할 수 있으며 이 경우 배송비가 추가될
            수 있습니다.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">품절</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            주문하신 이후 현지 매장이나 홈페이지 재고상황에 따라 품절이 발생할
            수 있습니다. 이 경우 고객님에게 개별적으로 안내를 드려 취소처리
            도와드립니다.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">가격변동</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            주문하신 이후 현지 상품가격 변동 및 환율변동으로 가격변동이 발생할
            수 있습니다.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">개인통관고유번호</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            - 해외직구상품의 경우 상품 ‘수령인’의 개인통관고유번호 입력이
            필수입니다. 개인통관고유번호가 잘못 입력된 경우 통관이 지연될 수
            있으니 수령인의 개인통관고유번호를 올바르게 입력해 주세요.
          </Typography>
          <Typography>
            - 외국인은 주민등록항목에 따른 외국인등록번호를 입력하세요
            (외국인등록번호가 없는 외국인은 여권번호를 입력해 주세요)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">개인통관고유번호 발급방법</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            PC: https://unipass.customs.go.kr/csp/persIndex.do
          </Typography>
          <Typography>Mobile: 모바일관세청 앱 설치 후 발급가능</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">관부가세</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            주문하시는 개별상품 판매가격이 150달러를 초과하는 경우 상품가격에
            관부가세가 포함되어 있으며 150달러 이하의 상품은 관부가세가 포함되어
            있지 않습니다.
          </Typography>
          <br />
          <Typography>
            또한 밀리언픽에서 여러가지 상품을 같이 주문하시는 경우 아래와 같이
            관부가세 발생여부를 미리 체크하실 수 있습니다.
          </Typography>
          <Typography>
            - 관부가세 미포함 상품만을 주문하며 주문 총금액이 150달러 이하:
            관부가세 발생하지 않음
          </Typography>
          <Typography>
            - 관부가세 미포함 상품만을 주문하며 주문 총금액이 150달러 초과:
            통관시 관부가세 발생. 단, 고객 동의하에 주문을 나누어 배송하는 경우
            관부가세 발생하지 않음
          </Typography>
          <Typography>
            - 관부가세 포함 상품만을 주문하는 경우: 통관시 관부가세 추가
            발생하지 않음
          </Typography>
          <Typography>
            - 관부가세 포함 상품과 미포함 상품을 같이 주문하는 경우: 관부가세
            미포함 상품에 대하여 관부가세 추가 발생. 단, 고객 동의하에 주문을
            나누어 배송하는 경우 관부가세 발생하지 않음
          </Typography>
          <br />
          <Typography>
            단, 밀리언픽에서 주문하시는 상품 이외 다른 사이트에서 구매하신
            상품이 같은 날짜에 입항하는 경우 관부가세 발생내역이 달라질 수
            있으니 구매 전에 미리 참고 부탁 드립니다.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">취소</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            - 구매 당일에는 취소 수수료 없이 취소 가능합니다.
          </Typography>
          <Typography>
            - 구매하신 다음날 이후 상품이 유럽현지에서 이미 구매가 완료되었으나
            아직 발송처리가 되지 않은 경우 취소수수료 1만원 납부 후 취소
            가능합니다.
          </Typography>
          <Typography>
            - 구매하신 다음날부터는 상품이 발송처리된 이후(주문상태가 배송중
            경우)에는 취소가 불가능하며 반품 또는 교환만이 가능합니다.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">반품 및 교환</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            - 반품 또는 교환의 경우 먼저 당사 고객센터로 연락을 주시어 반품 및
            교환을 접수해 주세요.
          </Typography>
          <Typography>
            - 소비자 귀책에 의한 교환/반품(단순변심 포함)은 제품 수령 후 7일이내
            까지만 가능합니다.{" "}
          </Typography>
          <Typography>
            - 상품의 내용이 표시·광고의 내용과 다른 경우에는 상품을 수령한
            날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일
            이내에 청약철회 가능합니다.
          </Typography>
          <Typography>
            - 고객센터로 교환/반품 신청 이후 안내되는 주소로 수령하셨던 상태
            그대로 상품을 발송해 주세요. 상품상태 확인 이후 소비자 귀책으로
            판단되는 경우 결제하신 총 금액에서 교환/반품관련 일체 비용을
            공제하고 환불처리 되며, 판매자 귀책으로 판단되는 경우 반품관련 일체
            비용을 판매자가 부담하며 결제하신 금액 전액은 환불처리 됩니다.
          </Typography>
          <Typography>
            - 소비자 귀책에 의한 교환/반품시 반품비용: 반품수수료 4만원 +
            관부가세 발생액 (관부가세 포함 상품 주문시)
          </Typography>
          <Typography>
            - 소비자 귀책에 의한 교환/반품시 교환비용: 교환수수료 5만원 +
            관부가세 발생액 (관부가세 포함 상품 주문시)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">반품 및 교환 불가한 경우</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            - 상품배송 완료 후 7일이 지난 상품
          </Typography>
          <Typography>- 고객부주의로 인한 상품훼손, 오염</Typography>
          <Typography>
            - 상품사용으로 인하여 상품의 재판매가 불가능한 경우
          </Typography>
          <Typography>
            - 모니터상의 색상과 실제 상품의 색상의 작은 불일치로 인한 사유
          </Typography>
          <Typography>
            - 상품 특성 또는 구매처의 특성상 미세한 주름, 스크래치, 올풀림 등이
            있을 수 있으며 상품상세페이지에 해당 내용이 이미 고지된 경우
          </Typography>
          <Typography>
            - 회원에게 최초 배송시에 포함되었던 구성품이 누락 또는 훼손된 경우
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">사이즈</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            사이즈 안내는 브랜드 홈페이지 공식안내를 따릅니다. 당사는 상품재고를
            보유하지 않으므로 별도의 사이즈 안내를 드리지 않사오니 이점 참고하여
            구매결정 해주세요. 오배송이 아닌 사이즈 미스로 인한 반품/교환은
            단순변심으로 간주되오니 신중하게 구매해 주세요.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">검수</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            명품브랜드의 경우 검수서비스를 제공하오나 그 외 SPA 등 카테고리는
            검수서비스를 제공하지 않으며 현지 홈페이지나 매장에서 구매한 상태
            그대로 발송해 드립니다.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="700">지적재산권</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Typography marginTop="1rem">
            상품 이미지와 설명 등 판매되는 상품에 대한 모든 지적재산권은
            브랜드에 있습니다. (당사가 실제 촬영한 이미지 제외)
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PurchaseInfo;
