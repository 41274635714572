import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OrderItemList from "../components/order_item_list";
import { BREAK_POINT_PC, Column, grey6 } from "../../../common/style/styles";
import { useLocation, useNavigate } from "react-router-dom";
import AddressInfo from "../components/address_info";
import PaymentInfo from "../components/payment_info";
import PointInfo from "../components/point_info";
import BottomButton from "../../../common/components/bottom_button";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import {
  addDashToPhoneNumber,
  calPromotionCodeDiscountPrice,
  convertDate,
  fillZero,
  roundNumber,
  sumOfItems,
} from "../../../common/js/common";
import OrderInfo from "../components/order_info";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CustomsNumberInfo from "../components/customs_number_info";
import CouponInfo from "../components/coupon_info";
import PromotionCodeInfo from "../components/promotion_code_info";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import TempPassword from "../components/temp_password";
import WriteAddress from "../components/write_address";

const regCustomsNumber = /^(P|p)([0-9]{12})$/;
const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
const regPassword =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,12}$/;

const OrderSheet = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  // 구매 아이템 목록
  const [items, setItems] = useState([]);
  // 아이템별 국가 리스트
  const [country, setCountry] = useState([]);

  // 주문정보
  const [orderInfo, setOrderInfo] = useState({
    userInfo: null,
    delivery_message: null,
    customs_number: null,
    discount: { point: 0, coupon: null, promotion_code: 0 },
    payment: null,
    temp_pw: null,
  });

  // 포인트 지급율
  const [pointRate, setPointRate] = useState(0);
  // 전체 포인트
  const [totalPoint, setTotalPoint] = useState(0);
  // 쿠폰 리스트
  const [couponList, setCouponList] = useState([]);
  // 프로모션 코드
  const [promotionCode, serPromotionCode] = useState({});
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 로그인 구매인지 확인
  const [isLogin, setIsLogin] = useState(false);

  const handleChangeOrderInfo = (name, value) => {
    setOrderInfo((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    // 로컬에 저장되어 있는 구매 아이템 리스트 가져오기
    const orderItemList = localStorage.getItem("orderItemListData");
    // 로컬에 저장되어 있는 구매 아이템 리스트가 없으면 홈으로 페이지 이동시키기
    if (!orderItemList) {
      navigate("/");
      return;
    }

    setItems([...JSON.parse(orderItemList)]);
    setCountry([
      ...new Set(
        JSON.parse(orderItemList)
          .map((item) => {
            if (item.is_show == true) {
              return item.country;
            }
          })
          .filter((item) => item)
      ),
    ]);

    // 로그인 했는지 확인
    getDataFromServer("/service/users/name")
      .then((response) => setIsLogin(true))
      .catch((error) => setIsLogin(false));

    // 서버에서 포인트 지급율, 전체 포인트, 쿠폰 리스트 가져오기
    getDataFromServer("/service/orders/discount-info").then((response) => {
      setPointRate(response.point_rate);
      setTotalPoint(response.total_point);
      setCouponList([...response.coupon_list]);
    });
    // 통관번호 정보 가져오기
    getDataFromServer("/service/users/customs-number").then((response) =>
      handleChangeOrderInfo("customs_number", response.customs_number)
    );
  }, []);

  // 구매하기 버튼 클릭
  const handleBottomButtonClick = async () => {
    if (processing) {
      return;
    }

    // 비회원 구매인경우 비밀번호 바르게 입력했는지 확인
    if (!isLogin) {
      if (!orderInfo.temp_pw) {
        alert("비회원 주문조회 비밀번호를 입력해주세요.");
        return;
      }
      if (regPassword.test(orderInfo.temp_pw) === false) {
        alert("비회원 주문조회 비밀번호를 바르게 입력해주세요.");
        return;
      }
    }

    // 회원인경우 주소지가 있는지 확인
    // console.log(orderInfo);

    // 비회원 구매인경우 주소 입력했는지 확인
    if (!isLogin) {
      if (Object.keys(orderInfo.userInfo.address).length !== 6) {
        alert("주소를 입력해주세요.");
        return;
      }
      // 회원인경우 주소를 선택했는지 확인
    } else {
      if (Object.keys(orderInfo.userInfo.address).length === 0) {
        alert("주소를 선택해주세요.");
        return;
      }
    }

    // 개인통관고유번호를 작성했는지 확인
    if (orderInfo.customs_number === "") {
      alert("개인통관고유번호를 입력해주세요.");
      return;
    }

    if (!regCustomsNumber.test(orderInfo.customs_number)) {
      alert("개인통관고유번호를 올바른 형식으로 입력해주세요.");
      return;
    }

    // 결제 수단 확인
    if (!orderInfo.payment) {
      alert("결제수단을 선택해주세요.");
      return;
    }

    setProcessing(true);

    // 서버에 결제 정보 전달
    const newItems = items
      .filter((value) => value.is_show && value.is_selected)
      .map((value) => {
        return {
          item_id: value.item_id,
          item_detail_id: value.item_detail_id,
          item_option_id: value.item_option_id,
          quantity: value.quantity,
          item_price: roundNumber(value.sale_price),
          country: value.country,
        };
      });

    const newData = {
      address: orderInfo.userInfo.address,
      discount: orderInfo.discount,
      customs_number: orderInfo.customs_number,
      delivery_message: orderInfo.delivery_message,
      payment: orderInfo.payment,
      items: newItems,
      promotion_code: promotionCode,
      non_member: isLogin
        ? null
        : {
            pw: orderInfo.temp_pw,
            token: localStorage.getItem("nonMemberToken"),
          },
    };

    try {
      const newOrder = await postDataFromServer(
        "/service/orders/order-sheet",
        newData
      );
      if (newOrder.status !== 201) {
        alert("잠시 후 결제를 다시 시도해주세요.");
        return;
      }

      // 결제 정보 저장 성공하면 결제 창 열기
      const totalPrice =
        sumOfItems(items.filter((item) => item.is_selected && item.is_show)) +
        [
          ...new Set(
            items
              .filter((item) => item.is_selected == true)
              .map((value) => {
                return value.country;
              })
          ),
        ].length *
          10000 -
        orderInfo.discount.point -
        orderInfo.discount.promotion_code;

      if (orderInfo.payment == "naverpay" && totalPrice < 10) {
        alert("결제 금액이 10원보다 커야 합니다.");
        return;
      }

      const orderName = `${
        items.find((item) => item.is_selected)["item_name"]
      }${
        items.filter((item) => item.is_selected.length === 1) ||
        orderInfo.payment == "naverpay"
          ? ""
          : ` 외 ${items.filter((item) => item.is_selected).length - 1} 건`
      }`;
      const payId =
        convertDate(new Date(newOrder.order_date)).replaceAll("-", "") +
        fillZero(10, newOrder.id);

      // 네이버페이 결제
      if (orderInfo.payment == "naverpay") {
        const productItems = items
          .filter((value) => value.is_show && value.is_selected)
          .map((value) => {
            return {
              categoryType: "PRODUCT",
              categoryId: "GENERAL",
              uid: value.item_option_id,
              name: `${value.brand_name} ${value.item_name} ${value.color} / ${
                value.option_name == "컬러" ? "one size" : value.size
              }`.slice(0, 127),
              count: value.quantity,
            };
          });

        let productCount = 0;

        for (const item of productItems) {
          productCount += item.count;
        }

        const data = {
          pg: "naverpay",
          merchant_uid: payId, // 상점에서 관리하는 주문 번호
          name: orderName,
          amount: totalPrice,
          buyer_email: orderInfo.userInfo.info.email,
          buyer_name: orderInfo.userInfo.info.name,
          buyer_tel: addDashToPhoneNumber(orderInfo.userInfo.info.cellphone),
          buyer_addr: `${orderInfo.userInfo.address.main_address} ${orderInfo.userInfo.address.detail_address}`,
          buyer_postcode: orderInfo.userInfo.address.zip_code,
          naverPopupMode: false, // 팝업모드 활성화
          m_redirect_url: window.location.href.includes("test")
            ? `https://test.million-pick.com/paid?id=${newOrder.id}&payId=${payId}&method=${orderInfo.payment}`
            : `https://million-pick.com/paid?id=${newOrder.id}&payId=${payId}&method=${orderInfo.payment}`,
          // `http://localhost:3000/paid?id=${newOrder.id}&payId=${payId}&method=${orderInfo.payment}`,
          naverChainId: process.env.REACT_APP_naverpay_chain_id,
          naverMerchantUserKey: newOrder.user_id,
          naverProducts: productItems,
          count: productCount,
        };

        const callback = (response) => {
          const { success, imp_uid, merchant_uid, error_msg } = response;

          if (success) {
            // 결제정보 서버에 저장
            postDataFromServer("/service/orders/payment", {
              imp_uid: imp_uid,
              merchant_uid: merchant_uid,
            }).then((response) => {
              alert("결제가 완료되었습니다.");
              // 결제완료 페이지로 이동
              navigate("/paid");
            });
          } else {
            // 결제 취소시 포인트 사용 되돌리기
            if (orderInfo.discount.point > 0) {
              deleteDataFromServer(`/service/orders/point/${newOrder.id}`);
            }
            alert(error_msg);
          }
        };

        const IMP = window.IMP;
        IMP.init(`${process.env.REACT_APP_IMPORT_CODE}`);
        IMP.request_pay(data, callback);

        //포트원 결제 (토스페이먼츠, 카카오페이)
      } else {
        // 결제 관련 정보 (merchant_uid = 주문번호)
        const data = {
          pg: orderInfo.payment,
          pay_method: "card",
          escrow: false,
          merchant_uid: payId,
          name: orderName,
          amount: totalPrice,
          buyer_email: orderInfo.userInfo.info.email,
          buyer_name: orderInfo.userInfo.info.name,
          buyer_tel: addDashToPhoneNumber(orderInfo.userInfo.info.cellphone),
          buyer_addr: `${orderInfo.userInfo.address.main_address} ${orderInfo.userInfo.address.detail_address}`,
          buyer_postcode: orderInfo.userInfo.address.zip_code,
          notice_url: window.location.href.includes("test")
            ? "https://test.million-pick.com:3000/service/orders/payment"
            : "https://million-pick.com:3000/service/orders/payment",
        };

        const callback = (response) => {
          const { success, imp_uid, merchant_uid, error_msg } = response;

          if (success) {
            // 결제정보 서버에 저장
            postDataFromServer("/service/orders/payment", {
              imp_uid: imp_uid,
              merchant_uid: merchant_uid,
            }).then((response) => {
              alert("결제가 완료되었습니다.");
              // 결제완료 페이지로 이동
              navigate("/paid");
            });
          } else {
            // 결제 취소시 포인트 사용 되돌리기
            if (orderInfo.discount.point > 0) {
              deleteDataFromServer(`/service/orders/point/${newOrder.id}`);
            }
            alert(error_msg);
          }
        };

        const IMP = window.IMP;
        IMP.init(`${process.env.REACT_APP_IMPORT_CODE}`);
        IMP.request_pay(
          windowsize.width <= BREAK_POINT_PC
            ? {
                ...data,
                m_redirect_url: window.location.href.includes("test")
                  ? `https://test.million-pick.com/paid?id=${newOrder.id}&payId=${payId}&method=${orderInfo.payment}`
                  : `https://million-pick.com/paid?id=${newOrder.id}&payId=${payId}&method=${orderInfo.payment}`,
                // `http://localhost:3000/paid?id=${newOrder.id}&payId=${payId}&method=${orderInfo.payment}`,
              }
            : data,
          callback
        );
      }

      setProcessing(false);
    } catch (error) {
      alert("잠시 후 결제를 다시 시도해주세요.");
      setProcessing(false);
      return;
    }
  };

  // 프로모션코드 적용 버튼 클릭
  const handlePromotionCodeButtonClick = (code) => {
    // 프로모션 코드 존재 확인
    getDataFromServer(`/service/benefits/promotion-code/${code}`)
      .then((response) => {
        if (!response.data) {
          alert("존재하지 않는 코드입니다.");
        } else {
          // 중복 적용 가능한지 확인 -> 중복적용 가능하면 적용

          if (
            response.data.is_reusable === true ||
            (response.data.is_reusable === false &&
              response.data.promotion_code_history_id === null)
          ) {
            serPromotionCode(response.data);
            const { discountPrice, selectedIds } =
              calPromotionCodeDiscountPrice(items, response.data);

            console.log(discountPrice, selectedIds);
            if (discountPrice > 0) {
              // console.log(discountPrice);
              handleChangeOrderInfo("discount", {
                ...orderInfo.discount,
                promotion_code: parseInt(discountPrice),
                selected_id_list: selectedIds,
              });
            } else {
              alert("해당 상품에 사용 불가능한 코드입니다.");
            }
          } else {
            alert("이미 사용한 코드입니다.");
          }
        }
      })
      .catch((error) => {
        if (error.response.data.hasOwnProperty("msg")) {
          alert(error.response.data.msg);
          return;
        } else {
          alert("잠시 후 다시 시도해주세요.");
        }
      });
    //적용하기
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box
        minHeight="100vh"
        sx={{ pointerEvents: processing ? "none" : "auto" }}
      >
        {/* 결제하기 헤더 */}
        <Box display="flex" alignItems="center" margin="0.5rem 2rem">
          <Box flex="1" display="flex" justifyContents="flex-start">
            <KeyboardArrowLeftIcon
              sx={{
                fontSize:
                  windowsize.width >= BREAK_POINT_PC ? "3.5rem" : "1.7rem",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "h4" : "subtitle2"}
            fontWeight="700"
            flex="12"
            textAlign="center"
          >
            결제하기
          </Typography>
        </Box>

        <OrderItemList country={country} items={items} pointRate={pointRate} />
        <Box height="3px" sx={{ background: grey6 }}></Box>
        {isLogin ? null : (
          <TempPassword
            password={orderInfo.temp_pw}
            handleChangeOrderInfo={handleChangeOrderInfo}
          />
        )}
        <Box height="3px" sx={{ background: grey6 }}></Box>
        {isLogin ? (
          <AddressInfo handleChangeOrderInfo={handleChangeOrderInfo} />
        ) : (
          <WriteAddress handleChangeOrderInfo={handleChangeOrderInfo} />
        )}
        <Box height="3px" sx={{ background: grey6 }}></Box>
        <CustomsNumberInfo
          info={orderInfo}
          handleChange={handleChangeOrderInfo}
        />
        <Box height="3px" sx={{ background: grey6 }}></Box>
        <CouponInfo />
        <Box height="3px" sx={{ background: grey6 }}></Box>
        <PointInfo
          orderInfo={orderInfo}
          totalPoint={totalPoint}
          handleChangeOrderInfo={handleChangeOrderInfo}
        />
        <Box height="3px" sx={{ background: grey6 }}></Box>
        <PromotionCodeInfo
          handlePromotionCodeButtonClick={handlePromotionCodeButtonClick}
        />
        <Box height="3px" sx={{ background: grey6 }}></Box>
        <OrderInfo
          orderInfo={null}
          items={items}
          cancelCharge={0}
          point={-orderInfo.discount.point}
          promotionCode={-orderInfo.discount.promotion_code}
        />
        <Box height="3px" sx={{ background: grey6 }}></Box>
        <PaymentInfo
          handleChangeOrderInfo={handleChangeOrderInfo}
          orderInfo={orderInfo}
        />

        <BottomButton
          handleClick={handleBottomButtonClick}
          phrase={"결제하기"}
        />
      </Box>
    </Column>
  );
};

export default OrderSheet;
