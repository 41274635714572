import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, useNavigate } from "react-router-dom";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import { BREAK_POINT_PC } from "../../../common/style/styles";
export const Title = ({ titleName, handleChangeCategory }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const handleClick = (event, id) => {
    !pathname.includes(id) && navigate("/categories/" + id);
  };

  return (
    <Box>
      <Box
        display={windowsize.width >= BREAK_POINT_PC ? "block" : "flex"}
        margin="1rem 0"
        justifyContent={
          windowsize.width >= BREAK_POINT_PC ? "flex-start" : "center"
        }
        alignItems="center"
      >
        {titleName.map((value, index) => (
          <Box display="flex">
            <Button
              color="inherit"
              onClick={(event) => handleClick(event, value.id)}
            >
              <Typography
                key={value.id}
                variant={
                  windowsize.width >= BREAK_POINT_PC
                    ? value.depth === 1 || titleName.length == 1
                      ? "h4"
                      : "subtitle2"
                    : "subtitle2"
                }
                fontWeight={
                  value.depth === 1 || titleName.length == 1 ? "600" : "500"
                }
                // align={windowsize.width >= BREAK_POINT_PC ? "left" : "center"}
              >
                {value.name}
              </Typography>
            </Button>
            {index !== titleName.length - 1 && (
              <NavigateNextIcon sx={{ marginTop: "8px" }} />
            )}
          </Box>
        ))}
      </Box>
      <Divider />
    </Box>
  );
};

export default Title;
