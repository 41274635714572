import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDataFromServer } from "../network/network";
import { info, secondary, lightgrey } from "../style/styles";
import menuList from "../json/menu_list.json";

const AdminHeader = () => {
  const { pathname } = useLocation();
  const [mainMenu, subMenu, mode] = [
    pathname.split("/")[2],
    pathname.split("/")[3],
    pathname.split("/")[4],
  ];
  const navigate = useNavigate();
  const [adminEmail, setAdminEamil] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  // 헤더에 표시할 어드민 유저의 이메일 가져오기
  useEffect(() => {
    getDataFromServer("/admin/users/email")
      .then((response) => {
        console.log(response);
        setIsAdmin(true);
        setAdminEamil(response.user_email);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          alert("로그인 후 사용해주세요.");
          navigate("/admin/login");
        } else {
          alert("잠시 후 다시 시도해주세요.");
          return;
        }
        setIsAdmin(false);
      });
  }, []);

  // 로그아웃
  const handleLogout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      getDataFromServer("/admin/logout");
      navigate("/admin/login");
    }
  };

  return (
    <>
      {isAdmin && (
        <Box position="fixed" top="0" left="0" width="100%" zIndex="110">
          <Box
            padding="4px 8px"
            display="flex"
            justifyContent="space-between"
            sx={{ backgroundColor: lightgrey }}
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              MPICK ADMIN
            </Typography>
            <Box display="flex" alignItems="center">
              <Divider orientation="vertical" />
              <Typography
                variant="h6"
                component="p"
                gutterBottom
                padding="0 8px"
              >
                {adminEmail}
              </Typography>
              <Divider orientation="vertical" />
              <Typography
                variant="subtitle1"
                component="p"
                gutterBottom
                padding="0 8px"
                onClick={handleLogout}
                sx={{ cursor: "pointer" }}
              >
                로그아웃
              </Typography>
            </Box>
          </Box>
          <Box padding="4px 8px" sx={{ backgroundColor: "black" }}>
            <Typography
              variant="subtitle2"
              component="p"
              gutterBottom
              color="white"
            >
              {menuList[mainMenu].map((value) => {
                if (pathname.includes(value.path)) {
                  if (!mode) {
                    return value.subMenu
                      ? value.mainMenu + " > " + value.subMenu
                      : value.mainMenu;
                  }
                  if (mode === "create") {
                    return (
                      value.mainMenu +
                      " > " +
                      value.subMenu
                        .replace("조회", "정보 생성")
                        .replace("관리", "정보 생성")
                    );
                  }
                  if (mode === "edit") {
                    return (
                      value.mainMenu +
                      " > " +
                      value.subMenu
                        .replace("조회", "정보 수정")
                        .replace("관리", "정보 수정")
                    );
                  }
                  if (mode === "show") {
                    return (
                      value.mainMenu +
                      " > " +
                      value.subMenu
                        .replace("조회", "정보 상세보기")
                        .replace("관리", "정보 상세보기")
                    );
                  }
                }
              })}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AdminHeader;
