import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { windowSizeState } from "../state/state";
import { BREAK_POINT_PC } from "../style/styles";
import { useRecoilState } from "recoil";
import { Box } from "@mui/material";

const MenuTab = ({ data, menu, handleChange, style }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);

  return (
    <Box overflow="scroll">
      <Tabs
        value={menu}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        style={{ overflow: "scroll" }}
      >
        {data &&
          data.map((value) => (
            <Tab
              key={value.id}
              value={value.id}
              label={value.name}
              sx={{
                overflow: "scroll",
                fontSize:
                  windowsize.width >= BREAK_POINT_PC
                    ? style.pc.fontSize
                    : style.mobile.fontSize,
                padding: "1rem",
              }}
            />
          ))}
      </Tabs>
    </Box>
  );
};

export default MenuTab;
