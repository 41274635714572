import { Box, Divider, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import {
  info,
  primary,
  BREAK_POINT_PC,
  Column,
} from "../../../common/style/styles";

// 수정, 삭제, 취소 선택 모달
const ActionModal = ({
  isOpened,
  handleClickModal,
  handleEdit,
  handleDelete,
}) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  return (
    <Modal open={isOpened} onClose={handleClickModal}>
      <Box
        position="absolute"
        left="1.2rem"
        right="1.2rem"
        bottom="0.8rem"
        style={{
          background: "transparent",
          borderRadius: "0.8rem",
        }}
      >
        <Column xs={12} sm={12} md={12} lg={9}>
          <Box
            style={{
              background: "white",
              borderRadius: "0.8rem",
              cursor: "pointer",
            }}
            marginBottom="0.3rem"
          >
            <Typography
              textAlign="center"
              padding="1rem 0"
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="500"
            >
              수정
            </Typography>
            <Divider />
            <Typography
              textAlign="center"
              padding="1rem 0"
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="500"
              color={primary}
            >
              삭제
            </Typography>
          </Box>

          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            textAlign="center"
            padding="1rem 0"
            color="white"
            sx={{ background: info, borderRadius: "0.8rem", cursor: "pointer" }}
            onClick={handleClickModal}
          >
            취소
          </Typography>
        </Column>
      </Box>
    </Modal>
  );
};

export default ActionModal;
