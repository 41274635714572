import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { convertDate, inputNumberWithComma } from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, grey3 } from "../../../common/style/styles";
import OrderStatusButton from "./order_status_button";

// 주문내역, 주문상세의 상품 정보
const DeliveryStateItem = ({ item, handleChangeState }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <Box margin="1rem 0">
      <Box display="flex">
        <img
          src={item.images}
          width={windowsize.width >= BREAK_POINT_PC ? "115px" : "97px"}
          height={windowsize.width >= BREAK_POINT_PC ? "160px" : "140px"}
        />
        <Box
          marginLeft={windowsize.width >= BREAK_POINT_PC ? "1.5rem" : "0.8rem"}
          width="90%"
        >
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
            fontWeight="700"
            margin="0.5rem 0 0.2rem 0"
          >
            {item.brand_name}
          </Typography>
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
            fontWeight="700"
            noWrap={true}
            width={
              windowsize.width >= BREAK_POINT_PC
                ? ((windowsize.width * 9) / 12 - 115) * 0.9
                : (windowsize.width - 137) * 0.8
            }
          >
            {item.item_name}
          </Typography>
          <Box display="flex" margin="0.5rem 0">
            <Typography
              color={grey3}
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              fontWeight="500"
            >
              옵션: {item.color}/{item.size}
            </Typography>
            <Typography
              marginLeft="1rem"
              color={grey3}
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              fontWeight="500"
            >
              수량: {item.quantity}
            </Typography>
          </Box>
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            fontWeight="700"
          >
            {inputNumberWithComma(item.item_price)}원
          </Typography>
        </Box>
      </Box>
      <OrderStatusButton item={item} handleChangeState={handleChangeState} />
    </Box>
  );
};

export default DeliveryStateItem;
