import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { replaceAt } from "../js/common";
import {
  Column,
  bottomModalStyle,
  borderColor,
  primary,
  grey3,
} from "../style/styles";

const FilterModal = ({ openFilter, handleClose, handleChangeFilter }) => {
  // 필터 상태 관리
  const [filterValue, setFilterValue] = useState({
    totalSaleItems: { state: true, type: "sale" },
    isOnSale: { state: false, type: "sale" },
    totalTaxItems: { state: true, type: "tax" },
    hasTaxRate: { state: false, type: "tax" },
    totalPriceItems: {
      state: true,
      type: "price",
      includeTaxRate: false,
    },
    below5: {
      state: false,
      type: "price",
      includeTaxRate: false,
    },
    between5and10: {
      state: false,
      type: "price",
      includeTaxRate: false,
    },
    between10and20: {
      state: false,
      type: "price",
      includeTaxRate: true,
    },
    between20and50: {
      state: false,
      type: "price",
      includeTaxRate: true,
    },
    between50and100: {
      state: false,
      type: "price",
      includeTaxRate: true,
    },
    over100: {
      state: false,
      type: "price",
      includeTaxRate: true,
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    let newValue = filterValue;

    const selectedFilter = newValue[name];

    for (const filter of Object.keys(newValue)) {
      if (newValue[filter].type === selectedFilter.type) {
        if (newValue[filter].type === "price") {
          if (name === "totalPriceItems") {
            // 전체 클릭
            if (filter === "totalPriceItems") {
              newValue[filter].state = true;
            } else {
              newValue[filter].state = false;
            }
          } else {
            // 개별 가격 클릭
            if (filter === "totalPriceItems" && newValue[filter].state) {
              newValue[filter].state = false;
            }
            if (name === filter) {
              newValue[filter].state = !newValue[filter].state;
            }
          }
        } else {
          newValue[filter].state = !newValue[filter].state;
        }
      }
    }

    // 관부가세 포함여부에 체크되어 있을 경우 가격 20만원 이하는 모두 false 처리
    if (name == "hasTaxRate") {
      for (const filter of Object.keys(newValue)) {
        if (newValue[filter].includeTaxRate === false) {
          newValue[filter].state = false;
        }
      }
    }

    setFilterValue({ ...newValue });
  };

  // 버튼 클릭했을 때
  const handleClick = (name) => {
    console.log(name);
    // 전체 초기화 버튼 클릭
    if (name === "reset") {
      let newFilterValue = filterValue;

      for (const filter of Object.keys(newFilterValue)) {
        if (filter.includes("total")) {
          newFilterValue[filter].state = true;
        } else {
          newFilterValue[filter].state = false;
        }
      }
      setFilterValue({ ...newFilterValue });

      // 적용하기 버튼 클릭
      // , 으로 구분 : and
      // . 으로 구분 : or
    } else {
      let params = "";

      for (const filter of Object.keys(filterValue)) {
        if (filterValue[filter].state && !filter.includes("total")) {
          if (filterValue[filter].type === "price") {
            params += "(" + filter + ").";
          } else {
            params += filter + ",";
          }
        }
      }

      params = params.slice(0, -1);

      const hasPriceFilter = Object.keys(filterValue).find(
        (filter) =>
          filterValue[filter].type === "price" &&
          !filter.includes("total") &&
          filterValue[filter].state
      );

      if (hasPriceFilter) {
        const lastComma = params.lastIndexOf(",");

        if (lastComma !== -1) {
          params = replaceAt(params, lastComma, ",(") + ")";
        } else {
          params = "(" + params + ")";
        }
      }

      if (params) {
        handleChangeFilter(params);
      }
      handleClose("filter");
    }
  };

  return (
    <Modal
      open={openFilter}
      onClose={() => handleClose("filter")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={bottomModalStyle}>
        <Column xs={12} sm={12} md={12} lg={9}>
          <List disablePadding>
            <ListItem
              sx={{
                flexDirection: "column",
                alignItems: "flex-start",
                margin: "1rem 0.5rem 0.5rem 0",
              }}
            >
              <Typography
                variant="subtitle2"
                component="p"
                marginBottom="0.5rem"
              >
                세일여부
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: filterValue.totalSaleItems.state
                      ? primary
                      : borderColor,
                    borderRadius: "47px",
                    marginRight: "0.3rem",
                    color: filterValue.totalSaleItems.state ? primary : grey3,
                  }}
                  name="totalSaleItems"
                  value={filterValue.totalSaleItems.state}
                  onClick={handleChange}
                >
                  전체
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: filterValue.isOnSale.state
                      ? primary
                      : borderColor,
                    color: filterValue.isOnSale.state ? primary : grey3,
                    borderRadius: "47px",
                  }}
                  name="isOnSale"
                  value={filterValue.isOnSale.state}
                  onClick={handleChange}
                >
                  세일 상품만
                </Button>
              </Box>
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                flexDirection: "column",
                alignItems: "flex-start",
                margin: "0.5rem",
              }}
            >
              <Typography
                variant="subtitle2"
                component="p"
                marginBottom="0.5rem"
              >
                관부가세
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: filterValue.totalTaxItems.state
                      ? primary
                      : borderColor,
                    borderRadius: "47px",
                    marginRight: "0.3rem",
                    color: filterValue.totalTaxItems.state ? primary : grey3,
                  }}
                  name="totalTaxItems"
                  value={filterValue.totalTaxItems.state}
                  onClick={handleChange}
                >
                  전체
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: filterValue.hasTaxRate.state
                      ? primary
                      : borderColor,
                    color: filterValue.hasTaxRate.state ? primary : grey3,
                    borderRadius: "47px",
                  }}
                  name="hasTaxRate"
                  value={filterValue.hasTaxRate.state}
                  onClick={handleChange}
                >
                  관부가세 포함
                </Button>
              </Box>
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                flexDirection: "column",
                alignItems: "flex-start",
                margin: "0.5rem",
              }}
            >
              <Typography
                variant="subtitle2"
                component="p"
                marginBottom="0.5rem"
              >
                가격
              </Typography>
              <Box>
                <Box marginBottom="0.5rem">
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: filterValue.totalPriceItems.state
                        ? primary
                        : borderColor,
                      borderRadius: "47px",
                      marginRight: "0.3rem",
                      color: filterValue.totalPriceItems.state
                        ? primary
                        : grey3,
                    }}
                    name="totalPriceItems"
                    value={filterValue.totalPriceItems.state}
                    onClick={handleChange}
                  >
                    전체
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: filterValue.below5.state
                        ? primary
                        : borderColor,
                      color: filterValue.below5.state ? primary : grey3,
                      borderRadius: "47px",
                    }}
                    name="below5"
                    value={filterValue.below5.state}
                    onClick={handleChange}
                  >
                    5만원 이하
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: filterValue.between5and10.state
                        ? primary
                        : borderColor,
                      color: filterValue.between5and10.state ? primary : grey3,
                      borderRadius: "47px",
                    }}
                    name="between5and10"
                    value={filterValue.between5and10.state}
                    onClick={handleChange}
                  >
                    5만원 ~ 10만원
                  </Button>
                </Box>
                <Box marginBottom="0.5rem">
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: filterValue.between10and20.state
                        ? primary
                        : borderColor,
                      color: filterValue.between10and20.state ? primary : grey3,
                      borderRadius: "47px",
                    }}
                    name="between10and20"
                    value={filterValue.between10and20.state}
                    onClick={handleChange}
                  >
                    10만원 ~ 20만원
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: filterValue.between20and50.state
                        ? primary
                        : borderColor,
                      color: filterValue.between20and50.state ? primary : grey3,
                      borderRadius: "47px",
                    }}
                    name="between20and50"
                    value={filterValue.between20and50.state}
                    onClick={handleChange}
                  >
                    20만원 ~ 50만원
                  </Button>
                </Box>
                <Box marginBottom="0.5rem">
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: filterValue.between50and100.state
                        ? primary
                        : borderColor,
                      color: filterValue.between50and100.state
                        ? primary
                        : grey3,
                      borderRadius: "47px",
                    }}
                    name="between50and100"
                    value={filterValue.between50and100.state}
                    onClick={handleChange}
                  >
                    50만원 ~ 100만원
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: filterValue.over100.state
                        ? primary
                        : borderColor,
                      color: filterValue.over100.state ? primary : grey3,
                      borderRadius: "47px",
                    }}
                    name="over100"
                    value={filterValue.over100.state}
                    onClick={handleChange}
                  >
                    100만원 이상
                  </Button>
                </Box>
              </Box>
            </ListItem>

            <ListItem disablePadding sx={{ display: "flex" }}>
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "center",
                  display: "block",
                  background: "black",
                  color: "white",
                  padding: "16px 0",
                  cursor: "pointer",
                }}
                variant="subtitle2"
                fontWeight="700"
                name="reset"
                onClick={() => handleClick("reset")}
              >
                전체 초기화
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "center",
                  display: "block",
                  background: primary,
                  color: "white",
                  padding: "16px 0",
                  cursor: "pointer",
                }}
                variant="subtitle2"
                fontWeight="700"
                onClick={() => handleClick("apply")}
              >
                적용하기
              </Typography>
            </ListItem>
          </List>
        </Column>
      </Box>
    </Modal>
  );
};

export default FilterModal;
