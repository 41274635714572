import { Box, Divider, Typography, Button, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  checkExportCountryOfEachItem,
  inputNumberWithComma,
  selectImageThumbnail,
  sumOfItems,
} from "../../../common/js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import Item from "../components/item";
import {
  BREAK_POINT_PC,
  Column,
  grey5,
  primary,
  blue,
} from "../../../common/style/styles";
import { cartItemNumState, windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import BottomButton from "../../../common/components/bottom_button";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import AlertModal from "../../../common/modal/alert_modal";
import LoginModal from "../../../common/modal/login_modal";

export const Index = () => {
  const navigate = useNavigate();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [cartItemNum, setCartItemNum] = useRecoilState(cartItemNumState);
  // 아이템 리스트
  const [items, setItems] = useState([]);
  // 아이템별 국가 리스트
  const [country, setCountry] = useState([]);
  // 포인트 지급율
  const [pointRate, setPointRate] = useState(0);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 상품 전체 or 국가별 선택 여부
  const [selectedState, setSelectedState] = useState({
    total: true,
    스페인: true,
    영국: true,
    프랑스: true,
  });
  const [alertIsOpened, setAlertIsOpened] = useState(false);
  const [loginIsOpened, setLoginIsOpened] = useState(false);

  const handleAlertModal = () => {
    setAlertIsOpened((prev) => !prev);
  };

  const handleLoginModal = () => {
    setLoginIsOpened((prev) => !prev);
  };

  // 상품 선택, 해제
  const handleSelectedItemsChange = (type, value, state) => {
    // console.log(type, value, state);
    let newItems = [];

    switch (type) {
      case "item":
        newItems = [
          ...items.map((item) => {
            item.id == value && (item.is_selected = state);
            return item;
          }),
        ];

        // console.log(newItems);
        break;

      case "country":
        setSelectedState((prev) => {
          return { ...prev, [value]: state };
        });
        newItems = [
          ...items.map((item) => {
            item.country == value && (item.is_selected = state);
            return item;
          }),
        ];
        break;
      case "total":
        setSelectedState({
          total: state,
          스페인: state,
          영국: state,
          프랑스: state,
        });

        newItems = [
          ...items.map((item) => {
            item.is_selected = state;
            return item;
          }),
        ];
        break;
      default:
        break;
    }

    const calculatedItems = checkExportCountryOfEachItem(newItems);
    // console.log(calculatedItems);
    setItems([...calculatedItems]);
    setCountry([
      ...new Set(
        calculatedItems
          .map((item) => {
            if (item.is_show == true) {
              return item.country;
            }
          })
          .filter((item) => item)
      ),
    ]);
  };

  // 상품 수량 변경
  const handleQuantityChange = (state, id) => {
    let newItems;

    if (processing) {
      return;
    }

    setProcessing(true);
    setLoading(true);

    if (state == "increase") {
      newItems = items.map((value) => {
        if (value.id == id) {
          return { ...value, quantity: value.quantity + 1 };
        } else {
          return value;
        }
      });
    } else if (state == "decrease") {
      const selectedItem = items.find((item) => item.id === id);
      if (selectedItem.quantity <= 1) {
        return;
      }
      newItems = items.map((value) => {
        if (value.id == id) {
          return { ...value, quantity: value.quantity - 1 };
        } else {
          return value;
        }
      });
    }

    const calculatedItems = checkExportCountryOfEachItem(newItems);

    const nonMemberToken = localStorage.getItem("nonMemberToken");

    putDataFromServer(
      `/service/carts/${id}?token=${nonMemberToken}&quantity=${
        newItems.find((item) => item.id === id).quantity
      }`
    )
      .then((response) => {
        setItems([...calculatedItems]);
        setCountry([
          ...new Set(
            calculatedItems
              .map((item) => {
                if (item.is_show == true) {
                  return item.country;
                }
              })
              .filter((item) => item)
          ),
        ]);
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setProcessing(false);
        setLoading(false);
      });
  };

  // 장바구니 삭제 모달에서 확인을 누른경우
  const handleSubmit = () => {
    handleDeleteItem();
    handleAlertModal();
  };

  // 상품 삭제
  const handleDeleteItem = (id) => {
    if (processing) {
      return;
    }

    let deletedItems = [];
    // 아이템 한개 삭제
    if (typeof id === "number") {
      deletedItems = [id];
      // 선택한 상품 삭제
    } else {
      deletedItems = items
        .filter((value) => value.is_selected)
        .map((item) => {
          return item.id;
        });
    }
    setProcessing(true);
    setLoading(true);
    const nonMemberToken = localStorage.getItem("nonMemberToken");
    const newItems = items.filter((value) => !deletedItems.includes(value.id));
    const calculatedItems = checkExportCountryOfEachItem(newItems);

    deleteDataFromServer(
      `/service/carts/${deletedItems.join(",")}?token=${nonMemberToken}`
    )
      .then((response) => {
        setCartItemNum((prev) => (prev -= deletedItems.length));
        setItems([...calculatedItems]);
        setCountry([
          ...new Set(
            calculatedItems
              .map((item) => {
                if (item.is_show == true) {
                  return item.country;
                }
              })
              .filter((item) => item)
          ),
        ]);
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setProcessing(false);
        setLoading(false);
      });
  };

  // 서버로부터 장바구니 아이템 리스트 가져오기
  const { isLoading, error, data } = useQuery("cartItems", () => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");
    return getDataFromServer(
      `/service/carts?token=${
        nonMemberToken == "undefined" ? null : nonMemberToken
      }`
    );
  });

  // 장바구니 아이템이 있으면 items 배열에 세팅
  useEffect(() => {
    if (data) {
      if (data.items.length !== 0) {
        const calculatedItems = checkExportCountryOfEachItem(data.items);

        setItems([...calculatedItems]);
        setCountry([
          ...new Set(
            calculatedItems
              .map((item) => {
                if (item.is_show == true) {
                  return item.country;
                }
              })
              .filter((item) => item)
          ),
        ]);
        setPointRate(data.point_rate);
      }
    }
  }, [data]);

  // 구매하기 클릭
  const handleBottomButtonClick = () => {
    // console.log("items", items);
    // is_show=true && is_selected=true 인 상품이 하나라도 있는지 확인
    const hasSelectedItem = items.find(
      (item) => item.is_show && item.is_selected
    );

    if (!hasSelectedItem) {
      alert("구매하려는 상품을 하나 이상 선택해주세요.");
      return;
    }

    // 구매 목록 로컬에 저장
    localStorage.setItem(
      "orderItemListData",
      JSON.stringify(
        items.filter((item) => item.is_selected && item.not_soldout)
      )
    );

    // 로그인 했는지 확인
    getDataFromServer("/service/users/name")
      .then((response) => {
        // 구매정보 입력 페이지로 이동
        navigate("/orderSheet");
      })
      .catch((error) => {
        // 로그인, 비로그인 선택
        handleLoginModal();
      });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box sx={{ pointerEvents: processing ? "none" : "auto" }}>
        {items.length > 0 ? (
          <Box>
            {/* 전체선택, 선택삭제 */}
            <Box sx={{ background: grey5 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="0.3rem 1rem"
              >
                <Box display="flex" alignItems="center">
                  <Checkbox
                    sx={{ padding: "0 0.5rem 0 0" }}
                    checked={selectedState.total}
                    onChange={() => {
                      handleSelectedItemsChange(
                        "total",
                        "total",
                        !selectedState.total
                      );
                    }}
                  />
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                  >
                    전체선택 ({cartItemNum})
                  </Typography>
                </Box>
                <Button onClick={handleAlertModal}>
                  <Typography
                    color={blue}
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                    fontWeight="500"
                  >
                    선택 삭제
                  </Typography>
                </Button>
              </Box>
              <Divider />
            </Box>

            {country.map((value, index) => (
              <Box key={index}>
                {/* 국가별 아이템 */}
                <Box margin="0.5rem 1rem">
                  <Checkbox
                    sx={{ padding: "0 0.5rem 0 0" }}
                    checked={selectedState[value]}
                    onChange={() => {
                      handleSelectedItemsChange(
                        "country",
                        value,
                        !selectedState[value]
                      );
                    }}
                  />
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                    component="span"
                    gutterBottom
                  >
                    <b>
                      {" "}
                      {value === "스페인"
                        ? "🇪🇸"
                        : value === "영국"
                        ? "🇬🇧"
                        : "🇫🇷"}{" "}
                      {value} 출고
                    </b>
                  </Typography>
                </Box>
                <Divider />
                <Box>
                  <Item
                    items={selectImageThumbnail(items)}
                    country={value}
                    pointRate={pointRate}
                    handleSelectedItemsChange={handleSelectedItemsChange}
                    handleQuantityChange={handleQuantityChange}
                    handleDeleteItem={handleDeleteItem}
                  />
                </Box>
                <Divider />
                {/* 국가별 상품가격 */}
                <Box margin="0.5rem 1rem">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={
                      windowsize.width >= BREAK_POINT_PC ? "0.5rem" : "0.2rem"
                    }
                  >
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                    >
                      상품가격
                    </Typography>
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                      fontWeight="500"
                    >
                      {inputNumberWithComma(
                        sumOfItems(
                          items.filter(
                            (item) =>
                              item.country == value &&
                              item.not_soldout &&
                              item.is_selected &&
                              item.is_show
                          )
                        )
                      )}{" "}
                      원
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                    >
                      배송비
                    </Typography>
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                      fontWeight="500"
                    >
                      10,000 원
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ borderWidth: "3px" }} />
              </Box>
            ))}
            {/* 총 상품가격 계산 */}
            <Box
              padding="0.5rem 1rem 2rem 1rem"
              sx={{
                backgroundColor: grey5,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom="0.5rem"
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                >
                  총 상품가격
                </Typography>
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                  fontWeight="500"
                >
                  {inputNumberWithComma(
                    sumOfItems(
                      items.filter(
                        (item) =>
                          item.not_soldout && item.is_selected && item.is_show
                      )
                    )
                  )}{" "}
                  원
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom="0.5rem"
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                >
                  총 배송비
                </Typography>
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                >
                  {inputNumberWithComma(
                    [
                      ...new Set(
                        items
                          .filter((item) => item.is_selected && item.is_show)
                          .map((value) => {
                            return value.country;
                          })
                      ),
                    ].length * 10000
                  )}{" "}
                  원
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="0.5rem"
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                  fontWeight="500"
                >
                  총 결제 예상 금액
                </Typography>
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC
                      ? "subtitle2"
                      : "subtitle2"
                  }
                  fontWeight="700"
                  color={primary}
                >
                  {inputNumberWithComma(
                    sumOfItems(
                      items.filter((item) => item.is_selected && item.is_show)
                    ) +
                      [
                        ...new Set(
                          items
                            .filter((item) => item.is_selected && item.is_show)
                            .map((value) => {
                              return value.country;
                            })
                        ),
                      ].length *
                        10000
                  )}{" "}
                  원
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : cartItemNum === 0 ? (
          // 장바구니 아이템이 없을 때
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexDirection="column"
            height={windowsize.height / 2}
          >
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
              fontWeight="700"
              margin="1rem 0"
            >
              장바구니가 비었습니다.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: 0 }}
              onClick={() => {
                navigate("/");
              }}
            >
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                }
                fontWeight="700"
                padding={
                  windowsize.width >= BREAK_POINT_PC
                    ? "0.7rem 2rem "
                    : "0.5rem 1.5rem "
                }
              >
                쇼핑하러 가기
              </Typography>
            </Button>
          </Box>
        ) : (
          <Box></Box>
        )}
        {items.length > 0 ? (
          <BottomButton
            handleClick={handleBottomButtonClick}
            phrase={
              inputNumberWithComma(
                sumOfItems(
                  items.filter((item) => item.is_selected && item.is_show)
                ) +
                  [
                    ...new Set(
                      items
                        .filter((item) => item.is_selected && item.is_show)
                        .map((value) => {
                          return value.country;
                        })
                    ),
                  ].length *
                    10000
              ) + "원 구매하기"
            }
          />
        ) : null}

        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
      <AlertModal
        title="장바구니"
        content="선택한 상품을 장바구니에서 삭제하시겠습니까?"
        isOpened={alertIsOpened}
        handleAlertModal={handleAlertModal}
        handleSubmit={handleSubmit}
      />
      <LoginModal
        isOpened={loginIsOpened}
        handleLogin={() => navigate("/login")}
        handleNotLogin={() => navigate("/orderSheet")}
      />
    </Column>
  );
};
