import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import {
  BREAK_POINT_PC,
  grey2,
  grey4,
  info,
  blue,
  grey3,
  grey,
} from "../../../common/style/styles";

const ItemOption = ({ itemInfo, colorList }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const navigate = useNavigate();
  // console.log(itemInfo, colorList);
  return (
    <Box margin="0.5rem 0">
      <Divider
        sx={{
          borderWidth: `${windowSize.width >= BREAK_POINT_PC ? "0" : "1px"}`,
        }}
      />
      <Box
        display="flex"
        margin={
          windowSize.width >= BREAK_POINT_PC ? "1rem 0 0.5rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="1"
          color={info}
        >
          브랜드 상품 번호
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="2"
          fontWeight="500"
        >
          {itemInfo.sku}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        margin={
          windowSize.width >= BREAK_POINT_PC ? "0.5rem 0 1rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="1"
          color={info}
        >
          색상
        </Typography>
        <Box display="flex" alignItems="center" flex="2" flexWrap="wrap">
          {colorList.map((colorInfo) => (
            <Button
              variant="outlined"
              style={{
                color: "black",
                margin: "0.4rem 0.4rem 0.4rem 0",
                borderWidth: colorInfo.color === itemInfo.color ? "2px" : "1px",

                borderColor:
                  colorInfo.color === itemInfo.color ? "black" : grey,
              }}
              onClick={() => {
                navigate(
                  `/items/${colorInfo.item_id}/${colorInfo.detail_id}/${colorInfo.option_id}`
                );
              }}
            >
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                flex="2"
                fontWeight="500"
              >
                {colorInfo.color}
              </Typography>
            </Button>
          ))}
        </Box>
      </Box>
      <Divider
        sx={{
          borderWidth: `${windowSize.width >= BREAK_POINT_PC ? "0" : "1px"}`,
        }}
      />
    </Box>
  );
};

export default ItemOption;
