import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  convertDate,
  convertDateTime,
  selectImageThumbnail,
} from "../../../common/js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { itemListTimeStampState } from "../../../common/state/state";
import MainMenu from "../../../common/components/main_menu";
import { preproccessingForDataTable } from "../../../common/js/common";
import columnData from "../../../common/json/detail_data_columns.json";
import CurationItemAddModal from "../components/curation_item_add_modal";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../../../common/style/styles";
import CurationEditData from "../components/curation_edit_data";
import CurationDataList from "../components/curation_data_list";
import dayjs, { Dayjs } from "dayjs";

const CurationShow = () => {
  const { mode, id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [columns, setColumns] = useState(
    columnData[`/admin/menu/curation/${mode}/`]
  );
  const [info, setInfo] = useState({});
  const [items, setItems] = useState([]);
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  // 이벤트 상품 추가 모달
  const [isOpened, setIsOpened] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 대표 이미지 (룩북 이미지) 리스트
  const [lookBookImages, setLookBookImages] = useState([]);
  // 대표 이미지 (룩북 이미지) 별 아이템 리스트
  const [curationItemsByImage, setCurationItemByImage] = useState({});
  // 이벤트 기간
  const [date, setDate] = useState({
    start: dayjs(new Date()),
    end: dayjs(new Date()),
  });
  const [hasDate, setHasDate] = useState(false);

  // 이벤트 기간 설정
  const handleChangeDate = (name, value) => {
    console.log(convertDate(dayjs(value, "DDMMYYYY").toDate()));
    setDate((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleHasDate = () => {
    setHasDate((prev) => {
      {
        return !prev;
      }
    });
  };

  // 대표 이미지 (룩북 이미지) 별 아이템 리스트 수정
  const handleChangeCurationItemsByImage = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCurationItemByImage((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // 대표 이미지 (룩북 이미지) 추가, 삭제
  const handleChangeLookBookImages = (event) => {
    const { name, value, files } = event.target;

    if (files.length) {
      setLookBookImages((prev) => {
        return [...prev, ...files];
      });
    } else {
      const newFiles = lookBookImages.filter((image) =>
        typeof image === "object" ? image.name !== value : image !== value
      );
      setLookBookImages([...newFiles]);

      const newIds = Object.keys(curationItemsByImage).filter(
        (id) => curationItemsByImage[id] === value
      );

      let newCurationItemsByImage = curationItemsByImage;
      for (const id of newIds) {
        delete newCurationItemsByImage[id];
      }
      setCurationItemByImage({ ...newCurationItemsByImage });
    }
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (selectedId) => {
    const hasId = selectedIDs.filter((value) => value == selectedId).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (selectedId === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter(
          (value) => value !== selectedId
        );
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (selectedId === -1) {
        const newSelectedIDs = items.map((value) => {
          return value.id;
        });
        setSelectedIDs([selectedId, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, selectedId]);
      }
    }
  };

  // 이벤트 상품 삭제
  const handleDeleteCurationItems = () => {
    if (processing) {
      return;
    }
    if (!selectedIDs.length) {
      alert("삭제할 이벤트 상품을 선택해주세요.");
      return;
    }

    if (!window.confirm("선택한 이벤트 상품을 삭제하시겠습니까?")) {
      return;
    }

    setLoading(true);
    setProcessing(true);

    const newItems = items.filter(
      (item) => !selectedIDs.includes(item.item_option_id)
    );
    setItems([...newItems]);

    setLoading(false);
    setProcessing(false);
  };

  // 이벤트 상품 추가 모달 열고 닫기
  const handleModalChange = () => {
    setIsOpened((prev) => !prev);
  };

  // 상품 추가시 items 배열 변경
  const handleAddSelectedItems = (itemList) => {
    setItems([...items, ...selectImageThumbnail(itemList)]);
  };

  // 메뉴 데이터 정보 수정
  const handleChangeData = (event) => {
    const { name, value, files } = event.target;
    if (name === "reset") {
      setInfo({});
    } else {
      setInfo({ ...info, [name]: files ? files[0] : value });
    }
  };

  // 메뉴 데이터 정보 저장
  const handleSaveData = () => {
    if (processing) {
      return;
    }

    const isEmpty = columns.info.find(
      (value) =>
        !info.hasOwnProperty(value.field) && value.field !== "event_duration"
    );

    if (isEmpty) {
      alert("정보를 입력해주세요.");
      return;
    }

    const startDate = dayjs(date.start, "DDMMYYYY").toDate();
    const endDate = dayjs(date.end, "DDMMYYYY").toDate();

    if (hasDate) {
      const today = new Date();
      // 시작일이 종료일보다 늦은경우
      if (startDate > endDate) {
        alert("시작일이 종료일보다 늦을 수 없습니다.");
        return;
      }

      // 종료 기간이 현재 날짜보다 이전인 경우
      if (today > endDate) {
        alert("종료기간은 현재일 이후여야 합니다.");
        return;
      }
    }

    setLoading(true);
    setProcessing(true);

    const newItems = items.map((value) => {
      return { item_id: value.item_id, item_option_id: value.item_option_id };
    });

    const formData = new FormData();
    formData.append("itemInfo", JSON.stringify(info));
    formData.append("items", JSON.stringify(newItems));
    formData.append(
      "curationItemsByImage",
      JSON.stringify(curationItemsByImage)
    );

    if (hasDate) {
      formData.append(
        "date",
        JSON.stringify({
          start: convertDate(startDate),
          end: convertDate(endDate),
        })
      );
    }

    let imageFileName = [];

    for (let image of lookBookImages) {
      if (typeof image === "object") {
        formData.append(image.name, image);
        imageFileName.push(image.name);
      } else {
        imageFileName.push(image);
      }
    }
    formData.append("lookBookImages", JSON.stringify(imageFileName));

    //  메뉴 데이터 생성
    if (id === "-1") {
      postDataFromServer(`/admin/menu/curation`, formData)
        .then((response) => {
          alert("데이터가 저장되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
        });

      // 메뉴 데이터 업데이트
    } else {
      setLoading(true);
      setProcessing(true);

      putDataFromServer(`/admin/menu/curation/${id}`, formData)
        .then((response) => {
          alert("데이터가 저장되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
        });
    }
  };

  //  메뉴 데이터 삭제
  const handleDeleteData = () => {
    if (processing) {
      return;
    }
    if (window.confirm("데이터를 삭제하시겠습니까?")) {
      setLoading(true);
      setProcessing(true);
      deleteDataFromServer(`/admin/menu/curation/${id}`)
        .then((response) => {
          alert("데이터가 삭제되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
        });
    }
  };

  useEffect(() => {
    // 로그인 정보 확인
    getDataFromServer("/admin/users/email")
      .then((response) => {
        setIsAdmin(true);
      })
      .catch((error) => {
        setIsAdmin(false);
      });
  }, []);

  useEffect(() => {
    if (mode === "create") {
      return;
    }
    setLoading(true);

    // curation id에 해당하는 데이터 가져오기
    getDataFromServer(
      `/admin/menu/curation/${id}?timestamp=${itemListTimeStamp}`
    )
      .then((response) => {
        setIsAdmin(true);
        setItems([...selectImageThumbnail(response.items)]);
        const newColumns = columnData[`/admin/menu/curation/${mode}/`];

        setColumns({ ...newColumns });
        setInfo({
          ...response.info,
          is_show:
            mode === "show"
              ? response.info.is_show
                ? "O"
                : "X"
              : response.info.is_show,
        });

        if (mode === "edit" && response.info.ended_at) {
          setHasDate(true);
          setDate({
            start: dayjs(new Date(response.info.started_at)),
            end: dayjs(new Date(response.info.ended_at)),
          });
        }

        if (response.info.curation_type === 3) {
          setLookBookImages([
            ...new Set(
              response.images.map((data) => {
                return data.image;
              })
            ),
          ]);

          let newCurationItemByImage = {};
          for (const data of response.images) {
            newCurationItemByImage[data.item_option_id] = data.image;
          }
          setCurationItemByImage({ ...newCurationItemByImage });
        }
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pathname]);

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {mode === "show" && id && Object.keys(info).length && (
              <CurationDataList
                columns={columns}
                itemInfo={info}
                items={items}
                lookBookImages={lookBookImages}
                curationItemsByImage={curationItemsByImage}
                handleDeleteData={handleDeleteData}
              />
            )}
            {mode === "create" ||
            (mode === "edit" && Object.keys(info).length) ? (
              <CurationEditData
                id={id}
                columns={columns}
                itemInfo={info}
                items={items}
                selectedIDs={selectedIDs}
                handleClickCheckBox={handleClickCheckBox}
                handleDeleteCurationItems={handleDeleteCurationItems}
                handleModalChange={handleModalChange}
                handleChangeData={handleChangeData}
                handleSaveData={handleSaveData}
                handleChangeLookBookImages={handleChangeLookBookImages}
                lookBookImages={lookBookImages}
                curationItemsByImage={curationItemsByImage}
                handleChangeCurationItemsByImage={
                  handleChangeCurationItemsByImage
                }
                hasDate={hasDate}
                handleHasDate={handleHasDate}
                date={date}
                handleChangeDate={handleChangeDate}
              />
            ) : null}
          </Box>
          <CurationItemAddModal
            id={id}
            isOpened={isOpened}
            handleModalChange={handleModalChange}
            handleAddSelectedItems={handleAddSelectedItems}
          />
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { CurationShow as CurationDetail };
