import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainMenu from "../../../common/components/main_menu";
import DataList from "../components/data_list";
import columnData from "../../../common/json/detail_data_columns.json";
import { getDataFromServer } from "../../../common/network/network";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { preproccessingForDataTable } from "../../../common/js/common";

const Show = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const columns = columnData["/admin/orders/order/show/"];
  const [rows, setRows] = useState({ order_info: {}, order_item_info: [] });
  const [isAdmin, setIsAdmin] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // list 페이지에서 상세페이지로 넘어와서 state가 존재할 때 localStorage로 데이터 저장
    if (state) {
      localStorage.setItem(
        "adminItemListData",
        JSON.stringify({ ...state, currentItem: pathname })
      );
    }

    setLoading(true);
    getDataFromServer(`/admin/orders/${id}`)
      .then((response) => {
        setIsAdmin(true);

        const newRows = preproccessingForDataTable(
          "/admin/orders/order/show",
          response
        )[0];

        setRows({ ...newRows });
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <DataList columns={columns} rows={rows} />
          </Box>
        </Box>
      )}
    </>
  );
};

export { Show as OrderDetail };
