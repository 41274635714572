import { AppBar, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Column, StyledLink } from "../style/styles";

const BottomButton = ({ handleClick, phrase }) => {
  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0 }}
      onClick={handleClick}
    >
      <Column xs={12} sm={12} md={12} lg={9}>
        <Toolbar>
          <Typography
            variant="subtitle2"
            fontWeight="700"
            textAlign="center"
            flex="9"
            color="white"
            sx={{
              cursor: "pointer",
            }}
          >
            {phrase}
          </Typography>
        </Toolbar>
      </Column>
    </AppBar>
  );
};

export default BottomButton;
