import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, Column } from "../../../common/style/styles";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { postDataFromServer } from "../../../common/network/network";
import { useNavigate } from "react-router-dom";

const Nonmember = () => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  // 로그인 정보
  const [loginData, setLoginData] = useState({ name: "", pw: "" });
  const [showPassword, setShowPassword] = useState(false);
  // 로그인 버튼 활성화
  const [activate, setActivate] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };

  // 주문자명, 비밀번호를 입력하면 loginData 버튼 활성화
  useEffect(() => {
    if (loginData.phone && loginData.pw) {
      setActivate(true);
    }
  }, [loginData]);

  const handleSubmit = () => {
    // 아이디, 비밀번호가 있는지 확인
    if (!loginData.phone || !loginData.pw) {
      alert("로그인 정보를 입력해주세요.");
      return;
    }

    postDataFromServer("/service/login/non-member", loginData)
      .then((response) => {
        navigate("/orders");
      })
      .catch((error) => {
        if (error.response.status == 403) {
          alert("일치하는 주문정보가 없습니다.");
        } else {
          alert("짐시 후 다시 시도해주세요.");
        }
      });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box display="flex">
        <Box
          marginTop={windowSize.height / 10 + "px"}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "h5" : "subtitle1"}
            marginBottom={windowSize.width >= BREAK_POINT_PC ? "2rem" : "1rem"}
          >
            비회원 주문조회 로그인
          </Typography>
          <Box padding="16px" maxWidth="600px" width="100%">
            {/* 주문자 핸드폰번호 */}
            <OutlinedInput
              variant="outlined"
              placeholder="핸드폰번호"
              id="outlined-id"
              style={{ borderRadius: "0", width: "100%", margin: "0 0 1rem 0" }}
              name="phone"
              value={loginData.phone}
              onChange={handleChangeInput}
            />

            {/* 비밀번호 */}
            <OutlinedInput
              id="outlined-password"
              placeholder="비밀번호"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              sx={{ borderRadius: "0", width: "100%", margin: "0 0 1rem 0" }}
              name="pw"
              value={loginData.pw}
              onChange={handleChangeInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                }
              }}
            />

            <Button
              variant="contained"
              color={activate ? "primary" : "grey"}
              style={{
                width: "100%",
                color: "white",
                margin: `${
                  windowSize.width >= BREAK_POINT_PC ? "2rem 0" : "1rem 0"
                }`,
                borderRadius: "0",
                height: `${
                  windowSize.width >= BREAK_POINT_PC ? "80px" : "54px"
                }`,
              }}
              onClick={handleSubmit}
            >
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight={activate ? "700" : "400"}
              >
                주문 조회
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Column>
  );
};

export default Nonmember;
