import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  deleteDataFromServer,
  getDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { windowSizeState } from "../../../common/state/state";
import { borderColor, primary, StyledLink } from "../../../common/style/styles";
import DataTable from "../../../common/components/data_table";
import MainMenu from "../../../common/components/main_menu";
import { preproccessingForDataTable } from "../../../common/js/common";
import columnData from "../../../common/json/columns.json";
import { TailSpin } from "react-loader-spinner";

const CategoryIndex = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  // 테이블 행 데이터
  const [columns, setColumns] = useState([...columnData[pathname]]);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  // 이벤트 순서 변경
  const [dataOrder, setDataOrder] = useState({
    source: "",
    target: "",
  });
  const [isAdmin, setIsAdmin] = useState(false);
  //더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDataFromServer(`/admin/menu/category`)
      .then((response) => {
        setIsAdmin(true);
        setColumns([...columnData[pathname]]);
        const newData = preproccessingForDataTable(pathname, response.items);
        setRows([...newData]);
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pathname]);

  // 드래그 동작
  const handleDrag = (event, id) => {
    switch (event.type) {
      // 드래그 시작
      case "dragstart":
        setDataOrder({ ...dataOrder, source: id });
        break;
      // 변경하고 싶은 순서에 있는 데이터 위로 드래그
      case "dragover":
        setDataOrder({ ...dataOrder, target: id });
        break;
      // 변경하고 싶은 위치로 데이터 옮김
      case "dragend":
        handleChangeOrder();
        break;
      default:
        break;
    }
  };

  // 데이터 순서 변경
  const handleChangeOrder = () => {
    const targetInfo = rows.find((item) => item.id === dataOrder.target);
    const sourceInfo = rows.find((item) => item.id === dataOrder.source);

    // 카테고리 (소) 끼리 , 카테고리 (대) 끼리 이동하지 않는 경우 return
    if (
      targetInfo.one &&
      sourceInfo.one &&
      targetInfo.zero !== sourceInfo.zero
    ) {
      alert("이동이 불가능합니다.");
      return;
    }

    if (
      (!targetInfo.one && sourceInfo.one) ||
      (targetInfo.one && !sourceInfo.one)
    ) {
      alert("이동이 불가능합니다.");
      return;
    }

    setLoading(true);
    setProcessing(true);

    let tempRows = rows.filter((data) => data.id !== dataOrder.source);

    const targetIndex = rows
      .map((data, index) => {
        if (data.id === dataOrder.target) {
          return index;
        }
      })
      .filter((data) => data !== undefined)[0];

    tempRows.splice(parseInt(targetIndex), 0, sourceInfo);

    // console.log("targetInfo", targetInfo, sourceInfo);
    const changedRows = tempRows
      .filter((data) => {
        if (targetInfo.one) {
          if (data.one && targetInfo.zero === data.zero) {
            return data;
          }
        } else {
          if (!data.one) {
            return data;
          }
        }
      })
      .map((data, index) => {
        return { ...data, category_order: index + 1 };
      });

    // 서버로 변경된 순서 데이터 전달
    putDataFromServer(`/admin/menu/category/order`, {
      items: changedRows,
    })
      .then((response) => {
        setRows([...tempRows]);
        setDataOrder({ source: "", target: "" });
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  // 데이터 삭제
  const handleDelete = (state, id) => {
    if (hasClick) {
      return;
    }

    if (window.confirm("데이터를 삭제하시겠습니까?")) {
      setLoading(true);
      setProcessing(true);
      setHasClick(true);
      deleteDataFromServer(`/admin/menu/category/${id}`)
        .then((response) => {
          alert("데이터가 삭제되었습니다.");
          const newRows = rows.filter((value) => id !== value.id);
          setRows([...newRows]);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
          setHasClick(false);
        });
    }
  };

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <Box margin="16px 0">
              <StyledLink to={`${pathname}/create/-1`}>
                <Button
                  variant="outlined"
                  style={{ borderRadius: "unset", margin: "0 8px" }}
                >
                  카테고리 추가
                </Button>
              </StyledLink>
            </Box>

            <Box
              border={"1px solid " + borderColor}
              sx={{ overflow: "auto" }}
              maxHeight={(windowSize.height * 8) / 13}
            >
              <DataTable
                columns={columns}
                rows={rows}
                handleDelete={handleDelete}
                handleDrag={handleDrag}
              />
            </Box>
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { CategoryIndex as CategoryManagement };
