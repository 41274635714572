import { Box, Button, Divider, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import WriteQuestionModal from "../../../common/modal/write_question_modal";
import { getDataFromServer } from "../../../common/network/network";
import {
  borderColor,
  BREAK_POINT_PC,
  grey,
  info,
  primary,
} from "../../../common/style/styles";
import PagingButton from "./paging_button";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { convertDate } from "../../../common/js/common";
import QnA from "../../../common/components/qna";

const ItemQnA = ({ itemInfo, QnACount }) => {
  const navigate = useNavigate();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [isLogin, setIsLogin] = useState(false);
  const [QnAList, setQnAList] = useState([]);
  // QnAState: qna 필터링 (0: 전체, 1: 답변 완료, 2: 미답변, 3: 내 QnA 보기)
  const [params, setParams] = useState({
    QnAState: 0,
    offset: 0,
  });
  const [count, setCount] = useState(QnACount);
  // 문의작성 모달 열렸는지 여부
  const [isOpened, setIsOpened] = useState(false);

  const hanleWriteQuestion = () => {
    if (!isLogin) {
      alert("로그인 후 이용해주세요.");
      return;
    }

    if (windowsize.width >= BREAK_POINT_PC) {
      handleModal();
    } else {
      navigate(
        `/contact/write/${itemInfo.item_info.item_id}/${itemInfo.item_info.item_option_id}`
      );
    }
  };

  // 문의작성 모달 열고 닫기
  const handleModal = () => {
    setIsOpened((prev) => !prev);
  };

  // prams가 변경되었을 때 서버로부터 데이터 가져오기
  const mutation = useMutation("QnADataList", () => {
    return getDataFromServer(
      `/service/qna/${itemInfo.item_info.item_id}?reference=${itemInfo.item_info.reference}
            &state=${params.QnAState}&offset=${params.offset}`
    );
  });

  useEffect(() => {
    mutation.mutate(params, {
      onSuccess: (data) => {
        if (params.offset === 0) {
          setQnAList([...data.qna_list]);
        } else {
          setQnAList([...QnAList, ...data.qna_list]);
        }
        setCount(data.count);
        setIsLogin(true);
      },
    });
  }, [params]);

  // 상품문의 필터링 변경
  const handleChangeQnAState = (state) => {
    if (params.QnAState == 3 && state == 3) {
      setParams({ ...params, QnAState: 0 });
      return;
    }
    setParams({ ...params, QnAState: state });
  };

  // 상품문의 리스트 더 가져오기
  const handleClickButton = () => {
    setParams({ ...params, offset: params.offset + 20 });
  };

  return (
    <Box>
      <Box>
        {/* 문의 필터링 */}
        <Box
          margin={
            windowsize.width >= BREAK_POINT_PC ? "3.7rem 0 1.5rem 0" : "1rem"
          }
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* QnA 답변 상태 */}
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" marginRight="2rem">
              <CheckOutlinedIcon
                style={{
                  color: `${params.QnAState === 0 ? primary : grey} `,
                  fontSize: `${
                    windowsize.width >= BREAK_POINT_PC ? "1.5rem" : "1rem"
                  }`,
                }}
              />
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body2"
                }
                color={params.QnAState === 0 ? primary : grey}
                onClick={() => {
                  handleChangeQnAState(0);
                }}
                sx={{ cursor: "pointer" }}
              >
                전체
              </Typography>
            </Box>
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body2"
              }
              marginRight="2rem"
              color={params.QnAState === 1 ? primary : grey}
              onClick={() => {
                handleChangeQnAState(1);
              }}
              sx={{ cursor: "pointer" }}
            >
              답변완료
            </Typography>

            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body2"
              }
              color={params.QnAState === 2 ? primary : grey}
              onClick={() => {
                handleChangeQnAState(2);
              }}
              sx={{ cursor: "pointer" }}
            >
              미답변
            </Typography>
          </Box>
          <Button
            variant={
              windowsize.width >= BREAK_POINT_PC ? "outlined" : "contained"
            }
            sx={{
              borderRadius: `${
                windowsize.width >= BREAK_POINT_PC ? "0" : "4px"
              }`,
              color: `${
                windowsize.width >= BREAK_POINT_PC ? "black" : "white"
              }`,
              borderColor: "black",
              background: `${
                windowsize.width >= BREAK_POINT_PC ? "white" : "black"
              }`,
            }}
            onClick={hanleWriteQuestion}
          >
            <Typography
              width="max-content"
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "caption"
              }
              fontWeight={windowsize.width >= BREAK_POINT_PC ? "500" : "600"}
            >
              문의 작성
            </Typography>
          </Button>
          {/* 내 문의 보기
            <Box display="flex" alignItems="center">
              <Typography variant="caption">내 Q&A 보기</Typography>
              <Switch
                size="small"
                checked={params.QnAState === 3 ? true : false}
                onClick={() => {
                  handleChangeQnAState(3);
                }}
              />
            </Box> */}
        </Box>
        {windowsize.width >= BREAK_POINT_PC ? <Divider /> : null}
        {/* 문의 내용 */}
        <Box>
          {count > 0 ? (
            QnAList.map((qna, index) => (
              <Box>
                <QnA key={qna.id} qna={qna} />
                {QnAList.length - 1 !== index && <Divider />}
              </Box>
            ))
          ) : (
            <Box
              margin="15rem 0"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <NoteAltOutlinedIcon
                style={{
                  color: borderColor,
                  fontSize: `${
                    windowsize.width >= BREAK_POINT_PC ? "4.4rem" : "3rem"
                  }`,
                }}
              />
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                }
                fontWeight="700"
                width="max-content"
                margin="0.3rem 0 0.5rem 0"
              >
                아직 작성된 문의가 없습니다.
              </Typography>
            </Box>
          )}
          {count < QnAList.length && (
            <Box display="flex" justifyContent="center">
              <PagingButton
                color={"white"}
                handleClickButton={handleClickButton}
              />
            </Box>
          )}
        </Box>
      </Box>
      <WriteQuestionModal
        isOpened={isOpened}
        handleModal={handleModal}
        itemId={itemInfo.item_info.item_id}
        itemOptionId={itemInfo.item_info.item_option_id}
      />
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "relative",
          top: "50%",
          left: "50%",
          zIndex: 200,
          padding: "16px",
        }}
        wrapperClass=""
        visible={mutation.isLoading}
      />
    </Box>
  );
};

export default ItemQnA;
