import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box, padding } from "@mui/system";
import React, { useState } from "react";
import { grey } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import styled from "styled-components";
import { Column, bottomModalStyle, BREAK_POINT_PC } from "../style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../state/state";
const OrderModal = ({ orderBy, handleChangeOrderby }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);

  return (
    <FormControl size="small">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={orderBy}
        onChange={handleChangeOrderby}
        style={{
          borderRadius: "0",
          width: `${windowsize.width >= BREAK_POINT_PC ? "200px" : "100px"}`,
          fontSize: `${windowsize.width >= BREAK_POINT_PC ? "20px" : "10.5pt"}`,
          fontWeight: "500",
        }}
      >
        <MenuItem value="id">
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
            fontWeight="500"
          >
            최신순
          </Typography>
        </MenuItem>
        <MenuItem value="popular">
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
            fontWeight="500"
          >
            인기순
          </Typography>
        </MenuItem>
        <MenuItem value={"lowPrice"}>
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
            fontWeight="500"
          >
            낮은가격순
          </Typography>
        </MenuItem>
        <MenuItem value={"highPrice"}>
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
            fontWeight="500"
          >
            높은가격순
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default OrderModal;
