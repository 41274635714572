import * as XLSX from "xlsx";
import orderState from "../json/order_state.json";

// 천단위 이상의 숫자에 콤마( , )를 삽입하는 함수 (notRound=true 인경우 반올림 적용 x)
export function inputNumberWithComma(price, notRound) {
  if (!notRound) {
    price = roundNumber(price);
  }

  price = String(price);

  return price.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
}

// 반올림 함수 (백원에서 반올림)
export function roundNumber(number) {
  return Math.round(parseInt(number) / 100) * 100;
}

// 전화번호에 - 추가해서 리턴하는 함수
export function addDashToPhoneNumber(phoneNumber) {
  const newPhoneNumber = String(phoneNumber);
  return newPhoneNumber.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

// 시작날짜와 종료날짜 사이 날짜들을 배열로 반환하는 함수
export function getDatesStartToLast(startDate, lastDate) {
  const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
  if (!(regex.test(startDate) && regex.test(lastDate)))
    return "Not Date Format";
  let result = [];
  const curDate = new Date(startDate);
  while (curDate <= new Date(lastDate)) {
    result.push(curDate.toISOString().split("T")[0]);
    curDate.setDate(curDate.getDate() + 1);
  }
  return result;
}

// 정해진 자릿수만큼 0으로 채워서 return
export function fillZero(digits, str) {
  str = String(str);
  return str.length >= digits
    ? str
    : new Array(digits - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
}

export function findHangulIndex(value) {
  const word = value.charCodeAt(0) - 44032;
  const index = parseInt(word / 588);

  return index;
}

// 문자열의 특정 인덱스에 글자 삽입
export function replaceAt(string, index, character) {
  return (
    string.substr(0, index) +
    character +
    string.substr(index + character.length - 1)
  );
}

// 썸네일로 사용할 이미지를 1장 고르는 함수 (컬러 옵션과 일치하는 이미지 1장 리턴)
export function selectImageThumbnail(itemList) {
  console.log(itemList);
  const newItemList = itemList.map((item) => {
    for (let image of item["images"]) {
      const colorOption = image.split("*")[1]
        ? image.split("*")[1].replaceAll("/", "N").toLowerCase()
        : image.split("*")[1];

      if (colorOption == item["color"].replaceAll("/", "N").toLowerCase()) {
        item["images"] = image.split("*")[0];
        break;
      }
    }
    return item;
  });

  return newItemList;
}

// 상품 상세페이지에서 보여줄 이미지를 고르는 함수 (컬러 옵션과 일치하는 모든 이미지 리턴)
export function selectItemImages(imageList, colorName) {
  const newImageList = imageList
    .map((image) => {
      const colorOption = image.split("*")[1]
        ? image.split("*")[1].replaceAll("/", "N").toLowerCase()
        : image.split("*")[1];

      if (colorOption == colorName.replaceAll("/", "N").toLowerCase()) {
        return image.split("*")[0];
      }
    })
    .filter((value) => value);

  return newImageList;
}

// 상품 합 가격
export function sumOfItems(items) {
  let totalPrice = 0;

  items.map((item) => {
    totalPrice += parseInt(
      roundNumber(item.sale_price ? item.sale_price : item.item_price) *
        item.quantity
    );
  });

  return totalPrice;
}

// 프로모션코드 할인 적용 금액 계산
export function calPromotionCodeDiscountPrice(items, promotionCodeInfo) {
  let filteredItems = items.filter((item) => item.is_selected && item.is_show);

  const deliveryFee =
    [
      ...new Set(
        items
          .filter((item) => item.is_selected == true)
          .map((value) => {
            return value.country;
          })
      ),
    ].length * 10000;

  let discountPrice = 0;

  // 브랜드, 카테고리, 할인여부 구분
  if (
    promotionCodeInfo.brands.includes(-1) ||
    items.filter((item) => promotionCodeInfo.brands.includes(item.brand_id))
      .length > 0
  ) {
    if (!promotionCodeInfo.brands.includes(-1)) {
      filteredItems = filteredItems.filter((item) =>
        promotionCodeInfo.brands.includes(item.brand_id)
      );
    }

    if (
      promotionCodeInfo.categories.includes(-1) ||
      items.filter(
        (item) =>
          promotionCodeInfo.categories.includes(item.category_id).length > 0
      )
    ) {
      if (!promotionCodeInfo.categories.includes(-1)) {
        filteredItems = filteredItems.filter(
          (item) =>
            promotionCodeInfo.categories.includes(item.category_id).length > 0
        );
      }

      if (promotionCodeInfo.exclude_sale_item === true) {
        filteredItems = filteredItems.filter(
          (item) => item.item_price === item.sale_price
        );
        if (filteredItems.length === 0) {
          return 0;
        }
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }

  const itemPrice = sumOfItems(
    filteredItems.filter((item) => item.is_selected && item.is_show)
  );

  console.log(filteredItems, itemPrice);

  const selectedIds = filteredItems.map((item) => item.item_option_id);

  // 상품할인인지 배송비할인인지 구분
  if (promotionCodeInfo.benefits_type === "item") {
    // 금액할인인지 비율할인인지 구분
    if (promotionCodeInfo.discount_type === "price") {
      discountPrice = parseInt(promotionCodeInfo.discount);
    } else {
      discountPrice = parseInt(
        itemPrice * parseFloat(promotionCodeInfo.discount / 100)
      );
    }
  } else {
    if (promotionCodeInfo.discount_type === "price") {
      discountPrice = parseInt(promotionCodeInfo.discount);
    } else {
      discountPrice = parseInt(
        deliveryFee * parseFloat(promotionCodeInfo.discount / 100)
      );
    }
  }

  // 금액과 해당되는 item_option_id 배열 반환

  return { discountPrice: discountPrice, selectedIds: selectedIds };
}

// 사이즈별 추가 가격 계산
// 최저가 나라에 재고가 없으면 다음으로 가격이 낮은 나라의 옵션 중 재고가 있는 것으로 대체, 가격 차액만큼 + 금액 표시
export function calculateSizeOptionPrice(sizeList, priceList, orderbySize) {
  // 상품의 최저 가격
  const minPrice = Math.min(
    ...priceList
      .filter((value) => value.country !== "한국")
      .map((value) => parseInt(value.sale_price))
  );

  // 최저 가격 나라
  const minPriceCountry = priceList.find(
    (value) => parseInt(value.sale_price) === minPrice
  ).country;

  // 사이즈 리스트를 사이즈 순서대로 정렬 (ex> S -> M -> L -> XL)
  let orderBySizeList = [];

  for (const size of orderbySize) {
    for (const value of sizeList) {
      if (size === value.size) {
        orderBySizeList.push(value);
      }
    }
  }

  // 나라별 가격 {나라:가격, ...}
  let priceByCountry = {};

  for (const info of priceList) {
    priceByCountry = {
      ...priceByCountry,
      [info.country]: parseInt(info.sale_price),
    };
  }

  // 사이즈별 재고가 있는 나라 중 최저가 나라 확인하기 {사이즈 : 국가 ,... }
  const sizeOptionPrice = {};

  for (const size of orderbySize) {
    for (const value of orderBySizeList) {
      if (size === value.size) {
        if (value.is_stock) {
          if (!Object.keys(sizeOptionPrice).includes(value.size)) {
            sizeOptionPrice[value.size] = value.country;
          } else {
            if (
              priceByCountry[sizeOptionPrice[value.size]] >
              priceByCountry[value.country]
            ) {
              sizeOptionPrice[value.size] = value.country;
            }
          }
        }
      }
    }
    if (!sizeOptionPrice.hasOwnProperty(size)) {
      sizeOptionPrice[size] = minPriceCountry;
    }
  }

  // 사이즈별 추가 가격 계산 및 노출 나라 설정
  const newSizeList = orderBySizeList.map((value) => {
    return {
      ...value,
      option_price: priceByCountry[value.country] - minPrice,
      is_show: sizeOptionPrice[value.size] === value.country ? true : false,
    };
  });

  return newSizeList;
}

// 상품별 최저가 상품 계산 후 최저가 상품 is_show = true, is_selected = true
// 최저가 아닌 상품 is_show = false, is_selected = false
// 최저가이지만 재고가 없는 상품 is_show = true, is_selected = false  포함하여 리턴
function calculateMinimumPrice(exportCountry, items, country) {
  let filteredExportCountry = {};

  // 각 나라에 해당하는 가격만 남기기
  Object.keys(exportCountry).map((key, index) => {
    filteredExportCountry[key] = exportCountry[key].filter((value) =>
      country.includes(value.country)
    );
  });

  // 각 나라별 최저가만 남기기 [ {cart_option_id: price , ... } ]
  Object.keys(filteredExportCountry).map(
    (key) =>
      (filteredExportCountry[key] = Math.min(
        ...filteredExportCountry[key].map((value) => parseInt(value.price))
      ))
  );

  // items 중 최저가 상품 is_selected = true / 최저가 아닌 상품 is_selected = false
  const filteredItems = items.map((item) => {
    if (
      parseInt(item.sale_price) === filteredExportCountry[item.cart_option_id]
    ) {
      if (item.is_stock) {
        return { ...item, is_show: true, is_selected: true };
      } else {
        return { ...item, is_show: true, is_selected: false };
      }
    } else {
      return { ...item, is_show: false, is_selected: false };
    }
  });
  return filteredItems;
}

// 상품이 여러나라에서 출고 가능할 때 어느 나라에서 출고하는게 최적인지 계산
export function checkExportCountryOfEachItem(items) {
  // 전체품절이나 사용자가 상품 선택을 해제한 경우 상품별 출고국가를 계산하는 로직에서 아이템 제외, 출고 국가 계산 후 상품 선택 해제한 아이템은 최저가 국가로 다시 노출

  // const items = items;

  let excludeItemIds = [];

  // 선택안한 아이템 확인하는 함수
  const notSelectedIds = [
    ...new Set(
      items
        .filter((item) => item.is_selected == false)
        .map((item) => {
          return item.id;
        })
    ),
  ];

  // 전체품절인지 확인하는 함수
  let soldoutIds = [];
  for (const item of items) {
    if (!item.not_soldout) {
      const temp = items
        .filter((product) => product.id == item.id)
        .filter((product) => product.not_soldout);

      if (temp.length == 0) {
        soldoutIds.push(item.id);
      }
    }
  }

  excludeItemIds = [...notSelectedIds, ...new Set(soldoutIds)];

  const selectedItems = items.filter(
    (item) => !excludeItemIds.includes(item.id)
  );

  // 상품별 출고 가능 국가 object { cart_option_id : [{country :국가, price : 가격} ... ] ... }
  // 품절인 국가 제외 , 옵션이 한 국가밖에 없는데 품절일경우 그냥 넣기
  let exportCountry = {};
  for (const item of selectedItems) {
    if (
      item.is_stock ||
      selectedItems.filter(
        (data) => data.cart_option_id === item.cart_option_id
      ).length === 1
    ) {
      Object.keys(exportCountry).includes(item.cart_option_id.toString())
        ? exportCountry[item.cart_option_id].push({
            country: item.country,
            price: item.sale_price,
          })
        : (exportCountry[item.cart_option_id] = [
            { country: item.country, price: item.sale_price },
          ]);
    }
  }

  // 출고 가능한 케이스 [{ caseId : 케이스 번호, totalPrice: 상품 가격, items: 필터링된 아이템}]
  // 1: 스페인, 2: 프랑스, 3: 영국, 4: 스페인+프랑스, 5: 스페인+영국, 6: 프랑스+영국, 7: 스페인+프랑스+영국
  const countryCase = [];

  // 출고 국가 : 스페인
  if (
    Object.keys(exportCountry).filter((key) =>
      exportCountry[key].map((value) => value.country).includes("스페인")
    ).length === Object.keys(exportCountry).length
  ) {
    const filteredItems = calculateMinimumPrice(exportCountry, selectedItems, [
      "스페인",
    ]);

    countryCase.push({
      caseId: 1,
      totalPrice:
        sumOfItems(
          filteredItems.filter((item) => item.is_selected && item.is_show)
        ) + 10000,
      items: filteredItems,
    });
    // console.log(
    //   "스페인 출고 가능",
    //   sumOfItems(filteredItems),
    //   sumOfItems(filteredItems) + 10000
    // );
  }
  // 출고 국가 : 프랑스
  if (
    Object.keys(exportCountry).filter((key) =>
      exportCountry[key].map((value) => value.country).includes("프랑스")
    ).length === Object.keys(exportCountry).length
  ) {
    const filteredItems = calculateMinimumPrice(exportCountry, selectedItems, [
      "프랑스",
    ]);

    countryCase.push({
      caseId: 2,
      totalPrice:
        sumOfItems(
          filteredItems.filter((item) => item.is_selected && item.is_show)
        ) + 10000,
      items: filteredItems,
    });
    // console.log(
    //   "프랑스 출고 가능",
    //   sumOfItems(filteredItems),
    //   sumOfItems(filteredItems) + 10000
    // );
  }
  // 출고 국가 : 영국
  if (
    Object.keys(exportCountry).filter((key) =>
      exportCountry[key].map((value) => value.country).includes("영국")
    ).length === Object.keys(exportCountry).length
  ) {
    const filteredItems = calculateMinimumPrice(exportCountry, selectedItems, [
      "영국",
    ]);

    countryCase.push({
      caseId: 3,
      totalPrice:
        sumOfItems(
          filteredItems.filter((item) => item.is_selected && item.is_show)
        ) + 10000,
      items: filteredItems,
    });

    // console.log(
    //   "영국 출고 가능",
    //   filteredItems,
    //   sumOfItems(filteredItems),
    //   sumOfItems(filteredItems) + 10000
    // );
  }
  // 출고 국가 : 스페인+프랑스
  if (
    Object.keys(exportCountry).filter(
      (key) =>
        exportCountry[key].map((value) => value.country).includes("스페인") ||
        exportCountry[key].map((value) => value.country).includes("프랑스")
    ).length === Object.keys(exportCountry).length
  ) {
    // item 중 최저가 item과 최저가 아닌 아이템 구분
    const filteredItems = calculateMinimumPrice(exportCountry, selectedItems, [
      "스페인",
      "프랑스",
    ]);
    countryCase.push({
      caseId: 4,
      totalPrice:
        sumOfItems(
          filteredItems.filter((item) => item.is_selected && item.is_show)
        ) + 20000,
      items: filteredItems,
    });
    // console.log(
    //   "스페인, 프랑스 출고 가능",
    //   sumOfItems(filteredItems),
    //   sumOfItems(filteredItems) + 20000
    // );
  }
  // 출고 국가 : 스페인+영국
  if (
    Object.keys(exportCountry).filter(
      (key) =>
        exportCountry[key].map((value) => value.country).includes("스페인") ||
        exportCountry[key].map((value) => value.country).includes("영국")
    ).length === Object.keys(exportCountry).length
  ) {
    // item 중 최저가 item과 최저가 아닌 아이템 구분
    const filteredItems = calculateMinimumPrice(exportCountry, selectedItems, [
      "스페인",
      "영국",
    ]);
    countryCase.push({
      caseId: 5,
      totalPrice:
        sumOfItems(
          filteredItems.filter((item) => item.is_selected && item.is_show)
        ) + 20000,
      items: filteredItems,
    });
    // console.log(
    //   "스페인, 영국 출고 가능",
    //   sumOfItems(filteredItems),
    //   sumOfItems(filteredItems) + 20000
    // );
  }
  // 출고 국가 : 프랑스+영국
  if (
    Object.keys(exportCountry).filter(
      (key) =>
        exportCountry[key].map((value) => value.country).includes("프랑스") ||
        exportCountry[key].map((value) => value.country).includes("영국")
    ).length === Object.keys(exportCountry).length
  ) {
    // item 중 최저가 item과 최저가 아닌 아이템 구분
    const filteredItems = calculateMinimumPrice(exportCountry, selectedItems, [
      "프랑스",
      "영국",
    ]);
    countryCase.push({
      caseId: 6,
      totalPrice:
        sumOfItems(
          filteredItems.filter((item) => item.is_selected && item.is_show)
        ) + 20000,
      items: filteredItems,
    });
    // console.log(
    //   "프랑스, 영국 출고 가능",
    //   sumOfItems(filteredItems),
    //   sumOfItems(filteredItems) + 20000
    // );
  }
  // 출고 국가 : 스페인+프랑스+영국
  if (
    Object.keys(exportCountry).filter(
      (key) =>
        exportCountry[key].map((value) => value.country).includes("스페인") ||
        exportCountry[key].map((value) => value.country).includes("프랑스") ||
        exportCountry[key].map((value) => value.country).includes("영국")
    ).length === Object.keys(exportCountry).length
  ) {
    // item 중 최저가 item과 최저가 아닌 아이템 구분
    const filteredItems = calculateMinimumPrice(exportCountry, selectedItems, [
      "스페인",
      "프랑스",
      "영국",
    ]);
    countryCase.push({
      caseId: 7,
      totalPrice:
        sumOfItems(
          filteredItems.filter((item) => item.is_selected && item.is_show)
        ) + 30000,
      items: filteredItems,
    });
    // console.log(
    //   "스페인, 프랑스, 영국 출고 가능",
    //   sumOfItems(filteredItems),
    //   sumOfItems(filteredItems) + 30000
    // );
  }

  // 출고 가능한 케이스 중 어느게 최적인지 계산
  // console.log("countryCase", countryCase);

  const minCase = countryCase.filter(
    (value) =>
      value.totalPrice ===
      Math.min(...countryCase.map((price) => price.totalPrice))
  );

  // console.log("minCase", minCase);

  // 선택 해제한 아이템이 없을 경우
  if (excludeItemIds.length === 0) {
    return minCase[0]["items"];
  }

  // 선택 해제한 아이템이 있을 경우 선택 해제한 아이템 최저가 국가로 다시 노출
  const excludeItems = items.filter((item) => excludeItemIds.includes(item.id));

  // console.log(items);
  // console.log("excludeItems", excludeItems);

  // array : [ {id: item_id, price: min price} ]
  const minPriceOfExcludeItems = excludeItemIds.map((id) => {
    const minPrice = Math.min(
      ...excludeItems
        .map((item) => id === item.id && parseInt(item.sale_price))
        .filter((value) => value)
    );
    return { id: id, price: minPrice };
  });

  const newExcludeItems = excludeItems.map((item) => {
    const isShow = minPriceOfExcludeItems
      .map((value) => {
        if (item.id === value.id && parseInt(item.sale_price) === value.price) {
          return true;
        } else if (
          item.id === value.id &&
          parseInt(item.sale_price) !== value.price
        ) {
          return false;
        }
      })
      .filter((value) => value)[0];

    if (isShow) {
      return { ...item, is_show: true, is_selected: false };
    } else {
      return { ...item, is_show: false, is_selected: false };
    }
  });

  return minCase[0]["items"].concat(newExcludeItems);
}

// 날짜를 양식에 맞게 변환 (년-월-일)
export function convertDate(data) {
  const year = data.getFullYear();
  const month = data.getMonth() + 1;
  const date = data.getDate();
  return `${year}-${month >= 10 ? month : "0" + month}-${
    date >= 10 ? date : "0" + date
  }`;
}

// 날짜, 시간을 양식에 맞게 변환 (년-월-일 시:분:초)
export function convertDateTime(data) {
  const year = data.getFullYear();
  const month = data.getMonth() + 1;
  const date = data.getDate();
  const time = data.getHours();
  const hour = data.getMinutes();
  const second = data.getSeconds();
  return `${year}-${month >= 10 ? month : "0" + month}-${
    date >= 10 ? date : "0" + date
  } ${time >= 10 ? time : "0" + time}:${hour >= 10 ? hour : "0" + hour}:${
    second >= 10 ? second : "0" + second
  }`;
}

// 날짜, 시간을 한글 양식에 맞게 변환 (월 일 시:분까지)
export function convertDateTimeByKo(data) {
  const year = data.getFullYear();
  const month = data.getMonth() + 1;
  const date = data.getDate();
  const time = data.getHours();
  const hour = data.getMinutes();
  const second = data.getSeconds();
  return `${month >= 10 ? month : "0" + month}월 ${
    date >= 10 ? date : "0" + date
  }일 ${time >= 10 ? time : "0" + time}:${hour >= 10 ? hour : "0" + hour}까지`;
}

// 종료시간 카운트다운 (일 시간 후 종료)
export function countdownByKo(data) {
  const newDate = data - new Date();

  const time = data.getHours();

  const date = Math.floor(newDate / 1000 / 60 / 60 / 24);
  // const temp = newDate - date
  const elapsedHour = Math.floor(newDate / 1000 / 60 / 60) % 24;
  return `${date}일 ${elapsedHour}시간 후 종료`;
}

// 서버에서 가져온 데이터를 데이터 테이블에 보여줄 수 있도록 가공
export function preproccessingForDataTable(path, data) {
  let newData = [];

  if (Array.isArray(data) && !data.length) {
    return newData;
  }

  // 사용자 관리 > 사용자 리스트 페이지
  if (path === "/admin/users/user") {
    newData = data.map((value) => {
      return {
        ...value,
        phone: value.phone ? addDashToPhoneNumber(value.phone) : null,
      };
    });
  }

  // 사용자 관리 > 관리자 리스트 페이지
  if (path === "/admin/users/admin") {
    newData = data.map((value) => {
      return {
        ...value,
        phone: value.phone ? addDashToPhoneNumber(value.phone) : null,
        member_since: value.member_since
          ? convertDateTime(new Date(value.member_since))
          : null,
        accessible_menu:
          value.accessible_menu[0] === "*" && value.accessible_menu.length === 1
            ? "전체메뉴"
            : "",
        user_type: value.user_type === 0 ? "슈퍼 관리자" : "일반 관리자",
      };
    });
  }

  // 사용자 관리 > 사용자 정보 상세보기
  if (path.includes("/admin/users/user/show")) {
    newData = data.map((value) => {
      return {
        ...value,
        user_type: value.user_type === 2 && "일반 회원",
        phone: value.phone ? addDashToPhoneNumber(value.phone) : null,
        member_since: value.member_since
          ? convertDate(new Date(value.member_since))
          : null,
        last_login_date: value.last_login_date
          ? convertDate(new Date(value.last_login_date))
          : null,
        receive_sms: value.receive_sms ? "O" : "X",
        receive_email: value.receive_email ? "O" : "X",
      };
    });
  }

  // 상품관리 > 상품 조회
  if (path.includes("/admin/items/item")) {
    newData = data.map((value) => {
      return {
        ...value,
        created_at: value.created_at
          ? convertDate(new Date(value.created_at))
          : null,
        updated_at: value.updated_at
          ? convertDate(new Date(value.updated_at))
          : null,
        status: value.status,
        images: selectItemImages(value.images, value.color)[0],
        item_price: inputNumberWithComma(value.item_price),
        sale_price: inputNumberWithComma(value.sale_price),
      };
    });
  }

  // 주문관리 > 주문 조회
  if (path === "/admin/orders/order") {
    // 주문번호, 주문 상품번호 : 날짜+ 숫자 10자리수의 order_id
    const itemData = data.data;
    const status = data.status;
    if (Array.isArray(itemData) && !itemData.length) {
      return newData;
    }

    // 배송 처리
    if (status < 6) {
      newData = itemData.map((value) => {
        return {
          ...value,
          order_id:
            convertDate(new Date(value.order_date)).replaceAll("-", "") +
            fillZero(10, value.order_id),
          order_item_id:
            convertDate(new Date(value.order_date)).replaceAll("-", "") +
            fillZero(10, value.order_item_id),
          order_date: value.order_date
            ? convertDate(new Date(value.order_date))
            : null,
          item_price: inputNumberWithComma(value.item_price),
          state:
            orderState.orderState[value.state] === "결제완료"
              ? "신규주문"
              : orderState.orderState[value.state],
          delivery_state_date: value.state_record.hasOwnProperty(3)
            ? value.state_record[3]
            : "",
          state_date: value.state_record[value.state],
          payment_method:
            value.payment_method === "kakaopay"
              ? "카카오페이"
              : value.payment_method === "naverpay"
              ? "네이버페이"
              : "체크/신용카드 결제",
          shipment_number: value.shipment_number ? value.shipment_number : "",
          deliver_company: value.deliver_company ? value.deliver_company : "",
          shipping_fee: inputNumberWithComma(
            [
              ...new Set(
                itemData
                  .map((country) => {
                    if (value.order_id === country.order_id) {
                      return country.order_country;
                    }
                  })
                  .filter((value) => value)
              ),
            ].length * 10000
          ),
          total_price: inputNumberWithComma(
            sumOfItems(
              itemData.filter((price) => price.order_id === value.order_id)
            ) +
              [
                ...new Set(
                  itemData
                    .map((country) => {
                      if (value.order_id === country.order_id) {
                        return country.order_country;
                      }
                    })
                    .filter((value) => value)
                ),
              ].length *
                10000
          ),
        };
      });
    }
    // 취소 처리
    if (status === 6 || status === 7) {
      newData = itemData.map((value) => {
        return {
          ...value,
          order_id:
            convertDate(new Date(value.order_date)).replaceAll("-", "") +
            fillZero(10, value.order_id),
          order_item_id:
            convertDate(new Date(value.order_date)).replaceAll("-", "") +
            fillZero(10, value.order_item_id),
          state: orderState.orderState[value.state],
          cancel_accept_date: value.cancel_accept_date
            ? convertDate(new Date(value.cancel_accept_date))
            : null,
          item_price: inputNumberWithComma(value.item_price) + "원",
        };
      });
    }
    // 반품 처리
    if (status === 8 || status === 9) {
      newData = itemData.map((value) => {
        return {
          ...value,
          order_id:
            convertDate(new Date(value.order_date)).replaceAll("-", "") +
            fillZero(10, value.order_id),
          order_item_id:
            convertDate(new Date(value.order_date)).replaceAll("-", "") +
            fillZero(10, value.order_item_id),
          state: orderState.orderState[value.state],
          cancel_accept_date: value.cancel_accept_date
            ? convertDate(new Date(value.cancel_accept_date))
            : null,
        };
      });
    }
    // 교환처리
    if (status === 10 || status === 14) {
      newData = itemData.map((value) => {
        return {
          ...value,
          order_id:
            convertDate(new Date(value.order_date)).replaceAll("-", "") +
            fillZero(10, value.order_id),
          order_item_id:
            convertDate(new Date(value.order_date)).replaceAll("-", "") +
            fillZero(10, value.order_item_id),
          state: orderState.orderState[value.state],
          exchange_state: value.exchange_state
            ? orderState.orderState[value.exchange_state]
            : null,
          shipment_number: value.shipment_number ? value.shipment_number : "",
          deliver_company: value.deliver_company ? value.deliver_company : "",
        };
      });
    }
  }

  // 주문관리 > 주문 상세보기
  if (path === "/admin/orders/order/show") {
    // 주문번호, 주문 상품번호 : 날짜+ 숫자 10자리수의 order_id

    const itemPrice = sumOfItems(data.order_item_info);
    const usedPoint = data.order_item_info.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.point;
      },
      0
    );
    const country = [
      ...new Set(
        data.order_item_info.map((country) => {
          return country.order_country;
        })
      ),
    ];
    const shipmentFee = country.length * 10000;
    const cancelPrice = sumOfItems(
      data.order_item_info.filter(
        (value) => value.state === 7 || value.state === 9
      )
    );
    const itemCountByCountry = data.order_item_info
      .map((country) => {
        return country.order_country;
      })
      .reduce((cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {});
    const cancelItemCountByCountry = data.order_item_info
      .filter((value) => value.state === 7 || value.state === 9)
      .map((country) => {
        return country.order_country;
      })
      .reduce((cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {});
    const cacelShipmentFee =
      country.filter(
        (value) =>
          Object.keys(cancelItemCountByCountry).length &&
          itemCountByCountry[value] === cancelItemCountByCountry[value]
      ).length * 10000;
    const cancelPoint = data.order_item_info.reduce(
      (accumulator, currentValue) => {
        if (currentValue.state === 7 || currentValue.state === 9) {
          return accumulator + currentValue.point;
        }
        return accumulator;
      },
      0
    );

    const promotionCodeDiscountPrice = data.order_item_info.find(
      (item) => item.benefits_type === "item"
    )
      ? data.order_item_info.reduce((accumulator, currentValue) => {
          console.log("discount_price", currentValue.discount_price);
          return (
            accumulator +
            (currentValue.discount_price ? currentValue.discount_price : 0)
          );
        }, 0)
      : data.order_item_info[0].discount_price;

    const newOrderInfo = {
      ...data.order_info,
      order_id:
        convertDate(new Date(data.order_info.order_date)).replaceAll("-", "") +
        fillZero(10, data.order_info.order_id),
      order_date: data.order_info.order_date
        ? convertDate(new Date(data.order_info.order_date))
        : null,
      payment_method:
        data.order_info.payment_method === "kakaopay"
          ? "카카오페이"
          : data.order_info.payment_method === "naverpay"
          ? "네이버페이"
          : "체크/신용카드 결제",
      total_item_price: inputNumberWithComma(itemPrice),
      total_net_price: inputNumberWithComma(itemPrice),
      total_point: inputNumberWithComma(usedPoint, true),
      total_payment_item_price: inputNumberWithComma(
        itemPrice + usedPoint,
        true
      ),
      total_shipping_fee: inputNumberWithComma(shipmentFee),
      cancel_price: inputNumberWithComma(
        -(
          cancelPrice +
          cacelShipmentFee +
          cancelPoint -
          data.order_info.total_charge
        ),
        true
      ),
      total_promotioncode: promotionCodeDiscountPrice,
      total_price: inputNumberWithComma(
        itemPrice +
          usedPoint +
          shipmentFee -
          (cancelPrice +
            cacelShipmentFee +
            cancelPoint +
            promotionCodeDiscountPrice -
            data.order_info.total_charge),
        true
      ),
    };

    const newOrderItemInfo = data.order_item_info.map((value) => {
      return {
        ...value,
        order_item_id:
          convertDate(new Date(data.order_info.order_date)).replaceAll(
            "-",
            ""
          ) + fillZero(10, value.order_item_id),
        state:
          orderState.orderState[value.state] === "결제완료"
            ? "신규주문"
            : orderState.orderState[value.state],
        item_option: `사이즈: ${value.size} / 컬러: ${value.color}`,
        item_price: inputNumberWithComma(value["item_price"]),
        point: value.point ? value.point : 0,
        promotioncode:
          value.benefits_type === "item" ? value.discount_price : 0,
        net_price: inputNumberWithComma(value["item_price"]),
        payment_item_price: inputNumberWithComma(
          value["item_price"] +
            value["point"] -
            (value.benefits_type === "item" ? value.discount_price : 0)
        ),
      };
    });
    newData = [{ order_info: newOrderInfo, order_item_info: newOrderItemInfo }];
  }

  // 브랜드 관리 > 브랜드 조회
  if (path === "/admin/brands/brand") {
    newData = data.map((value) => {
      const newTaxRate = Object.keys(value.tax_rate).map((country) => {
        return `${country} : ${value.tax_rate[country]}`;
      });

      return {
        ...value,
        is_show: value.is_show ? "O" : "X",
        tax_rate: newTaxRate.join(", "),
      };
    });
  }

  // 브랜드 관리 > 브랜드 상세보기
  if (path === "/admin/brands/brand/show") {
    const newTaxRate = Object.keys(data.tax_rate).map((country) => {
      return `${country} : ${data.tax_rate[country]}`;
    });
    newData = [
      {
        ...data,
        is_show: data.is_show ? "O" : "X",
        tax_rate: newTaxRate.join(", "),
      },
    ];
  }

  // 메뉴관리
  if (path.includes("/admin/menu/")) {
    newData = data.map((value) => {
      return {
        ...value,
        is_show: value.is_show ? "O" : "X",
      };
    });
  }

  return newData;
}

// 국가별 정보 데이터 만들기
// {컬럼명: {프랑스:데이터, 스페인:데이터, 영국:데이터} , ... }
export function preproccessingOfCountryData(
  countryList,
  columns,
  rows,
  hasMultipleData
) {
  let countryDataArray = {};

  columns.map((column) => {
    countryDataArray[column.field] = {};
  });

  for (const data of Object.keys(countryDataArray)) {
    for (const row of rows) {
      if (hasMultipleData) {
        Object.keys(countryDataArray[data]).includes(row.country)
          ? countryDataArray[data][row.country].push(row[data])
          : (countryDataArray[data][row.country] = [row[data]]);
      } else {
        countryDataArray[data] = {
          ...countryDataArray[data],
          [row.country]: row[data],
        };
      }
    }
  }

  return countryDataArray;
}

// 엑셀 다운로드 데이터 만들기
export function createXlsxData(columns, rows, fileName) {
  // console.log(columns, rows);

  const newColumns = columns
    .map((value) => {
      if (value.headerName !== "checkBox" && value.headerName !== "actions") {
        return value.headerName;
      }
    })
    .filter((value) => value);

  const newRows = rows.map((row) => {
    let data = {};
    columns
      .map((column) => {
        if (
          column.headerName !== "checkBox" &&
          column.headerName !== "actions"
        ) {
          if (column.type === "json") {
            let status = "";
            Object.keys(row.status).map((key, index) => {
              status =
                status +
                column.content[key][row.status[key]].name +
                (Object.keys(row.status).length - 1 === index ? "" : "/");
            });
            data[column.field] = status;
          } else {
            data[column.field] = row[column.field];
          }
        }
      })
      .filter((value) => value);

    return data;
  });

  const worksheet = XLSX.utils.json_to_sheet(newRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

  XLSX.utils.sheet_add_aoa(worksheet, [newColumns], {
    origin: "A1",
  });

  XLSX.writeFile(workbook, `${fileName}_${Date.now()}.xlsx`);
}

// 상품 정보 수정할 때 db데이터를 수정 가능한 데이터 형태로 변환
export function convert_item_db_info(info) {
  // basicInfo : {국가 : {site : site}, ... }
  // commonInfo : {brand_name : brand_name, cateogry: category:, color : ["컬러명", ...],
  //               description: description, is_show: is_show, item_name: item_name, sku: sku }
  // country : ["국가", ...]
  // imageInfo {컬러명 : [이미지 파일명 or 이미지 파일 object, ...], ...}
  // priceInfo : {국가 : {currency: currency, origin_price: origin_price, origin_sale_price: origin_sale_price}, ...}
  // sizeInfo : {국가 : {available_stock: available_stock, color: color, id: id, size: size }, ...}

  const country = info.price_info.map((data) => {
    return data.country;
  });
  const color = [
    ...new Set(
      info.size_info.map((data) => {
        return data.color;
      })
    ),
  ];
  let basicInfo = {};
  for (let data of info.price_info) {
    basicInfo[data.country] = {
      site: data.site,
      is_stock: data.is_stock,
      is_discontinued: data.is_discontinued,
    };
  }
  const commonInfo = { ...info.common_info, color: color };
  let priceInfo = {};
  for (let data of info.price_info) {
    priceInfo[data.country] = {
      id: data.id,
      currency: data.currency,
      origin_price: data.origin_price,
      origin_sale_price: data.origin_sale_price,
    };
  }
  let sizeInfo = {};

  for (let data of country) {
    sizeInfo[data] = info.size_info.filter((info) => info.country == data);
  }

  let imageInfo = {};
  for (let colorName of color) {
    const imageList = selectItemImages(info.common_info.images, colorName);
    imageInfo[colorName] = imageList;
  }

  // console.log(country, basicInfo, commonInfo, priceInfo, sizeInfo, imageInfo);
  return {
    country: country,
    basicInfo: basicInfo,
    commonInfo: commonInfo,
    priceInfo: priceInfo,
    sizeInfo: sizeInfo,
    imageInfo: imageInfo,
    saleInfo: info.sale_info ? info.sale_info : {},
  };
}
