import {
  Box,
  Divider,
  ListItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { lightcoral } from "../../../common/style/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const SaleInfoForm = ({ newColumns, inputData, handleInputDataChange }) => {
  return (
    <Box>
      <ListItem style={{ backgroundColor: `${lightcoral}` }}>
        어드민 할인 정보
      </ListItem>

      <ListItem display="flex" margin="12px 0 ">
        <Typography minWidth="80px">할인 금액</Typography>
        <TextField
          variant="standard"
          type="number"
          name="price"
          value={inputData.saleInfo.price}
          onChange={(event) => {
            handleInputDataChange(event, "saleInfo", null, null, null);
          }}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <Typography>원</Typography>
      </ListItem>
      <ListItem display="flex" margin="12px 0 ">
        <Typography minWidth="80px">할인율</Typography>
        <TextField
          variant="standard"
          type="number"
          name="rate"
          value={inputData.saleInfo.rate}
          onChange={(event) => {
            handleInputDataChange(event, "saleInfo", null, null, null);
          }}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <Typography>%</Typography>
      </ListItem>
      <Divider />
      <ListItem>
        <Typography>기간 설정</Typography>
        <Switch
          name="hasDate"
          checked={inputData.saleInfo.hasDate}
          onChange={(event) => {
            handleInputDataChange(event, "saleInfo", null, null, null);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
      </ListItem>
      {inputData.saleInfo.hasDate ? (
        <ListItem sx={{ display: "block" }}>
          <Box display="flex" margin="12px 0 ">
            <Typography margin="0 8px 0 0 ">시작일</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DatePicker
                  views={["day"]}
                  label="시작일"
                  value={
                    inputData.saleInfo.start
                      ? dayjs(inputData.saleInfo.start)
                      : dayjs(new Date())
                  }
                  onChange={(newValue) => {
                    handleInputDataChange(
                      { target: { name: "start", value: newValue } },
                      "saleInfo",
                      null,
                      null,
                      null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                  format="YYYY-MM-DD"
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box display="flex" margin="12px 0 ">
            <Typography margin="0 8px 0 0 ">종료일</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DatePicker
                  // views={["day"]}
                  label="종료일"
                  value={
                    inputData.saleInfo.end
                      ? dayjs(inputData.saleInfo.end)
                      : dayjs(new Date())
                  }
                  onChange={(newValue) => {
                    handleInputDataChange(
                      { target: { name: "end", value: newValue } },
                      "saleInfo",
                      null,
                      null,
                      null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                  format="YYYY-MM-DD"
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </ListItem>
      ) : null}
    </Box>
  );
};

export default SaleInfoForm;
