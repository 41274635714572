import { Box, Typography } from "@mui/material";
import React from "react";
import { borderColor, BREAK_POINT_PC } from "../../../common/style/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";

const PagingButton = ({ color, handleClickButton }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <Box
      margin="8px 0"
      padding={windowsize.width >= BREAK_POINT_PC ? "2rem 0" : "1.2rem 0"}
      sx={{
        backgroundColor: `${color}`,
        cursor: "pointer",
        borderWidth: `${
          windowsize.width >= BREAK_POINT_PC ? "2px" : "1px 0 3px 0"
        }`,
      }}
      display="flex"
      justifyContent="center"
      border={
        windowsize.width >= BREAK_POINT_PC
          ? "2px solid black"
          : "1px solid " + borderColor
      }
      onClick={handleClickButton}
      width={windowsize.width >= BREAK_POINT_PC ? "40%" : "100%"}
      alignItems="center"
    >
      <Typography variant={windowsize.width >= BREAK_POINT_PC ? "h4" : "body2"}>
        더보기
      </Typography>
      <KeyboardArrowDownIcon
        style={{
          fontSize: `${windowsize.width >= BREAK_POINT_PC ? "3rem" : "1rem"}`,
        }}
      />
    </Box>
  );
};

export default PagingButton;
