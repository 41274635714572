import { Box, Checkbox, Divider, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../state/state";
import { BREAK_POINT_PC } from "../style/styles";

// 회원가입, 정보 수정 페이지의 동의 여부
const AgreeForm = ({ data, handleChangeInput, isEditing }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <Box
      display="flex"
      flexDirection="column"
      margin={windowSize.width >= BREAK_POINT_PC ? "3rem 0 0 0" : "1rem 0 0 0"}
      width="100%"
    >
      {windowSize.width >= BREAK_POINT_PC ? (
        <Typography
          fontWeight="600"
          variant="h5"
          component="p"
          margin="0 0 1rem 0"
        >
          동의하기
        </Typography>
      ) : (
        <Box>
          <Divider sx={{ borderWidth: "2px" }} />
          <Typography
            fontWeight="500"
            variant="subtitle2"
            component="p"
            margin="1rem"
          >
            동의하기
          </Typography>
          <Divider />
        </Box>
      )}
      <Box
        display={isEditing ? "none" : "flex"}
        margin={windowSize.width < BREAK_POINT_PC && "0 1rem"}
      >
        <Checkbox
          sx={{ padding: "9px 9px 9px 0" }}
          checked={data.totalTerms}
          color="primary"
          onChange={handleChangeInput}
          value={data.totalTerms}
          name="agree.totalTerms"
        />
        <Typography
          fontWeight="400"
          variant="subtitle2"
          component="p"
          padding="8px 0"
        >
          (필수) 약관 전제 동의
        </Typography>
      </Box>
      <Box
        display={isEditing ? "none" : "flex"}
        margin={windowSize.width < BREAK_POINT_PC && "0 1rem"}
      >
        <Checkbox
          sx={{ padding: "9px 9px 9px 0" }}
          checked={data.termsOfUse}
          color="primary"
          onChange={handleChangeInput}
          value={data.termsOfUse}
          name="agree.termsOfUse"
        />
        <Typography
          fontWeight="400"
          variant="subtitle2"
          component="p"
          padding="8px 0"
        >
          (필수) 이용약관 동의
        </Typography>
      </Box>
      <Box
        display={isEditing ? "none" : "flex"}
        margin={windowSize.width < BREAK_POINT_PC && "0 1rem"}
      >
        <Checkbox
          sx={{ padding: "9px 9px 9px 0" }}
          checked={data.personalInfo}
          color="primary"
          onChange={handleChangeInput}
          value={data.personalInfo}
          name="agree.personalInfo"
        />
        <Typography
          fontWeight="400"
          variant="subtitle2"
          component="p"
          padding="8px 0"
        >
          (필수) 개인정보 수집 및 이용 동의
        </Typography>
      </Box>
      <Box
        display="flex"
        margin={windowSize.width < BREAK_POINT_PC && "0 1rem"}
      >
        <Checkbox
          sx={{ padding: "9px 9px 9px 0" }}
          checked={data.marketing}
          color="primary"
          onChange={handleChangeInput}
          value={data.marketing}
          name="agree.marketing"
        />
        <Typography
          fontWeight="400"
          variant="subtitle2"
          component="p"
          padding="8px 0"
        >
          (선택) 마케팅 정보 활동 및 수신 동의
        </Typography>
      </Box>
    </Box>
  );
};

export default AgreeForm;
