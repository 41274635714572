import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { getDataFromServer } from "../../../common/network/network";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC } from "../../../common/style/styles";

const PromotionCodeInfo = ({ handlePromotionCodeButtonClick }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [promotionCode, setPromotionCode] = useState();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPromotionCode(value.toUpperCase());
  };

  return (
    <>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
        fontWeight="600"
        margin="0.8rem 1rem"
      >
        프로모션 코드
      </Typography>
      <Divider />
      <Box margin="0.8rem 1rem" display="flex">
        <TextField
          variant="outlined"
          style={{ flex: "11" }}
          inputProps={{
            style: {
              fontSize: windowSize.width >= BREAK_POINT_PC ? "16" : "12px",
              padding:
                windowSize.width >= BREAK_POINT_PC ? "0.5rem" : "0.5rem 0.3rem",
            },
          }}
          // fullWidth={true}
          size="small"
          value={promotionCode}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          size="small"
          style={{
            margin: "0 0 0 0.5rem",
            background: "black",
            color: "white",
            flex: "1",
          }}
          onClick={() => {
            handlePromotionCodeButtonClick(promotionCode);
          }}
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
            fontWeight="600"
          >
            적용
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default PromotionCodeInfo;
