import { Box, Button, Divider, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import React from "react";
import { convertDateTime } from "../../../common/js/common";
import { borderColor } from "@mui/system";
import { primary, secondary, StyledLink } from "../../../common/style/styles";
import { useLocation } from "react-router-dom";

const DataList = ({ submenu, data, handleDelete }) => {
  const { pathname } = useLocation();
  console.log(data);
  return (
    <>
      {/* 수정, 삭제 버튼 */}
      <Box display="flex" border={"1px solid " + borderColor} padding="8px">
        <Box>
          <StyledLink to={pathname.replace("show", "edit")}>
            <Button
              variant="outlined"
              style={{ borderRadius: "unset", margin: "0 8px" }}
            >
              {submenu === "faq" ? "FAQ" : "공지사항"} 수정
            </Button>
          </StyledLink>
          <Button
            variant="outlined"
            style={{ borderRadius: "unset", margin: "0 8px" }}
            onClick={handleDelete}
          >
            {submenu === "faq" ? "FAQ" : "공지사항"} 삭제
          </Button>
        </Box>
      </Box>
      {/* 제목 */}
      <Box
        margin="8px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h4">{data.title}</Typography>
          {data.is_banner ? (
            <Typography
              margin="0px 4px"
              border={`1px solid ${primary}`}
              padding="2px 8px"
              color={primary}
              borderRadius="4px"
            >
              배너
            </Typography>
          ) : null}
          {!data.is_show ? (
            <Typography
              margin="0 4px"
              border={`1px solid ${secondary}`}
              borderRadius="4px"
              padding="2px 8px"
              color={secondary}
            >
              비공개
            </Typography>
          ) : null}
        </Box>
        <Typography>{convertDateTime(new Date(data.created_at))}</Typography>
      </Box>
      <Divider />
      {/* 내용 */}
      <Box margin="8px">
        {data.is_banner ? (
          <Box margin="8px 0">
            <Typography variant="h6">배너 이미지</Typography>
            <img src={data.image} />
            <Divider />
          </Box>
        ) : null}
        <Box margin="8px 0">
          {ReactHtmlParser(
            data.content && data.content.replaceAll("<img", "<img width='100%'")
          )}
        </Box>
      </Box>
    </>
  );
};

export default DataList;
