import { Box, Divider, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDataFromServer, postDataFromServer } from "../network/network";
import QnAForm from "../components/qna_form";
import { CenterModalStyle } from "../style/styles";

const WriteQuestionModal = ({
  isOpened,
  handleModal,
  itemId,
  ItemOptionId,
}) => {
  const navigate = useNavigate();
  // 더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  const [question, setQuestion] = useState({
    title: "",
    content: "",
    qna_type: itemId ? 0 : 1,
    is_show: true,
    is_secret: false,
  });
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    getDataFromServer("/service/users/name")
      .then((response) => {
        setIsLogin(true);
      })
      .catch((error) => {
        alert("로그인 후 사용해주세요.");
        navigate("/login");
        return;
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setQuestion({
      ...question,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (hasClick) {
      return;
    }
    setHasClick(true);
    // 제목, 내용을 작성했는지 확인
    if (!question.title || !question.content) {
      alert("문의 내용을 작성해주세요.");
      setHasClick(false);
      return;
    }

    const newData = {
      ...question,
      item_id: itemId ? itemId : null,
      item_option_id: ItemOptionId ? ItemOptionId : null,
    };

    // 서버로 문의 내용 전송
    postDataFromServer("/service/qna", newData)
      .then((response) => {
        alert("문의가 접수되었습니다.");
        navigate(-1);
      })
      .catch((error) => {
        // 회원정보 없을 시 로그인 페이지로 이동
        alert("로그인 후 이용해주세요.");
        navigate("/login");
      });
  };

  return (
    <Modal open={isOpened} onClose={handleModal}>
      <Box sx={CenterModalStyle} width="50%">
        {isLogin && (
          <Box margin="8px">
            <Typography
              variant="h6"
              component="p"
              textAlign="center"
              margin="2.5rem 0 1rem 0"
              fontWeight="700"
            >
              문의하기
            </Typography>
            <QnAForm
              itemId={itemId}
              question={question}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              handleModal={handleModal}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default WriteQuestionModal;
