import { Box, Divider } from "@mui/material";
import React, { useState } from "react";
import MenuTab from "../../../common/components/menu_tab";
import defaultImg from "../../../media_assets/image/gallery1.jpg";

const SizeGuide = ({ sizeImages }) => {
  const menuList = [
    { id: 1, name: "남성 의류" },
    { id: 2, name: "여성 의류" },
  ];

  const [menu, setMenu] = useState(1);

  // 탭 변경
  const handleChange = (event, newValue) => {
    setMenu(newValue);
  };

  return (
    <div style={{ width: "100%", fontSize: "small" }}>
      {sizeImages.length ? (
        <Box>
          {sizeImages.map((value, index) => (
            <img
              key={index}
              style={{
                backgroundImage: `url(${defaultImg})`,
                width: "100%",
              }}
              src={value}
              srcSet={value}
              // loading="lazy"
            />
          ))}
        </Box>
      ) : (
        <Box>
          <MenuTab
            data={menuList}
            menu={menu}
            handleChange={handleChange}
            style={{ pc: { fontSize: "21pt" }, mobile: { fontSize: "10.5pt" } }}
          />
          {menu === 1 ? (
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                margin: "8px",
              }}
            >
              <tbody>
                <tr>
                  <th>구분</th>
                  <th>한국</th>
                  <th>영국</th>
                </tr>
                <tr>
                  <td>XS</td>
                  <td>85</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>S</td>
                  <td>90</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>M</td>
                  <td>95</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>L</td>
                  <td>100</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                margin: "8px",
              }}
            >
              <tbody>
                <tr>
                  <th>구분</th>
                  <th>한국</th>
                  <th>영국</th>
                </tr>
                <tr>
                  <td>XS</td>
                  <td>44(85)</td>
                  <td>4-6</td>
                </tr>
                <tr>
                  <td>S</td>
                  <td>55(90)</td>
                  <td>8-10</td>
                </tr>
                <tr>
                  <td>M</td>
                  <td>66(95)</td>
                  <td>10-12</td>
                </tr>
                <tr>
                  <td>L</td>
                  <td>77(100)</td>
                  <td>16-18</td>
                </tr>
              </tbody>
            </table>
          )}
        </Box>
      )}
    </div>
  );
};

export default SizeGuide;
