import { Box, Button } from "@mui/material";
import { borderColor } from "@mui/system";
import React from "react";
import { StyledLink } from "../../../common/style/styles";

const OptionHeader = ({ submenu, handleDelete }) => {
  return (
    <Box display="flex" border={"1px solid " + borderColor} padding="8px">
      <Box>
        <StyledLink to={`/admin/etc/${submenu}/create/-1`}>
          <Button
            variant="outlined"
            style={{ borderRadius: "unset", margin: "0 8px" }}
          >
            {submenu === "faq" ? "FAQ" : "공지사항"} 생성
          </Button>
        </StyledLink>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleDelete}
        >
          {submenu === "faq" ? "FAQ" : "공지사항"} 삭제
        </Button>
      </Box>
    </Box>
  );
};

export default OptionHeader;
