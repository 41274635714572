import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { info, lightgrey, StyledLink } from "../style/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ArrowUpward } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import defaultImg from "../../media_assets/image/gallery1.jpg";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import deliveryColde from "../json/delivery_code.json";
import { Typography } from "@material-ui/core";

const StyledFlag = styled.div`
  font-size: 10pt;
  padding: 4px;
  margin: 8px;
  border: ${({ color }) => color && `1px solid ${color}`};
  color: ${({ color }) => color && `${color}`};
  border-radius: 8px;
  text-align: center;
`;

const DataTable = ({
  listData,
  columns,
  rows,
  selectedIDs,
  handleChangeOrderby,
  handleClickCheckBox,
  handleDelete,
  handleInputInfoChange,
  handleDrag,
}) => {
  const { pathname } = useLocation();
  const [dragBtn, setDragBtn] = useState(false);

  const onClickDragBtn = () => {
    setDragBtn((prev) => !prev);
  };
  return (
    <Table width="100%" height="100%" size="small">
      <TableHead
        style={{
          backgroundColor: `${lightgrey}`,
          top: "0px",
          left: "0px",
          position: "sticky",
          zIndex: 110,
        }}
      >
        <TableRow>
          {columns.map((value, index) => (
            <TableCell
              key={index}
              style={{
                minWidth: `${value.width}px`,
                cursor: "pointer",
                position: value.field === "checkBox" ? "sticky" : "static",
                left: "0",
                background: `${lightgrey}`,
              }}
            >
              {value.field === "checkBox" ? (
                <Checkbox
                  checked={selectedIDs.includes(-1) ? true : false}
                  onChange={() => handleClickCheckBox(-1)}
                />
              ) : value.field === "drag" ? (
                <DragHandleIcon />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() =>
                    value.field !== "actions" &&
                    handleChangeOrderby(value.field, value.orderby)
                  }
                >
                  {value.headerName}
                  {value.standard && (
                    <>
                      {value.orderby === "desc" ? (
                        <ArrowDownwardIcon fontSize="small" color="secondary" />
                      ) : (
                        <ArrowUpward fontSize="small" color="secondary" />
                      )}
                    </>
                  )}
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.id}
            draggable={dragBtn}
            onDragStart={(event) => {
              handleDrag(event, row.id);
            }}
            onDragEnd={(event) => {
              handleDrag(event, row.id);
              onClickDragBtn();
            }}
            onDragOver={(event) => {
              handleDrag(event, row.id);
            }}
          >
            {columns.map((value, index) => (
              <TableCell
                key={index}
                style={{
                  minWidth: `${value.width}px`,
                  position: value.field === "checkBox" ? "sticky" : "static",
                  left: "0",
                  background: "white",
                }}
              >
                {value.type === "image" ? (
                  <img
                    style={{
                      backgroundImage: `url(${defaultImg})`,
                    }}
                    src={row.images}
                    width={value.width + "px"}
                  />
                ) : value.field === "checkBox" ? (
                  <Checkbox
                    checked={selectedIDs.includes(row.id) ? true : false}
                    onChange={() => handleClickCheckBox(row.id)}
                  />
                ) : value.field === "drag" ? (
                  <Box
                    sx={{ cursor: "pointer" }}
                    width="100%"
                    onMouseDown={onClickDragBtn}
                  >
                    <DragHandleIcon />
                  </Box>
                ) : value.type === "input" ? (
                  <TextField
                    size="small"
                    value={row[value.field]}
                    name={value.field}
                    onChange={(event) => {
                      handleInputInfoChange(event, row.id);
                    }}
                  />
                ) : value.type === "open_link" ? (
                  <a
                    target="_blank"
                    href={
                      row.deliver_company === "LX판토스"
                        ? `https://www.epantos.com/ecp/web/pr/dt/popup/dlvChaseInqPopup.do?locale=ko&quickNo=${row.shipment_number}`
                        : `https://www.acieshop.com/pod.php?OrderNo=${row.shipment_number}`
                    }
                  >
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 0,
                        color: "white",
                        background: "black",
                        margin: "0 0.4rem",
                      }}
                      type="submit"
                    >
                      <Typography variant="body1">배송조회</Typography>
                    </Button>
                  </a>
                ) : value.type === "link" ? (
                  <a target="_blank" href={row[value.field]}>
                    이동
                  </a>
                ) : value.type === "select" ? (
                  <FormControl variant="standard">
                    <InputLabel>선택</InputLabel>
                    <Select
                      name={value.field}
                      value={row[value.field]}
                      onChange={(event) => {
                        handleInputInfoChange(event, row.id);
                      }}
                      label="filter"
                    >
                      {value.option.map((option, index) => (
                        <MenuItem key={index} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : value.field === "actions" ? (
                  !pathname.includes("curation/edit") && (
                    <Box display="flex" justifyContent="space-between">
                      <StyledLink
                        to={
                          pathname.includes("items")
                            ? `${pathname}/show/${row.item_id}/${row.item_detail_id}/${row.item_option_id}`
                            : `${pathname}/show/${row.id}`
                        }
                        state={{
                          listData: listData,
                        }}
                      >
                        <Button variant="outlined" color="secondary">
                          보기
                        </Button>
                      </StyledLink>
                      {!pathname.includes("user") &&
                        !pathname.includes("order") && (
                          <StyledLink
                            to={
                              pathname.includes("items")
                                ? `${pathname}/edit/${row.item_id}/${row.item_detail_id}/${row.item_option_id}`
                                : `${pathname}/edit/${row.id}`
                            }
                          >
                            <Button variant="outlined" color="secondary">
                              수정
                            </Button>
                          </StyledLink>
                        )}
                      {!pathname.includes("order") && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            handleDelete("table", row.id);
                          }}
                        >
                          삭제
                        </Button>
                      )}
                    </Box>
                  )
                ) : (
                  row[value.field]
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DataTable;
