import React, { useState, useEffect } from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import { primary, secondary } from "../style/styles";

const StyledButton = styled(Button)`
  && {
    color: ${({ selected }) => (selected ? "white" : "black")};
    background: ${({ selected }) => (selected ? "black" : "white")};
    border-radius: ${({ selected }) => (selected ? "2rem" : "0px")};
    padding: ${({ padding }) => padding};
    font-weight: ${({ selected }) => (selected ? "500" : "400")};
    font-size: ${({ fontSize }) => fontSize};
    margin: ${({ margin }) => margin};
    &:hover {
      color: ${({ selected }) => (selected ? "white" : "black")};
      background: ${({ selected }) => (selected ? "black" : "white")};
    }
  }
`;

const MenuButton = ({ menuList, selectedMenu, handleChange, style }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={style.justifyContent}
      width="100%"
    >
      {menuList.map((menu) => (
        <StyledButton
          key={menu.id}
          value={menu.id}
          selected={selectedMenu == menu.id ? true : false}
          padding={style.padding}
          maxHeight={style.maxHeight}
          fontSize={style.fontSize}
          margin={style.margin}
          onClick={handleChange}
        >
          {menu.name}
        </StyledButton>
      ))}
    </Box>
  );
};

export default MenuButton;
