import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  borderColor,
  BREAK_POINT_MOBILE,
  primary,
} from "../../../common/style/styles";
import inquiryData from "../../../common/json/inquiry_condition.json";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import ClearIcon from "@mui/icons-material/Clear";
import { convertDate } from "../../../common/js/common";
import { getDataFromServer } from "../../../common/network/network";

const Container = styled.div`
  margin: 16px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  margin: 8px;
  border: 1px solid ${primary};
  border-radius: unset;
  background-color: white;
  color: ${primary};
  padding: 8px 16px;
  cursor: pointer;
`;

const SearchHeader = ({
  condition,
  handleChangeInquiry,
  handleClickInquiry,
}) => {
  // console.log(condition);
  const { pathname } = useLocation();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  // 조회 조건 선택 값
  const [filter, setFilter] = useState("");
  // 조회 값 입력 값
  const [search, setSearch] = useState("");
  // 조회 기간 조건 선택 값
  const [filterDate, setFilterDate] = useState("");
  // 조화 기간 범위
  const [date, setDate] = useState({
    startAt: new Date(),
    endAt: new Date(),
  });
  // 조회 조건 select 브랜드 리스트
  const [selectList, setSelectList] = useState([]);

  // 조회기간 변경
  const handleDateChange = (name, value) => {
    // console.log(name, value);
    setDate({ ...date, [name]: value });
  };

  // 조회조건 변경 (조회값 초기화)
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setSearch("");
  };

  // 조회 기간 조건 변경
  const handleFilterDateChange = (event) => {
    setFilterDate(event.target.value);
  };

  // 조회값 변경
  const handleSearchWordChange = (event) => {
    setSearch(event.target.value);
  };

  // 조회조건 버튼 클릭
  const handleAddButtonClick = (condition) => {
    switch (condition) {
      case "filter":
        if (!filter) {
          alert("조회조건을 선택해주세요.");
          return;
        }

        if (!search) {
          alert("조회값을 입력해주세요.");
          return;
        }

        handleChangeInquiry(
          "add",
          condition,
          {
            id: Date.now(),
            field: {
              name: filter.split(":")[1],
              value: filter.split(":")[0],
            },
            data: {
              name: search.split(":")[1],
              value: search.split(":")[0],
            },
          },
          null
        );
        break;
      case "date":
        if (!filterDate) {
          alert("조회 기간 조건을 선택해주세요.");
          return;
        }

        // 종료일이 시작일보다 앞이면 return
        if (new Date(date.startAt) > new Date(date.endAt)) {
          alert("시작일은 종료일보다 이후의 날짜로 설정할 수 없습니다. ");
          return;
        }

        handleChangeInquiry(
          "add",
          condition,
          {
            id: Date.now(),
            field: {
              name: filterDate.split(":")[1],
              value: filterDate.split(":")[0],
            },
            data: {
              name: `${convertDate(date.startAt)} ~ ${convertDate(
                date.endAt
              )} `,
              value: {
                startAt: convertDate(date.startAt),
                endAt: convertDate(date.endAt),
              },
            },
          },
          null
        );

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!selectList.length) {
      getDataFromServer("/service/brands?token=null").then((response) =>
        setSelectList([...response.brand_list])
      );
    }
  }, []);

  return (
    <Box
      border={"1px solid " + borderColor}
      margin="16px 0"
      padding="8px"
      width="100%"
    >
      <Box display="flex">
        <Box>
          {/* 조회 조건 */}
          <Container style={{ minWidth: "480px" }}>
            <FormControl variant="standard" sx={{ minWidth: 120, flex: 1 }}>
              <InputLabel>조회조건</InputLabel>
              <Select
                label="filter"
                value={filter}
                onChange={handleFilterChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {inquiryData[pathname].condition.map((value) => (
                  <MenuItem
                    key={value.field}
                    value={value.field + ":" + value.headerName}
                  >
                    {value.headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {inquiryData[pathname].condition.map(
              (value) =>
                value.field + ":" + value.headerName === filter &&
                (value.type === "string" ? (
                  <TextField
                    label="조회값"
                    variant="standard"
                    name="search"
                    value={search}
                    sx={{ margin: "0 16px", flex: 1 }}
                    onChange={handleSearchWordChange}
                  />
                ) : (
                  <Box sx={{ margin: "0 16px", flex: 1 }}>
                    <FormControl
                      variant="standard"
                      sx={{ minWidth: 200, flex: 1 }}
                    >
                      <InputLabel>선택</InputLabel>
                      <Select
                        label="filter"
                        value={search}
                        onChange={handleSearchWordChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {value.field === "brand_name"
                          ? selectList.map((select) => (
                              <MenuItem value={select.id + ":" + select.name}>
                                {select.name}
                              </MenuItem>
                            ))
                          : value.option.map((option) => (
                              <MenuItem value={option}>{option}</MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Box>
                ))
            )}

            <StyledButton
              onClick={() => {
                handleAddButtonClick("filter");
              }}
            >
              조회 조건 추가
            </StyledButton>
          </Container>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box display="flex">
          {/* 조회 기간 */}
          <Container>
            <FormControl
              variant="standard"
              sx={{ minWidth: 120, flex: 1, margin: "0 8px" }}
            >
              <InputLabel>조회기간</InputLabel>
              <Select
                label="filterDate"
                value={filterDate}
                onChange={handleFilterDateChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {inquiryData[pathname].date.map((value) => (
                  <MenuItem
                    key={value.field}
                    value={value.field + ":" + value.headerName}
                  >
                    {value.headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {windowSize > BREAK_POINT_MOBILE ? (
                <MobileDatePicker
                  label="시작일"
                  format="yyyy/MM/dd"
                  name="startAt"
                  value={date.endAt}
                  onChange={(value) => {
                    handleDateChange("startAt", value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              ) : (
                <DesktopDatePicker
                  label="시작일"
                  format="yyyy/MM/dd"
                  name="startAt"
                  value={date.startAt}
                  onChange={(value) => {
                    handleDateChange("startAt", value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
              <Typography padding="0 8px">~</Typography>
              {windowSize > BREAK_POINT_MOBILE ? (
                <MobileDatePicker
                  label="종료일"
                  format="yyyy/MM/dd"
                  name="endAt"
                  value={date.endAt}
                  onChange={(value) => {
                    handleDateChange("endAt", value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              ) : (
                <DesktopDatePicker
                  label="종료일"
                  format="yyyy/MM/dd"
                  name="endAt"
                  value={date.endAt}
                  onChange={(value) => {
                    handleDateChange("endAt", value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </LocalizationProvider>
            <StyledButton
              onClick={() => {
                handleAddButtonClick("date");
              }}
            >
              조회 조건 추가
            </StyledButton>
          </Container>
        </Box>
      </Box>
      {/* 필터 조건 나열 */}
      <Box padding="8px">
        {/* 조회 조건 */}
        <Box display="flex">
          {condition.filter.length ? (
            condition.filter.map((value) => (
              <Box
                key={value.id}
                padding="8px"
                margin="8px"
                border={"1px solid " + borderColor}
                borderRadius="8px"
                width="max-content"
                display="flex"
                alignItems="center"
              >
                <Typography variant="caption">{value.field.name} :</Typography>
                <Typography variant="caption">
                  {value.data.name ? value.data.name : value.data.value}
                </Typography>
                <ClearIcon
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleChangeInquiry("delete", "filter", null, value.id)
                  }
                />
              </Box>
            ))
          ) : (
            <Box></Box>
          )}
        </Box>
        {/* 조회 기간 */}
        <Box display="flex">
          {condition.date.length ? (
            condition.date.map((value) => (
              <Box
                key={value.id}
                padding="8px"
                margin="8px"
                border={"1px solid " + borderColor}
                borderRadius="8px"
                width="max-content"
                display="flex"
                alignItems="center"
              >
                <Typography variant="caption">{value.field.name} :</Typography>
                <Typography variant="caption">
                  {value.data.name ? value.data.name : value.data.value}
                </Typography>
                <ClearIcon
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleChangeInquiry("delete", "date", null, value.id)
                  }
                />
              </Box>
            ))
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
      <Button
        variant="contained"
        style={{ borderRadius: "unset", color: "white", margin: "0 8px" }}
        onClick={handleClickInquiry}
      >
        조회하기
      </Button>
    </Box>
  );
};

export default SearchHeader;
