import { Box, Button } from "@mui/material";
import React from "react";
import { StyledLink } from "../../../common/style/styles";
import { borderColor } from "../../../common/style/styles";

const OptionHeader = ({
  handleChangePaging,
  handleExportExcel,
  handleDelete,
}) => {
  return (
    <Box display="flex" border={"1px solid " + borderColor} padding="8px">
      <Box>
        <StyledLink to={"/admin/items/item/create/-1"}>
          <Button
            variant="outlined"
            style={{ borderRadius: "unset", margin: "0 8px" }}
          >
            상품 수기 등록
          </Button>
        </StyledLink>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={() => handleDelete("checkBox")}
        >
          선택 삭제
        </Button>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={() => handleChangePaging(100)}
        >
          100건씩 보기
        </Button>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={() => handleChangePaging(500)}
        >
          500건씩 보기
        </Button>
      </Box>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={handleExportExcel}
      >
        엑셀 내려받기
      </Button>
      <a href="https://mpick.s3.ap-northeast-2.amazonaws.com/mpick/ep/items.tsv">
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
        >
          네이버 쇼핑 노출 상품 tsv 파일 내려받기
        </Button>
      </a>
    </Box>
  );
};

export default OptionHeader;
