import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useMutation } from "react-query";
import {
  convertDateTime,
  selectImageThumbnail,
} from "../../../common/js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import {
  borderColor,
  BREAK_POINT_PC,
  StyledLink,
} from "../../../common/style/styles";
import { useRecoilState } from "recoil";
import {
  itemListTimeStampState,
  selectedMenuState,
  windowSizeState,
} from "../../../common/state/state";
import AddIcon from "@mui/icons-material/Add";
import HorizontalItemList from "./horizontal_item_list";
import SlideItemList from "./slide_item_list";
import LookBookItemList from "./look_book_item_list";
import ItemList from "./item_list";

const CurationList = () => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [selectedMenu, setSelectedMenu] = useRecoilState(selectedMenuState);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );
  // 메뉴에 해당하는 큐레이션 리스트
  const [curationList, setCurationList] = useState([]);
  // 아이템 리스트
  const [itemList, setItemList] = useState({});
  // 아이템 찜 리스트
  const [likeItemList, setLikeItemList] = useState([]);
  // 룩북 이미지 - 아이템 관계
  const [lookbookImages, setLookbookImages] = useState({});

  const menuMutation = useMutation("menuData", () => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    return getDataFromServer(`/service/curations/${selectedMenu}`);
  });

  const itemListMutation = useMutation("itemListData", {
    mutationFn: (curation) => {
      const nonMemberToken = localStorage.getItem("nonMemberToken");
      return getDataFromServer(
        `/service/curation/${curation.id}?token=${
          nonMemberToken == "undefined" ? null : nonMemberToken
        }&timestamp=${itemListTimeStamp}&type=main&device=${
          windowSize.width >= BREAK_POINT_PC ? "pc" : "mobile"
        }`
      );
    },
    onSuccess: (data, error, variables, context) => {
      let newItemList = data.item_list;
      if (data.look_book_info) {
        let tempObject = lookbookImages;
        for (const info of data.look_book_info) {
          if (tempObject.hasOwnProperty(info.image)) {
            tempObject[info.image].push(info.item_option_id);
          } else {
            tempObject[info.image] = [info.item_option_id];
          }
        }

        newItemList.lookbook = tempObject;
        setLookbookImages((prev) => {
          return { ...prev, [data.curation_name]: tempObject };
        });
      }
      setItemList((prev) => {
        return { ...prev, [data.curation_name]: data.item_list };
      });
      setLikeItemList((prev) => {
        return [...prev, ...data.like_items];
      });
    },
  });

  // 선택된 메뉴탭에 따라 큐레이션 리스트 가져오기
  // 아이템 리스트 및 찜 리스트 초기화
  useEffect(() => {
    if (selectedMenu == -1) {
      return;
    }

    menuMutation.mutate(selectedMenu, {
      onSuccess: (data, variables, context) => {
        setCurationList(data.curation_list);
        setItemList({});
        setLikeItemList([]);
        setLookbookImages({});
      },
      onError: (error, variable, context) => {
        console.log(error);
        // error
      },
    });
  }, [selectedMenu]);

  // 큐레이션별 상품 리스트 뷸러오기
  useEffect(() => {
    if (curationList.length > 0) {
      curationList.forEach((curation) => {
        itemListMutation.mutate(curation);
      });
    }
  }, [curationList]);

  // 좋아요 변경 적용
  const handleChangeLikeItemList = (event, state, item_option_id) => {
    event.preventDefault();
    // console.log("handleChangeLikeItemList", state, item_option_id);
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    if (state == "add") {
      postDataFromServer("/service/likes", {
        token: nonMemberToken === "undefined" ? null : nonMemberToken,
        table_name: "item_options",
        table_id: item_option_id.toString(),
      }).then((response) => {
        // console.log(response);
        setLikeItemList([...likeItemList, item_option_id]);
        // 비회원 토큰 정보가 없을경우 토큰정보 로컬에 저장
        if (response["token"] !== "null") {
          localStorage.setItem("nonMemberToken", response["token"]);
        }
      });
    } else if (state == "delete") {
      deleteDataFromServer(
        `/service/likes/-1?token=${nonMemberToken}&table_name=item_options&table_id=${item_option_id.toString()}`
      ).then((response) => {
        // console.log(response);
        setLikeItemList([
          ...likeItemList.filter((value) => value !== item_option_id),
        ]);
      });
    }
  };

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <Box>
      {curationList.length
        ? curationList.map((value) => (
            <Box
              key={value.id}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography
                fontWeight="600"
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "h5" : "subtitle1"
                }
                textAlign={
                  windowSize.width >= BREAK_POINT_PC ? "left" : "center"
                }
                margin={
                  windowSize.width >= BREAK_POINT_PC
                    ? "3rem 0 2.8rem 1rem"
                    : "2rem 0 1.7rem 0.25rem"
                }
              >
                {value.name}
              </Typography>
              {Object.keys(itemList).length
                ? Object.keys(itemList).map(
                    (data) =>
                      data === value.name && (
                        <Box>
                          {/* 모바일 버전 */}
                          {windowSize.width < BREAK_POINT_PC ? (
                            itemList[data].length ? (
                              // 이벤트 유형 1
                              itemList[data][0].curation_type === 1 ? (
                                <Box>
                                  <ItemList
                                    items={selectImageThumbnail(itemList[data])}
                                    likeItemList={likeItemList}
                                    handleChangeLikeItemList={
                                      handleChangeLikeItemList
                                    }
                                  />
                                </Box>
                              ) : // 이벤트 유형 2,4
                              itemList[data][0].curation_type === 2 ||
                                itemList[data][0].curation_type === 4 ? (
                                <Box>
                                  <HorizontalItemList
                                    items={selectImageThumbnail(itemList[data])}
                                  />
                                </Box>
                              ) : // 이벤트 유형 3
                              itemList[data][0].curation_type === 3 ? (
                                <Box>
                                  <LookBookItemList
                                    items={selectImageThumbnail(itemList[data])}
                                    lookbookImages={lookbookImages[value.name]}
                                  />
                                </Box>
                              ) : null
                            ) : (
                              // 이벤트에 상품 없음
                              <Box width="100%">
                                <Box
                                  margin="auto 0"
                                  textAlign="center"
                                  padding="20% 0"
                                >
                                  <Typography variant="subtitle2">
                                    상품이 존재하지 않습니다.
                                  </Typography>
                                </Box>
                              </Box>
                            )
                          ) : // pc 버전
                          itemList[data].length ? (
                            itemList[data][0].curation_type === 1 ||
                            itemList[data][0].curation_type === 4 ? (
                              <Box>
                                <ItemList
                                  items={selectImageThumbnail(itemList[data])}
                                  likeItemList={likeItemList}
                                  handleChangeLikeItemList={
                                    handleChangeLikeItemList
                                  }
                                />
                              </Box>
                            ) : itemList[data][0].curation_type === 2 ? (
                              <Box>
                                <SlideItemList
                                  items={selectImageThumbnail(itemList[data])}
                                />
                              </Box>
                            ) : itemList[data][0].curation_type === 3 ? (
                              <Box>
                                <LookBookItemList
                                  items={selectImageThumbnail(itemList[data])}
                                  lookbookImages={lookbookImages[value.name]}
                                />
                              </Box>
                            ) : null
                          ) : (
                            // 이벤트에 상품 없음
                            <Box width="100%">
                              <Box
                                margin="auto 0"
                                textAlign="center"
                                padding="20% 0"
                              >
                                <Typography variant="h5">
                                  상품이 존재하지 않습니다.
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          {/* 더보기 */}
                          {itemList[data].length
                            ? itemList[data][0].curation_type !== 3 && (
                                <StyledLink to={"/curation/" + value.id}>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={
                                      windowSize.width >= BREAK_POINT_PC
                                        ? "2rem 0 "
                                        : "1rem 0 0 0"
                                    }
                                  >
                                    <Box
                                      width={
                                        windowSize.width >= BREAK_POINT_PC
                                          ? "660px"
                                          : "100%"
                                      }
                                      height={
                                        windowSize.width >= BREAK_POINT_PC
                                          ? "140px"
                                          : "56px"
                                      }
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      border={
                                        windowSize.width >= BREAK_POINT_PC
                                          ? `2px solid ${borderColor}`
                                          : `1px solid ${borderColor}`
                                      }
                                      sx={{
                                        borderWidth: ` ${
                                          windowSize.width >= BREAK_POINT_PC
                                            ? "2px 2px 2px 2px"
                                            : "1px 0 8px 0 "
                                        }
                        `,
                                      }}
                                    >
                                      <AddIcon
                                        style={{
                                          fontSize:
                                            windowSize.width >= BREAK_POINT_PC
                                              ? "2rem"
                                              : "1rem",
                                        }}
                                      />
                                      <Typography
                                        variant={
                                          windowSize.width >= BREAK_POINT_PC
                                            ? "h6"
                                            : "body1"
                                        }
                                        fontWeight="500"
                                        margin="0 0 0 4px"
                                      >
                                        더보기
                                      </Typography>
                                    </Box>
                                  </Box>
                                </StyledLink>
                              )
                            : null}
                        </Box>
                      )
                  )
                : null}
            </Box>
          ))
        : null}
    </Box>
  );
};

export default CurationList;
