import {
  Box,
  Button,
  Checkbox,
  FormControl,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../state/state";
import { borderColor, BREAK_POINT_PC } from "../style/styles";

// QnA 작성 양식
const QnAForm = ({
  itemId,
  question,
  handleInputChange,
  handleSubmit,
  handleModal,
}) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const navigate = useNavigate();
  return (
    <Box margin="0 16px">
      <Box margin="16px 0">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          marginBottom="0.3rem"
        >
          문의 제목
        </Typography>
        <TextField
          placeholder="문의 제목을 입력해주세요."
          variant="outlined"
          fullWidth={true}
          name="title"
          value={question.title}
          onChange={handleInputChange}
        />
      </Box>
      <Box margin="16px 0">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          marginBottom="0.3rem"
        >
          문의 내용
        </Typography>
        <TextField
          placeholder="문의 내용을 입력해주세요."
          variant="outlined"
          multiline
          rows={10}
          fullWidth={true}
          name="content"
          value={question.content}
          onChange={handleInputChange}
        />
      </Box>
      <Box
        display={windowsize.width >= BREAK_POINT_PC ? "flex" : "block"}
        alignItems="center"
        justifyContent="space-between"
        margin="1rem 0"
      >
        {itemId && windowsize.width >= BREAK_POINT_PC ? (
          <FormControl>
            <Select
              name="is_secret"
              value={question.is_secret}
              onChange={handleInputChange}
            >
              <MenuItem name="is_secret" value={true}>
                공개
              </MenuItem>
              <MenuItem name="is_secret" value={false}>
                비공개
              </MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={question.is_secret}
              color="primary"
              onChange={handleInputChange}
              value={question.is_secret}
              name="is_secret"
              sx={{ padding: "4px 4px 4px 0" }}
            />
            <Typography>비공개로 전환</Typography>
          </Box>
        )}
        <Box marginTop={windowsize.width >= BREAK_POINT_PC ? "0" : "3rem"}>
          <Button
            variant="outlined"
            style={{
              marginRight: "16px",
              color: "black",
              borderColor: "black",
              borderRadius: windowsize.width >= BREAK_POINT_PC ? "4px" : "0",
            }}
            onClick={() => {
              windowsize.width >= BREAK_POINT_PC ? handleModal() : navigate(-1);
            }}
          >
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
              }
              fontWeight="700"
              padding="0.5rem 2rem"
            >
              취소
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              background:
                question.title && question.content ? "black" : borderColor,
              color: "white",
              borderRadius: windowsize.width >= BREAK_POINT_PC ? "4px" : "0",
            }}
            onClick={handleSubmit}
          >
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
              }
              fontWeight="700"
              padding="0.5rem 2rem"
            >
              등록
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default QnAForm;
