import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { borderColor, lightcoral } from "../../../common/style/styles";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const CommonInfoForm = ({ newColumns, inputData, handleInputDataChange }) => {
  const [tempColor, setTempColor] = useState("");
  const [hasOption, setHasOption] = useState(false);
  const [htmlValue, setHtmlValue] = useState("");

  // 에디터 입력값 변경
  useEffect(() => {
    handleInputDataChange(
      {
        target: {
          name: "description",
          value: htmlValue,
        },
      },
      "commonInfo"
    );
  }, [htmlValue]);

  // tempColor 입력값 변경
  useEffect(() => {
    console.log(
      newColumns.commonInfo.find((value) => value.column === "brand_name")
        .option
    );
    setHasOption(
      newColumns.commonInfo.find((value) => value.column === "brand_name")
        .option.length > 0
        ? true
        : false
    );
  }, [newColumns]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setTempColor(value);
  };

  return (
    <Box>
      <ListItem style={{ backgroundColor: `${lightcoral}` }}>
        기본 정보
      </ListItem>
      <Divider />
      {newColumns.commonInfo.map((value) => (
        <ListItem
          key={value.column}
          sx={{ flexDirection: "column" }}
          width="100%"
        >
          <Box display="flex" width="100%" alignItems="center">
            <Typography component="p" minWidth="150px">
              {value.name}
            </Typography>
            {value.type === "string" && (
              <TextField
                variant="standard"
                fullWidth={true}
                name={value.column}
                value={inputData.commonInfo[value.column]}
                onChange={(event) => {
                  handleInputDataChange(event, "commonInfo");
                }}
              />
            )}
            {value.type === "editor" && (
              <Box width="100%" height="200px" overflow="scroll">
                <ReactQuill
                  theme="snow"
                  name={value.column}
                  value={htmlValue || inputData.commonInfo[value.column]}
                  onChange={setHtmlValue}
                />
              </Box>
            )}
            {value.type === "select" && (
              <FormControl variant="standard" fullWidth={true}>
                {/* <Typography>
                  {inputData.commonInfo[value.column].toString()}
                </Typography> */}
                <InputLabel>{value.name}</InputLabel>
                <Select
                  name={value.column}
                  value={
                    value.option.length > 0
                      ? inputData.commonInfo[value.column]
                      : ""
                  }
                  onChange={(event) => {
                    handleInputDataChange(event, "commonInfo");
                  }}
                  label="filter"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {value.option.length > 0
                    ? value.option.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            )}
            {value.type === "multiple" && (
              <Box width="100%" margin="8px 0">
                {/* 추가한 컬러 리스트 */}
                <Box display="flex">
                  {inputData.commonInfo.color &&
                    inputData.commonInfo.color.map((color) => (
                      <Box
                        key={color}
                        padding="8px"
                        margin="8px"
                        border={"1px solid " + borderColor}
                        borderRadius="8px"
                        width="max-content"
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="caption">{color}</Typography>
                        <ClearIcon
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleInputDataChange(
                              { target: { name: "color", value: color } },
                              "commonInfo",
                              "delete"
                            );
                          }}
                        />
                      </Box>
                    ))}
                </Box>
                {/* 컬러 추가 */}
                <Box display="flex">
                  <TextField
                    label={value.name}
                    variant="standard"
                    fullWidth={true}
                    name={value.column}
                    value={tempColor}
                    onChange={handleChange}
                  />
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "unset",
                      margin: "0 8px",
                      width: "150px",
                    }}
                    onClick={() => {
                      handleInputDataChange(
                        {
                          target: {
                            name: "color",
                            value: tempColor,
                          },
                        },
                        "commonInfo",
                        "add"
                      );
                    }}
                  >
                    컬러 추가
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Divider sx={{ width: "100%" }} />
        </ListItem>
      ))}
    </Box>
  );
};

export default CommonInfoForm;
