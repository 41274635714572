import {
  Box,
  Button,
  InputBase,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteDataFromServer,
  getDataFromServer,
} from "../../../common/network/network";
import MainMenu from "../../../common/components/main_menu";
import OptionHeader from "../components/option_header";
import {
  borderColor,
  lightgrey,
  primary,
  secondary,
} from "../../../common/style/styles";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import DataTable from "../../../common/components/data_table";
import {
  createXlsxData,
  orderbyRows,
  preproccessingForDataTable,
} from "../../../common/js/common";
import columnData from "../../../common/json/columns.json";
import inquiryCondition from "../../../common/json/inquiry_condition.json";
import { TailSpin } from "react-loader-spinner";

const Index = () => {
  const { pathname } = useLocation();
  const { mainMenu, subMenu } = useParams();
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  // 테이블 행 데이터
  const [columns, setColumns] = useState([...columnData[pathname]]);
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  // 총 데이터 갯수
  const [count, setCount] = useState(0);
  // 현재페이지, 마지막페이지 정보
  const [page, setPage] = useState({ total: 1, current: 1 });
  // url 파라미터
  const [params, setParams] = useState({
    limit: subMenu === "admin" ? 100 : 15,
    offset: 0,
    orderby: "id desc",
    filter: null,
  });
  // 조회 조건
  const [filter, setFilter] = useState("");
  // 조회 값
  const [search, setSearch] = useState("");
  // 조회조건 배열
  const [condition, setCondition] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 데이터 삭제
  const handleDelete = (state, id) => {
    // 선택된 데이터가 없을 때 return
    if (state === "checkBox" && !selectedIDs.length) {
      alert("선택된 데이터 없음");
      return;
    }

    if (window.confirm("데이터를 삭제하시겠습니까?")) {
      deleteDataFromServer(
        `/admin/${mainMenu}/${subMenu}?id=${
          state === "checkBox" ? selectedIDs.join(",") : id
        }`
      ).then((response) => {
        // 서버에서 해당 페이지 데이터 다시 가져오기
        mutation.mutate(params);
      });
    }
  };

  // 엑셀 다운로드
  const handleExportExcel = () => {
    createXlsxData(columns, rows, `${mainMenu}_${subMenu}`);
  };

  // 페이징 숫자 변경 (100개, 500개씩 보기)
  const handleChangePaging = (paging) => {
    setParams({ ...params, limit: paging });
  };

  // 특정 페이지 이동
  const handleChangeCurrentPage = () => {
    // 이동하려는 페이지 숫자가 전체 페이지를 넘어섰을 경우 리턴
    if (page.current > page.total) {
      return;
    }
    setParams({
      ...params,
      offset: params.limit * (page.current - 1),
    });
  };

  // 페이지 변경
  const handlePageInputChange = (event) => {
    // 페이지 입력이 숫자가 아니거나 0보다 작으면 리턴
    if (!event.target.value || event.target.value <= 0) {
      return;
    }
    setPage({ ...page, current: event.target.value });
  };

  // 조회조건 변경
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // 조회 값 변경
  const handleSearchInputChange = (event) => {
    setSearch(event.target.value);
  };

  // 조회조건 추가, 삭제
  const handleChangeInquiry = (state, id) => {
    if (state === "add") {
      if (!filter) {
        alert("조회조건을 선택해주세요.");
        return;
      }
      setCondition([
        ...condition,
        { id: Date.now(), filter: filter, search: search },
      ]);
      setFilter("");
      setSearch("");
    } else if (state == "delete") {
      const newCondition = condition.filter((value) => value.id !== id);
      setCondition([...newCondition]);
    }
  };

  // 조회하기 버튼 클릭했을때 조회조건을 기준으로 필터링하기
  const handleClickInquiry = () => {
    console.log(filter, condition);
    if (!condition.length) {
      alert("조회조건이 없습니다.");
      return;
    }

    console.log(condition);
    let newCondition = "";
    condition.map((value) => {
      newCondition =
        newCondition +
        value.filter.split(":")[0] +
        ":" +
        (value.search.includes(":")
          ? value.search.split(":")[0]
          : value.search) +
        ",";
    });
    console.log(newCondition);
    setParams({ ...params, filter: newCondition.slice(0, -1) });
  };

  // 특정 컬럼 기준으로 정렬
  const handleChangeOrderby = (fieldName, orderby) => {
    const newColumns = columns.map((value) => {
      const newValue = value;

      if (value.standard) {
        // 같은 컬럼 선택 > 기존 정렬방식의 반대방식으로 정렬
        if (fieldName === value.field) {
          newValue.standard = true;
          orderby === "desc"
            ? (newValue.orderby = "asc")
            : (newValue.orderby = "desc");
          // 다른 컬럼 선택시 기존컬럼 선택 해지
        } else {
          newValue.standard = false;
        }
        // 다른 컬럼 선택 > 기존 정렬방식대로 정렬
      } else {
        if (fieldName === value.field) {
          newValue.standard = true;
        }
      }
      return { ...newValue };
    });

    setColumns([...newColumns]);

    console.log(
      newColumns.filter((value) => value.standard)[0].orderby,
      fieldName
    );

    setParams({
      ...params,
      orderby: `${fieldName} ${
        newColumns.filter((value) => value.standard)[0].orderby
      }`,
    });
  };

  // prams가 변경되었을 때 서버로부터 데이터 가져오기
  const mutation = useMutation("AdminPageDataList", {
    mutationFn: () => {
      return getDataFromServer(
        `${pathname}?limit=${params.limit}&offset=${params.offset}&orderby=${params.orderby}&filter=${params.filter}`
      );
    },
    onSuccess: (data) => {
      setIsAdmin(true);
      const newRows = preproccessingForDataTable(pathname, data.rows);
      setColumns([...columnData[pathname]]);
      setRows([...newRows]);
      setCount(data.count);
      setPage({
        ...page,
        total: Math.ceil(data.count / params.limit),
      });
      setSelectedIDs([]);
    },
    onError: (error) => {
      setIsAdmin(false);
    },
  });

  useEffect(() => {
    mutation.mutate(params);
  }, [params, pathname]);

  return (
    <>
      {isAdmin && (
        <Box display="flex">
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }}>
            {inquiryCondition[pathname].length ? (
              <OptionHeader
                condition={condition}
                filter={filter}
                search={search}
                handleFilterChange={handleFilterChange}
                handleSearchInputChange={handleSearchInputChange}
                handleChangePaging={handleChangePaging}
                handleChangeInquiry={handleChangeInquiry}
                handleClickInquiry={handleClickInquiry}
                handleDelete={handleDelete}
                handleExportExcel={handleExportExcel}
              />
            ) : (
              <Box></Box>
            )}
            {count > 0 ? (
              <Box
                border={"1px solid " + borderColor}
                sx={{ overflow: "auto" }}
                maxHeight={(windowSize.height * 8) / 13}
              >
                <DataTable
                  columns={columns}
                  rows={rows}
                  selectedIDs={selectedIDs}
                  handleChangeOrderby={handleChangeOrderby}
                  handleClickCheckBox={handleClickCheckBox}
                  handleDelete={handleDelete}
                />
                <Box
                  style={{
                    backgroundColor: `${lightgrey}`,
                    bottom: "0px",
                    left: "0px",
                    position: "sticky",
                    zIndex: 110,
                    padding: "8px",
                  }}
                >
                  {count}개 중 {params.offset + 1} -{" "}
                  {params.offset + rows.length} 데이터 표시중 ({" "}
                  <InputBase
                    type="number"
                    style={{
                      margin: "2px",
                      border: `2px solid ${primary}`,
                      borderRadius: "4px",
                      width: "50px",
                    }}
                    value={page.current}
                    onChange={handlePageInputChange}
                  />
                  / {page.total} )
                  <Button
                    variant="outlined"
                    style={{ borderRadius: "unset", margin: "0 8px" }}
                    onClick={handleChangeCurrentPage}
                  >
                    페이지 이동
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography textAlign="center" variant="h4" margin="32px 0">
                데이터가 없습니다.
              </Typography>
            )}
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={mutation.isLoading}
          />
        </Box>
      )}
    </>
  );
};

export { Index as UserManagement };
