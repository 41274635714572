import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DurationSelector = ({ date, handleChangeDate }) => {
  return (
    <Box>
      <Box display="flex" margin="16px 0 ">
        <Typography margin="0 8px 0 0 ">시작일 : </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              views={["day"]}
              label="시작일"
              value={date.start}
              onChange={(newValue) => {
                handleChangeDate("start", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
              format="YYYY-MM-DD"
            />
          </Stack>
        </LocalizationProvider>
      </Box>
      <Box display="flex" margin="16px 0 ">
        <Typography margin="0 8px 0 0 ">종료일 : </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              views={["day"]}
              label="종료일"
              value={date.end}
              onChange={(newValue) => {
                handleChangeDate("end", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
              format="YYYY-MM-DD"
            />
          </Stack>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DurationSelector;
