import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import React from "react";
import {
  borderColor,
  lightgrey,
  lightcoral,
} from "../../../common/style/styles";

const DataList = ({ columns, rows }) => {
  // console.log(columns, rows);
  return (
    <Box margin="16px">
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          데이터 상세보기
        </ListItem>
        <Divider />
        <ListItem style={{ backgroundColor: `${lightcoral}` }}>
          주문 정보
        </ListItem>
        {columns.orderInfo.map((value) => (
          <ListItem
            key={value.field}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Typography component="p" flex="1">
                {value.name}
              </Typography>
              <Typography component="p" flex="5">
                {rows.order_info[value.field]}
              </Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
        <ListItem style={{ backgroundColor: `${lightcoral}` }}>
          주문 상품 정보
        </ListItem>

        {columns.orderItemInfo.map((value) => (
          <ListItem value={value.field} sx={{ flexDirection: "column" }}>
            <Box display="flex" width="100%" flexWrap="wrap">
              <Typography component="p" flex="1">
                {value.name}
              </Typography>
              {rows.order_item_info.map((row) => (
                <Typography component="p" flex="1">
                  {row[value.field]}
                </Typography>
              ))}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DataList;
