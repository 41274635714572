import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import {
  HomeRounded,
  LocalOfferRounded,
  WidgetsRounded,
  FavoriteRounded,
  PersonRounded,
} from "@mui/icons-material";
import { Column, StyledLink } from "../style/styles";
import { useRecoilState } from "recoil";
import { useLocation, useMatch } from "react-router-dom";
import { tabMenuState } from "../state/state";
import { getDataFromServer } from "../network/network";

const TabBar = () => {
  //홈 , 브랜드, 카테고리, 찜, 마이페이지
  const [tabMenu, setTabMenu] = useRecoilState(tabMenuState);
  const [color, setColor] = useState({
    home: "primary",
    brands: "secondary",
    categories: "secondary",
    likes: "secondary",
    mypage: "secondary",
  });
  const location = useLocation().pathname;
  const [isLogin, setIsLogin] = useState(false);

  // url 경로에 따라 탭 메뉴 설정
  useEffect(() => {
    handleClick(location == "/" ? "home" : location.split("/")[1]);
  }, [location]);

  // 클릭한 메뉴만 분홍색으로 변경
  const handleClick = (name) => {
    for (let key in color) {
      key == name ? (color[key] = "primary") : (color[key] = "secondary");
    }
    setColor({ ...color });
    setTabMenu(name);
  };

  // 로그인한 상태인지 확인
  // (마이페이지 클릭했을 때 로그인 했으면 마이페이지, 안했으면 로그인 페이지로 이동시키기 )
  useEffect(() => {
    getDataFromServer("/service/login/confirm").then((response) => {
      setIsLogin(response.is_login);
    });
  }, [tabMenu]);

  return (
    <AppBar
      id="tab-bar"
      position="fixed"
      color="inherit"
      sx={{ top: "auto", bottom: 0 }}
    >
      <Column xs={12} sm={12} md={12} lg={9}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <StyledLink to="/">
            <IconButton
              sx={{ display: "flex", flexDirection: "column" }}
              color={color.home}
              onClick={() => {
                handleClick("home");
              }}
            >
              <HomeRounded />
              <Typography variant="caption" component="div">
                홈
              </Typography>
            </IconButton>
          </StyledLink>
          <StyledLink to="/brands">
            <IconButton
              sx={{ display: "flex", flexDirection: "column" }}
              color={color.brands}
              onClick={() => {
                handleClick("brands");
              }}
            >
              <LocalOfferRounded />
              <Typography variant="caption" component="div">
                브랜드
              </Typography>
            </IconButton>
          </StyledLink>
          <StyledLink to="/categories">
            <IconButton
              sx={{ display: "flex", flexDirection: "column" }}
              color={color.categories}
              onClick={() => {
                handleClick("categories");
              }}
            >
              <WidgetsRounded />
              <Typography variant="caption" component="div">
                카테고리
              </Typography>
            </IconButton>
          </StyledLink>
          <StyledLink to="/likes">
            <IconButton
              sx={{ display: "flex", flexDirection: "column" }}
              color={color.likes}
              onClick={() => {
                handleClick("likes");
              }}
            >
              <FavoriteRounded />
              <Typography variant="caption" component="div">
                찜
              </Typography>
            </IconButton>
          </StyledLink>
          <StyledLink to={isLogin ? "/mypage" : "/login"}>
            <IconButton
              sx={{ display: "flex", flexDirection: "column" }}
              color={color.mypage}
              onClick={() => {
                handleClick("mypage");
              }}
            >
              <PersonRounded />
              <Typography variant="caption" component="div">
                마이페이지
              </Typography>
            </IconButton>
          </StyledLink>
        </Toolbar>
      </Column>
    </AppBar>
  );
};

export default TabBar;
