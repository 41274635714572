import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { inputNumberWithComma } from "../../../common/js/common";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { blue, BREAK_POINT_PC, grey4 } from "../../../common/style/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const PriceDropdown = ({ priceList }) => {
  const flags = { 프랑스: "🇫🇷", 영국: "🇬🇧", 한국: "🇰🇷", 스페인: "🇪🇸" };
  const [isOpened, setIsOpened] = useState(false);
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const handleClick = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <Box
      width={windowSize.width > BREAK_POINT_PC ? "160px" : "150px"}
      display="flex"
      marginTop="0"
      flexDirection="column"
    >
      {/* 최저판매가 국가 */}
      <Box width="100%" onClick={handleClick}>
        <Button
          variant="contained"
          style={{
            color: blue,
            background: grey4,
            width: "max-content",
            display: "flex",
            boxShadow: "none",
          }}
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography
            variant={windowSize.width > BREAK_POINT_PC ? "body2" : "caption"}
          >
            국가별 최저가 알아보기
          </Typography>
        </Button>
      </Box>
      {/* 국가별 가격 리스트 */}
      <Box position="relative">
        <Box
          sx={{
            width: `${windowSize.width >= BREAK_POINT_PC ? "180px" : "150px"}`,
            padding: 0,
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: "white",
            padding: "8px",
            transition:
              "opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            boxShadow:
              "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            display: `${isOpened ? "block" : "none"}`,
            zIndex: "110",
          }}
        >
          <Box width="100%" display="flex" padding="4px">
            <Typography
              variant={windowSize.width >= BREAK_POINT_PC ? "body2" : "caption"}
              component="span"
              sx={{ flex: 1 }}
            >
              국가
            </Typography>
            <Typography
              variant={windowSize.width >= BREAK_POINT_PC ? "body2" : "caption"}
              component="span"
              sx={{ flex: 1 }}
            >
              가격
            </Typography>
          </Box>
          <Divider />
          {priceList.map((value, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                display: "flex",
                padding: "4px",
              }}
            >
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "body2" : "caption"
                }
                component="span"
                sx={{ flex: 1 }}
              >
                {value["country"]}
              </Typography>
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "body2" : "caption"
                }
                component="span"
                sx={{ flex: 1 }}
              >
                {inputNumberWithComma(value.sale_price)}원
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PriceDropdown;
