import { Box, Divider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { primary, StyledLink, grey } from "../style/styles";
import menuList from "../json/menu_list.json";

// 어드민 메인메뉴
const MainMenu = () => {
  const { pathname } = useLocation();
  const [mainMenu, subMenu] = [pathname.split("/")[2], pathname.split("/")[3]];
  const [openSubMenu, setOpenSubMenu] = useState(null);

  // 서브메뉴 있는 메뉴 클릭시 서브메뉴 나타내기
  const handleOpenSubMenu = (name) => {
    setOpenSubMenu(name);
  };

  const Container = styled.div`
    border-right: ${({ isShow }) => (isShow ? `3px solid ${primary}` : "none")};
    cursor: pointer;
    width: 100%;
    background-color: ${({ isShow }) => (isShow ? `${grey}` : "none")};
  `;

  const StyledTypography = styled.p`
    font-size: 12pt;
    padding: ${({ isSubMenu }) => (isSubMenu ? "16px" : "16px 8px")};
    margin: 0;
    background-color: ${({ isSelected }) =>
      isSelected ? `${primary}` : "none"};
  `;

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        display: "flex",
        minWidth: "220px",
      }}
    >
      <Box width="100%">
        {Object.keys(menuList).map((key, index) =>
          menuList[key].length > 1 ? (
            <div key={index}>
              <Container isShow={mainMenu === key ? true : false}>
                <StyledTypography onClick={() => handleOpenSubMenu(key)}>
                  {menuList[key][0].mainMenu}
                </StyledTypography>
                {(openSubMenu === key || mainMenu === key) &&
                  menuList[key].map(
                    (value) =>
                      value.isShow && (
                        <StyledLink to={value.path} key={value.path}>
                          <StyledTypography
                            isSelected={
                              pathname.includes(value.path) ? true : false
                            }
                            isSubMenu={true}
                          >
                            {value.subMenu}
                          </StyledTypography>
                        </StyledLink>
                      )
                  )}
              </Container>
              <Divider />
            </div>
          ) : (
            <StyledLink to={menuList[key][0].path} key={index}>
              <Container
                isShow={pathname.includes(menuList[key][0].path) ? true : false}
              >
                <StyledTypography
                  isSelected={
                    pathname.includes(menuList[key][0].path) ? true : false
                  }
                >
                  {menuList[key][0].mainMenu}
                </StyledTypography>
              </Container>
              <Divider />
            </StyledLink>
          )
        )}
      </Box>
    </Box>
  );
};

export default MainMenu;
