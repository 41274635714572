import { Box, Checkbox, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  grey6,
  StyledLink,
  info,
  BREAK_POINT_PC,
} from "../../../common/style/styles";
import { inputNumberWithComma, roundNumber } from "../../../common/js/common";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import AlertModal from "../../../common/modal/alert_modal";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

const Item = ({
  items,
  country,
  pointRate,
  handleSelectedItemsChange,
  handleQuantityChange,
  handleDeleteItem,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);

  const handleAlertModal = (ItemOptionIds) => {
    setIsOpened((prev) => !prev);

    if (ItemOptionIds) {
      setDeleteItem(ItemOptionIds);
    }
  };

  // 장바구니 삭제 모달에서 확인을 누른경우
  const handleSubmit = () => {
    handleDeleteItem(deleteItem);
    handleAlertModal();
  };

  return (
    <>
      {items.map(
        (item, index) =>
          item.country === country &&
          item.is_show && (
            <Box key={index} position="relative">
              <Box
                sx={{ opacity: !item.not_soldout || item.is_stock ? 1 : 0.4 }}
              >
                {/*   상품 선택, 삭제 */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="0.5rem 1rem"
                >
                  <Box display="flex" alignItems="center" width="90%">
                    <Checkbox
                      disabled={
                        !item.not_soldout || item.is_stock ? false : true
                      }
                      checked={item.is_selected}
                      onChange={() => {
                        handleSelectedItemsChange(
                          "item",
                          item.id,
                          !item.is_selected
                        );
                      }}
                      color="primary"
                      sx={{ padding: "9px 0 9px 0" }}
                    />
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                      fontWeight="500"
                      margin="0 4px"
                      minWidth="max-content"
                    >
                      [{item.brand_name}]
                    </Typography>
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                      fontWeight="700"
                      component="span"
                      noWrap={true}
                    >
                      {item.item_name}
                    </Typography>
                  </Box>
                  <Box>
                    <CloseIcon
                      sx={{
                        cursor: "pointer",
                        fontSize:
                          windowsize.width >= BREAK_POINT_PC
                            ? "1.3rem"
                            : "1rem",
                      }}
                      onClick={() => {
                        handleAlertModal(item.id);
                      }}
                    />
                  </Box>
                </Box>

                {/* 상품 정보 */}
                <Box
                  display="flex"
                  margin={
                    windowsize.width >= BREAK_POINT_PC
                      ? "1rem 0"
                      : "0.5rem 1rem"
                  }
                >
                  <StyledLink
                    to={`/items/${item.item_id}/${item.item_detail_id}/${item.item_option_id}`}
                  >
                    <img
                      style={{
                        backgroundImage: `url(${defaultImg})`,
                        width:
                          windowsize.width >= BREAK_POINT_PC ? "100px" : "70px",
                        height:
                          windowsize.width >= BREAK_POINT_PC
                            ? "140px"
                            : "100px",
                      }}
                      src={item.images}
                      srcSet={item.images}
                      // loading="lazy"
                    />
                  </StyledLink>
                  <Box
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    marginLeft={
                      windowsize.width >= BREAK_POINT_PC ? "2rem" : "0.5rem"
                    }
                  >
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                      }
                      marginBottom={
                        windowsize.width >= BREAK_POINT_PC ? "0.5rem" : "0.2rem"
                      }
                      color={info}
                    >
                      옵션 :{" "}
                      {item.option_name == "컬러" ? "one size" : item.size} /{" "}
                      {item.color}
                    </Typography>
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                      }
                      marginBottom={
                        windowsize.width >= BREAK_POINT_PC ? "0.5rem" : "0.2rem"
                      }
                      color={info}
                    >
                      적립예정금액:{" "}
                      {inputNumberWithComma(
                        parseInt(
                          roundNumber(item.sale_price) * (pointRate / 100)
                        ).toString(),
                        true
                      )}
                      원
                    </Typography>
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                      }
                      marginBottom={
                        windowsize.width >= BREAK_POINT_PC ? "0.5rem" : "0.2rem"
                      }
                      color={info}
                    >
                      금액 : {inputNumberWithComma(item.sale_price)}원
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      {/* 수량 증가,감소 */}
                      <Box
                        display="flex"
                        alignItems="center"
                        margin="0.5rem 0"
                        sx={{
                          backgroundColor: `${grey6}`,
                        }}
                      >
                        <Box
                          padding="0.2rem 0.5rem"
                          onClick={() =>
                            (item.not_soldout || item.is_stock) &&
                            handleQuantityChange("decrease", item.id)
                          }
                        >
                          <RemoveIcon
                            sx={{
                              fontSize: "0.8rem",
                            }}
                          />
                        </Box>
                        <Box
                          padding="0.2rem 0.5rem"
                          sx={{
                            border: "1px solid " + grey6,
                            background: "white",
                          }}
                        >
                          <Typography
                            variant={
                              windowsize.width >= BREAK_POINT_PC
                                ? "body1"
                                : "body2"
                            }
                            fontWeight="700"
                          >
                            {item.quantity}
                          </Typography>
                        </Box>
                        <Box
                          padding="0.2rem 0.5rem"
                          onClick={() =>
                            (item.not_soldout || item.is_stock) &&
                            handleQuantityChange("increase", item.id)
                          }
                        >
                          <AddIcon
                            sx={{
                              fontSize: "0.8rem",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </Box>
              {!item.not_soldout || !item.is_stock ? (
                <Box
                  position="absolute"
                  top="50%"
                  left={windowsize.width >= BREAK_POINT_PC ? "3%" : "1.7rem"}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtlte2" : "body2"
                    }
                    fontWeight="700"
                  >
                    SOLD
                  </Typography>
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtlte2" : "body2"
                    }
                    fontWeight="700"
                  >
                    OUT
                  </Typography>
                </Box>
              ) : null}
            </Box>
          )
      )}
      <AlertModal
        title="장바구니"
        content="선택한 상품을 장바구니에서 삭제하시겠습니까?"
        isOpened={isOpened}
        handleAlertModal={handleAlertModal}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Item;
