import { Box, Button, Divider, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convert_item_db_info } from "../../../common/js/common";
import {
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import {
  borderColor,
  lightgrey,
  lightcoral,
  primary,
} from "../../../common/style/styles";
import CommonInfoForm from "./common_info_form";
import CountryInfoForm from "./country_info_form";
import ImageListForm from "./image_list_form";
import SaleInfoForm from "./sale_info_form";
import dayjs, { Dayjs } from "dayjs";

const EditData = ({ params, columns, rows }) => {
  const { mode } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [newColumns, setNewColumns] = useState({});
  // 입력된 데이터
  const [inputData, setInputData] = useState({
    commonInfo: {},
    basicInfo: {},
    saleInfo: {},
    priceInfo: {},
    sizeInfo: {},
    imageInfo: {},
    country: [],
  });
  // 상품 등록 클릭했는지 확인 - 더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // if (mode === "create") {
    const tempColumns = columns.commonInfo.map((value) => {
      if (value.column === "brand_name") {
        getDataFromServer("/service/brands?token=null").then(
          (response) =>
            (value.option = response.brand_list.map((data) => {
              return { name: data.name, value: data.id };
            }))
        );
      }
      if (value.column === "category") {
        getDataFromServer("/service/categories").then((response) => {
          value.option = [
            ...response.category_list.one.map((data) => {
              return {
                name: `${response.category_list.zero
                  .map((value) => {
                    if (value.id === data.ancestry) {
                      return value.name;
                    }
                  })
                  .find((value) => value)}-${data.name}`,
                value: data.id,
              };
            }),
          ];
        });
      }
      return value;
    });

    // console.log(tempColumns);
    setNewColumns({ ...columns, commonInfo: tempColumns });
    // }
  }, []);

  useEffect(() => {
    // console.log(rows);
    if (mode === "create" || !Object.keys(rows).length) {
      return;
    }
    // 데이터 수정할 때 이전정보 세팅

    const data = convert_item_db_info(rows);

    // console.log(data);
    setInputData({ ...data });
  }, [columns, rows]);

  // InputData 입력값 변경
  const handleInputDataChange = (event, type, mode, option, id) => {
    const { name, value, files, checked } = event.target;

    let newCommonInfo = {};
    let newPriceInfo = {};
    let newSizeInfo = {};
    let newBasicInfo = {};
    let newCountry = [];
    let newCountrySizeInfo = [];
    let newImageInfo = {};

    switch (type) {
      // 기본 정보 변경
      case "commonInfo":
        // 컬러 추가, 삭제
        if (name === "color") {
          // 컬러를 입력했는지 확인
          if (!value) {
            alert("컬러를 입력해주세요.");
            return;
          }
          if (mode === "add") {
            newCommonInfo = {
              ...inputData[type],
              [name]: inputData[type][name]
                ? [...inputData[type][name], value]
                : [value],
            };
            newImageInfo = {
              ...inputData.imageInfo,
              [value]: [],
            };
          }
          if (mode === "delete") {
            const tempColorArray = inputData[type][name].filter(
              (data) => data !== value
            );
            newCommonInfo = {
              ...inputData[type],
              [name]: [...tempColorArray],
            };

            newImageInfo = inputData.imageInfo;
            delete newImageInfo[value];
          }
        }
        // 기본 정보 입력 기록
        if (!mode) {
          newCommonInfo = { ...inputData[type], [name]: value };
        }

        setInputData((prev) => {
          return {
            ...prev,
            [type]: newCommonInfo,
            imageInfo: Object.keys(newImageInfo).length
              ? newImageInfo
              : prev.imageInfo,
          };
        });
        break;

      // 어드민 할인정보
      case "saleInfo":
        // console.log(name, value, checked);
        if (name === "price" || name === "rate") {
          if (value < 0) {
            alert("할인 가격을 확인해주세요");
            return;
          }
        }

        setInputData((prev) => {
          return {
            ...prev,
            saleInfo: {
              ...prev.saleInfo,
              [name]: name === "hasDate" ? checked : value,
            },
          };
        });
        break;

      // 국가별 정보 - 국가 추가, 삭제
      case "countryInfo":
        // 국가를 선택했는지 확인
        if (!value) {
          alert("국가를 선택해주세요.");
          return;
        }
        if (mode === "add") {
          // 이미 추가된 국가인지 확인
          if (inputData.country.includes(value)) {
            alert("이미 존재하는 국가입니다.");
            return;
          }
          // 컬러 옵션이 존재하는지 확인
          if (!inputData.commonInfo.hasOwnProperty("color")) {
            alert("컬러 옵션을 먼저 추가해주세요.");
            return;
          }
          newPriceInfo = { ...inputData.priceInfo, [value]: {} };
          newSizeInfo = {
            ...inputData.sizeInfo,
            [value]: [
              {
                id: Date.now(),
                color: "",
                size: "",
                available_stock: "",
                is_stock: true,
              },
            ],
          };
          newBasicInfo = {
            ...inputData.basicInfo,
            [value]: { is_discontinued: false, is_stock: true },
          };
          newCountry = [...inputData.country, value];
        }
        if (mode === "delete") {
          newPriceInfo = inputData.priceInfo;
          delete newPriceInfo[value];
          newSizeInfo = inputData.sizeInfo;
          delete newSizeInfo[value];
          newBasicInfo = inputData.basicInfo;
          delete newBasicInfo[value];
          newCountry = inputData.country.filter((data) => data !== value);
        }
        setInputData((prev) => {
          return {
            ...prev,
            country: [...newCountry],
            priceInfo: { ...newPriceInfo },
            sizeInfo: { ...newSizeInfo },
            basicInfo: { ...newBasicInfo },
          };
        });
        break;

      // 국가별 정보 - basic info변경
      case "basicInfo":
        setInputData({
          ...inputData,
          basicInfo: {
            ...inputData.basicInfo,
            [option]: {
              ...inputData.basicInfo[option],
              [name]: name === "site" ? value : checked,
            },
          },
        });
        break;

      // 국가별 정보 - 가격정보(price info)변경
      case "priceInfo":
        // 가격이 -1 이하이면 return
        if (name.includes("price") && value < 0) {
          alert("가격을 확인해주세요");
          return;
        }
        setInputData((prev) => {
          return {
            ...prev,
            priceInfo: {
              ...prev.priceInfo,
              [option]: { ...prev.priceInfo[option], [name]: value },
            },
          };
        });
        break;

      // 국가별 정보 - 사이즈 정보 (size info) 변경
      case "sizeInfo":
        // 사이즈 추가, 삭제
        if (mode === "add") {
          newCountrySizeInfo = [
            { id: Date.now(), color: "", size: "", available_stock: "" },
            ...inputData.sizeInfo[option],
          ];
        }
        if (mode === "delete") {
          newCountrySizeInfo = inputData.sizeInfo[option].filter(
            (data) => data.id !== value
          );
        }
        // 컬러, 사이즈, 품절 여부, 재고 수량 변경
        if (!mode) {
          // 재고수량 음수 입력했는지 확인
          if (name === "available_stock" && value < 0) {
            alert("재고 수량을 확인해주세요.");
            return;
          }
          newCountrySizeInfo = inputData.sizeInfo[option].map((data) => {
            if (data.id === id) {
              return { ...data, [name]: name === "is_stock" ? checked : value };
            } else {
              return data;
            }
          });
        }
        setInputData((prev) => {
          return {
            ...prev,
            sizeInfo: {
              ...prev.sizeInfo,
              [option]: [...newCountrySizeInfo],
            },
          };
        });
        break;

      // 이미지 리스트 변경 (컬러별 이미지 리스트 만들기)
      case "imageInfo":
        if (mode === "add") {
          newImageInfo = inputData.imageInfo[option].length
            ? {
                ...inputData.imageInfo,
                [option]: [...inputData.imageInfo[option], ...files],
              }
            : { ...inputData.imageInfo, [option]: [...files] };
        }
        if (mode === "delete") {
          const tempImgArray = inputData.imageInfo[option].filter(
            (data) => (typeof data === "object" ? data.name : data) !== value
          );

          newImageInfo = { ...inputData.imageInfo, [option]: tempImgArray };
        }
        // 이미지 순서 변경
        if (mode === "orderby") {
          const sourceFile = inputData.imageInfo[option].filter(
            (data) =>
              (typeof data === "object" ? data.name : data) === value.source
          )[0];

          let tempImgArray = inputData.imageInfo[option].filter(
            (data) =>
              (typeof data === "object" ? data.name : data) !== value.source
          );

          const targetIndex = inputData.imageInfo[option]
            .map((data, index) => {
              if (
                (typeof data === "object" ? data.name : data) === value.target
              ) {
                return index;
              }
            })
            .filter((data) => data !== undefined)[0];

          tempImgArray.splice(parseInt(targetIndex), 0, sourceFile);

          newImageInfo = { ...inputData.imageInfo, [option]: tempImgArray };
        }

        setInputData((prev) => {
          return {
            ...prev,
            imageInfo: {
              ...newImageInfo,
            },
          };
        });
        break;
      default:
        break;
    }
  };

  // 데이터 서버에 저장
  const handleSaveData = () => {
    if (hasClick) {
      return;
    }

    // 비어 있는 데이터 있는지 확인
    console.log(inputData);
    // commonInfo 확인
    if (
      Object.keys(newColumns.commonInfo).length >
      Object.keys(inputData.commonInfo).length
    ) {
      alert("상품의 기본정보를 모두 입력해주세요.");
      setHasClick(false);
      return;
    }
    // 컬러 (color) 확인
    if (!inputData.commonInfo.color.length) {
      alert("컬러를 하나 이상 추가해주세요.");
      return;
    }
    // 국가 (country)확인
    if (!inputData.country.length) {
      alert("국가를 하나 이상 추가해주세요.");
      setHasClick(false);
      return;
    }

    // saleInfo 확인

    if (Object.keys(inputData.saleInfo).length) {
      // 할인 금액, 할인율 듈다 입력했을 경우 return
      if (
        inputData.saleInfo.price &&
        inputData.saleInfo.price !== "0" &&
        inputData.saleInfo.rate &&
        inputData.saleInfo.rate !== "0"
      ) {
        alert("할인 설정은 할인 금액과 할인율 중 하나만 설정할 수 있습니다.");
        return;
      }

      if (inputData.saleInfo.hasDate) {
        // 할인 설정 안한경우
        if (
          (!inputData.saleInfo.price || inputData.saleInfo.price === "0") &&
          (!inputData.saleInfo.rate || inputData.saleInfo.rate === "0")
        ) {
          alert("할인 설정을 해주세요.");
          return;
        }

        const startDate = dayjs(inputData.saleInfo.start, "DDMMYYYY").toDate();
        const endDate = dayjs(inputData.saleInfo.end, "DDMMYYYY").toDate();
        const today = new Date();
        // 시작일이 종료일보다 늦은경우
        if (startDate > endDate) {
          alert("시작일이 종료일보다 늦을 수 없습니다.");
          return;
        }

        // 종료 기간이 현재 날짜보다 이전인 경우
        if (today > endDate) {
          alert("종료기간은 현재일 이후여야 합니다.");
          return;
        }
      }
    }

    // basicInfo 확인
    const hasEmptyBasicInfo = Object.keys(inputData.basicInfo).find(
      (country) =>
        newColumns.countryInfo.basicInfo.length !==
        Object.keys(inputData.basicInfo[country]).length
    );

    if (hasEmptyBasicInfo) {
      alert(`${hasEmptyBasicInfo}의 국가 정보를 모두 입력해주세요`);
      setHasClick(false);
      return;
    }
    // priceInfo 확인
    const hasEmptyPriceInfo = Object.keys(inputData.priceInfo).find(
      (country) =>
        Object.keys(inputData.priceInfo[country]).length <
        newColumns.countryInfo.priceInfo.length
    );
    // console.log(newColumns.countryInfo.priceInfo);
    if (hasEmptyPriceInfo) {
      alert(`${hasEmptyPriceInfo}의 가격 정보를 모두 입력해주세요`);
      setHasClick(false);
      return;
    }
    // sizeInfo 확인
    // 컬러별로 1개 이상 사이즈가 있는지 확인
    const tempColorLength = inputData.commonInfo.color.length;
    const hasEmptySizeColor = Object.keys(inputData.sizeInfo).find(
      (country) => {
        const tempSizeLength = [
          ...new Set(
            inputData.sizeInfo[country]
              .map((data) => data.color)
              .filter((data) => data)
          ),
        ].length;
        if (tempSizeLength !== tempColorLength) {
          return country;
        }
      }
    );

    if (hasEmptySizeColor) {
      alert(`${hasEmptySizeColor}의 컬러별 사이즈 정보를 입력해주세요`);
      setHasClick(false);
      return;
    }
    // 사이즈 정보가 모두 입력되었는지 확인
    const hasEmptySizeInfo = Object.keys(inputData.sizeInfo).find((country) => {
      const checkSizeInfo = inputData.sizeInfo[country].find((info) => {
        const temp = newColumns.countryInfo.sizeInfo.find((data) => {
          if (!info[data.column]) {
            return false;
          }
        });
        if (!temp) {
          return false;
        }
      });
      if (checkSizeInfo === false) {
        return country;
      }
    });
    if (hasEmptySizeInfo) {
      alert(`${hasEmptySizeInfo}의 사이즈 정보를 입력해주세요`);
      return;
    }
    // imageInfo 확인
    const hasEmptyImageInfo = Object.keys(inputData.imageInfo).find(
      (country) => !inputData.imageInfo[country].length
    );
    if (hasEmptyImageInfo) {
      alert(`${hasEmptyImageInfo} 컬러의 이미지를 추가해주세요`);

      return;
    }

    setHasClick(true);
    setLoading(true);
    setProcessing(true);

    // 데이터 전송
    const formData = new FormData();
    formData.append("commonInfo", JSON.stringify(inputData.commonInfo));
    formData.append("basicInfo", JSON.stringify(inputData.basicInfo));
    formData.append("priceInfo", JSON.stringify(inputData.priceInfo));
    formData.append("sizeInfo", JSON.stringify(inputData.sizeInfo));
    formData.append("saleInfo", JSON.stringify(inputData.saleInfo));
    formData.append("country", JSON.stringify(inputData.country));

    const brandName = newColumns.commonInfo
      .find((value) => value.column === "brand_name")
      .option.find((value) => value.value === inputData.commonInfo.brand_name)
      .name.toString()
      .replaceAll(/[ㄱ-ㅎ|가-힣]/g, "")
      .trim();

    let imageFileName = [];

    for (let color of Object.keys(inputData.imageInfo)) {
      for (let data of inputData.imageInfo[color]) {
        if (typeof data === "object") {
          const imageName = `${brandName}/${inputData.commonInfo.sku.replaceAll(
            "/",
            ""
          )}/${inputData.commonInfo.sku.replaceAll("/", "")}_${color.replaceAll(
            "/",
            "N"
          )}_${Date.now()}_${inputData.imageInfo[color].indexOf(data)}.${
            data.type.split("/")[1]
          }`.replaceAll(" ", "-");
          formData.append(`${imageName}*${color}`, data);
          imageFileName.push(`${imageName}*${color}`);
        } else {
          imageFileName.push(`${data}*${color}`);
        }
      }
    }

    formData.append("imageFileName", JSON.stringify(imageFileName));

    // console.log(imageFileName);
    // console.log(formData);
    if (params.mode === "create") {
      postDataFromServer("/admin/items", formData)
        .then((response) => {
          alert("상품 수기등록이 완료되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    } else {
      putDataFromServer(
        `/admin/items/${params.id}/${params.detailId}/${params.optionId}`,
        formData
      )
        .then((response) => {
          alert("상품 정보 수정이 완료되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setHasClick(false);
          setLoading(false);
          setProcessing(false);
        });
    }
  };

  return (
    <Box margin="16px" sx={{ pointerEvents: processing ? "none" : "auto" }}>
      <Box margin="8px 0">
        <Button
          variant="outlined"
          style={{ borderRadius: "unset" }}
          onClick={handleSaveData}
        >
          데이터 저장
        </Button>
        {pathname.includes("update") && (
          <Button
            variant="outlined"
            style={{ borderRadius: "unset", margin: "0 8px" }}
          >
            데이터 삭제
          </Button>
        )}
      </Box>
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          {pathname.includes("create") && "상품 수기 등록"}
          {pathname.includes("edit") &&
            "상품 정보 수정 (한국 상품 정보는 표시되지 않습니다. / sku별로 상품 정보가 수정됩니다.)"}
        </ListItem>
        <Divider />
        {Object.keys(newColumns).length && (
          <Box>
            <CommonInfoForm
              newColumns={newColumns}
              inputData={inputData}
              handleInputDataChange={handleInputDataChange}
            />
            <SaleInfoForm
              newColumns={newColumns}
              inputData={inputData}
              handleInputDataChange={handleInputDataChange}
            />
            <CountryInfoForm
              newColumns={newColumns}
              inputData={inputData}
              handleInputDataChange={handleInputDataChange}
            />
            <ImageListForm
              inputData={inputData}
              handleInputDataChange={handleInputDataChange}
            />
          </Box>
        )}
      </List>
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={loadling}
      />
    </Box>
  );
};

export default EditData;
