import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { borderColor, primary } from "../../../common/style/styles";
import MainMenu from "../../../common/components/main_menu";
import DataTable from "../components/data_table";
import SearchHeader from "../components/search_header";
import columnData from "../../../common/json/columns.json";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import {
  getDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import FooterButtons from "../components/footer_buttons";
import EditModal from "../components/edit_modal";
import ErrorMsgModal from "../components/error_msg_modal";
import { deleteDataFromServer } from "../../../common/network/network";
import { TailSpin } from "react-loader-spinner";
import { set } from "date-fns";

const Index = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  // 테이블 행 데이터
  const [columns, setColumns] = useState([...columnData[pathname]]);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  // 크롤링 브랜드 리스트
  const [brandList, setBrandList] = useState([]);
  // 검색 리스트 데이터
  const [searchData, setSearchData] = useState({
    brandList: [],
    categoryList: [],
    countryList: ["영국", "프랑스", "스페인", "한국"],
    errorList: [],
  });
  // 크롤링 메뉴 추가, 수정 모달
  const [isEditModalOpen, setIsEditModalOpen] = useState({
    create: false,
    update: false,
  });
  // 크롤링 이슈 상세보기 모달에 필요한 id, 크롤링 나라 수
  const [isErrorMsgModalOpen, setIsErrorMsgModalOpen] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  //더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 크롤링 메뉴 추가, 수정 모달 열고 닫기
  const handleChangeEditModal = (status) => {
    const newData = rows.filter((value) => selectedIDs.includes(value.id));

    console.log(newData);
    if (status === "update") {
      // 수정버튼 클릭했을 때 선택된 크롤링 정보가 없으면 return
      if (!selectedIDs.length) {
        alert("수정할 크롤링 정보를 선택해주세요.");
        return;
      }

      if (selectedIDs.length > 1) {
        alert("수정할 크롤링 정보를 1개만 선택해주세요.");
        return;
      }
    }

    setIsEditModalOpen({
      ...isEditModalOpen,
      [status]: !isEditModalOpen[status],
    });
  };

  //크롤링 이슈 상세보기 모달 열고 닫기
  const handleChangeErrorMsgModal = (id, countryNum) => {
    console.log(id, countryNum);
    id
      ? setIsErrorMsgModalOpen({ id: id, countryNum: countryNum })
      : setIsErrorMsgModalOpen(null);
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (brand, id) => {
    const checked = selectedIDs.filter((value) => value == id || value == brand)
      .length
      ? true
      : false;

    // 브랜드에 해당하는 id 리스트
    const brandIds = rows
      .filter((value) => value.brand_name === brand)
      .map((value) => value.id);

    // 체크 해지
    if (checked) {
      // 브랜드 전체선택
      if (id === -1) {
        //해당 브랜드의 id 값 삭제
        const newSelectedIDs = selectedIDs.filter(
          (value) => !brandIds.includes(value) && value !== brand
        );
        setSelectedIDs([...newSelectedIDs]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 브랜드 전체선택
      if (id === -1) {
        //해당 브랜드의 id 값 추가
        setSelectedIDs([...selectedIDs, brand, ...brandIds]);

        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 크롤링 실행
  const handleExecuteCrawling = () => {
    if (hasClick) {
      return;
    }
    if (!selectedIDs.length) {
      alert("실행할 크롤링 메뉴를 선택해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);
    setHasClick(true);

    const newData = rows
      .filter((value) => selectedIDs.includes(value.id))
      .map((value) => {
        return {
          crawling_info_id: value.id,
          country: value.country_list.includes(",")
            ? value.country_list.split(",")
            : [value.country_list],
        };
      });

    postDataFromServer("/admin/crawling/history", { data: newData })
      .then((response) => {
        alert("선택한 url의 크롤링을 순차적으로 실행합니다.");
        window.location.reload();
      })
      .catch((error) => {
        alert("잠시 뒤에 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
        setHasClick(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getDataFromServer("/admin/crawling?data=null")
      .then((response) => {
        setIsAdmin(true);

        setBrandList([
          ...new Set(response.crawling_info.map((value) => value.brand_name)),
        ]);

        setRows([...response.crawling_info]);
        const newCategoryList = [
          ...response.category_list.one.map((data) => {
            return {
              name: `${response.category_list.zero
                .map((value) => {
                  if (value.id === data.ancestry) {
                    return value.name;
                  }
                })
                .find((value) => value)} - ${data.name}`,
              id: data.id,
            };
          }),
        ];
        setSearchData({
          ...searchData,
          brandList: response.brand_list,
          errorList: response.error_msg_list,
          categoryList: newCategoryList,
        });
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // 조건에 따라 데이터 필터링
  const handleFilterData = (data) => {
    getDataFromServer(`/admin/crawling?data=${JSON.stringify(data)}`)
      .then((response) => {
        setIsAdmin(true);

        setBrandList([
          ...new Set(response.crawling_info.map((value) => value.brand_name)),
        ]);

        setRows([...response.crawling_info]);
      })
      .catch((error) => {
        setIsAdmin(false);
      });
  };

  // 크롤링 정보 삭제
  const handleDelete = () => {
    if (hasClick) {
      return;
    }
    if (!selectedIDs.length) {
      alert("삭제할 크롤링 메뉴를 선택해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);
    setHasClick(true);

    const newData = selectedIDs
      .filter((value) => /[0-9]/.test(value))
      .join(",");

    console.log(newData);

    deleteDataFromServer(`/admin/crawling?ids=${newData}`)
      .then((response) => {
        alert("선택한 크롤링 메뉴가 삭제되었습니다.");
        window.location.reload();
      })
      .catch((error) => {
        alert("짐시 후 다시 시도해주세요.");
      });

    setLoading(false);
    setProcessing(false);
    setHasClick(false);
  };

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <SearchHeader
              searchData={searchData}
              handleFilterData={handleFilterData}
            />
            <Box
              sx={{ overflow: "auto" }}
              maxHeight={(windowSize.height * 5) / 7}
            >
              <DataTable
                brandList={brandList}
                columns={columns}
                rows={rows}
                selectedIDs={selectedIDs}
                handleClickCheckBox={handleClickCheckBox}
                handleChangeModal={handleChangeErrorMsgModal}
              />
            </Box>
            <FooterButtons
              handleExecuteCrawling={handleExecuteCrawling}
              handleChangeModal={handleChangeEditModal}
              handleDelete={handleDelete}
            />
          </Box>
        </Box>
      )}
      <EditModal
        brandList={searchData.brandList}
        categoryList={searchData.categoryList}
        countryList={searchData.countryList}
        isOpened={isEditModalOpen}
        handleChangeModal={handleChangeEditModal}
        updateData={
          isEditModalOpen.update
            ? rows.find((value) => selectedIDs.includes(value.id))
            : null
        }
      />
      <ErrorMsgModal
        isOpened={isErrorMsgModalOpen}
        handleChangeModal={handleChangeErrorMsgModal}
      />
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={loadling}
      />
    </>
  );
};

export { Index as CrawlingInfo };
