import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { borderColor } from "../../../common/style/styles";
import { primary } from "../../../common/style/styles";
import statusData from "../../../common/json/inquiry_condition.json";
import { useLocation } from "react-router-dom";

const OrderStatus = ({ status, handleStatusOfParamsChange }) => {
  const { pathname } = useLocation();

  return (
    <Box display="flex" border={"1px solid " + borderColor} padding="4px">
      {statusData[pathname].status.map((data) => (
        <Box key={data.name} display="flex">
          <Box
            display="flex"
            color={status === data.value && primary}
            onClick={() => {
              handleStatusOfParamsChange(data.value);
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="subtitle2" padding="4px 16px">
              {data.name}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
        </Box>
      ))}
    </Box>
  );
};

export default OrderStatus;
