import { Divider } from "@mui/material";
import React from "react";

const TaxInfo = () => {
  return (
    <div style={{ fontSize: "small" }}>
      <p>
        주문하시는 개별상품 판매가격이 150달러를 초과하는 경우 상품가격에
        관부가세가 포함되어 있으며 150달러 이하의 상품은 관부가세가 포함되어
        있지 않습니다.
      </p>
      <p>
        또한 밀리언픽에서 여러가지 상품을 같이 주문하시는 경우 아래와 같이
        관부가세 발생여부를 미리 체크하실 수 있습니다.
      </p>

      <p>
        - 관부가세 미포함 상품만을 주문하며 주문 총금액이 150달러 이하: 관부가세
        발생하지 않음
      </p>
      <p>
        - 관부가세 미포함 상품만을 주문하며 주문 총금액이 150달러 초과: 통관시
        관부가세 발생. 단, 고객 동의하에 주문을 나누어 배송하는 경우 관부가세
        발생하지 않음
      </p>
      <p>
        - 관부가세 포함 상품만을 주문하는 경우: 통관시 관부가세 추가 발생하지
        않음
      </p>
      <p>
        - 관부가세 포함 상품과 미포함 상품을 같이 주문하는 경우: 관부가세 미포함
        상품에 대하여 관부가세 추가 발생. 단, 고객 동의하에 주문을 나누어
        배송하는 경우 관부가세 발생하지 않음
      </p>
      <p>
        단, 밀리언픽에서 주문하시는 상품 이외 다른 사이트에서 구매하신 상품이
        같은 날짜에 입항하는 경우 관부가세 발생내역이 달라질 수 있으니 구매 전에
        미리 참고 부탁 드립니다.
      </p>
    </div>
  );
};

export default TaxInfo;
