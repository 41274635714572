import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { borderColor } from "../../../common/style/styles";
import CancelModal from "./cancel_modal";
import UploadFileModal from "./upload_file_modal";

const ChangeStatusButton = ({
  selectedIDs,
  handleChangeStatus,
  handleUploadExcel,
  handleCancelSale,
}) => {
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleUploadFileModalChange = () => {
    setIsUploadFileModalOpen((prev) => !prev);
  };

  const handleCancelModalChange = () => {
    setIsCancelModalOpen((prev) => !prev);
  };

  const handleCancelButtonClick = () => {
    // 선택된 제품이 없으면 return
    if (!selectedIDs.length) {
      alert("판매 취소하려는 상품을 선택해주세요.");
      return;
    }

    handleCancelModalChange();
  };

  return (
    <Box
      margin="4px 0"
      padding="8px"
      border={"1px solid " + borderColor}
      display="flex"
    >
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handleChangeStatus(2);
        }}
      >
        선택건 발주 확인
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handleChangeStatus(3);
        }}
      >
        선택건 발송 처리
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={handleUploadFileModalChange}
      >
        엑셀 일괄 발송처리
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={handleCancelButtonClick}
      >
        판매 취소
      </Button>
      <UploadFileModal
        isOpened={isUploadFileModalOpen}
        handleModalChange={handleUploadFileModalChange}
        handleUploadExcel={handleUploadExcel}
      />
      <CancelModal
        StateName={"판매 취소"}
        isOpened={isCancelModalOpen}
        handleModalChange={handleCancelModalChange}
        handleCancelSale={handleCancelSale}
      />
    </Box>
  );
};

export default ChangeStatusButton;
