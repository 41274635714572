import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import {
  BREAK_POINT_PC,
  grey2,
  secondary,
  StyledLink,
} from "../../../common/style/styles";

// 개인정보
const PersonalInfo = ({ userInfo }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      padding="2rem 1rem"
      sx={{ background: secondary, color: "white" }}
      flexDirection={windowSize.width >= BREAK_POINT_PC ? "row" : "column"}
    >
      {/* 이름, 이메일 */}
      <Box flex="1" marginLeft="2rem">
        <Typography
          textAlign="center"
          variant={
            windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
          }
          fontWeight="700"
        >
          {userInfo.user_name} 님
        </Typography>
        <Box
          padding={
            windowSize.width >= BREAK_POINT_PC ? "0.3rem 0" : "0.3rem 0.5rem"
          }
          marginTop={windowSize.width >= BREAK_POINT_PC ? "0" : "0.2rem"}
          sx={{ background: grey2 }}
          borderRadius="1rem"
        >
          <Typography
            textAlign="center"
            variant={windowSize.width >= BREAK_POINT_PC ? "body1" : "body2"}
          >
            {userInfo.user_email}
          </Typography>
        </Box>
      </Box>
      {windowSize.width < BREAK_POINT_PC && (
        <Divider
          flexItem
          orientation="horizental"
          sx={{ background: grey2, margin: "1rem 0" }}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-evenly"
        flex="3"
        width={windowSize.width >= BREAK_POINT_PC ? "fit-content" : "100%"}
        sx={{ cursor: "pointer" }}
      >
        {windowSize.width >= BREAK_POINT_PC && (
          <Divider flexItem orientation="vertical" sx={{ background: grey2 }} />
        )}
        {/* 포인트 */}
        <StyledLink to="/point">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              color="white"
              textAlign="center"
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
              }
            >
              {userInfo.point}P
            </Typography>
            <Typography
              color="white"
              textAlign="center"
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
            >
              포인트 &gt;
            </Typography>
          </Box>
        </StyledLink>
        {/* 쿠폰 */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ cursor: "pointer" }}
        >
          <Typography
            textAlign="center"
            variant={
              windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
            }
          >
            {userInfo.coupon}장
          </Typography>
          <Typography
            textAlign="center"
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          >
            쿠폰 &gt;
          </Typography>
        </Box>
        {/* 구매후기 */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ cursor: "pointer" }}
        >
          <Typography
            textAlign="center"
            variant={
              windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
            }
          >
            {userInfo.review}개
          </Typography>
          <Typography
            textAlign="center"
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          >
            구매후기 &gt;
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInfo;
