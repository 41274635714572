import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import MenuButton from "../../../common/components/menu_button";
import Banner from "../../../common/components/banner";
import CurationList from "../components/curation_list";
import { useQueries, useQuery } from "react-query";
import { getDataFromServer } from "../../../common/network/network";
import { useRecoilState } from "recoil";
import {
  selectedMenuState,
  windowSizeState,
} from "../../../common/state/state";
import { BREAK_POINT_PC, Column, primary } from "../../../common/style/styles";
import { Box, Divider } from "@mui/material";
import kakaoChanelIcon from "../../../media_assets/image/consult_small_yellow_pc.png";

export const Index = (props) => {
  const [selectedMenu, setSelectedMenu] = useRecoilState(selectedMenuState);
  const [isInit, setIsInit] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const menuStyle = {
    margin: `${windowSize.width >= BREAK_POINT_PC ? "0 3rem" : "0.5rem 1rem"}`,
    padding: `${
      windowSize.width >= BREAK_POINT_PC ? "0.8rem 1.5rem" : "0.4rem 0.5rem"
    }`,
    maxHeight: "54x",
    fontSize: `${windowSize.width >= BREAK_POINT_PC ? "18pt" : "12pt"}`,
    justifyContent: "center",
  };

  // 탭 변경
  const handleChange = (event) => {
    setSelectedMenu(event.target.value);
  };

  const results = useQueries([
    // 배너 데이터 가져오기
    {
      queryKey: "bannerData",
      queryFn: () => {
        return getDataFromServer("/service/notices");
      },
    },
    // 큐레이션 메뉴 탭 데이터 가져오기
    {
      queryKey: "curationsMenuData",
      queryFn: () => {
        return getDataFromServer("/service/menu?name=curations");
      },
    },
  ]);

  useEffect(() => {
    // console.log(results);
    if (!isInit) {
      return;
    }
    if (results[1].isSuccess) {
      setSelectedMenu(results[1].data.menu[0].id);
      setMenuList(results[1].data.menu);
      setIsInit(false);
    }
  }, [results]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    try {
      if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
          kakao.init(process.env.REACT_APP_kakao_js_key);
        }
      }

      window.Kakao.Channel.createChatButton({
        container: "#kakao-talk-channel-chat-button",
        channelPublicId: process.env.REACT_APP_kakao_channel_id,
        title: "consult",
        size: "small",
        color: "yellow",
        shape: "pc",
        supportMultipleDensities: true,
      });

      document.body.appendChild(script);
      document.body.removeChild(script);
    } catch (err) {}
  }, []);

  return (
    <>
      <Box height={results[0].isLoading && "100vh"}>
        {results[0].isSuccess && (
          <Banner
            data={results[0].data.banner}
            height={
              windowSize.width >= BREAK_POINT_PC
                ? (windowSize.width * 100) / 220
                : (windowSize.width * 100) / 150
            }
          />
        )}
        {results[1].isSuccess && (
          <MenuButton
            menuList={results[1].data.menu}
            selectedMenu={selectedMenu}
            handleChange={handleChange}
            style={menuStyle}
          />
        )}
        <Divider />
        <Column xs={12} sm={12} md={12} lg={9}>
          {selectedMenu && <CurationList />}
        </Column>
      </Box>
      <Box sx={{ position: "fixed", bottom: 140, right: 16 }}>
        <div id="kakao-talk-channel-chat-button"></div>
      </Box>
      <Footer />
    </>
  );
};
