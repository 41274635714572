import { Box, Typography, Divider } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, info, primary } from "../../../common/style/styles";
import itemDeliveryIcon from "../../../media_assets/image/item-delivery.svg";

const DeliveryInfo = ({ itemInfo }) => {
  const deliveryCountry = itemInfo.price_list
    .map((value) => {
      if (value.country !== "한국") {
        return value.country;
      }
    })
    .filter((value) => value)
    .join(",");
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <Box>
      {windowSize.width >= BREAK_POINT_PC ? (
        <Box>
          <Box display="flex">
            <img src={itemDeliveryIcon} />
            <Typography variant="subtitle1" marginLeft="0.5rem">
              배송정보
            </Typography>
          </Box>
          <Divider />
        </Box>
      ) : null}
      <Box
        display="flex"
        margin={
          windowSize.width >= BREAK_POINT_PC ? "1rem 0 0.5rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="1"
          color={info}
        >
          배송가능국가
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="2"
          fontWeight="500"
        >
          {deliveryCountry}에서 출고 가능
        </Typography>
      </Box>
      <Box
        display="flex"
        margin={
          windowSize.width >= BREAK_POINT_PC
            ? "0.5rem 0 0.5rem 0"
            : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="1"
          color={info}
        >
          배송기간
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="2"
          color={primary}
          fontWeight="500"
        >
          7~14일 이내 배송예상
        </Typography>
      </Box>
      <Box
        display="flex"
        margin={
          windowSize.width >= BREAK_POINT_PC
            ? "0.5rem 0 0.5rem 0"
            : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="1"
          color={info}
        >
          배송비
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="2"
          fontWeight="500"
        >
          기본 10,000원{windowSize.width < BREAK_POINT_PC && <br />} (출고국가에
          따라 변동 가능)
        </Typography>
      </Box>
      <Box
        display="flex"
        margin={
          windowSize.width >= BREAK_POINT_PC ? "0.5rem 0 1rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="1"
          color={info}
        >
          관부가세
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          flex="2"
          fontWeight="500"
        >
          {itemInfo.item_info.item_price_has_tax_rate ? "포함" : "미포함"}
        </Typography>
      </Box>
      <Divider
        sx={{
          borderWidth: `${windowSize.width >= BREAK_POINT_PC ? "0" : "1px"}`,
        }}
      />
    </Box>
  );
};

export default DeliveryInfo;
