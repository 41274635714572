import { Reviews } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { secondary, grey, grey3, BREAK_POINT_PC } from "../style/styles";
import defaultImg from "../../media_assets/image/gallery1.jpg";
import { convertDate, convertDateTime, selectItemImages } from "../js/common";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../state/state";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ActionModal from "../../service/order/modal/action_modal";
import { deleteDataFromServer } from "../network/network";

// 리뷰 개별 카드 아이템
const ReviewItem = ({ review, canEdit }) => {
  // console.log(review);
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [isOpened, setIsOpened] = useState(false);
  const [processing, setProcessiong] = useState(false);

  const handleClickModal = () => {
    setIsOpened((prev) => !prev);
  };

  const handleEdit = () => {};

  const handleDelete = () => {
    setProcessiong(true);
    deleteDataFromServer(`/service/reviews/${review.id}`)
      .then((response) => {
        alert("리뷰가 삭제됐습니다.");
        handleClickModal();
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      });
    setProcessiong(false);
  };
  return (
    <>
      {review ? (
        <Box
          margin={
            windowsize.width >= BREAK_POINT_PC ? "0.5rem 1rem" : "0.5rem 0"
          }
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          {!canEdit ? (
            <Box
              margin={
                windowsize.width >= BREAK_POINT_PC ? "2px 0 4px 0" : "1rem 2rem"
              }
            >
              {/* 만족도 */}
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                }
                fontWeight="700"
                marginRight="1rem"
              >
                {review.rating === 0 ? '"별로에요"' : '"만족해요"'}
              </Typography>
              <Box display="flex">
                {/* 유저 정보 */}
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                  }
                  margin="0 4px 0 0"
                  fontWeight="700"
                >
                  {review.user_email} / {review.user_name}
                </Typography>
                {/* 작성일 */}
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                  }
                  margin="0 0 0 4px"
                  color={grey}
                >
                  {convertDate(new Date(review.created_at))}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box margin="1rem" display="flex" justifyContent="space-between">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                }
                fontWeight="700"
              >
                {review.rating === 0 ? '"별로에요"' : '"만족해요"'}
              </Typography>
              <MoreHorizIcon
                onClick={handleClickModal}
                sx={{ cursor: "pointer" }}
              />
            </Box>
          )}
          {windowsize.width < BREAK_POINT_PC ? <Divider /> : null}
          {/* 구매한 상품 정보 */}
          <Box
            margin={windowsize.width >= BREAK_POINT_PC ? "2rem 0" : "1rem 2rem"}
            display="flex"
          >
            <img
              style={{
                backgroundImage: `url(${defaultImg})`,
                height: `${
                  windowsize.width >= BREAK_POINT_PC ? "120px" : "85px"
                }`,
                margin: "0 4px 0 0",
              }}
              src={selectItemImages(review.item_images, review.color)[0]}
              loading="lazy"
              height="100%"
            />
            <Box>
              <Box display="flex">
                {windowsize.width < BREAK_POINT_PC ? (
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    marginLeft="1rem"
                    color={grey3}
                    flex="2"
                  >
                    브랜드
                  </Typography>
                ) : null}
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                  }
                  fontWeight="500"
                  margin={
                    windowsize.width >= BREAK_POINT_PC ? "0 0.5rem 0 1rem" : "0"
                  }
                  flex="4"
                >
                  {review.brand_name}
                </Typography>
              </Box>
              <Box display="flex">
                {windowsize.width < BREAK_POINT_PC ? (
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    marginLeft="1rem"
                    color={grey3}
                    flex="2"
                  >
                    상품명
                  </Typography>
                ) : null}
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                  }
                  fontWeight={
                    windowsize.width >= BREAK_POINT_PC ? "700" : "500"
                  }
                  margin={
                    windowsize.width >= BREAK_POINT_PC ? "0 0.5rem 0 1rem" : "0"
                  }
                  noWrap={true}
                  flex="4"
                >
                  {review.item_name}
                </Typography>
              </Box>

              {windowsize.width >= BREAK_POINT_PC ? (
                <Typography
                  variant="subtitle1"
                  fontWeight="500"
                  color={grey3}
                  margin="0 0.5rem 0 1rem"
                >
                  옵션 : {review.color} / {review.size}
                </Typography>
              ) : (
                <Box display="flex">
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    marginLeft="1rem"
                    color={grey3}
                    flex="2"
                  >
                    옵션
                  </Typography>
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    noWrap={true}
                    flex="4"
                  >
                    {review.color} / {review.size}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {windowsize.width < BREAK_POINT_PC ? <Divider /> : null}
          {/* 리뷰 내용*/}
          <Box
            margin={
              windowsize.width >= BREAK_POINT_PC ? "2px 0 4px 0" : "1rem 2rem"
            }
          >
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
              }
            >
              {review.content}
            </Typography>
            <Box
              margin={
                windowsize.width >= BREAK_POINT_PC ? "2rem 0" : "1.5rem 0"
              }
            >
              {review.images &&
                review.images.map((img) => (
                  <img
                    style={{
                      backgroundImage: `url(${defaultImg})`,
                      height: `${
                        windowsize.width >= BREAK_POINT_PC ? "240px" : "130px"
                      }`,
                      margin: `${
                        windowsize.width >= BREAK_POINT_PC
                          ? "0 1.2rem 0 0"
                          : "0 0.5rem 0 0"
                      }`,
                    }}
                    src={img}
                    srcSet={img}
                    loading="lazy"
                    height="100%"
                  />
                ))}
            </Box>
          </Box>
          <ActionModal
            isOpened={isOpened}
            handleClickModal={handleClickModal}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ReviewItem;
