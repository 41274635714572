import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Column, borderColor, primary } from "../../../common/style/styles";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BuyModal from "../../../common/modal/buy_modal";

const BuyButton = ({ sizeList, isLike, itemInfo, handleChangeIsLike }) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleClickModal = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ top: "auto", bottom: 0, background: "white" }}
    >
      <Column xs={12} sm={12} md={12} lg={9}>
        <Toolbar>
          <Box
            display="flex"
            // justifyContent="space-between"
            width="100%"
            margin="0.5rem 1.5rem"
            alignItems="center"
          >
            <Button
              variant="contained"
              style={{ flex: 9, borderRadius: "40px", background: "black" }}
              onClick={handleClickModal}
            >
              <Typography variant="body1" fontWeight={"700"} padding="0.5rem 0">
                구매하기
              </Typography>
            </Button>
            <Box
              display="flex"
              justifyContent="center"
              flex="1"
              marginLeft="1rem"
            >
              {isLike ? (
                <FavoriteIcon
                  sx={{ color: primary, cursor: "pointer" }}
                  onClick={() => {
                    handleChangeIsLike("delete");
                  }}
                />
              ) : (
                <FavoriteBorderIcon
                  sx={{ color: borderColor, cursor: "pointer" }}
                  onClick={() => {
                    handleChangeIsLike("add");
                  }}
                />
              )}
            </Box>
          </Box>
        </Toolbar>
      </Column>
      <BuyModal
        isOpened={isOpened}
        handleClickModal={handleClickModal}
        sizeList={sizeList}
        itemInfo={itemInfo}
      />
    </AppBar>
  );
};

export default BuyButton;
