import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useParams } from "react-router-dom";
import MainMenu from "../../../common/components/main_menu";
import {
  deleteDataFromServer,
  getDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { primary } from "../../../common/style/styles";
import DataTable from "../components/data_table";
import OptionHeader from "../components/option_header";

const NoticeIndex = () => {
  const { pathname } = useLocation();
  const { submenu } = useParams();
  const [isAdmin, setIsAdmin] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 데이터 순서 변경
  const [dataOrder, setDataOrder] = useState({
    source: "",
    target: "",
  });

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = dataList.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 드래그 동작
  const handleDrag = (event, id) => {
    switch (event.type) {
      // 드래그 시작
      case "dragstart":
        setDataOrder({ ...dataOrder, source: id });
        break;
      // 변경하고 싶은 순서에 있는 데이터 위로 드래그
      case "dragover":
        setDataOrder({ ...dataOrder, target: id });
        break;
      // 변경하고 싶은 위치로 데이터 옮김
      case "dragend":
        handleChangeOrder();
        break;
      default:
        break;
    }
  };

  // 데이터 순서 변경
  const handleChangeOrder = () => {
    if (processing) {
      return;
    }
    const targetInfo = dataList.find((item) => item.id === dataOrder.target);
    const sourceInfo = dataList.find((item) => item.id === dataOrder.source);

    let tempDataList = dataList.filter((data) => data.id !== dataOrder.source);

    const targetIndex = dataList
      .map((data, index) => {
        if (data.id === dataOrder.target) {
          return index;
        }
      })
      .filter((data) => data !== undefined)[0];

    tempDataList.splice(parseInt(targetIndex), 0, sourceInfo);

    const changedData = tempDataList.map((data, index) => {
      return { ...data, notice_order: index + 1 };
    });

    setLoading(true);
    setProcessing(true);

    putDataFromServer(`/admin/etc/${submenu}/order`, {
      data: changedData,
    })
      .then((response) => {
        setDataList([...changedData]);
        setDataOrder({ source: "", target: "" });
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  // 삭제
  const handleDelete = () => {
    if (!selectedIDs.length) {
      alert("삭제할 데이터를 선택해주세요.");
      return;
    }

    if (processing) {
      return;
    }

    setLoading(true);
    setProcessing(true);

    deleteDataFromServer(`/admin/etc/${submenu}/${selectedIDs}`)
      .then((response) => {
        alert("삭제가 완료되었습니다.");
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  useEffect(() => {
    getDataFromServer(`/admin/etc/${submenu}`)
      .then((response) => {
        setIsAdmin(true);

        setDataList([...response.data]);
      })
      .catch((error) => {
        setIsAdmin(false);
      });
  }, [submenu]);

  return (
    <>
      {isAdmin && (
        <Box display="flex">
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <OptionHeader submenu={submenu} handleDelete={handleDelete} />
            {dataList.length > 0 ? (
              <Box sx={{ overflow: "auto" }}>
                <DataTable
                  submenu={submenu}
                  dataList={dataList}
                  selectedIDs={selectedIDs}
                  handleClickCheckBox={handleClickCheckBox}
                  handleDrag={handleDrag}
                />
              </Box>
            ) : (
              <Typography textAlign="center" variant="h4" margin="32px 0">
                데이터가 없습니다.
              </Typography>
            )}
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
      ;
    </>
  );
};

export { NoticeIndex as NoticeManagement };
