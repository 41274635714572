import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  Chip,
  InputLabel,
} from "@mui/material";
import { borderColor, grey } from "../../../common/style/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDataFromServer } from "../../../common/network/network";

const EditData = ({ columns, rows, handleChange, handleSaveData }) => {
  const [newColumns, setNewColumns] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    if (categoryList.length === 0) {
      const tempColumns = columns.map((value) => {
        if (value.column === "brands") {
          getDataFromServer("/service/brands?token=null").then((response) => {
            setBrandList([
              ...value.option,
              ...response.brand_list.map((data) => {
                return { name: data.name, value: data.id };
              }),
            ]);
            value.option = [
              ...value.option,
              ...response.brand_list.map((data) => {
                return { name: data.name, value: data.id };
              }),
            ];
          });
        }
        if (value.column === "categories") {
          getDataFromServer("/service/categories").then((response) => {
            setCategoryList([
              ...value.option,
              ...response.category_list.one.map((data) => {
                return {
                  name: `${response.category_list.zero
                    .map((value) => {
                      if (value.id === data.ancestry) {
                        return value.name;
                      }
                    })
                    .find((value) => value)}-${data.name}`,
                  value: data.id,
                };
              }),
            ]);
            value.option = [
              ...value.option,
              ...response.category_list.one.map((data) => {
                return {
                  name: `${response.category_list.zero
                    .map((value) => {
                      if (value.id === data.ancestry) {
                        return value.name;
                      }
                    })
                    .find((value) => value)}-${data.name}`,
                  value: data.id,
                };
              }),
            ];
          });
        }
        return value;
      });

      setNewColumns([...tempColumns]);
    }
  }, []);

  return (
    <Box margin="16px">
      <Box margin="8px 0" display="flex">
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleSaveData}
        >
          정보 저장
        </Button>
      </Box>
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem
          style={{ backgroundColor: `${grey}`, flexDirection: "column" }}
        >
          <Typography variant="subtitle1">데이터 </Typography>
          <Typography>
            *표시 항목은 필수 항목, 이외는 선택 항목입니다.
          </Typography>
        </ListItem>
        <Divider />
        {newColumns.map((value) => (
          <ListItem
            key={value.column}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Typography variant="subtitle2" component="p" flex="1">
                {value.name}
              </Typography>
              {value.type === "select" && (
                <Select
                  name={value.column}
                  value={rows[value.column] != null ? rows[value.column] : null}
                  onChange={handleChange}
                  label="filter"
                  sx={{ flex: "5" }}
                >
                  {value.option.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id ? option.id : option.value}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {(value.type === "string" || value.type === "number") && (
                <TextField
                  sx={{ flex: "5" }}
                  value={rows[value.column]}
                  name={value.column}
                  type={value.type}
                  onChange={handleChange}
                />
              )}
              {value.type === "date" && (
                <Box flex={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <DatePicker
                        views={["day"]}
                        label={value.name}
                        value={
                          rows[value.column] ? dayjs(rows[value.column]) : null
                        }
                        onChange={(newValue) => {
                          handleChange({
                            target: { name: value.column, value: newValue },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                        format="YYYY-MM-DD"
                      />
                    </Stack>
                  </LocalizationProvider>
                </Box>
              )}
              {value.type === "string and select" && (
                <Box flex={5}>
                  <TextField
                    sx={{ flex: "1" }}
                    value={rows.discount}
                    name="discount"
                    type={value.type}
                    onChange={handleChange}
                  />
                  <Select
                    name="discount_type"
                    value={rows.discount_type ? rows.discount_type : null}
                    onChange={handleChange}
                    label="filter"
                    sx={{ flex: "1" }}
                  >
                    {value.option.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id ? option.id : option.value}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
              {value.type === "multi-select" && brandList.length !== 0 && (
                <Box flex={5}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel>{value.name}</InputLabel>
                    <Select
                      name={value.column}
                      value={rows[value.column] ? rows[value.column] : []}
                      label={value.name}
                      onChange={handleChange}
                      multiple
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {categoryList.length > 0 && brandList.length > 0
                            ? selected.map((select) => (
                                <Chip
                                  key={select}
                                  label={
                                    value.column == "brands"
                                      ? brandList.find(
                                          (brand) => select === brand.value
                                        ).name
                                      : categoryList.find(
                                          (category) =>
                                            select === category.value
                                        ).name
                                  }
                                />
                              ))
                            : null}
                        </Box>
                      )}
                      //   MenuProps={MenuProps}
                    >
                      <MenuItem value="">None</MenuItem>
                      {value.option.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.id ? option.id : option.value}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default EditData;
