import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
const register = require("babel-register");

register({
  presets: ["es2015", "react", "stage-0"],
  extensions: [".jsx"],
});

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartGraph = ({ labels, totalPrice, orderPrice, cancelPrice }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "판매액 그래프",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "총 주문금액",
        data: totalPrice,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "순 주문금액",
        data: orderPrice,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "취소금액",
        data: cancelPrice,
        borderColor: "rgb(53, 162, 35)",
        backgroundColor: "rgba(53, 162, 35, 0.5)",
      },
    ],
  };

  return (
    <>
      {" "}
      <Line options={options} data={data} />{" "}
    </>
  );
};

export default ChartGraph;
