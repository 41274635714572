import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import MainMenu from "../../../common/components/main_menu";

import columnData from "../../../common/json/detail_data_columns.json";
import DataList from "../components/data_list";
import {
  deleteDataFromServer,
  getDataFromServer,
} from "../../../common/network/network";
import { preproccessingForDataTable } from "../../../common/js/common";
import EditData from "../components/edit_data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../../../common/style/styles";

// 데이터 상세보기, 추가, 수정
const Show = () => {
  const { mode, id, detailId, optionId } = useParams();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const columns = columnData[`/admin/items/item/${mode}/`];
  const [rows, setRows] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // list 페이지에서 상세페이지로 넘어와서 state가 존재할 때 localStorage로 데이터 저장
    if (state) {
      localStorage.setItem(
        "adminItemListData",
        JSON.stringify({ ...state, currentItem: pathname })
      );
    }

    setLoading(true);
    // 로그인 정보 확인
    getDataFromServer("/admin/users/email")
      .then((response) => {
        setIsAdmin(true);
      })
      .catch((error) => {
        setIsAdmin(false);
      });

    if (mode == "create") {
      setLoading(false);
      return;
    }

    const path = `/admin/items/${id}/${detailId}/${optionId}?status=${mode}`;
    getDataFromServer(path)
      .then((response) => {
        setIsAdmin(true);
        setRows({ ...response });
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDelete = () => {
    deleteDataFromServer(`/admin/items?id=${optionId}`).then((response) => {
      alert("상품이 삭제 처리되었습니다.");
      navigate(-1);
    });
  };

  return (
    <>
      {isAdmin && (
        <Box display="flex">
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {mode === "show" && id && Object.keys(rows).length && (
              <DataList
                columns={columns}
                rows={rows}
                handleDelete={handleDelete}
              />
            )}
            {(mode === "edit" || mode === "create") && id && (
              <EditData
                params={{
                  mode: mode,
                  id: id,
                  detailId: detailId,
                  optionId: optionId,
                }}
                columns={columns}
                rows={rows}
              />
            )}
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { Show as ItemDetail };
