import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  borderColor,
  lightgrey,
  lightcoral,
  primary,
  StyledLink,
} from "../../../common/style/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getDataFromServer } from "../../../common/network/network";

const MainMenuEditData = ({
  id,
  columns,
  itemInfo,
  handleChangeData,
  handleSaveData,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [infoColumns, setInfoColumns] = useState(columns);

  // 입력/보기 정보 변경됐을 때 리렌더링
  useEffect(() => {
    setInfoColumns([...columns]);
  }, [columns, itemInfo]);

  useEffect(() => {
    pathname.includes("curation") &&
      getDataFromServer("/admin/menu/main").then((response) => {
        console.log(response, infoColumns);
        const newInfo = infoColumns.map((value) => {
          if (value.field === "menu_id") {
            return { ...value, option: response.items };
          }
          return value;
        });

        setInfoColumns([...newInfo]);
      });
  }, []);

  return (
    <Box margin="16px">
      <Box margin="8px 0" display="flex">
        <Button
          variant="outlined"
          style={{
            borderRadius: "unset",
            margin: "0 8px",
          }}
          onClick={handleSaveData}
        >
          메인 메뉴 정보 저장
        </Button>
      </Box>

      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          데이터 {pathname.includes("create") ? "생성" : "수정"}
        </ListItem>
        <Divider />

        {infoColumns.map((value) => (
          <ListItem
            key={value.field}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Typography component="p" flex="1">
                {value.name}
              </Typography>
              {value.type === "select" ? (
                <FormControl
                  variant="standard"
                  sx={{ flex: "5", margin: "8px 0" }}
                >
                  <InputLabel>선택</InputLabel>
                  <Select
                    name={value.field}
                    value={
                      itemInfo[value.field] !== "" ? itemInfo[value.field] : ""
                    }
                    onChange={handleChangeData}
                    label="filter"
                  >
                    {value.option.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id ? option.id : option.value}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : value.type === "string" || value.type === "number" ? (
                <TextField
                  sx={{ flex: "5", margin: "8px 0" }}
                  size="small"
                  name={value.field}
                  value={itemInfo[value.field] ? itemInfo[value.field] : ""}
                  onChange={handleChangeData}
                  type={value.type}
                  step="0.01"
                />
              ) : value.type === "image" ? (
                <Box flex="5" margin="8px 0">
                  <Box margin="8px 0">
                    <Button
                      variant="outlined"
                      style={{ borderRadius: "unset" }}
                    >
                      <label
                        htmlFor="size"
                        style={{
                          cursor: "pointer",
                          padding: "8px",
                        }}
                      >
                        이미지 파일 선택
                      </label>
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      id="size"
                      onChange={handleChangeData}
                    />
                  </Box>
                  {itemInfo[value.field] !== "NULL" && (
                    <img
                      src={
                        typeof itemInfo[value.field] === "object"
                          ? URL.createObjectURL(itemInfo[value.field])
                          : itemInfo[value.field]
                      }
                      width="80%"
                    />
                  )}
                </Box>
              ) : (
                <Typography component="p" flex="5">
                  {itemInfo[value.field]}
                </Typography>
              )}
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MainMenuEditData;
