import { Box, Button } from "@mui/material";
import React from "react";
import { borderColor } from "../../../common/style/styles";

const ChangeStatus = ({ handleModalChange, handelDeleteSaleItem }) => {
  return (
    <Box
      margin="4px 0"
      padding="8px"
      border={"1px solid " + borderColor}
      display="flex"
    >
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handleModalChange("curation");
        }}
      >
        이벤트 추가
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handleModalChange("status");
        }}
      >
        상품 상태 변경
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
      >
        판매가 변경
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handleModalChange("saleItem");
        }}
      >
        어드민 할인 설정
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handelDeleteSaleItem();
        }}
      >
        어드민 할인 해제
      </Button>
    </Box>
  );
};

export default ChangeStatus;
