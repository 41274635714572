import { Box } from "@material-ui/core";
import { List, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainMenu from "../../../common/components/main_menu";
import { borderColor, StyledLink } from "../../../common/style/styles";
import styled from "styled-components";
import { getDataFromServer } from "../../../common/network/network";
import { Audio, TailSpin } from "react-loader-spinner";
import { primary } from "../../../common/style/styles";
import ShippingProcess from "../components/shipping_process";
import CSProcess from "../components/cs_process";
import ChartGraph from "../components/chart";
import {
  convertDate,
  getDatesStartToLast,
  inputNumberWithComma,
} from "../../../common/js/common";

const Index = () => {
  const StyledTypography = styled.p`
    font-size: 12pt;
    flex: 1;
    margin: 0;
    text-decoration: ${({ link }) => link && "underline"};
    cursor: pointer;
  `;

  const [orderCount, setOrderCount] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadling, setLoading] = useState(false);
  const [label, setLabel] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [orderPrice, setOrderPrice] = useState([]);
  const [cancelPrice, setCancelPrice] = useState([]);

  useEffect(() => {
    setLoading(true);

    const now = new Date();
    const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    const koreaTimeDiff = 9 * 60 * 60 * 1000;
    const koreaNow = new Date(utcNow + koreaTimeDiff);
    const startDate = convertDate(koreaNow);

    const tempLabel = getDatesStartToLast(
      convertDate(new Date(koreaNow.setDate(koreaNow.getDate() - 30))),
      startDate
    );

    setLabel(tempLabel);

    getDataFromServer("/admin/dashboard")
      .then((response) => {
        setIsAdmin(true);
        setOrderCount({ ...response.count });
        setLoading(false);

        let tempTotalPriceList = [];
        let tempOrderPriceList = [];
        let tempCancelPriceList = [];

        for (const date of tempLabel) {
          const orderData = response.order_price.list.filter(
            (value) => convertDate(new Date(value.paid_at * 1000)) === date
          );
          const orderPrice = orderData.reduce((a, b) => a + b.amount, 0);
          tempOrderPriceList.push(orderPrice);

          const cancelData = response.cancel_price.list.filter(
            (value) => convertDate(new Date(value.cancelled_at * 1000)) === date
          );

          const cancelPrice = cancelData.reduce(
            (a, b) => a + b.cancel_amount,
            0
          );
          tempCancelPriceList.push(cancelPrice);

          tempTotalPriceList.push(orderPrice + cancelPrice);
        }

        setTotalPrice(tempTotalPriceList);
        setOrderPrice(tempOrderPriceList);
        setCancelPrice(tempCancelPriceList);
      })
      .catch((error) => {
        setIsAdmin(false);
      });
  }, []);

  return (
    <>
      {isAdmin && (
        <Box display="flex">
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <Box display="flex">
              {/* 배송 처리 */}
              <ShippingProcess orderCount={orderCount} />
              {/* 취소, 반품, 문의 처리 */}
              <CSProcess orderCount={orderCount} />
            </Box>
            {/* 판매액 그래프 */}
            <Box
              border={"1px solid " + borderColor}
              margin="4px"
              padding="1rem"
            >
              <List>
                <ListItem>
                  <StyledTypography>
                    총 주문금액 :{" "}
                    {inputNumberWithComma(
                      totalPrice.reduce((a, b) => a + b, 0)
                    )}{" "}
                    원
                  </StyledTypography>
                </ListItem>
                <ListItem>
                  <StyledTypography>
                    순 주문금액 :{" "}
                    {inputNumberWithComma(
                      orderPrice.reduce((a, b) => a + b, 0)
                    )}{" "}
                    원
                  </StyledTypography>
                </ListItem>

                <ListItem>
                  <StyledTypography>
                    취소금액 :{" "}
                    {inputNumberWithComma(
                      cancelPrice.reduce((a, b) => a + b, 0)
                    )}{" "}
                    원
                  </StyledTypography>
                </ListItem>
              </List>
              <Box margin="0.5rem">
                <ChartGraph
                  labels={label}
                  totalPrice={totalPrice}
                  orderPrice={orderPrice}
                  cancelPrice={cancelPrice}
                />
              </Box>
            </Box>

            <Box
              width="50%"
              border={"1px solid " + borderColor}
              margin="4px"
              padding="4px"
            >
              <List>
                <ListItem>
                  <StyledTypography>신규 가입자 수</StyledTypography>
                  <StyledTypography>XXX 명</StyledTypography>
                </ListItem>
                <ListItem>
                  <StyledTypography>탈퇴자 수</StyledTypography>
                  <StyledTypography>XXX 명</StyledTypography>
                </ListItem>
                <ListItem>
                  <StyledTypography>방문자 수</StyledTypography>
                  <StyledTypography>XXX 명</StyledTypography>
                </ListItem>
                <ListItem>구글 에널리틱스 지표</ListItem>
              </List>
            </Box>
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { Index as DashBoard };
