import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { borderColor, primary } from "../../../common/style/styles";
import ItemStatus from "./item_status";
import styled from "styled-components";
import inquiryData from "../../../common/json/inquiry_condition.json";
import { useLocation } from "react-router-dom";
import { getDataFromServer } from "../../../common/network/network";
import ClearIcon from "@mui/icons-material/Clear";

const StyledTypography = styled.p`
  font-size: 12pt;
  flex: 1;
  margin: 0;
  text-align: center;
  display: inline;
`;

const Container = styled.div`
  margin: 16px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  margin: 8px;
  border: 1px solid ${primary};
  border-radius: unset;
  background-color: white;
  color: ${primary};
  padding: 8px 16px;
  cursor: pointer;
`;

const SearchHeader = ({
  itemStatusLoding,
  condition,
  status,
  countOfItemStatus,
  handleStatusOfParamsChange,
  handleChangeInquiry,
  handleClickInquiry,
}) => {
  const { pathname } = useLocation();
  // 조회 조건 선택 값
  const [filter, setFilter] = useState("");
  // 조회 값 입력 값
  const [search, setSearch] = useState("");
  // 판매가 조건
  const [price, setPrice] = useState({ minPrice: 0, maxPrice: 0 });
  // 큐레이션
  const [curation, setCuration] = useState("");
  // 조회 조건 select 리스트 (브랜드 리스트, 카테고리 리스트 )
  const [selectList, setSelectList] = useState({
    brand_name: [],
    category_name: [],
  });
  // 큐레이션 리스트
  const [curationList, setCurationList] = useState([]);

  // 조회조건 변경 (조회값 초기화)
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setSearch("");
  };

  // 조회값 변경
  const handleSearchWordChange = (event) => {
    setSearch(event.target.value);
  };

  // 큐레이션 선택 변경
  const handleCurationChange = (event) => {
    setCuration(event.target.value);
  };

  // 최소 금액, 최대 금액 변경
  const handlePriceChange = (event) => {
    const { name, value } = event.target;
    if (value < 0) {
      return;
    }
    setPrice({ ...price, [name]: value });
  };

  useEffect(() => {
    if (!selectList.brand_name.length && !selectList.category_name.length) {
      let newSelectList = {};

      getDataFromServer("/service/brands?token=null").then(
        (response) => (newSelectList.brand_name = response.brand_list)
      );
      getDataFromServer("/service/categories").then((response) => {
        newSelectList.category_name = [
          ...response.category_list.one.map((data) => {
            return {
              name: `${response.category_list.zero
                .map((value) => {
                  if (value.id === data.ancestry) {
                    return value.name;
                  }
                })
                .find((value) => value)}-${data.name}`,
              id: data.id,
            };
          }),
        ];
      });

      setSelectList(newSelectList);
    }

    if (!curationList.length) {
      getDataFromServer("/service/curations").then((response) =>
        setCurationList([...response.curation_list])
      );
    }
  }, []);

  // 조회조건 버튼 클릭
  const handleAddButtonClick = (condition) => {
    switch (condition) {
      case "filter":
        if (!filter) {
          alert("조회조건을 선택해주세요.");
          return;
        }

        if (!search) {
          alert("조회값을 입력해주세요.");
          return;
        }

        handleChangeInquiry(
          "add",
          "filter",
          {
            id: Date.now(),
            field: {
              name: filter.split(":")[1],
              value: filter.split(":")[0],
            },
            data: {
              name: search.split(":")[1],
              value: search.split(":")[0],
            },
          },
          null
        );
        break;

      case "price":
        // 최소 금액 : 0, 최대 금액 : 0 일 경우 리턴
        if (price.minPrice === 0 && price.maxPrice === 0) {
          alert("판매가를 설정해주세요.");
          return;
        }
        // 최소 금액 > 최대 금액 일 경우 리턴
        if (price.minPrice > price.maxPrice) {
          alert("최소 금액이 최대 금액보다 높게 설정되어 있습니다.");
          return;
        }

        handleChangeInquiry(
          "add",
          "price",
          {
            id: Date.now(),
            field: { name: "판매가", value: "price" },
            data: {
              name: `${price.minPrice} ~ ${price.maxPrice} `,
              value: { minPrice: price.minPrice, maxPrice: price.maxPrice },
            },
          },
          null
        );
        break;

      case "is_stock":
        handleChangeInquiry(
          "add",
          "is_stock",
          {
            id: Date.now(),
            field: {
              name: "한국 품절 또는 없는 상품",
              value: "not_in_kr",
            },
            data: { name: "포함", value: true },
          },
          null
        );
        break;

      case "curation":
        if (!curation) {
          alert("이벤트를 선택해주세요.");
          return;
        }

        handleChangeInquiry(
          "add",
          "curation",
          {
            id: Date.now(),
            field: { name: "이벤트", value: "curation" },
            data: {
              name: curation.split(":")[1],
              value: curation.split(":")[0],
            },
          },
          null
        );
        break;

      default:
        break;
    }
  };

  return (
    <>
      <ItemStatus
        itemStatusLoding={itemStatusLoding}
        status={status}
        countOfItemStatus={countOfItemStatus}
        handleStatusOfParamsChange={handleStatusOfParamsChange}
      />
      <Box border={"1px solid " + borderColor} margin="16px 0" padding="8px">
        <Box display="flex">
          {/* 조회 조건 */}
          <Container>
            <FormControl variant="standard" sx={{ minWidth: 120, flex: 1 }}>
              <InputLabel>조회조건</InputLabel>
              <Select
                label="filter"
                value={filter}
                onChange={handleFilterChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {inquiryData[pathname].map((value) => (
                  <MenuItem
                    key={value.field}
                    value={value.field + ":" + value.headerName}
                  >
                    {value.headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {inquiryData[pathname].map(
              (value) =>
                value.field + ":" + value.headerName === filter &&
                (value.type === "string" ? (
                  <TextField
                    label="조회값"
                    variant="standard"
                    name="search"
                    value={search}
                    sx={{ margin: "0 16px", flex: 1 }}
                    onChange={handleSearchWordChange}
                  />
                ) : (
                  <Box sx={{ margin: "0 16px", flex: 1 }}>
                    <FormControl
                      variant="standard"
                      sx={{ minWidth: 200, flex: 1 }}
                    >
                      <InputLabel>선택</InputLabel>
                      <Select
                        label="filter"
                        value={search}
                        onChange={handleSearchWordChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {value.option.length
                          ? value.option.map((select) => (
                              <MenuItem
                                value={select.value + ":" + select.name}
                              >
                                {select.name}
                              </MenuItem>
                            ))
                          : selectList[value.field].map((select) => (
                              <MenuItem value={select.id + ":" + select.name}>
                                {select.name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Box>
                ))
            )}

            <StyledButton
              onClick={() => {
                handleAddButtonClick("filter");
              }}
            >
              조회 조건 추가
            </StyledButton>
          </Container>
          <Divider orientation="vertical" flexItem />
          {/* 판매가 */}
          <Container>
            <StyledTypography>판매가</StyledTypography>
            <TextField
              style={{ flex: 1 }}
              label="최소 금액"
              variant="standard"
              name="minPrice"
              type="number"
              value={price.minPrice}
              onChange={handlePriceChange}
            />
            <StyledTypography>~</StyledTypography>
            <TextField
              style={{ flex: 1 }}
              label="최대 금액"
              variant="standard"
              name="maxPrice"
              type="number"
              value={price.maxPrice}
              onChange={handlePriceChange}
            />
            <StyledButton onClick={() => handleAddButtonClick("price")}>
              조회 조건 추가
            </StyledButton>
          </Container>
        </Box>
        <Divider />
        <Box display="flex">
          {/* 한국 품절 여부 */}
          <Container>
            <Typography variant="subtitle2" sx={{ flex: 1 }}>
              한국 품절 여부
            </Typography>
            <StyledButton onClick={() => handleAddButtonClick("is_stock")}>
              조회 조건 추가
            </StyledButton>
          </Container>
          <Divider orientation="vertical" flexItem />
          {/* 이벤트 */}
          <Container>
            <Typography variant="subtitle2" margin="0 16px 0 8px">
              이벤트
            </Typography>
            <FormControl variant="standard" sx={{ minWidth: 120, flex: 1 }}>
              <InputLabel>이벤트 리스트</InputLabel>
              <Select
                label="filter"
                value={curation}
                onChange={handleCurationChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {curationList.map((value) => (
                  <MenuItem value={value.id + ":" + value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <StyledButton
              onClick={() => {
                handleAddButtonClick("curation");
              }}
            >
              조회 조건 추가
            </StyledButton>
          </Container>
        </Box>
        {/* 필터 조건 나열 */}
        <Box padding="8px">
          {/* 조회 조건 */}
          <Box display="flex">
            {condition.filter.length ? (
              condition.filter.map((value) => (
                <Box
                  key={value.id}
                  padding="8px"
                  margin="8px"
                  border={"1px solid " + borderColor}
                  borderRadius="8px"
                  width="max-content"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="caption">
                    {value.field.name} :
                  </Typography>
                  <Typography variant="caption">
                    {value.data.name ? value.data.name : value.data.value}
                  </Typography>
                  <ClearIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleChangeInquiry("delete", "filter", null, value.id)
                    }
                  />
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
          {/* 판매가 */}
          <Box display="flex">
            {condition.price.length ? (
              condition.price.map((value) => (
                <Box
                  key={value.id}
                  padding="8px"
                  margin="8px"
                  border={"1px solid " + borderColor}
                  borderRadius="8px"
                  width="max-content"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="caption">
                    {value.field.name} :
                  </Typography>
                  <Typography variant="caption">
                    {value.data.name ? value.data.name : value.data.value}
                  </Typography>
                  <ClearIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleChangeInquiry("delete", "price", null, value.id)
                    }
                  />
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
          {/* 한국 품절 여부 */}
          <Box display="flex">
            {condition.is_stock.length ? (
              condition.is_stock.map((value) => (
                <Box
                  key={value.id}
                  padding="8px"
                  margin="8px"
                  border={"1px solid " + borderColor}
                  borderRadius="8px"
                  width="max-content"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="caption">
                    {value.field.name} :
                  </Typography>
                  <Typography variant="caption">
                    {value.data.name ? value.data.name : value.data.value}
                  </Typography>
                  <ClearIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleChangeInquiry("delete", "is_stock", null, value.id)
                    }
                  />
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
          {/* 이벤트(큐레이션) */}
          <Box display="flex">
            {condition.curation.length ? (
              condition.curation.map((value) => (
                <Box
                  key={value.id}
                  padding="8px"
                  margin="8px"
                  border={"1px solid " + borderColor}
                  borderRadius="8px"
                  width="max-content"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="caption">
                    {value.field.name} :
                  </Typography>
                  <Typography variant="caption">
                    {value.data.name ? value.data.name : value.data.value}
                  </Typography>
                  <ClearIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleChangeInquiry("delete", "curation", null, value.id)
                    }
                  />
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
        </Box>
        <Button
          variant="contained"
          style={{ borderRadius: "unset", color: "white", margin: "0 8px" }}
          onClick={handleClickInquiry}
        >
          조회하기
        </Button>
      </Box>
    </>
  );
};

export default SearchHeader;
