import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useMutation, useQuery } from "react-query";
import { useRecoilState } from "recoil";
import ItemList from "../../../common/components/item_list";
import { selectImageThumbnail } from "../../../common/js/common";
import { getDataFromServer } from "../../../common/network/network";
import { beforePageState, windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, Column, primary } from "../../../common/style/styles";
import { useNavigate } from "react-router-dom";

export const Index = () => {
  const [beforePage, setBeforePage] = useRecoilState(beforePageState);
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const navigate = useNavigate();
  // 아이템 리스트
  const [items, setItems] = useState([]);
  // 아이템 option id 리스트
  const [likeItems, setLikeItems] = useState([]);
  // 서버에 전송할 파라미터
  const [params, setParams] = useState({
    offset: 0,
  });
  // 스크롤 하단 감지
  const [target, setTarget] = useState(null);
  // 무한 스크롤으로 데이터 가져올 때 로딩 스피너 보여주기
  const [isLoading, setIsLoading] = useState(false);
  // 아이템 마지막 id
  const [lastID, setLastID] = useState(null);
  // 페이지 이동, 아이템 더 가져오기 중 어떤게 수행되었는지 확인
  const [changedValue, setChangedValue] = useState("init");
  // 이전페이지의 아이템 option id
  const [lastItem, setLastItem] = useState(null);

  // prams가 변경되었을 때 서버로부터 데이터 가져오기
  const mutation = useMutation("likeItems", () => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");
    return getDataFromServer(
      `/service/likes?token=${
        nonMemberToken == "undefined" ? null : nonMemberToken
      }&offset=${params["offset"]}`
    );
  });

  // 상품 더 요쳥
  const handleChangeOffset = (number) => {
    if (number > 0) {
      setChangedValue("offset");
      setParams((prevState) => ({
        ...prevState,
        offset: prevState["offset"] + number,
      }));
    }
  };

  // 변경된 prams로 데이터 가져오기
  useEffect(() => {
    // 아이템 상세페이지에서 리스트로 뒤로가기 해서 이동 안했을 시 local에 있는 데이터 삭제

    if (!beforePage.includes("likes")) {
      localStorage.setItem("itemListData", "null");
    } else {
      const itemListData = localStorage.getItem("itemListData");
      if (itemListData && itemListData !== "null") {
        const newItemListData = JSON.parse(itemListData);

        // 페이지에 해당하는 데이터가 아니면 삭제
        if (
          !newItemListData["listData"].hasOwnProperty("pageName") ||
          newItemListData.listData.pageName !== "likes"
        ) {
          localStorage.setItem("itemListData", "null");
          return;
        }

        // 아이템 상세페에지 -> 리스트 이동시 이전 리스트 데이터 세팅
        setParams({ ...newItemListData.listData.params });
        setLastID(newItemListData.listData.lastID);
        setItems([...newItemListData.items]);
        setLikeItems([...newItemListData.likeItemList]);
        setLastItem(newItemListData.currentItem.split("/")[4]);
        localStorage.setItem("itemListData", "null");
        return;
      }
    }

    if (lastItem) {
      return;
    }

    mutation.mutate(params, {
      onSuccess: (data) => {
        if (changedValue == "init") {
          setItems([...data.items]);
          setLikeItems([
            ...data.items.map((value) => {
              return value.item_option_id;
            }),
          ]);
          setLastID(data.last_item_id);
        } else if (changedValue == "offset") {
          const last_item = document.getElementById(
            `${items[items.length - 1]["item_option_id"]}`
          );

          const { scrollHeight } = document.body;
          const myScroll = window.scrollY;
          myScroll / scrollHeight > 0.7 &&
            last_item.scrollIntoView({
              behavior: "auto",
              block: "end",
            });

          setItems((itemList) => itemList.concat(data["items"]));
          setLikeItems((itemList) =>
            itemList.concat(
              data.items.map((value) => {
                return value.item_option_id;
              })
            )
          );
        }
      },
    });
  }, [params]);

  // 아이템 리스트 배열이 변경됐을 때 로딩 아이콘 종료
  useEffect(() => {
    if (changedValue === "offset") {
      setIsLoading(false);
    }
  }, [items]);

  // 아이템 상세페이지 -> 리스트페이지로 돌아왔을 때 클릭했던 아이템이 있는 곳으로 스크롤 이동
  useEffect(() => {
    if (items && lastItem) {
      const element = document.getElementById(lastItem);
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [lastItem]);

  // 스크롤 제일 하단 감지하면 onIntersect 함수 실행
  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(onIntersect, {
        rootMargin: "100px",
        threshold: 0.6,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target]);

  // 스크롤 제일 하단일 때 데이터 요청
  const onIntersect = async ([entry], observer) => {
    // last item id가 items에 있기 전까지 데이터 가져오기
    if (
      entry.isIntersecting &&
      !isLoading &&
      lastID !== -1 &&
      items.length !== 0
    ) {
      const isLastId = items.filter((item) => item.item_id == lastID);
      if (isLastId.length == 0) {
        handleChangeOffset(10);
        setIsLoading(true);
      }
      if (!isLoading) {
        await new Promise((resolve) => setTimeout(resolve, 150));
        observer.observe(entry.target);
      }
    }
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {lastID ? (
        <Box>
          <Box margin="1rem 0">
            <ItemList
              listData={{
                pageName: "likes",
                params: params,
                lastID: lastID,
                titleName: "",
                categoryList: [],
              }}
              items={selectImageThumbnail(items)}
              likeItemList={likeItems}
              handleChangeLikeItemList={null}
            />
            {likeItems.length > 0 && (
              <Box
                id="test-item"
                ref={setTarget}
                display="flex"
                justifyContent="center"
              >
                <TailSpin
                  height="30"
                  width="30"
                  color={primary}
                  radius="1"
                  wrapperStyle={{
                    position: "relative",
                    margin: "16px",
                  }}
                  wrapperClass=""
                  visible={isLoading}
                />
              </Box>
            )}
          </Box>
          {!items.length && !mutation.isLoading ? (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexDirection="column"
              height={windowsize.height / 2}
            >
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                }
                fontWeight="700"
                margin="1rem 0"
              >
                찜 한 상품이 존재하지 않습니다.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: 0 }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                  }
                  fontWeight="700"
                  padding={
                    windowsize.width >= BREAK_POINT_PC
                      ? "0.7rem 2rem "
                      : "0.5rem 1.5rem "
                  }
                >
                  쇼핑하러 가기
                </Typography>
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Column>
  );
};
