import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteDataFromServer,
  getDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import UserInfo from "../components/user_info";
import {
  BREAK_POINT_PC,
  Column,
  primary,
  StyledLink,
} from "../../../common/style/styles";
import { TailSpin } from "react-loader-spinner";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import Address from "../../order/components/address";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

// 내정보 페이지
const MyInfo = () => {
  const navigate = useNavigate();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [userInfo, setUserInfo] = useState({});
  const [addressInfo, setAddressInfo] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  // 로그인했는지 확인
  const [isLogin, setIsLogion] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDataFromServer("/service/users")
      .then((response) => {
        setIsLogion(true);
        setUserInfo({ ...response.user_info });
        setAddressInfo([...response.address_info]);
        // 기본 베송지가 있을 때는 기본 배송지 보여주고 없을 때는 가장 최근 주소 보여주기.
        if (response.address_info.length) {
          const hasDefaultAddress = response.address_info.find(
            (value) => value.is_default
          );
          if (hasDefaultAddress) {
            setSelectedAddress({ ...hasDefaultAddress });
          } else {
            setSelectedAddress({ ...response.address_info[0] });
          }
        }
      })
      .catch((error) => {
        // 회원정보 없을 시 로그인 페이지로 이동
        alert("로그인 후 이용해주세요.");
        navigate("/login");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // 회원탈퇴
  const handleSubmit = () => {
    putDataFromServer("/service/users/withdrawal")
      .then((response) => {
        localStorage.removeItem("itemListData");
        localStorage.removeItem("orderItemListData");
        localStorage.removeItem("nonmemberOrderItem");
        alert("탈퇴처리가 완료되었습니다.");
        navigate("/");
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box>
        {isLogin ? (
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="2rem 1rem 0.5rem 1rem"
            >
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle1"
                }
                component="p"
              >
                회원 정보
              </Typography>
              <StyledLink to="/userinfo/edit" state={{ userInfo: userInfo }}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                  >
                    회원 정보 수정
                  </Typography>
                  <NavigateNextOutlinedIcon />
                </Box>
              </StyledLink>
            </Box>
            <Divider />
            <Box margin="1rem">
              <UserInfo userInfo={userInfo} />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="2rem 1rem 0.5rem 1rem"
            >
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle1"
                }
                component="p"
              >
                배송지 정보
              </Typography>
              <StyledLink to="/address" state={{ addressInfo: addressInfo }}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                  >
                    배송지 정보 관리
                  </Typography>
                  <NavigateNextOutlinedIcon />
                </Box>
              </StyledLink>
            </Box>
            <Divider />
            <Box margin="1rem">
              {!addressInfo.length ? (
                <Typography
                  variant="subtitle2"
                  component="p"
                  textAlign="center"
                  margin="8rem 0"
                >
                  등록된 배송지가 없습니다.
                </Typography>
              ) : (
                <Address selectedAddress={selectedAddress} />
              )}
            </Box>
            <Divider />
            <Button
              variant="contained"
              sx={{
                borderRadius: "0",
                background: "black",
                width: "100%",
                color: "white",
                margin: "32px 0",
                padding: "0.5rem 0",
              }}
              onClick={handleSubmit}
            >
              <Typography
                variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "body1"}
                fontWeight="700"
              >
                회원 탈퇴
              </Typography>
            </Button>
          </Box>
        ) : null}
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Column>
  );
};

export default MyInfo;
