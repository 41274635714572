import { Box, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { addDashToPhoneNumber } from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, grey3 } from "../../../common/style/styles";

// 내정보 페이지의 회원정보
const UserInfo = ({ userInfo }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  return (
    <Box>
      <Box display="flex" marginBottom="0.8rem">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          color={grey3}
          flex="1"
          fontWeight="500"
        >
          고객명
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          flex="4"
        >
          {userInfo.name}
        </Typography>
      </Box>

      <Box display="flex" marginBottom="0.8rem">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          color={grey3}
          flex="1"
          fontWeight="500"
        >
          이메일
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          flex="4"
        >
          {userInfo.email}
        </Typography>
      </Box>
      <Box display="flex" marginBottom="0.8rem">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          color={grey3}
          flex="1"
          fontWeight="500"
        >
          연락처
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          flex="4"
        >
          {addDashToPhoneNumber(userInfo.cellphone)}
        </Typography>
      </Box>
      <Box display="flex" marginBottom="0.8rem">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          color={grey3}
          flex="1"
          fontWeight="500"
        >
          개인통관
          {windowSize.width < "564" && <br />}
          고유번호
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          flex="4"
        >
          {userInfo.customs_number}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserInfo;
