import { Box, Divider, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDataFromServer } from "../../../common/network/network";
import { borderColor, CenterModalStyle } from "../../../common/style/styles";

// 크롤링 이슈 모달
const ErrorMsgModal = ({ isOpened, handleChangeModal }) => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    isOpened &&
      getDataFromServer(
        `/admin/crawling/error/${isOpened.id}?limit=${isOpened.countryNum}`
      )
        .then((response) => {
          setIsAdmin(true);

          setErrorMsg([...response.error_msg]);
        })
        .catch((error) => {
          setIsAdmin(false);
        });
  }, [isOpened]);

  return (
    <>
      {isAdmin && (
        <Modal
          open={isOpened}
          onClose={() => {
            handleChangeModal();
          }}
        >
          <Box
            sx={CenterModalStyle}
            width="50%"
            overflowY="scroll"
            height="90%"
          >
            <Box margin="16px">
              <Box>
                <Typography>크롤링 이슈</Typography>
                <Divider />
                <Box margin="8px 0">
                  {errorMsg.length > 0 &&
                    errorMsg.map((value) => (
                      <Box
                        display="flex"
                        margin="4px 0"
                        border={`1px solid ${borderColor}`}
                        padding="4px"
                      >
                        <Typography minWidth="fit-content" margin="0 8px">
                          {value.content}
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography margin="0 8px" overflow="scroll">
                          {value.memo}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ErrorMsgModal;
