import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainMenu from "../../../common/components/main_menu";
import {
  getDataFromServer,
  postDataFromServer,
  deleteDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { primary } from "../../../common/style/styles";
import DataList from "../components/data_list";
import EditData from "../components/edit_data";

const NoticeShow = () => {
  const { submenu, mode, id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [data, setData] = useState({
    content: "",
    image: null,
    is_banner: false,
    is_show: true,
    title: "",
  });
  const [imageList, setImageList] = useState([]);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 이미지 업로드
  const handleUploadImage = async (imgFile) => {
    const formData = new FormData();
    formData.append("image", imgFile);
    const response = await postDataFromServer(
      `/admin/etc/${submenu}/image`,
      formData
    );
    setImageList((prevState) => {
      return [...prevState, response.name];
    });
    return response.name;
  };

  // 업로드된 이미지 삭제
  const handleDeleteImage = (url) => {
    console.log(url);

    deleteDataFromServer(`/admin/etc/${submenu}/image?url=${url}`).then(
      (response) => {
        const newImageList = imageList.filter((image) => image !== url);
        setImageList([...newImageList]);
      }
    );
  };

  // faq, 공지사항 저장
  const handleSubmit = () => {
    console.log(data);

    // 제목, 내용 있는지 확인
    if (!data.content || !data.title) {
      alert("정보를 입력해주세요.");
      return;
    }

    // 배너로 지정한 경우 이미지가 있는지 확인
    if (data.is_banner && !data.image) {
      alert("배너 이미지를 선택해주세요.");
      return;
    }

    if (processing) {
      return;
    }

    setProcessing(true);
    setLoading(true);

    const formData = new FormData();

    if (data.image && typeof data.image === "object") {
      formData.append("image", data.image);
    }
    formData.append("info", JSON.stringify(data));

    if (data.id) {
      putDataFromServer(`/admin/etc/${submenu}/${data.id}`, formData)
        .then((response) => {
          alert("수정이 완료되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    } else {
      postDataFromServer(`/admin/etc/${submenu}`, formData)
        .then((response) => {
          alert("저장이 완료되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setProcessing(false);
          setLoading(false);
        });
    }
  };

  // 삭제
  const handleDelete = () => {
    if (processing) {
      return;
    }

    setLoading(true);
    setProcessing(true);

    deleteDataFromServer(`/admin/etc/${submenu}/${data.id}`)
      .then((response) => {
        alert("삭제가 완료되었습니다.");
        navigate(-1);
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  const handleChange = (name, value) => {
    console.log(name, value, data);
    setData((prevState) => {
      return { ...prevState, [name]: value };
    });

    // 이미지를 삭제했으면 임시 이미지 저장 object (imgData) 에서 삭제
    const images = value.match(/<img [^>]*src="[^"]*"[^>]*>/gm)
      ? value
          .match(/<img [^>]*src="[^"]*"[^>]*>/gm)
          .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"))
      : [];

    if (images.length < imageList.length) {
      const deletedImage = imageList.find((url) => !images.includes(url));

      // 이미지 삭제
      handleDeleteImage(deletedImage);
    }
  };

  useEffect(() => {
    if (mode === "create") {
      return;
    }
    getDataFromServer(`/admin/etc/${submenu}/${id}`)
      .then((response) => {
        setIsAdmin(true);
        setData({ ...response.data });
      })
      .catch((error) => {
        setIsAdmin(false);
      });
  }, [pathname]);

  useEffect(() => {
    // 로그인 정보 확인
    getDataFromServer("/admin/users/email")
      .then((response) => {
        setIsAdmin(true);
      })
      .catch((error) => {
        setIsAdmin(false);
      });
  }, []);

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {mode === "show" ? (
              <DataList
                submenu={submenu}
                data={data}
                handleDelete={handleDelete}
              />
            ) : null}
            {mode === "edit" || mode === "create" ? (
              <EditData
                submenu={submenu}
                data={data}
                handleChange={handleChange}
                handleUploadImage={handleUploadImage}
                handleSubmit={handleSubmit}
              />
            ) : null}
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { NoticeShow as NoticeDetail };
