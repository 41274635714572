import {
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
} from "@mui/material";
import React from "react";
import { convertDate } from "../../../common/js/common";
import { borderColor, primary } from "../../../common/style/styles";
import defaultImg from "../../../media_assets/image/gallery1.jpg";

const DataTable = ({
  submenu,
  rows,
  handleReplyChange,
  handleSubmit,
  handleDeleteItem,
}) => {
  return (
    <List width="100%" height="100%">
      {rows.map((data) => (
        <ListItem
          sx={{
            flexDirection: "column",
            alignItems: "flex-start",
            border: `1px solid ${borderColor}`,
            margin: "16px 0",
            padding: "8px",
          }}
          width="100%"
        >
          {/* 회원 정보 */}
          <Box width="100%">
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography margin="0 4px 0 0">
                  {data.user_name} ({data.user_email})
                </Typography>
                <Divider flexItem orientation="vertical" />
                <Typography margin="0 4px">
                  {submenu === "qna"
                    ? data.item_name
                      ? `상품 문의 (${data.item_name} ${data.sku})`
                      : "일반 문의"
                    : `${data.item_name} ${data.sku}`}
                </Typography>
                <Divider flexItem orientation="vertical" />
                <Typography margin="0 0 0 4px">
                  {convertDate(new Date(data.created_at))}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography color={data.has_reply ? primary : "black"}>
                  {data.has_reply ? "답변 완료" : "미답변"}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ margin: "0 8px" }}
                  onClick={() => {
                    handleDeleteItem(data.id);
                  }}
                >
                  삭제하기
                </Button>
              </Box>
            </Box>
          </Box>
          {/* 문의, 리뷰 내용 */}
          <Divider width="100%" />
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              <Typography margin="4px">
                {data.title ? `[ ${data.title} ]` : null}
              </Typography>
              {data.images
                ? data.images.map((img) => (
                    <img
                      style={{
                        backgroundImage: `url(${defaultImg})`,
                      }}
                      src={img}
                      width="100px"
                    />
                  ))
                : null}
              <Typography margin="4px" sx={{ whiteSpace: "pre-wrap" }}>
                {data.content}
              </Typography>
            </Box>
            {submenu === "review" ? (
              <Button
                variant="outlined"
                size="medium"
                style={{ margin: "8px 0", maxHeight: "40px" }}
              >
                우수 리뷰 선정
              </Button>
            ) : null}
          </Box>
          {/* 답변 내용 */}
          <Divider width="100%" />
          <Box width="100%">
            {data.has_reply ? (
              <Typography margin="4px" sx={{ whiteSpace: "pre-wrap" }}>
                {data.reply}
              </Typography>
            ) : (
              <Box
                margin="8px 4px"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                width="100%"
              >
                <TextField
                  style={{ width: "800px" }}
                  label="답변 내용"
                  variant="outlined"
                  name="reply"
                  multiline
                  rows={4}
                  value={data.reply ? data.reply : ""}
                  onChange={(event) => {
                    handleReplyChange(event, data.id);
                  }}
                />
                <Box display="flex" flexDirection="column">
                  <Button
                    variant="outlined"
                    size="medium"
                    sx={{ maxHeight: "40px" }}
                    onClick={() => {
                      handleSubmit(data.id);
                    }}
                  >
                    답변 등록하기
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default DataTable;
