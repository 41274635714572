import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { borderColor, primary } from "../../../common/style/styles";

const StyledTypography = styled.p`
  font-size: 12pt;
  flex: 1;
  margin: 0 8px 0 0;
  text-align: start;
  display: inline;
  min-width: 70px;
`;

const SearchHeader = ({
  condition,
  handleChangeInquiry,
  handleClickInquiry,
}) => {
  return (
    <Box
      padding="8px"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      border={"1px solid " + borderColor}
      margin="16px 0"
    >
      <Box margin="8px" display="flex" alignItems="center">
        <StyledTypography>답변 상태</StyledTypography>
        <FormControl sx={{ width: "200px" }}>
          <InputLabel>답변 상태</InputLabel>
          <Select
            name="has_reply"
            value={condition.has_reply}
            label="답변 상태"
            onChange={handleChangeInquiry}
          >
            <MenuItem value={null}>None</MenuItem>
            <MenuItem value={false}>미답변</MenuItem>
            <MenuItem value={true}>답변완료</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box margin="8px" display="flex" alignItems="center">
        <StyledTypography>검색어</StyledTypography>
        <TextField
          style={{ width: "600px" }}
          label="검색어 입력 (상품명, 질문내용, 질문자 이름 등) "
          variant="standard"
          name="qeury"
          value={condition.qeury}
          onChange={handleChangeInquiry}
        />
      </Box>
      <Button
        variant="contained"
        style={{ borderRadius: "unset", color: "white", margin: "8px" }}
        onClick={handleClickInquiry}
      >
        조회하기
      </Button>
    </Box>
  );
};

export default SearchHeader;
