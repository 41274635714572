import { Box, Button, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { windowSizeState } from "../../../common/state/state";
import {
  Column,
  info,
  lightgrey,
  grey,
  BREAK_POINT_PC,
  StyledLink,
} from "../../../common/style/styles";
import logoImg from "../../../media_assets/image/millionpick_logo.png";

const StyledListItem = styled(ListItem)`
  && {
    margin: 0.75rem 0;
    padding: 0;
  }
`;
const Footer = () => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const StyledName = styled(Typography)`
    && {
      color: ${info};
      font-size: ${windowSize.width >= BREAK_POINT_PC ? "32px" : "10px"};
      font-weight: 700;
    }
  `;

  const StyledValue = styled(Typography)`
    && {
      color: ${info};
      font-size: ${windowSize.width >= BREAK_POINT_PC ? "32px" : "10px"};
      font-weight: 500;
    }
  `;

  return (
    <Box
      component="footer"
      width="100%"
      margin={windowSize.width >= BREAK_POINT_PC ? "6rem 0" : "2rem 0"}
    >
      <Box
        backgroundColor={
          windowSize.width >= BREAK_POINT_PC ? lightgrey : "whith"
        }
        padding={
          windowSize.width >= BREAK_POINT_PC ? "3rem 0 3rem 0" : "1rem 0 1rem 0"
        }
      >
        <Column xs={12} sm={12} md={12} lg={9}>
          <img
            style={{ marginLeft: "1rem" }}
            src={logoImg}
            width={windowSize.width >= BREAK_POINT_PC ? "180px" : "80px"}
          />
        </Column>
      </Box>
      <Column xs={12} sm={12} md={12} lg={9}>
        <List
          style={{
            margin: windowSize.width >= BREAK_POINT_PC ? "3rem 0" : "1rem",
          }}
        >
          <StyledListItem>
            <Box margin="0 0.5rem 0 0">
              <StyledLink to="/terms">
                <StyledName>이용약관</StyledName>
              </StyledLink>
            </Box>
            <Box margin="0 0.5rem 0 0">
              <StyledLink to="/privacy">
                <StyledName>개인정보 취급방침</StyledName>
              </StyledLink>
            </Box>
            <form
              action="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5108701808"
              method="post"
              target="_self"
            >
              <Button variant="text" type="submit">
                <StyledName>사업자정보확인</StyledName>
              </Button>
            </form>
          </StyledListItem>
          <StyledListItem>
            <Box display="flex" flex={1}>
              <StyledName>상호명 :</StyledName>
              <StyledValue>(주)밀리언픽</StyledValue>
            </Box>
            <Box display="flex" flex={1}>
              <StyledName>대표자 :</StyledName>
              <StyledValue>심민규</StyledValue>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Box display="flex">
              <StyledName>주소 :</StyledName>
              <StyledValue>
                5층 502호(숭인동) 서울특별시 종로구 종로 385
              </StyledValue>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Box display="flex" flex={1}>
              <StyledName>사업자등록번호 :</StyledName>
              <StyledValue>510-87-01808</StyledValue>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Box display="flex">
              <StyledName>통신판매업 신고번호 : </StyledName>
              <StyledValue>2020-서울종로-1547 </StyledValue>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Box display="flex">
              <StyledName>개인정보보호책임자 : </StyledName>
              <StyledValue>심민규</StyledValue>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Box display="flex" marginBottom="3rem">
              <img
                width="50px"
                height="50px"
                src="https://image.inicis.com/mkt/certmark/escrow/escrow_60x60_color.png"
                alt="클릭하시면 이니시스 결제시스템의 유효성을 확인하실 수 있습니다."
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://mark.inicis.com/mark/escrow_popup_v3.php?mid=MOI4546519",
                    "mark",
                    "scrollbars=no,resizable=no,width=565,height=683"
                  );
                }}
              />
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "caption"
                }
                color={grey}
                marginLeft="0.5rem"
                display="block"
              >
                고객님은 안전거래를 위해 결제시 저희 쇼핑몰에서 가입한 구매안전
                서비스를 이용하실 수 있습니다. [서비스가입정보확인]
              </Typography>
            </Box>
          </StyledListItem>
        </List>
      </Column>
    </Box>
  );
};

export default Footer;
