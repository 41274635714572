import {
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import {
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { CenterModalStyle, primary } from "../../../common/style/styles";

// 크롤링 메뉴 추가, 수정
const EditModal = ({
  brandList,
  categoryList,
  countryList,
  isOpened,
  handleChangeModal,
  updateData,
}) => {
  const status = isOpened.create ? "create" : isOpened.update ? "update" : null;

  const [selectedData, setSelectedData] = useState({
    brand: "",
    crawlingName: "",
    category: "",
    country: [],
    url: "",
    period: null,
  });

  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!updateData) {
      return;
    }

    setSelectedData({
      brand: brandList.find((data) => data.name === updateData.brand_name).id,
      crawlingName: updateData.crawling_name,
      category: categoryList.find(
        (data) => data.name === updateData.category_name
      ).id,
      country: updateData.country_list.split(","),
      url: updateData.url,
      period: updateData.update_period,
    });
  }, [updateData]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // 국가 선택에서 none 선택 방지
    if (Array.isArray(value) && value.includes("")) {
      return;
    }

    // 주기 음수 입력 방지
    if (name === "period" && parseInt(value) < 0) {
      return;
    }

    setSelectedData({ ...selectedData, [name]: value });
  };

  const handleCloseModal = () => {
    setProcessing(false);
    setLoading(false);
    setSelectedData({
      brand: "",
      crawlingName: "",
      category: "",
      country: [],
      url: "",
      period: null,
    });
    handleChangeModal(status);
  };

  const handleSubmit = () => {
    // 비어있는 정보가 있으면 return
    const isEmpty = Object.keys(selectedData).find(
      (key) => selectedData[key] === null && !selectedData[key].length
    );

    if (isEmpty) {
      alert("비어있는 정보가 있는지 확인해주세요.");
      return;
    }

    if (processing) {
      return;
    }

    setProcessing(true);
    setLoading(true);
    if (status === "create") {
      postDataFromServer("/admin/crawling", selectedData)
        .then((response) => {
          alert("크롤링 메뉴 정보가 저장되었습니다.");
          handleCloseModal(status);
          window.location.reload();
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
          return;
        })
        .finally(() => {
          setProcessing(false);
          setLoading(false);
        });
    }

    if (status === "update") {
      putDataFromServer(`/admin/crawling/${updateData.id}`, selectedData)
        .then((response) => {
          alert("크롤링 메뉴 정보가 수정되었습니다.");
          handleCloseModal(status);
          window.location.reload();
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
          return;
        })
        .finally(() => {
          setProcessing(false);
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      open={isOpened[status]}
      onClose={handleCloseModal}
      sx={{ pointerEvents: processing ? "none" : "auto" }}
    >
      <Box sx={CenterModalStyle} width="50%">
        <Box margin="16px">
          <Typography variant="h6">
            크롤링 메뉴 {status === "create" ? "추가" : "수정"}
          </Typography>
          <Divider />
          <Box margin="8px 0" display="flex" alignItems="center">
            <Typography margin="0 8px 0 0" width="80px">
              브랜드명 :
            </Typography>
            <FormControl sx={{ width: "80%" }} size="small">
              <InputLabel>브랜드</InputLabel>
              <Select
                name="brand"
                value={selectedData.brand}
                label="브랜드"
                onChange={handleChange}
              >
                <MenuItem value="">None</MenuItem>
                {brandList.map((brand) => (
                  <MenuItem key={brand.name} value={brand.id}>
                    {brand.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box margin="8px 0" display="flex" alignItems="center">
            <Typography margin="0 8px 0 0" width="80px">
              크롤링명 :
            </Typography>
            <TextField
              size="small"
              name="crawlingName"
              sx={{ width: "80%" }}
              value={selectedData.crawlingName}
              onChange={handleChange}
            />
          </Box>
          <Box margin="8px 0" display="flex" alignItems="center">
            <Typography margin="0 8px 0 0" width="80px">
              카테고리 :
            </Typography>
            <FormControl sx={{ width: "80%" }} size="small">
              <InputLabel>카테고리</InputLabel>
              <Select
                name="category"
                value={selectedData.category}
                label="카테고리"
                onChange={handleChange}
              >
                <MenuItem value="">None</MenuItem>
                {categoryList.map((category) => (
                  <MenuItem key={category.name} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box margin="8px 0" display="flex" alignItems="center">
            <Typography margin="0 8px 0 0" width="80px">
              국가 :
            </Typography>
            <FormControl sx={{ width: "80%" }} size="small">
              <InputLabel>국가</InputLabel>
              <Select
                name="country"
                value={selectedData.country}
                label="국가"
                onChange={handleChange}
                multiple
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                //   MenuProps={MenuProps}
              >
                <MenuItem value="">None</MenuItem>
                {countryList.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box margin="8px 0" display="flex" alignItems="center">
            <Typography margin="0 8px 0 0" width="80px">
              url :
            </Typography>
            <TextField
              size="small"
              sx={{ width: "80%" }}
              name="url"
              value={selectedData.url}
              onChange={handleChange}
            />
          </Box>
          <Box margin="8px 0" display="flex" alignItems="center">
            <Typography margin="0 8px 0 0" width="80px">
              주기 :
            </Typography>
            <TextField
              size="small"
              name="period"
              value={selectedData.period}
              onChange={handleChange}
              type="number"
            />
            <Typography margin="0 0 0 8px">일</Typography>
          </Box>
          <Typography margin="0 0 16px 16px">
            * 0일 입력시 자동으로 실행되지 않습니다.
          </Typography>
          <Box>
            <Button
              variant="outlined"
              style={{ borderRadius: "unset", margin: "0 8px" }}
              onClick={handleSubmit}
            >
              {status === "create" ? "추가" : "수정"}
            </Button>
            <Button
              variant="outlined"
              style={{ borderRadius: "unset", margin: "0 8px" }}
              color="secondary"
              onClick={handleCloseModal}
            >
              닫기
            </Button>
          </Box>
        </Box>
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Modal>
  );
};

export default EditModal;
