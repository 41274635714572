import { Box, Divider, Typography } from "@mui/material";
import React from "react";

const ReturnExchangeInfo = () => {
  return (
    <Box>
      <Typography gutterBottom variant="subtitle2">
        취소
      </Typography>
      <Typography gutterBottom>
        - 구매 당일에는 취소 수수료 없이 취소 가능합니다.
      </Typography>
      <Typography gutterBottom>
        - 구매하신 다음날 이후 상품이 유럽현지에서 이미 구매가 완료되었으나 아직
        발송처리가 되지 않은 경우 취소수수료 1만원 납부 후 취소 가능합니다.
      </Typography>
      <Typography gutterBottom>
        - 구매하신 다음날부터는 상품이 발송처리된 이후(주문상태가 배송중
        경우)에는 취소가 불가능하며 반품 또는 교환만이 가능합니다.
      </Typography>
      <br />
      <Typography gutterBottom variant="subtitle2">
        반품 및 교환
      </Typography>
      <Typography gutterBottom>
        - 반품 또는 교환의 경우 먼저 당사 고객센터로 연락을 주시어 반품 및
        교환을 접수해 주세요.
      </Typography>
      <Typography gutterBottom>
        - 소비자 귀책에 의한 교환/반품(단순변심 포함)은 제품 수령 후 7일이내
        까지만 가능합니다.
      </Typography>
      <Typography gutterBottom>
        - 상품의 내용이 표시·광고의 내용과 다른 경우에는 상품을 수령한 날부터
        3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에
        청약철회 가능합니다.
      </Typography>
      <Typography gutterBottom>
        - 고객센터로 교환/반품 신청 이후 안내되는 주소로 수령하셨던 상태 그대로
        상품을 발송해 주세요. 상품상태 확인 이후 소비자 귀책으로 판단되는 경우
        결제하신 총 금액에서 교환/반품관련 일체 비용을 공제하고 환불처리 되며,
        판매자 귀책으로 판단되는 경우 반품관련 일체 비용을 판매자가 부담하며
        결제하신 금액 전액은 환불처리 됩니다.
      </Typography>
      <Typography gutterBottom>
        - 소비자 귀책에 의한 교환/반품시 반품비용: 반품수수료 4만원 + 관부가세
        발생액 (관부가세 포함 상품 주문시)
      </Typography>
      <Typography gutterBottom>
        - 소비자 귀책에 의한 교환/반품시 교환비용: 교환수수료 5만원 + 관부가세
        발생액 (관부가세 포함 상품 주문시)
      </Typography>
      <br />
      <Typography gutterBottom variant="subtitle2">
        반품 및 교환 불가한 경우
      </Typography>
      <Typography gutterBottom>- 상품배송 완료 후 7일이 지난 상품</Typography>
      <Typography gutterBottom>- 고객부주의로 인한 상품훼손, 오염</Typography>
      <Typography gutterBottom>
        - 상품사용으로 인하여 상품의 재판매가 불가능한 경우
      </Typography>
      <Typography gutterBottom>
        - 모니터상의 색상과 실제 상품의 색상의 작은 불일치로 인한 사유
      </Typography>
      <Typography gutterBottom>
        - 상품 특성 또는 구매처의 특성상 미세한 주름, 스크래치, 올풀림 등이 있을
        수 있으며 상품상세페이지에 해당 내용이 이미 고지된 경우
      </Typography>
      <Typography gutterBottom>
        - 회원에게 최초 배송시에 포함되었던 구성품이 누락 또는 훼손된 경우
      </Typography>
      <br />
    </Box>
  );
};

export default ReturnExchangeInfo;
