import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useParams } from "react-router-dom";
import { convertDate } from "../../../common/js/common";
import { getDataFromServer } from "../../../common/network/network";
import {
  BREAK_POINT_PC,
  Column,
  grey,
  grey3,
  grey4,
  primary,
} from "../../../common/style/styles";
import ReactHtmlParser from "react-html-parser";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

// 개별 공지사항 페이지
const Notice = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);

  const [title, setTitle] = useState(state ? state.title : null);
  const [content, setContent] = useState(state ? state.content : null);
  const [date, setDate] = useState(state ? state.date : null);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // url로 접속한 경우 서버에서 id에 해당하는 공지사항 데이터 가져오기
    getDataFromServer(`/service/notice/${id}`)
      .then((response) => {
        setTitle(response.notice.title);
        setContent(response.notice.content);
        setDate(convertDate(new Date(response.notice.created_at)));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box margin="1rem">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "h4" : "h6"}
          textAlign="center"
          fontWeight="500"
        >
          {title}
        </Typography>
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body2"}
          color={grey3}
          textAlign="right"
          marginBottom="0.5rem"
        >
          {date}
        </Typography>
        <Divider />
        <Box marginTop="0.5rem" width="100%">
          {ReactHtmlParser(
            content && content.replaceAll("<img", "<img width='100%'")
          )}
        </Box>

        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Column>
  );
};

export default Notice;
