import { Button, Divider, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  selectItemImages,
  preproccessingOfCountryData,
} from "../../../common/js/common";
import {
  borderColor,
  lightgrey,
  lightcoral,
  StyledLink,
} from "../../../common/style/styles";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import ReactHtmlParser from "react-html-parser";

const DataList = ({ columns, rows, handleDelete }) => {
  const { pathname } = useLocation();
  const countryList = [null, "스페인", "프랑스", "영국", "한국"];
  const [basicInfo, setBasicInfo] = useState({});
  const [priceInfo, setPriceInfo] = useState({});
  const [sizeInfo, setSizeInfo] = useState({});
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setBasicInfo({
      ...preproccessingOfCountryData(
        countryList,
        columns.countryInfo.basicInfo,
        rows.basic_info
      ),
    });

    setPriceInfo({
      ...preproccessingOfCountryData(
        countryList,
        columns.countryInfo.priceInfo,
        rows.price_info
      ),
    });

    setSizeInfo({
      ...preproccessingOfCountryData(
        countryList,
        columns.countryInfo.sizeInfo,
        rows.size_info,
        true
      ),
    });
  }, []);

  return (
    <Box margin="16px">
      <Box margin="8px 0">
        <StyledLink to={pathname.replace("show", "edit")}>
          <Button variant="outlined" style={{ borderRadius: "unset" }}>
            데이터 수정
          </Button>
        </StyledLink>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleDelete}
        >
          데이터 삭제
        </Button>
      </Box>

      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          데이터 상세보기
        </ListItem>
        <Divider />
        {/* 기본 정보 */}
        <ListItem style={{ backgroundColor: `${lightcoral}` }}>
          기본 정보
        </ListItem>
        <Divider />
        {columns.commonInfo.map((value) => (
          <ListItem
            key={value.field}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Typography component="p" flex="1">
                {value.name}
              </Typography>
              <Typography component="p" flex="5">
                {ReactHtmlParser(rows.common_info[value.field])}
              </Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
        {/* 국가별 정보 */}
        <ListItem style={{ backgroundColor: `${lightcoral}` }}>
          국가별 정보
        </ListItem>
        <ListItem>
          {countryList.map((country) => (
            <Typography component="p" flex="1" margin="0 8px">
              {country}
            </Typography>
          ))}
        </ListItem>
        {/* 기본정보 (basic info) */}
        {columns.countryInfo.basicInfo.map((value) => (
          <ListItem value={value.field} sx={{ flexDirection: "column" }}>
            <Box display="flex" width="100%" flexWrap="wrap">
              <Typography component="p" margin="0 8px" flex="1" maxWidth="30%">
                {value.name}
              </Typography>
              {Object.keys(basicInfo).length &&
                countryList.map(
                  (country) =>
                    country && (
                      <Typography
                        component="p"
                        flex="1"
                        margin="0 8px"
                        maxWidth="30%"
                        sx={{
                          wordWrap: "break-word",
                        }}
                      >
                        {basicInfo[value.field][country]}
                      </Typography>
                    )
                )}
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
        {/* 가격 정보 (price info) */}
        {columns.countryInfo.priceInfo.map((value) => (
          <ListItem value={value.field} sx={{ flexDirection: "column" }}>
            <Box display="flex" width="100%">
              <Typography component="p" flex="1" maxWidth="30%">
                {value.name}
              </Typography>
              {Object.keys(priceInfo).length &&
                countryList.map(
                  (country) =>
                    country && (
                      <Typography
                        component="p"
                        flex="1"
                        margin="0 8px"
                        maxWidth="30%"
                      >
                        {priceInfo[value.field][country]}
                      </Typography>
                    )
                )}
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
        {/* 사이즈 정보 (size info) */}
        <ListItem sx={{ flexDirection: "column" }}>
          <Box display="flex" width="100%">
            <Typography component="p" flex="1" maxWidth="30%">
              사이즈 / 재고상태
            </Typography>
            {Object.keys(sizeInfo).length &&
              countryList.map(
                (country) =>
                  country && (
                    <Box flex="1" maxWidth="30%">
                      {sizeInfo.size[country] &&
                        sizeInfo.size[country].map((data, index) => (
                          <Typography component="p" margin="0 8px">
                            {sizeInfo.size[country][index]}
                            {" : "}
                            {sizeInfo.is_stock[country][index] === true
                              ? "재고 있음"
                              : "재고 없음"}
                          </Typography>
                        ))}
                    </Box>
                  )
              )}
          </Box>

          <Divider sx={{ width: "100%" }} />
        </ListItem>
        <ListItem
          value={columns.countryInfo.sizeInfo.field}
          sx={{ flexDirection: "column" }}
        >
          <Divider sx={{ width: "100%" }} />
        </ListItem>

        {/* 이미지 리스트*/}
        <ListItem style={{ backgroundColor: `${lightcoral}` }}>
          이미지 리스트
        </ListItem>
        <Box display="flex" flexWrap="wrap">
          {selectItemImages(
            rows.common_info.images,
            rows.common_info.color
          ).map((img) => (
            <img
              src={img}
              style={{
                backgroundImage: `url(${defaultImg})`,
                width: "300px",
                height: "150%",
              }}
            />
          ))}
        </Box>
      </List>
    </Box>
  );
};

export default DataList;
