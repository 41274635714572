import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableBody,
  Button,
} from "@mui/material";
import React from "react";
import { borderColor, lightgrey } from "../../../common/style/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DataTable = ({
  brandList,
  columns,
  rows,
  selectedIDs,
  handleClickCheckBox,
  handleChangeModal,
}) => {
  return (
    <Box margin="0 8px">
      {brandList.map((brand) => (
        <Accordion style={{ border: `1px solid ${borderColor}` }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" alignItems="center">
              <Typography padding="0 8px">{brand}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ overflow: "auto" }}>
              <Table size="small" style={{ margin: "8px 0" }}>
                <TableHead
                  style={{
                    backgroundColor: `${lightgrey}`,
                    top: "0px",
                    left: "0px",
                    position: "sticky",
                    zIndex: 110,
                  }}
                >
                  <TableRow>
                    {columns.map((value, index) => (
                      <TableCell
                        key={index}
                        style={{
                          minWidth: `${value.width}px`,
                          cursor: "pointer",
                        }}
                      >
                        {value.field === "checkBox" ? (
                          <Checkbox
                            checked={selectedIDs.includes(brand) ? true : false}
                            onChange={() => handleClickCheckBox(brand, -1)}
                          />
                        ) : (
                          <Box display="flex" alignItems="center">
                            {value.headerName}
                          </Box>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {columns.map(
                        (value, index) =>
                          brand === row.brand_name && (
                            <TableCell
                              key={index}
                              style={{ minWidth: `${value.width}px` }}
                            >
                              {value.field === "checkBox" ? (
                                <Checkbox
                                  checked={
                                    selectedIDs.includes(row.id) ? true : false
                                  }
                                  onChange={() =>
                                    handleClickCheckBox(row.brand_name, row.id)
                                  }
                                />
                              ) : value.field === "has_error" ? (
                                <Box>
                                  {row[value.field] && (
                                    <Button
                                      variant="outlined"
                                      onClick={() => {
                                        handleChangeModal(
                                          row.id,
                                          row.country_list.split(",").length
                                        );
                                      }}
                                    >
                                      크롤링 이슈 보기
                                    </Button>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  {value.field === "url" ? (
                                    <a href={row[value.field]} target="_blank">
                                      {row[value.field]}
                                    </a>
                                  ) : (
                                    <Typography>{row[value.field]}</Typography>
                                  )}
                                </Box>
                              )}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default DataTable;
