import { Box, List, ListItem } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { borderColor, StyledLink } from "../../../common/style/styles";

const StyledTypography = styled.p`
  font-size: 12pt;
  flex: 1;
  margin: 0;
  text-decoration: ${({ link }) => link && "underline"};
  cursor: pointer;
`;

const ShippingProcess = ({ orderCount }) => {
  return (
    <Box flex="1" border={"1px solid " + borderColor} margin="4px">
      <List>
        <ListItem>
          <StyledTypography>신규 주문</StyledTypography>
          <StyledTypography link={true}>
            <StyledLink to={`/admin/orders/order`} state={{ status: 1 }}>
              {orderCount.new_order} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
        <ListItem>
          <StyledTypography>배송 준비</StyledTypography>

          <StyledTypography link={true}>
            <StyledLink to={`/admin/orders/order`} state={{ status: 2 }}>
              {orderCount.prepare} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
        <ListItem>
          <StyledTypography>배송 중</StyledTypography>
          <StyledTypography link={true}>
            <StyledLink to={`/admin/orders/order`} state={{ status: 3 }}>
              {orderCount.delivery} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
        <ListItem>
          <StyledTypography>배송 완료</StyledTypography>
          <StyledTypography link={true}>
            <StyledLink to={`/admin/orders/order`} state={{ status: 4 }}>
              {orderCount.finish} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
        <ListItem>
          <StyledTypography>구매 확정</StyledTypography>
          <StyledTypography link={true}>
            <StyledLink to={`/admin/orders/order`} state={{ status: 5 }}>
              {orderCount.confirm} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
      </List>
    </Box>
  );
};

export default ShippingProcess;
