import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { borderColor, BREAK_POINT_PC } from "../../../common/style/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const Title = ({ itemInfo, handleChangeIsLike }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      margin={windowSize.width < BREAK_POINT_PC ? "0.8rem 0 0 1rem" : "0"}
    >
      <Box flex="3">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight={windowSize.width >= BREAK_POINT_PC ? "700" : "500"}
        >
          {itemInfo.brand_name}
        </Typography>
        <Typography
          variant={
            windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
          }
          fontWeight="700"
        >
          {itemInfo.item_name}
        </Typography>
      </Box>
      {windowSize.width >= BREAK_POINT_PC ? (
        <Box flex="1" display="flex" justifyContent="flex-end">
          {itemInfo.is_like ? (
            <FavoriteIcon
              color="primary"
              style={{
                fontSize: "4rem",
              }}
              onClick={() => {
                handleChangeIsLike("delete");
              }}
            />
          ) : (
            <FavoriteBorderIcon
              style={{
                fontSize: "4rem",
                color: `${borderColor}`,
              }}
              onClick={() => {
                handleChangeIsLike("add");
              }}
            />
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default Title;
