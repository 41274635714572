import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { convertDate } from "../js/common";
import { windowSizeState } from "../state/state";
import { BREAK_POINT_PC, grey, grey4, info, blue } from "../style/styles";

const QnA = ({ qna }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <Box>
      <Box
        margin={windowsize.width >= BREAK_POINT_PC ? "1.5rem 0" : "0.8rem 1rem"}
      >
        {qna.item_name ? (
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body1"}
            fontWeight="500"
            color={blue}
            marginBottom="0.5rem"
          >
            {qna.item_name} / {qna.color}
          </Typography>
        ) : null}
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          color={qna.has_reply ? "green" : info}
          fontWeight="500"
        >
          {qna.has_reply ? "답변완료" : "미답변"}
        </Typography>
        {/* 문의 내용 */}
        <Box margin="0.5rem 0 1rem 0">
          <Typography
            variant={
              windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
            }
            fontWeight="700"
          >
            {qna.is_secret && "🔒"} {qna.title}
          </Typography>
          <Typography
            variant={
              windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
            }
            whiteSpace="pre-wrap"
          >
            {qna.content}
          </Typography>
        </Box>
        {/* 답변 */}
        {qna.has_reply ? (
          <Box
            sx={{ background: grey4 }}
            padding="1rem"
            marginBottom="1rem"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="700"
              marginRight="0.5rem"
              sx={{
                background: "black",
                color: "white",
                padding: "0.2rem 0.5rem",
              }}
            >
              답변
            </Typography>
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="500"
              whiteSpace="pre-wrap"
            >
              {qna.reply}
            </Typography>
          </Box>
        ) : null}
        <Box display="flex" marginBottom="1.5rem">
          <Typography
            color={grey}
            marginRight="0.2rem"
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          >
            {qna.email}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
            color={grey}
            marginLeft="0.2rem"
          >
            {convertDate(new Date(qna.created_at))}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default QnA;
