import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { useRecoilState } from "recoil";
import { inputNumberWithComma } from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, grey, StyledLink } from "../../../common/style/styles";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <NavigateNextIcon
        style={{
          color: "black",
          fontSize: "4rem",
          transform: "translate(0,-60px)",
        }}
      />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <NavigateBeforeIcon
        style={{
          color: "black",
          fontSize: "4rem",
          transform: "translate(-80px,-60px)",
        }}
      />
    </div>
  );
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: false,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

// pc 이벤트 유형 2
const SlideItemList = ({ items }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <Box>
      <Slider {...settings}>
        {items &&
          items.map((item) => (
            <Box key={item.id} padding="0 0.5rem">
              <StyledLink
                to={`items/${item.item_id}/${item.item_detail_id}/${item.item_option_id}`}
              >
                <img
                  src={item.images}
                  alt="제품 이미지"
                  width="100%"
                  height={(((windowSize.width * 0.75) / 2 - 16) * 100) / 66}
                  style={{
                    backgroundImage: !item.images
                      ? `url(${defaultImg})`
                      : "none",
                    objectFit: "contain",
                  }}
                />
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontWeight="500"
                    noWrap={true}
                    margin="1rem 0 0.25rem 0"
                  >
                    {item.brand_name}
                  </Typography>
                  <Typography
                    variant="h6"
                    noWrap={true}
                    fontWeight="700"
                    marginBottom="0.75rem"
                  >
                    {item.item_name}
                  </Typography>
                  {/* 가격 */}
                  <Box display="flex">
                    {item.sale_price !== item.item_price ? (
                      <Box>
                        <Typography
                          variant="subtitle1"
                          fontWeight="700"
                          component="span"
                          color="primary"
                        >
                          {Math.round(
                            100 - (item.sale_price / item.item_price) * 100
                          )}
                          %
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          fontWeight="700"
                          component="span"
                          color={grey}
                          margin="0 1rem"
                          sx={{
                            textDecoration: "line-through",
                            marginLeft: "8px",
                          }}
                        >
                          {inputNumberWithComma(item.item_price)}원
                        </Typography>
                      </Box>
                    ) : null}
                    <Typography
                      variant="subtitle1"
                      fontWeight="700"
                      component="span"
                    >
                      {inputNumberWithComma(item.sale_price)}원
                    </Typography>
                  </Box>
                </Box>
              </StyledLink>
            </Box>
          ))}
      </Slider>
    </Box>
  );
};

export default SlideItemList;
