import React from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { BREAK_POINT_PC, primary, StyledLink } from "../../common/style/styles";
import { windowSizeState } from "../state/state";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  appendDots: (dots) => (
    <div
      style={{
        bottom: "25px",
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
};

const Banner = ({ data, height }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const Container = styled.div`
    margin-top: ${windowSize.width >= BREAK_POINT_PC ? "30px" : "0"};
  `;

  return (
    <Container>
      <Slider {...settings}>
        {data &&
          data.map((value) => (
            <div key={value.id}>
              <StyledLink to={`notice/${value.id}`}>
                <img
                  src={value.images}
                  alt="배너 이미지"
                  width="100%"
                  height={height}
                />
              </StyledLink>
            </div>
          ))}
      </Slider>
    </Container>
  );
};

export default Banner;
