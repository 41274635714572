import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { CenterModalStyle } from "../../../common/style/styles";

// 엑셀 일괄 발송처리 할때 엑셀 파일을 업로드하는 모달
const UploadFileModal = ({
  isOpened,
  handleModalChange,
  handleUploadExcel,
}) => {
  // 엑셀 파일
  const [excelFile, setExcelFile] = useState(null);

  // 엑셀 일괄 발송처리 -> 엑셀 업로드
  const handleExcelFile = (event) => {
    if (!event.target.files[0]) {
      return;
    }
    setExcelFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    if (!excelFile) {
      alert("파일을 첨부해주세요.");
      return;
    }
    handleUploadExcel(excelFile);
    handleModalChange();
    setExcelFile(null);
  };

  return (
    <Modal
      open={isOpened}
      onClose={() => {
        handleModalChange();
        setExcelFile(null);
      }}
    >
      <Box sx={CenterModalStyle} width="50%">
        <Box margin="16px 8px">
          <Typography variant="h6" margin="8px 0">
            엑셀 일괄 발송처리
          </Typography>
          <Divider />
          <Box margin="8px 0" padding="8px" border={"1px dashed"}>
            <label htmlFor="excel">
              <Typography margin="0 4px">
                발송처리할 파일을 선택해주세요. (.xlsx 확장자만 첨부 가능)
              </Typography>
            </label>
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              name="excel"
              id="excel"
              onChange={handleExcelFile}
            />
          </Box>
          <Typography>선택된 파일 : {excelFile && excelFile.name}</Typography>
          <Box>
            <Button
              variant="outlined"
              sx={{ margin: "0 8px 0 0" }}
              onClick={handleSubmit}
            >
              업로드
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleModalChange();
                setExcelFile(null);
              }}
            >
              취소
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadFileModal;
