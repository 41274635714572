import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDataFromServer } from "../../../common/network/network";

const SearchHeader = ({ searchData, handleFilterData }) => {
  const [selectedData, setSelectedData] = useState({
    brand: "",
    category: "",
    country: "",
    error: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setSelectedData({ ...selectedData, [name]: value });
  };

  const handleSubmit = () => {
    // 선택된 필터가 하나도 없으면 retrun
    const hasSelected = Object.keys(selectedData).find(
      (value) => selectedData[value]
    );
    console.log(selectedData, hasSelected);
    if (!hasSelected) {
      alert("검색 조건을 선택해주세요.");
      return;
    }
    handleFilterData(selectedData);
  };

  return (
    <Box padding="8px" display="flex">
      <Box margin="0 8px 0 0">
        <FormControl sx={{ width: "200px" }}>
          <InputLabel>브랜드</InputLabel>
          <Select
            name="brand"
            value={selectedData.brand}
            label="브랜드"
            onChange={handleChange}
          >
            <MenuItem value="">None</MenuItem>
            {searchData.brandList.map((brand) => (
              <MenuItem key={brand.name} value={brand.id}>
                {brand.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box margin="0 8px 0 0">
        <FormControl sx={{ width: "200px" }}>
          <InputLabel>카테고리</InputLabel>
          <Select
            name="category"
            value={selectedData.category}
            label="카테고리"
            onChange={handleChange}
          >
            <MenuItem value="">None</MenuItem>
            {searchData.categoryList.map((category) => (
              <MenuItem key={category.name} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box margin="0 8px 0 0">
        <FormControl sx={{ width: "150px" }}>
          <InputLabel>국가</InputLabel>
          <Select
            name="country"
            value={selectedData.country}
            label="국가"
            onChange={handleChange}
            sx={{ width: "150px" }}
          >
            <MenuItem value="">None</MenuItem>
            {searchData.countryList.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box margin="0 8px 0 0">
        <FormControl sx={{ width: "200px" }}>
          <InputLabel>크롤링 이슈</InputLabel>
          <Select
            name="error"
            value={selectedData.error}
            label="크롤링 이슈"
            onChange={handleChange}
          >
            <MenuItem value="">None</MenuItem>
            {searchData.errorList.map((error) => (
              <MenuItem key={error.text} value={error.text}>
                {error.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Button
        variant="contained"
        style={{ borderRadius: "unset", color: "white", margin: "0 8px" }}
        onClick={handleSubmit}
      >
        조회하기
      </Button>
    </Box>
  );
};

export default SearchHeader;
