import { Box, Button } from "@mui/material";
import { id } from "date-fns/locale";
import React, { useState } from "react";
import { borderColor } from "../../../common/style/styles";
import ChargeModal from "./charge_modal";
import RefuseModal from "./refuse_modal";
import ReturnChargesModal from "./return_charges_modal";

// 결제취소&반품&교환 완료, 거부 버튼
const RefuseAndAccecptanceButton = ({
  status,
  selectedIDs,
  handleCancelAndReturn,
  handleExchangeItem,
}) => {
  const buttonName = status === 6 ? "취소" : status === 8 ? "반품" : "교환";
  const [isOpened, setIsOpened] = useState({
    refuse: false,
    returnCharges: false,
  });

  const handleModalChange = (name) => {
    if (!selectedIDs.length) {
      alert("처리할 주문번호를 선택해주세요.");
      return;
    }
    setIsOpened({ ...isOpened, [name]: !isOpened[name] });
  };

  // 취소&반품 완료 처리 버튼 클릭
  const handleAcceptance = () => {
    // 결제 취소 수락
    if (status === 6) {
      handleModalChange("returnCharges");
      // 반품 수락
    } else if (status === 8) {
      handleModalChange("returnCharges");
      // 교환 수락
    } else {
      handleExchangeItem(true, null);
    }
  };

  return (
    <Box
      margin="4px 0"
      padding="8px"
      border={"1px solid " + borderColor}
      display="flex"
    >
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={handleAcceptance}
      >
        {status !== 10
          ? `${buttonName} 완료 처리`
          : `${buttonName}상품 발송 처리`}
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handleModalChange("refuse");
        }}
      >
        {buttonName} 거부(철회) 처리
      </Button>
      <RefuseModal
        name={buttonName}
        status={status}
        isOpened={isOpened.refuse}
        handleModalChange={handleModalChange}
        handleCancelAndReturn={handleCancelAndReturn}
        handleExchangeItem={handleExchangeItem}
      />
      <ChargeModal
        name={buttonName}
        isOpened={isOpened.returnCharges}
        handleModalChange={handleModalChange}
        handleCancelAndReturn={handleCancelAndReturn}
      />
    </Box>
  );
};

export default RefuseAndAccecptanceButton;
