import React from "react";
import { Box, Typography } from "@mui/material";
import {
  StyledLink,
  borderColor,
  BREAK_POINT_PC,
} from "../../../common/style/styles";
import myInfoImage from "../../../media_assets/image/myinfo.svg";
import cartImage from "../../../media_assets/image/cart.svg";
import deliveryImage from "../../../media_assets/image/delivery.svg";
import qnaImage from "../../../media_assets/image/qna.svg";
import smallMyInfoImage from "../../../media_assets/image/myinfo-small.svg";
import smallCartImage from "../../../media_assets/image/cart-small.svg";
import smallDeliveryImage from "../../../media_assets/image/delivery-small.svg";
import smallQnAImage from "../../../media_assets/image/qna-small.svg";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";

// 마이페이지에서 상단 아이콘 메뉴
const PersonalMenu = () => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <Box width="100%" margin="1rem" display="flex">
      <Box
        border={"1px solid " + borderColor}
        padding={
          windowSize.width >= BREAK_POINT_PC
            ? "2rem 3rem"
            : "1rem 1rem 0.5rem 1rem"
        }
        flex="1"
        margin={
          windowSize.width >= BREAK_POINT_PC
            ? "1rem 1rem 1rem 0"
            : "0.5rem 0.3rem 0.5rem 0"
        }
      >
        <StyledLink to="/myinfo">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={
                windowSize.width >= BREAK_POINT_PC
                  ? myInfoImage
                  : smallMyInfoImage
              }
            />
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              component="p"
              margin={
                windowSize.width >= BREAK_POINT_PC ? "1rem 0" : "0.2rem 0"
              }
            >
              내정보
            </Typography>
          </Box>
        </StyledLink>
      </Box>
      <Box
        margin={windowSize.width >= BREAK_POINT_PC ? "1rem" : "0.5rem "}
        border={"1px solid " + borderColor}
        padding={
          windowSize.width >= BREAK_POINT_PC
            ? "2rem 3rem"
            : "1rem 1rem 0.3rem 1rem"
        }
        flex="1"
      >
        <StyledLink to="/carts">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={
                windowSize.width >= BREAK_POINT_PC ? cartImage : smallCartImage
              }
            />
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              component="p"
              margin={
                windowSize.width >= BREAK_POINT_PC ? "1rem 0" : "0.2rem 0"
              }
              width="max-content"
            >
              장바구니
            </Typography>
          </Box>
        </StyledLink>
      </Box>
      <Box
        margin={windowSize.width >= BREAK_POINT_PC ? "1rem" : "0.5rem "}
        border={"1px solid " + borderColor}
        padding={
          windowSize.width >= BREAK_POINT_PC
            ? "2rem 3rem"
            : "1rem 1rem 0.3rem 1rem"
        }
        flex="1"
      >
        <StyledLink to="/orders">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={
                windowSize.width >= BREAK_POINT_PC
                  ? deliveryImage
                  : smallDeliveryImage
              }
            />
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              component="p"
              margin={
                windowSize.width >= BREAK_POINT_PC ? "1rem 0" : "0.2rem 0"
              }
              width="max-content"
            >
              주문 & 배송
            </Typography>
          </Box>
        </StyledLink>
      </Box>
      <Box
        margin={
          windowSize.width >= BREAK_POINT_PC
            ? "1rem 0 1rem 1rem"
            : "0.5rem 0 0.5rem 0.3rem"
        }
        border={"1px solid " + borderColor}
        padding={
          windowSize.width >= BREAK_POINT_PC
            ? "2rem 3rem"
            : "1rem 1rem 0.5rem 1rem"
        }
        flex="1"
      >
        <StyledLink to="/contact">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={
                windowSize.width >= BREAK_POINT_PC ? qnaImage : smallQnAImage
              }
            />
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              component="p"
              margin={
                windowSize.width >= BREAK_POINT_PC ? "1rem 0" : "0.2rem 0"
              }
              width="max-content"
            >
              문의하기
            </Typography>
          </Box>
        </StyledLink>
      </Box>
    </Box>
  );
};

export default PersonalMenu;
