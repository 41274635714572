import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { useRecoilState } from "recoil";
import {
  convertDateTimeByKo,
  countdownByKo,
  inputNumberWithComma,
} from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, StyledLink, grey } from "../../../common/style/styles";
import defaultImg from "../../../media_assets/image/gallery1.jpg";

const settings = {
  centerMode: true,
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  swipeToSlide: true,
  arrows: false,
  appendDots: (dots) => (
    <div
      style={{
        bottom: "25px",
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
};

// 모바일 버전 이벤트 유형 2,4
const HorizontalItemList = ({ items }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  return (
    <Box>
      <Slider {...settings}>
        {items &&
          items.map((item) => (
            <Box key={item.id} padding="0 0.5rem">
              <StyledLink
                to={`items/${item.item_id}/${item.item_detail_id}/${item.item_option_id}`}
              >
                <img
                  src={item.images}
                  alt="제품 이미지"
                  width="100%"
                  height={((windowSize.width / 2.5 - 16) * 100) / 66}
                  style={{
                    backgroundImage: !item.images
                      ? `url(${defaultImg})`
                      : "none",
                    objectFit: "contain",
                  }}
                />

                <Box>
                  {/* 이벤트 남은 일자 표시 */}
                  <Box>
                    {windowSize.width < BREAK_POINT_PC &&
                    item.curation_type === 4 ? (
                      <Box
                        sx={{ background: "black" }}
                        padding="0.15rem 0.5rem 0.15rem 0.5rem"
                        width="fit-content"
                        margin="0.5rem 0"
                        display="flex"
                      >
                        <Typography
                          variant="body2"
                          fontWeight="500"
                          color="white"
                        >
                          {countdownByKo(new Date(item.ended_at))}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                  {/* 브랜드명 */}
                  <Typography
                    variant="body2"
                    noWrap={true}
                    fontWeight="500"
                    margin="0.5rem 0 0.25rem 0"
                  >
                    {item.brand_name}
                  </Typography>
                  {/* 제품명 */}
                  <Typography
                    variant="body1"
                    noWrap={true}
                    fontWeight="700"
                    marginBottom="0.5rem"
                  >
                    {item.item_name}
                  </Typography>
                  {/* 가격 */}
                  <Box display="flex">
                    <Typography
                      variant="body1"
                      fontWeight="700"
                      component="span"
                    >
                      {inputNumberWithComma(item.sale_price)}원
                    </Typography>
                    {item.sale_price !== item.item_price ? (
                      <Typography
                        variant="body1"
                        fontWeight="700"
                        component="span"
                        color="primary"
                        margin="0 0 0 0.25rem"
                      >
                        {Math.round(
                          100 - (item.sale_price / item.item_price) * 100
                        )}
                        %
                      </Typography>
                    ) : null}
                  </Box>
                  {item.curation_type === 4 ? (
                    <Typography
                      variant="body2"
                      fontWeight="700"
                      component="span"
                      color={grey}
                      sx={{
                        textDecoration: "line-through",
                      }}
                    >
                      {inputNumberWithComma(item.item_price) + "원"}
                    </Typography>
                  ) : null}
                </Box>
              </StyledLink>
            </Box>
          ))}
      </Slider>
    </Box>
  );
};

export default HorizontalItemList;
