import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import React from "react";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import {
  BREAK_POINT_PC,
  grey4,
  StyledLink,
} from "../../../common/style/styles";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";

// 마이페이지에서 하단 리스트
const CommonMenu = ({ handleLogout }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <List
      style={{
        width: "100%",
      }}
    >
      <StyledLink to="/notices/0">
        <ListItem width="100%" sx={{ padding: 0, margin: "0.4rem 0" }}>
          <ListItemButton
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              background: grey4,
              padding: `${
                windowSize.width >= BREAK_POINT_PC
                  ? "1.5rem 1rem"
                  : "0.8rem 0.5rem"
              }`,
            }}
          >
            <Box display="flex">
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                component="p"
                margin="0 8px"
                fontWeight="500"
              >
                공지사항
              </Typography>
            </Box>
            <NavigateNextOutlinedIcon />
          </ListItemButton>
        </ListItem>
      </StyledLink>
      <StyledLink to="/notices/1">
        <ListItem
          margin="16px"
          width="100%"
          sx={{ padding: 0, margin: "0.4rem 0" }}
        >
          <ListItemButton
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              background: grey4,
              padding: `${
                windowSize.width >= BREAK_POINT_PC
                  ? "1.5rem 1rem"
                  : "0.8rem 0.5rem"
              }`,
            }}
          >
            <Box display="flex">
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                component="p"
                margin="0 8px"
                fontWeight="500"
              >
                FAQ
              </Typography>
            </Box>
            <NavigateNextOutlinedIcon />
          </ListItemButton>
        </ListItem>
      </StyledLink>

      <StyledLink to="/terms">
        <ListItem
          margin="16px"
          width="100%"
          sx={{ padding: 0, margin: "0.4rem 0" }}
        >
          <ListItemButton
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              background: grey4,
              padding: `${
                windowSize.width >= BREAK_POINT_PC
                  ? "1.5rem 1rem"
                  : "0.8rem 0.5rem"
              }`,
            }}
          >
            <Box display="flex">
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                component="p"
                margin="0 8px"
                fontWeight="500"
              >
                서비스 이용약관
              </Typography>
            </Box>
            <NavigateNextOutlinedIcon />
          </ListItemButton>
        </ListItem>
      </StyledLink>

      <StyledLink to="/privacy">
        <ListItem
          margin="16px"
          width="100%"
          sx={{ padding: 0, margin: "0.4rem 0" }}
        >
          <ListItemButton
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              background: grey4,
              padding: `${
                windowSize.width >= BREAK_POINT_PC
                  ? "1.5rem 1rem"
                  : "0.8rem 0.5rem"
              }`,
            }}
          >
            <Box display="flex">
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                component="p"
                margin="0 8px"
                fontWeight="500"
              >
                개인정보 수집 및 이용
              </Typography>
            </Box>
            <NavigateNextOutlinedIcon />
          </ListItemButton>
        </ListItem>
      </StyledLink>

      <ListItem
        margin="16px"
        width="100%"
        sx={{ padding: 0, margin: "0.4rem 0" }}
      >
        <ListItemButton
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            background: grey4,
            padding: `${
              windowSize.width >= BREAK_POINT_PC
                ? "1.5rem 1rem"
                : "0.8rem 0.5rem"
            }`,
          }}
          onClick={handleLogout}
        >
          <Box display="flex">
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              component="p"
              margin="0 8px"
              fontWeight="500"
            >
              로그아웃
            </Typography>
          </Box>
          <NavigateNextOutlinedIcon />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default CommonMenu;
