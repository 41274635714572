import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDataFromServer } from "../../../common/network/network";
import AccordionList from "../components/accordion_list";
import NoticeList from "../components/notice_list";
import { BREAK_POINT_PC, Column, primary } from "../../../common/style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

// 공지사항, faq 리스트 페이지
const Notices = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  useEffect(() => {
    getDataFromServer(`/service/notices/${id}`).then((response) =>
      setData([...response.data])
    );
  }, []);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {data.length ? (
        id === "0" ? (
          <Box margin="1rem">
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h4" : "h6"}
              textAlign="center"
              margin="1rem 0 2rem 0"
              fontWeight="500"
            >
              공지사항
            </Typography>
            <NoticeList data={data} />
          </Box>
        ) : (
          <Box margin="1rem">
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h4" : "h6"}
              textAlign="center"
              margin="1rem 0 2rem 0"
              fontWeight="500"
            >
              FAQ
            </Typography>
            <AccordionList data={data} isQnA={false} />
          </Box>
        )
      ) : (
        <Box height={windowsize.height / 2} display="flex" alignItems="center">
          <Typography
            variant="subtitle1"
            component="p"
            textAlign="center"
            width="100%"
          >
            {id === "0"
              ? "등록된 공지사항이 없습니다."
              : "등록된 FAQ가 없습니다."}
          </Typography>
        </Box>
      )}
    </Column>
  );
};

export default Notices;
