import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import { inputNumberWithComma } from "../../../common/js/common";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const CurationItemList = ({
  id,
  itemColumns,
  itemInfo,
  item,
  selectedIDs,
  handleClickCheckBox,
  lookBookImages,
  curationItemsByImage,
  handleChangeCurationItemsByImage,
}) => {
  return (
    <ListItem
      sx={{ flexDirection: "column", alignItems: "center" }}
      width="100%"
    >
      <Box display="flex" width="100%">
        {itemColumns.map((value) => (
          <>
            {value.field === "checkbox" ? (
              <Checkbox
                flex="1"
                checked={
                  selectedIDs.includes(item.item_option_id) ? true : false
                }
                onChange={() => handleClickCheckBox(item.item_option_id)}
              />
            ) : value.field === "drag" ? (
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
              >
                <DragHandleIcon />
              </Box>
            ) : value.field === "images" ? (
              <Box flex="1">
                <img
                  style={{
                    backgroundImage: `url(${defaultImg})`,
                    margin: "0 8px",
                  }}
                  src={item.images}
                  width="50px"
                />
              </Box>
            ) : value.field === "look_book" ? (
              itemInfo.curation_type === 3 ? (
                <Box flex="1">
                  <FormControl fullWidth>
                    <InputLabel>이미지 순서</InputLabel>
                    <Select
                      name={item.item_option_id}
                      value={curationItemsByImage[item.item_option_id]}
                      label="이미지 순서"
                      onChange={handleChangeCurationItemsByImage}
                    >
                      {lookBookImages.length ? (
                        lookBookImages.map((image, index) => (
                          <MenuItem
                            style={{
                              display: "block",
                              margin: "8px",
                            }}
                            value={
                              typeof image === "object" ? image.name : image
                            }
                          >
                            {index + 1}번째 이미지
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          style={{
                            display: "block",
                            margin: "8px",
                          }}
                          value={null}
                        >
                          X
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              ) : null
            ) : (
              <Typography key={value.field} component="p" flex="1">
                {id !== "-1" && value.field.includes("price")
                  ? inputNumberWithComma(item[value.field])
                  : item[value.field]}
              </Typography>
            )}
          </>
        ))}
      </Box>
    </ListItem>
  );
};

export default CurationItemList;
