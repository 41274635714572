import { Modal, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import {
  BREAK_POINT_PC,
  CenterModalStyle,
  Column,
} from "../../../common/style/styles";
import CouponItem from "../components/coupon_item";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
const Coupon = () => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [couponList, setCouponList] = useState([]);
  const navigate = useNavigate();
  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box margin="0.6rem 1.2rem">
        <Box display="flex" alignItems="center" marginBottom="1.2rem">
          <NavigateBeforeIcon
            sx={{
              flex: "1",
              fontSize: windowSize.width >= BREAK_POINT_PC ? "3rem" : "2rem",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <Typography
            flex="9"
            textAlign="center"
            variant={windowSize.width >= BREAK_POINT_PC ? "h4" : "h6"}
            fontWeight="700"
            marginRight={windowSize.width >= BREAK_POINT_PC ? "7rem" : "2rem"}
          >
            보유 쿠폰
          </Typography>
        </Box>
        {couponList.length ? (
          <Box>
            <Box marginBottom="0.3rem">
              <CouponItem />
            </Box>
            <Box marginBottom="0.3rem">
              <CouponItem />
            </Box>
            <Box marginBottom="0.3rem">
              <CouponItem />
            </Box>
            <Box marginBottom="0.3rem">
              <CouponItem />
            </Box>
            <Box marginBottom="0.3rem">
              <CouponItem />
            </Box>
          </Box>
        ) : (
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
            fontWeight="700"
            width="100%"
            margin="2rem 0"
            textAlign="center"
          >
            현재 선택할 수 있는 할인 쿠폰이 없습니다.
          </Typography>
        )}
      </Box>
    </Column>
  );
};

export default Coupon;
