import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import ItemList from "../../../common/components/item_list";
import {
  convertDateTime,
  selectImageThumbnail,
} from "../../../common/js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import {
  itemListTimeStampState,
  windowSizeState,
} from "../../../common/state/state";
import { BREAK_POINT_PC, Column, primary } from "../../../common/style/styles";

// 큐레이션 리스트 페이지
export const Show = () => {
  const { id } = useParams();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  // 아이템 리스트
  const [itemList, setItemList] = useState([]);
  // 타이틀 명
  const [titleName, setTitleName] = useState(null);
  // 아이템 찜 리스트
  const [likeItemList, setLikeItemList] = useState([]);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );

  useEffect(() => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");
    getDataFromServer(
      `/service/curation/${id}?token=${
        nonMemberToken == "undefined" ? null : nonMemberToken
      }&timestamp=${itemListTimeStamp}&type=curation`
    ).then((response) => {
      setTitleName(response.curation_name);
      setItemList(response.item_list);
      setLikeItemList(response.like_items);
    });
  }, []);

  // 좋아요 변경 적용
  const handleChangeLikeItemList = (event, state, item_option_id) => {
    event.preventDefault();
    // console.log("handleChangeLikeItemList", state, item_option_id);
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    if (state == "add") {
      postDataFromServer("/service/likes", {
        token: nonMemberToken === "undefined" ? null : nonMemberToken,
        table_name: "item_options",
        table_id: item_option_id.toString(),
      }).then((response) => {
        // console.log(response);
        setLikeItemList([...likeItemList, item_option_id]);
        // 비회원 토큰 정보가 없을경우 토큰정보 로컬에 저장
        if (response["token"] !== "null") {
          localStorage.setItem("nonMemberToken", response["token"]);
        }
      });
    } else if (state == "delete") {
      deleteDataFromServer(
        `/service/likes/-1?token=${nonMemberToken}&table_name=item_options&table_id=${item_option_id.toString()}`
      ).then((response) => {
        // console.log(response);
        setLikeItemList([
          ...likeItemList.filter((value) => value !== item_option_id),
        ]);
      });
    }
  };

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {titleName && (
        <Box>
          <Box margin="1rem">
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h4" : "subtitle2"}
              fontWeight="600"
              gutterBottom
              align={windowsize.width >= BREAK_POINT_PC ? "left" : "center"}
            >
              {titleName}
            </Typography>
          </Box>
          <Divider />
          <Box margin="1rem 0">
            <ItemList
              items={selectImageThumbnail(itemList)}
              likeItemList={likeItemList}
              handleChangeLikeItemList={handleChangeLikeItemList}
            />
            {!itemList.length ? (
              <Box width="100%">
                <Box margin="auto 0" textAlign="center" padding="20% 0">
                  상품이 존재하지 않습니다.
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
    </Column>
  );
};
