import React, { useState } from "react";
import {
  Box,
  Divider,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { BREAK_POINT_PC, grey3 } from "../../../common/style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
const PointInfo = ({ orderInfo, totalPoint, handleChangeOrderInfo }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  // 사용 포인트
  const [point, setPoint] = useState(0);

  // 포인트 사용 금액 변경
  const handleChange = (event) => {
    // 보유 포인트가 0인 경우 return
    if (totalPoint === 0) {
      return;
    }
    // 입력 포인트가 음수인경우 return
    if (event.target.value < 0) {
      return;
    }

    // 입력 포인트가 보유 포인트보다 많을 경우 최대 포인트로 설정 후 return
    if (event.target.value > totalPoint) {
      setPoint(totalPoint);
      handleChangeOrderInfo("discount", {
        ...orderInfo.discount,
        point: totalPoint,
      });
      return;
    }
    setPoint(event.target.value);
    handleChangeOrderInfo("discount", {
      ...orderInfo.discount,
      point: event.target.value ? parseInt(event.target.value) : 0,
    });
  };

  // 전액사용
  const handleClick = () => {
    setPoint(totalPoint);
    handleChangeOrderInfo("discount", {
      point: totalPoint,
    });
  };

  return (
    <>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h5" : "subtitle2"}
        fontWeight="600"
        margin="0.8rem 1rem"
      >
        포인트
      </Typography>
      <Divider />
      <Box margin="0.8rem 1rem">
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            보유 포인트
          </Typography>
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            fontWeight="600"
          >
            {totalPoint}원
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="0.8rem"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            사용 포인트
          </Typography>
          <Box display="flex" alignItems="center">
            <TextField
              InputProps={{
                style: { width: "80px", textAlign: "right" },
                endAdornment: (
                  <InputAdornment position="end">원</InputAdornment>
                ),
              }}
              variant="standard"
              size="small"
              type="number"
              value={point}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              size="small"
              style={{
                margin: "0 0 0 0.5rem",
                background: "black",
                color: "white",
              }}
              onClick={handleClick}
            >
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                fontWeight="600"
              >
                전액 사용
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PointInfo;
