import { Box, Button } from "@mui/material";
import React from "react";
import { borderColor } from "../../../common/style/styles";

// 페이지 하단 크롤링 실행, 추가 버튼
const FooterButtons = ({
  handleExecuteCrawling,
  handleChangeModal,
  handleDelete,
}) => {
  return (
    <Box
      margin="16px 0"
      padding="8px"
      border={"1px solid " + borderColor}
      display="flex"
    >
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={handleExecuteCrawling}
      >
        크롤링 실행
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handleChangeModal("create");
        }}
      >
        크롤링 메뉴 추가
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={() => {
          handleChangeModal("update");
        }}
      >
        크롤링 메뉴 수정
      </Button>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={handleDelete}
      >
        크롤링 메뉴 삭제
      </Button>
    </Box>
  );
};

export default FooterButtons;
