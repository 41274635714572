import { Box, Button, InputBase, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  deleteDataFromServer,
  getDataFromServer,
} from "../../../common/network/network";
import { windowSizeState } from "../../../common/state/state";
import {
  borderColor,
  Column,
  info,
  primary,
  StyledLink,
} from "../../../common/style/styles";
import DataTable from "../../../common/components/data_table";
import MainMenu from "../../../common/components/main_menu";
import { preproccessingForDataTable } from "../../../common/js/common";
import columnData from "../../../common/json/columns.json";
import BrandTagModal from "../components/brand_tag_modal";
import { TailSpin } from "react-loader-spinner";

const Index = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  // 테이블 행 데이터
  const [columns, setColumns] = useState([...columnData[pathname]]);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  // 브랜드 태그 추가 모달
  const [isOpened, setIsOpened] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDataFromServer("/admin/brands")
      .then((response) => {
        setIsAdmin(true);
        const newData = preproccessingForDataTable(
          pathname,
          response.brand_list
        );
        setRows([...newData]);
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDelete = (type, id) => {
    setLoading(true);
    setProcessing(true);
    if (window.confirm("삭제하시겠습니까?")) {
      deleteDataFromServer(`/admin/brands/${id}`)
        .then((response) => {
          alert("삭제가 완료되었습니다.");
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    }
    setLoading(false);
    setProcessing(false);
  };

  // 브랜드 태그 추가 모달 열고 닫기
  const handleChangeModal = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <Box>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <Box margin="8px">
              <StyledLink to={`${pathname}/create/-1`}>
                <Button
                  variant="outlined"
                  style={{ borderRadius: "unset", margin: "0 8px" }}
                >
                  브랜드 추가
                </Button>
              </StyledLink>
              <Button
                variant="outlined"
                style={{ borderRadius: "unset", margin: "0 8px" }}
                onClick={handleChangeModal}
              >
                브랜드 태그 추가
              </Button>
            </Box>
            {rows.length > 0 ? (
              <Box
                border={"1px solid " + borderColor}
                sx={{ overflow: "auto" }}
                maxHeight={(windowSize.height * 8) / 13}
              >
                <DataTable
                  columns={columns}
                  rows={rows}
                  selectedIDs={[]}
                  handleChangeOrderby={null}
                  handleClickCheckBox={null}
                  handleInputInfoChange={null}
                  handleDelete={handleDelete}
                />
              </Box>
            ) : (
              <Typography textAlign="center" variant="h4" margin="32px 0">
                데이터가 없습니다.
              </Typography>
            )}
          </Box>
          <BrandTagModal
            isOpened={isOpened}
            handleChangeModal={handleChangeModal}
          />
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </Box>
  );
};

export { Index as BrandManagement };
