import {
  Box,
  Divider,
  Input,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CenterModalStyle, primary } from "../../../common/style/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TailSpin } from "react-loader-spinner";
import { postDataFromServer } from "../../../common/network/network";
import DurationSelector from "../../../common/components/duration_selector";
import { convertDate } from "../../../common/js/common";

// 할인 설정 모달
const SaleItemModal = ({ selectedIDs, isOpened, handleModalChange }) => {
  const [hasDate, setHasDate] = useState(true);
  const [date, setDate] = useState({
    start: dayjs(new Date()),
    end: dayjs(new Date()),
  });
  const [sale, setSale] = useState({
    price: "",
    rate: "",
  });
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  const handleChangeDate = (name, value) => {
    setDate((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSale = (event) => {
    const { name, value } = event.target;

    // 음수 입력시 return
    if (value < 0) {
      return;
    }
    setSale((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleHasDate = () => {
    setHasDate((prev) => {
      {
        return !prev;
      }
    });
  };

  const handleButtonClick = () => {
    console.log(hasDate, date, sale);

    if (processing) {
      return;
    }

    // 할인 금액, 할인율 듈다 입력했을 경우 return
    if (sale.price && sale.price !== "0" && sale.rate && sale.rate !== "0") {
      alert("할인 설정은 할인 금액과 할인율 중 하나만 설정할 수 있습니다.");
      return;
    }

    // 할인 설정 안한경우
    if (
      (!sale.price || sale.price === "0") &&
      (!sale.rate || sale.rate === "0")
    ) {
      alert("할인 설정을 해주세요.");
      return;
    }

    if (hasDate) {
      const startDate = dayjs(date.start, "DDMMYYYY").toDate();
      const endDate = dayjs(date.end, "DDMMYYYY").toDate();
      const today = new Date();
      // 시작일이 종료일보다 늦은경우
      if (startDate > endDate) {
        alert("시작일이 종료일보다 늦을 수 없습니다.");
        return;
      }

      // 종료 기간이 현재 날짜보다 이전인 경우
      if (today > endDate) {
        alert("종료기간은 현재일 이후여야 합니다.");
        return;
      }
    }

    const newDate = {
      start: convertDate(dayjs(date.start, "DDMMYYYY").toDate()),
      end: convertDate(dayjs(date.end, "DDMMYYYY").toDate()),
    };

    setLoading(true);
    setProcessing(true);

    postDataFromServer("/admin/items/sale", {
      ids: selectedIDs,
      sale: sale,
      date: hasDate ? newDate : null,
    })
      .then((response) => {
        if (response.error_items.length) {
          alert(
            `제품 번호 ${response.error_items.join(
              ","
            )} 제외한 상품이 할인 설정되었습니다.`
          );
        } else {
          alert("선택한 상품이 할인 설정되었습니다.");
        }
        handleModalClose();
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      });

    setLoading(false);
    setProcessing(false);
  };

  const handleModalClose = () => {
    setSale({ price: "", rate: "" });
    setDate({ start: dayjs(new Date()), end: dayjs(new Date()) });
    setHasDate(true);
    handleModalChange("saleItem");
  };

  return (
    <Modal
      open={isOpened}
      onClose={handleModalClose}
      sx={{ pointerEvents: processing ? "none" : "auto" }}
    >
      <Box sx={CenterModalStyle} width="50%">
        <Box padding="16px">
          <Typography variant="h6">할인 설정</Typography>
          <Box display="flex" margin="12px 0 ">
            <Typography minWidth="80px">할인 금액</Typography>
            <TextField
              variant="standard"
              type="number"
              name="price"
              value={sale.price}
              onChange={handleSale}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <Typography>원</Typography>
          </Box>
          <Box display="flex" margin="12px 0 ">
            <Typography minWidth="80px">할인율</Typography>
            <TextField
              variant="standard"
              type="number"
              name="rate"
              value={sale.rate}
              onChange={handleSale}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <Typography>%</Typography>
          </Box>
          <Divider />
          <Box display="flex">
            <Typography variant="h6">할인 기간 설정</Typography>
            <Switch
              checked={hasDate}
              onChange={handleHasDate}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          {hasDate ? (
            <DurationSelector date={date} handleChangeDate={handleChangeDate} />
          ) : null}
        </Box>
        <Box
          padding="16px"
          sx={{
            backgroundColor: `${primary}`,
            textAlign: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleButtonClick}
        >
          선택한 아이템 할인 설정하기
        </Box>
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Modal>
  );
};

export default SaleItemModal;
