import React, { useState } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { calculateSizeOptionPrice, inputNumberWithComma } from "../js/common";
import { Grid } from "@material-ui/core";
import { Box, Divider, Typography } from "@mui/material";
import styled from "styled-components";
import { BREAK_POINT_PC, StyledLink, grey, borderColor } from "../style/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useLocation } from "react-router-dom";
import { windowSizeState } from "../state/state";
import { useRecoilState } from "recoil";
import defaultImg from "../../media_assets/image/gallery1.jpg";
import BuyModal from "../modal/buy_modal";
import { getDataFromServer } from "../network/network";

// 브랜드, 카테고리, 찜 페이지의 아이템 리스트
const ItemList = ({
  listData,
  items,
  likeItemList,
  handleChangeLikeItemList,
}) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const { pathname } = useLocation();

  const Container = styled.div`
    padding: 0 8px;
  `;
  // 구매하기 모달 확인
  const [isOpened, setIsOpened] = useState(false);
  // 찜목록에서 구매하기 버튼 누를경우 아이템의 사이즈리스트
  const [sizeList, setSizeList] = useState([]);
  // 찜목록에서 구매하기 버튼 누를경우 아이템의 정보
  const [selectedItem, setSelectedItem] = useState({});
  // 구매하기 모달 열고 닫기
  const handleClickModal = (itemOptionId) => {
    if (typeof itemOptionId === "number" && itemOptionId) {
      const newSelectedItem = items.find(
        (item) => item.item_option_id === itemOptionId
      );
      setSelectedItem({ ...newSelectedItem });
      const nonMemberToken = localStorage.getItem("nonMemberToken");
      getDataFromServer(
        `/service/likes/${
          newSelectedItem.item_id
        }/${newSelectedItem.color.replace("/", "&")}?reference=${
          newSelectedItem.reference
        }&token=${nonMemberToken == "undefined" ? null : nonMemberToken}`
      ).then((response) => {
        // 사이즈별 추가 가격 계산

        if (response.size_list.length !== 0) {
          const newSizeList = calculateSizeOptionPrice(
            response.size_list,
            response.price_list,
            response.orderby_size
          );

          setSizeList([...newSizeList]);
        }
      });
    }

    setIsOpened((prev) => !prev);
  };

  return (
    <Container id="scrollArea">
      <Grid
        container
        // spacing={3}
        // rowSpacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        {items.map((item) => (
          <Grid item key={item.item_option_id} xs={6} sm={6} md={6} lg={6}>
            <StyledLink
              to={`/items/${item.item_id}/${item.item_detail_id}/${item.item_option_id}`}
              state={{
                listData: listData,
                items: items,
                likeItemList: likeItemList,
              }}
            >
              <Box padding="0 10px">
                <img
                  style={{
                    backgroundImage: !item.images
                      ? `url(${defaultImg})`
                      : "none",
                    objectFit: "contain",
                  }}
                  id={item.item_option_id}
                  src={item.images}
                  srcSet={item.images}
                  alt={item.item_name}
                  // loading="lazy"
                  width={"100%"}
                  height={
                    windowSize.width >= BREAK_POINT_PC
                      ? (((windowSize.width * 0.75 - 56) / 2) * 100) / 66
                      : (((windowSize.width - 56) / 2 - 20) * 100) / 66
                  }
                />
                {/* 상품 정보 */}
                {windowSize.width >= BREAK_POINT_PC ? (
                  <Box>
                    {/* 브랜드, 상품명 */}
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Box width="80%">
                        {/* 브랜드 이름 */}
                        <Typography
                          variant="h6"
                          fontWeight="500"
                          noWrap={true}
                          margin="1rem 0 0.25rem 0"
                        >
                          {item.brand_name}
                        </Typography>
                        {/* 상품명 */}
                        <Typography
                          variant="h5"
                          noWrap={true}
                          fontWeight="700"
                          marginBottom={
                            windowSize.width >= BREAK_POINT_PC
                              ? "0.75rem"
                              : "0.4rem"
                          }
                        >
                          {item.item_name}
                        </Typography>
                      </Box>
                      {/* 좋아요 표시 */}
                      {!pathname.includes("likes") &&
                        (likeItemList.includes(item.item_option_id) ? (
                          <Box
                            marginTop="1rem"
                            onClick={(event) => {
                              handleChangeLikeItemList(
                                event,
                                "delete",
                                item.item_option_id
                              );
                            }}
                          >
                            <FavoriteIcon
                              color="primary"
                              style={{
                                fontSize: "3rem",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            onClick={(event) => {
                              handleChangeLikeItemList(
                                event,
                                "add",
                                item.item_option_id
                              );
                            }}
                            marginTop="1rem"
                          >
                            <FavoriteBorderIcon
                              // color={borderColor}
                              style={{
                                fontSize: "3rem",
                                color: `${borderColor}`,
                              }}
                            />
                          </Box>
                        ))}
                    </Box>

                    {/* 가격 표시 */}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      margin={
                        !pathname.includes("likes")
                          ? "0.75rem 0 6rem 0"
                          : "0.75rem 0 1rem 0"
                      }
                    >
                      <Box display="flex">
                        {item.item_price !== item.sale_price && (
                          <Box>
                            <Typography
                              variant="h5"
                              fontWeight="700"
                              component="span"
                              color="primary"
                              marginRight={
                                windowSize.width >= BREAK_POINT_PC
                                  ? "1rem"
                                  : "0.5rem"
                              }
                            >
                              {Math.round(
                                100 - (item.sale_price / item.item_price) * 100
                              )}
                              %
                            </Typography>

                            <Typography
                              variant="h5"
                              fontWeight="700"
                              component="span"
                              color={grey}
                              sx={{
                                textDecoration: "line-through",
                              }}
                              marginRight={
                                windowSize.width >= BREAK_POINT_PC
                                  ? "1rem"
                                  : "0.5rem"
                              }
                            >
                              {inputNumberWithComma(item.item_price)}원
                            </Typography>
                          </Box>
                        )}

                        <Typography
                          variant="h5"
                          fontWeight="700"
                          component="span"
                        >
                          {inputNumberWithComma(item.sale_price)}원
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box marginBottom="1rem">
                    {/* 브랜드, 상품명 */}
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Box width="100%">
                        {/* 브랜드 이름 */}
                        <Typography
                          variant="body2"
                          fontWeight="500"
                          noWrap={true}
                          margin="0.5rem 0 0.25rem 0"
                        >
                          {item.brand_name}
                        </Typography>
                        {/* 상품명 */}
                        <Typography
                          variant="body1"
                          noWrap={true}
                          fontWeight="700"
                        >
                          {item.item_name}
                        </Typography>
                      </Box>
                    </Box>

                    {/* 가격 표시 */}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        {item.item_price !== item.sale_price && (
                          <Typography
                            variant="subtitle2"
                            fontWeight="700"
                            component="span"
                            color="primary"
                            margin="0 0.5rem 0 0"
                          >
                            {Math.round(
                              100 - (item.sale_price / item.item_price) * 100
                            )}
                            %
                          </Typography>
                        )}

                        <Typography
                          variant="subtitle2"
                          fontWeight="700"
                          component="span"
                        >
                          {inputNumberWithComma(item.sale_price)}원
                        </Typography>
                      </Box>

                      {/* 좋아요 표시 */}
                      {!pathname.includes("likes") &&
                        (likeItemList.includes(item.item_option_id) ? (
                          <Box
                            onClick={(event) => {
                              handleChangeLikeItemList(
                                event,
                                "delete",
                                item.item_option_id
                              );
                            }}
                            margin="0.4rem"
                          >
                            <FavoriteIcon
                              color="primary"
                              style={{
                                fontSize: "1.5rem",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            onClick={(event) => {
                              handleChangeLikeItemList(
                                event,
                                "add",
                                item.item_option_id
                              );
                            }}
                            margin="0.4rem"
                          >
                            <FavoriteBorderIcon
                              color="primary"
                              style={{
                                fontSize: "1.5rem",
                              }}
                            />
                          </Box>
                        ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </StyledLink>
            {pathname.includes("likes") && (
              <Box
                sx={{ backgroundColor: "black" }}
                margin={
                  windowSize.width >= BREAK_POINT_PC ? "2rem 10px" : "1rem 10px"
                }
              >
                <Typography
                  variant={
                    windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                  }
                  gutterBottom
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "4px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleClickModal(item.item_option_id);
                  }}
                >
                  구매하기
                </Typography>
                <BuyModal
                  isOpened={isOpened}
                  handleClickModal={handleClickModal}
                  sizeList={sizeList}
                  itemInfo={selectedItem}
                />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ItemList;
