import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { CenterModalStyle } from "../../../common/style/styles";

// 수수료 책정 모달
const ChargeModal = ({
  name,
  isOpened,
  handleModalChange,
  handleCancelAndReturn,
}) => {
  const [cancelFee, setCancelFee] = useState(0);

  const handleChangeCancelFee = (event) => {
    const fee = parseInt(event.target.value);
    if (fee > 0) {
      setCancelFee(fee);
    }
  };

  const handleSubmit = () => {
    handleModalChange("returnCharges");
    handleCancelAndReturn(true, null, cancelFee);
  };

  const handleModalClose = () => {
    setCancelFee(0);
    handleModalChange("returnCharges");
  };

  return (
    <Modal open={isOpened} onClose={handleModalChange}>
      <Box sx={CenterModalStyle} width="50%">
        <Box margin="16px">
          <Typography variant="h6">취소수수료 입력</Typography>
          <Box margin="8px 0 16px 0">
            <Typography>취소 수수료</Typography>
            <TextField
              placeholder="취소 수수료를 입력해주세요."
              fullWidth={true}
              value={cancelFee}
              onChange={handleChangeCancelFee}
            />
          </Box>
          <Box margin="8px 0">
            <Button
              variant="outlined"
              sx={{ margin: "0 8px 0 0" }}
              onClick={handleSubmit}
            >
              {name} 완료 처리
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleModalClose}
            >
              닫기
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChargeModal;
