import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { fillZero } from "../../../common/js/common";
import orderState from "../../../common/json/order_state.json";
import {
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import {
  BREAK_POINT_PC,
  grey4,
  primary,
  StyledLink,
  grey3,
  blue,
} from "../../../common/style/styles";
import CancelModal from "../modal/cancel_modal";
import deleveryCode from "../../../common/json/delivery_code.json";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

//주문 상태별 버튼
/* 
    (검은색 버튼)
    결제완료 - 결제취소 
    배송준비 - 취소요청
    배송완료 - 반품요청, 교환요청, 구매확정 
    구매확정 - 리뷰남기기 
    반품요청 - 반품철회
    교환요청  - 교환철회

    ==================
    (회색버튼)
    취소완료
    반품완료
    교환완료
*/

const StyledButton = styled(Button)`
  && {
    color: white;
    background: ${({ canClickable }) => (canClickable ? "black" : blue)};
    border-radius: 2px;
    padding: 0.5rem 1.5rem;
    margin: 1rem 0.2rem 1rem 0;
    font-weight: 500;
    font-size: ${({ fontSize }) => fontSize};
    flex: 1;
    &:hover {
      color: white;
      background: ${({ canClickable }) => (canClickable ? "black" : blue)};
    }
  }
`;

const OrderStatusButton = ({ item, handleChangeState }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const navigate = useNavigate();
  // 취소,반품,교환 모달
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  // 취소,반품,교환 구분
  const [cancelState, setCancelState] = useState(0);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 취소,반품,교환 모달 열고 닫기
  const handleCancelModalChange = (stateToChange) => {
    stateToChange && setCancelState(stateToChange);
    setIsCancelOpen((prev) => !prev);
  };

  // 취소, 철화할건지 확인후 진행
  const checkIsAssure = (
    orderItemId,
    currentState,
    stateToChange,
    buttonName
  ) => {
    if (
      !window.confirm(
        `${buttonName
          .replace("이", "을")
          .replace("가", "를")} 진행하시겠습니까?`
      )
    ) {
      return;
    }

    handleChangeState(orderItemId, currentState, stateToChange, buttonName);
  };

  return (
    <Box sx={{ pointerEvents: processing ? "none" : "auto" }}>
      <Box display="flex">
        {orderState.orderState[item.state] === "결제완료" && (
          <StyledButton
            fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
            canClickable={true}
            onClick={() => {
              navigate(`/cancel/${item.order_id}/${item.order_item_id}/7`);
            }}
          >
            결제취소
          </StyledButton>
        )}
        {orderState.orderState[item.state] === "배송준비" && (
          <StyledButton
            onClick={() => {
              navigate(`/cancel/${item.order_id}/${item.order_item_id}/6`);
            }}
            canClickable={true}
            fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
          >
            취소요청
          </StyledButton>
        )}

        {orderState.orderState[item.state] === "배송완료" && (
          <Box display="flex" flex="1">
            <StyledButton
              fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
              canClickable={true}
              onClick={() => {
                navigate(`/cancel/${item.order_id}/${item.order_item_id}/8`);
              }}
            >
              반품요청
            </StyledButton>
            <StyledButton
              fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
              canClickable={true}
              onClick={() => {
                navigate(`/cancel/${item.order_id}/${item.order_item_id}/8`);
              }}
            >
              교환요청
            </StyledButton>
            <StyledButton
              fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
              canClickable={true}
              onClick={() => {
                checkIsAssure(item.order_item_id, item.state, 5, "구매확정이");
              }}
            >
              구매확정
            </StyledButton>
          </Box>
        )}

        {orderState.orderState[item.state] === "구매확정" && (
          <StyledButton
            fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
            canClickable={true}
            onClick={() => {
              navigate(`/reviews/${item.order_item_id}`);
            }}
          >
            후기{!item.review_id ? "작성" : "수정"}
          </StyledButton>
        )}
        {orderState.orderState[item.state] === "반품요청" && (
          <StyledButton
            fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
            canClickable={true}
            onClick={() => {
              checkIsAssure(item.order_item_id, item.state, 4, "반품철회가");
            }}
          >
            반품철회
          </StyledButton>
        )}
        {orderState.orderState[item.state] === "교환요청" && (
          <StyledButton
            fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
            canClickable={true}
            onClick={() => {
              checkIsAssure(item.order_item_id, item.state, 4, "교환철회가");
            }}
          >
            교환철회
          </StyledButton>
        )}
        {orderState.orderState[item.state] === "취소요청" && (
          <StyledButton
            fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
            canClickable={true}
            onClick={() => {
              checkIsAssure(item.order_item_id, item.state, 2, "취소철회가");
            }}
          >
            취소철회
          </StyledButton>
        )}
        {orderState.orderState[item.state] === "취소완료" && (
          <StyledButton
            fontSize={windowsize.width >= BREAK_POINT_PC ? "12pt" : "10.5pt"}
            canClickable={false}
          >
            취소완료
          </StyledButton>
        )}
      </Box>

      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={loadling}
      />
    </Box>
  );
};

export default OrderStatusButton;
