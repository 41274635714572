import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import {
  borderColor,
  lightgrey,
  lightcoral,
  primary,
  StyledLink,
} from "../../../common/style/styles";
import { useLocation, useNavigate } from "react-router-dom";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import { inputNumberWithComma } from "../../../common/js/common";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { getDataFromServer } from "../../../common/network/network";
import CurationItemList from "./curation_item_list";
import ClearIcon from "@mui/icons-material/Clear";
import DurationSelector from "../../../common/components/duration_selector";

const CurationEditData = ({
  id,
  columns,
  itemInfo,
  items,
  selectedIDs,
  handleClickCheckBox,
  handleDeleteCurationItems,
  handleModalChange,
  handleChangeData,
  handleSaveData,
  handleChangeLookBookImages,
  lookBookImages,
  curationItemsByImage,
  handleChangeCurationItemsByImage,
  hasDate,
  handleHasDate,
  date,
  handleChangeDate,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [infoColumns, setInfoColumns] = useState(columns.info);
  const itemColumns = columns.items;

  useEffect(() => {
    getDataFromServer("/admin/menu/main").then((response) => {
      const newInfo = columns.info.map((value) => {
        if (value.field === "menu_id") {
          return { ...value, option: response.items };
        }
        return value;
      });

      setInfoColumns([...newInfo]);
    });
  }, [columns]);

  return (
    <Box margin="16px">
      <Box margin="8px 0" display="flex">
        <Button
          variant="outlined"
          style={{
            borderRadius: "unset",
            margin: "0 8px",
          }}
          onClick={handleSaveData}
        >
          이벤트 정보 저장
        </Button>
        <Button
          variant="outlined"
          style={{
            borderRadius: "unset",
            margin: "0 8px",
          }}
          onClick={handleDeleteCurationItems}
        >
          이벤트 상품 삭제
        </Button>
        <Button
          variant="outlined"
          style={{
            borderRadius: "unset",
            margin: "0 8px",
          }}
          onClick={handleModalChange}
        >
          이벤트 상품 추가
        </Button>
      </Box>
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          데이터 {pathname.includes("create") ? "생성" : "수정"}
        </ListItem>
        <Divider />
        {/* 이벤트 정보 */}
        <ListItem style={{ backgroundColor: `${lightcoral}` }}>
          이벤트 정보
        </ListItem>
        {infoColumns.map((value) => (
          <ListItem
            key={value.field}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Typography component="p" flex="1">
                {value.name}
              </Typography>
              {value.type === "select" ? (
                <FormControl
                  variant="standard"
                  sx={{ flex: "5", margin: "8px 0" }}
                >
                  <InputLabel>선택</InputLabel>
                  <Select
                    name={value.field}
                    value={
                      itemInfo[value.field] !== "" ? itemInfo[value.field] : ""
                    }
                    onChange={handleChangeData}
                    label="filter"
                  >
                    {value.option.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id ? option.id : option.value}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : value.type === "string" || value.type === "number" ? (
                <TextField
                  sx={{ flex: "5", margin: "8px 0" }}
                  size="small"
                  name={value.field}
                  value={itemInfo[value.field] ? itemInfo[value.field] : ""}
                  onChange={handleChangeData}
                  type={value.type}
                  step="0.01"
                />
              ) : value.type === "image" ? (
                <Box flex="5" margin="8px 0">
                  <Box margin="8px 0">
                    <Button
                      variant="outlined"
                      style={{ borderRadius: "unset" }}
                    >
                      <label
                        htmlFor="size"
                        style={{
                          cursor: "pointer",
                          padding: "8px",
                        }}
                      >
                        이미지 파일 선택
                      </label>
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      id="size"
                      onChange={handleChangeData}
                    />
                  </Box>
                  {itemInfo[value.field] !== "NULL" && (
                    <img
                      src={
                        typeof itemInfo[value.field] === "object"
                          ? URL.createObjectURL(itemInfo[value.field])
                          : itemInfo[value.field]
                      }
                      width="80%"
                    />
                  )}
                </Box>
              ) : value.type === "date" ? (
                <Box flex="5">
                  <Switch
                    checked={hasDate}
                    onChange={handleHasDate}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  {hasDate ? (
                    <DurationSelector
                      date={date}
                      handleChangeDate={handleChangeDate}
                    />
                  ) : null}
                </Box>
              ) : (
                <Typography component="p" flex="5">
                  {itemInfo[value.field]}
                </Typography>
              )}
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
        {/* 룩북 이미지 첨부 */}
        {itemInfo.curation_type === 3 ? (
          <>
            <ListItem style={{ backgroundColor: `${lightcoral}` }}>
              대표 이미지 (룩북 이미지)
            </ListItem>
            <ListItem>
              <Box>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "unset",
                    margin: "8px",
                    border: `1px solid ${primary}`,
                    padding: "4px",
                    color: primary,
                  }}
                >
                  <label htmlFor="images">대표 이미지 파일 선택</label>
                  <input
                    type="file"
                    accept="image/*"
                    id="images"
                    multiple
                    onChange={handleChangeLookBookImages}
                  />
                </Button>
                <Box display="flex" flexWrap="wrap">
                  {lookBookImages.map((image) => (
                    <Box position="relative">
                      <ClearIcon
                        fontSize="small"
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: 10,
                          top: 10,
                        }}
                        onClick={() => {
                          handleChangeLookBookImages({
                            target: {
                              value:
                                typeof image === "object" ? image.name : image,
                              files: [],
                            },
                          });
                        }}
                      />
                      <img
                        name={typeof image === "object" ? image.name : image}
                        src={
                          typeof image === "object"
                            ? URL.createObjectURL(image)
                            : image
                        }
                        style={{ width: "300px" }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </ListItem>{" "}
          </>
        ) : null}
        {/* 이벤트 상품 정보 */}
        {itemColumns.length && (
          <Box>
            <ListItem style={{ backgroundColor: `${lightcoral}` }}>
              이벤트 상품 정보
            </ListItem>
            <ListItem sx={{ flexDirection: "column" }} width="100%">
              <Box display="flex" width="100%">
                {itemColumns.map((value) => (
                  <>
                    {value.field === "checkbox" ? (
                      <Checkbox
                        flex="1"
                        checked={selectedIDs.includes(-1) ? true : false}
                        onChange={() => handleClickCheckBox(-1)}
                      />
                    ) : value.field === "drag" ? (
                      <Box display="flex" alignItems="center">
                        <DragHandleIcon />
                      </Box>
                    ) : value.field === "look_book" ? (
                      itemInfo.curation_type === 3 ? (
                        <Typography key={value.field} component="p" flex="1">
                          {value.name}
                        </Typography>
                      ) : null
                    ) : (
                      <Typography key={value.field} component="p" flex="1">
                        {value.name}
                      </Typography>
                    )}
                  </>
                ))}
              </Box>
            </ListItem>
            {items.map((item) => (
              <CurationItemList
                id={id}
                itemColumns={itemColumns}
                itemInfo={itemInfo}
                item={item}
                selectedIDs={selectedIDs}
                handleClickCheckBox={handleClickCheckBox}
                lookBookImages={lookBookImages}
                curationItemsByImage={curationItemsByImage}
                handleChangeCurationItemsByImage={
                  handleChangeCurationItemsByImage
                }
              />
            ))}
          </Box>
        )}
      </List>
    </Box>
  );
};

export default CurationEditData;
