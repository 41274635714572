import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDataFromServer, postDataFromServer } from "../network/network";
import QnAForm from "../components/qna_form";
import { Column } from "../style/styles";

const WriteQuestion = () => {
  const navigate = useNavigate();
  const { id, optionId } = useParams();
  // 더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  const [question, setQuestion] = useState({
    title: "",
    content: "",
    qna_type: id ? 0 : 1,
    is_show: true,
    is_secret: false,
  });
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    getDataFromServer("/service/users/name")
      .then((response) => {
        setIsLogin(true);
      })
      .catch((error) => {
        // 로그인 정보가 없으면 로그인으로 리다이렉트
        alert("로그인 후 사용해주세요.");
        navigate("/login");
        return;
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    setQuestion({
      ...question,
      [name]: name === "is_secret" ? checked : value,
    });
  };

  const handleSubmit = () => {
    if (hasClick) {
      return;
    }
    setHasClick(true);
    // 제목, 내용을 작성했는지 확인
    if (!question.title || !question.content) {
      alert("문의 내용을 작성해주세요.");
      setHasClick(false);
      return;
    }

    const newData = {
      ...question,
      item_id: id ? id : null,
      item_option_id: optionId ? optionId : null,
    };

    // 서버로 문의 내용 전송
    postDataFromServer("/service/qna", newData)
      .then((response) => {
        alert("문의가 접수되었습니다.");
        navigate(-1);
      })
      .catch((error) => {
        // 회원정보 없을 시 로그인 페이지로 이동
        alert("로그인 후 이용해주세요.");
        navigate("/login");
      });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {isLogin && (
        <Box margin="8px">
          <Typography
            variant="subtitle2"
            component="p"
            textAlign="center"
            margin="8px 0"
          >
            문의하기
          </Typography>
          <Divider />
          <QnAForm
            itemId={id}
            optionId={optionId}
            question={question}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
          />
        </Box>
      )}
    </Column>
  );
};

export default WriteQuestion;
