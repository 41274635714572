import React from "react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {
  borderColor,
  lightgrey,
  lightcoral,
  StyledLink,
} from "../../../common/style/styles";
import { useLocation } from "react-router-dom";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import { inputNumberWithComma } from "../../../common/js/common";
import CurationItemList from "./curation_item_list";

const CurationDataList = ({
  columns,
  itemInfo,
  items,
  lookBookImages,
  curationItemsByImage,
  handleDeleteData,
}) => {
  const { pathname } = useLocation();
  console.log(curationItemsByImage);

  return (
    <Box margin="16px">
      <Box margin="8px 0">
        <StyledLink to={pathname.replace("show", "edit")}>
          <Button variant="outlined" style={{ borderRadius: "unset" }}>
            데이터 수정
          </Button>
        </StyledLink>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleDeleteData}
        >
          데이터 삭제
        </Button>
      </Box>
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          데이터 상세보기
        </ListItem>
        <Divider />
        <ListItem style={{ backgroundColor: `${lightcoral}` }}>
          이벤트 정보
        </ListItem>
        {columns.info.map((value) => (
          <ListItem
            key={value.field}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Box display="flex" width="100%">
                {value.field === "look_book" ? (
                  itemInfo.curation_type === 3 ? (
                    <Typography key={value.field} component="p" flex="1">
                      {value.name}
                    </Typography>
                  ) : null
                ) : (
                  <Typography component="p" flex="1">
                    {value.name}
                  </Typography>
                )}
                {value.field === "look_book" ? (
                  <Box display="flex" flexWrap="wrap" flex="5">
                    {lookBookImages.map((image) => (
                      <Box>
                        <img
                          name={typeof image === "object" ? image.name : image}
                          src={
                            typeof image === "object"
                              ? URL.createObjectURL(image)
                              : image
                          }
                          style={{ width: "300px" }}
                        />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography component="p" flex="5">
                    {itemInfo[value.field]}
                  </Typography>
                )}
              </Box>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
        {columns.items.length > 0 && (
          <Box>
            <ListItem style={{ backgroundColor: `${lightcoral}` }}>
              이벤트 상품 정보
            </ListItem>
            <ListItem sx={{ flexDirection: "column" }} width="100%">
              <Box display="flex" width="100%">
                {columns.items.map((value) => (
                  <Typography key={value.field} component="p" flex="1">
                    {value.name}
                  </Typography>
                ))}
              </Box>
            </ListItem>
            {items.map((item) => (
              <CurationItemList
                itemColumns={columns.items}
                itemInfo={itemInfo}
                item={item}
                lookBookImages={lookBookImages}
                curationItemsByImage={curationItemsByImage}
              />
            ))}
          </Box>
        )}
      </List>
    </Box>
  );
};

export default CurationDataList;
