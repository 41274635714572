import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  convertDateTimeByKo,
  inputNumberWithComma,
} from "../../../common/js/common";
import { Grid } from "@material-ui/core";
import { Box, Divider, Typography } from "@mui/material";
import styled from "styled-components";
import { BREAK_POINT_PC, StyledLink } from "../../../common/style/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useLocation } from "react-router-dom";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import defaultImg from "../../../media_assets/image/gallery1.jpg";

// pc, 모바일 이벤트 유형 1 & pc 이벤트유형 4
const ItemList = ({
  listData,
  items,
  likeItemList,
  handleChangeLikeItemList,
}) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const { pathname } = useLocation();

  const Container = styled.div`
    padding: 0 8px;
  `;

  return (
    <Container id="scrollArea">
      {items.length > 0 ? (
        <Grid
          container
          // spacing={3}
          // rowSpacing={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          {items.map((item) => (
            <Grid item key={item.item_option_id} xs={6} sm={6} md={4} lg={4}>
              <StyledLink
                to={`/items/${item.item_id}/${item.item_detail_id}/${item.item_option_id}`}
                state={{
                  listData: listData,
                  items: items,
                  likeItemList: likeItemList,
                }}
              >
                <Box padding="0 10px">
                  <img
                    style={{
                      backgroundImage: !item.images
                        ? `url(${defaultImg})`
                        : "none",
                      objectFit: "contain",
                    }}
                    id={item.item_option_id}
                    src={item.images}
                    srcSet={item.images}
                    alt={item.item_name}
                    // loading="lazy"
                    width="100%"
                    height={
                      windowSize.width >= BREAK_POINT_PC
                        ? (((windowSize.width * 0.75 - 56) / 3) * 100) / 66
                        : (((windowSize.width - 56) / 2 - 20) * 100) / 66
                    }
                  />
                  {/* 이벤트 남은 일자 표시 */}
                  <Box>
                    {windowSize.width >= BREAK_POINT_PC &&
                    item.curation_type === 4 ? (
                      <Box
                        sx={{ background: "black" }}
                        padding="0.25rem 1.2rem"
                        width="fit-content"
                        margin="1rem 0"
                      >
                        <Typography
                          variant="subtitle1"
                          fontWeight="500"
                          component="span"
                          color="white"
                        >
                          {convertDateTimeByKo(new Date(item.ended_at))}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                  {/* 브랜드 이름 */}
                  <Typography
                    variant={
                      windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                    }
                    fontWeight="500"
                    noWrap={true}
                    margin="0.5rem 0 0.25rem 0"
                  >
                    {item.brand_name}
                  </Typography>
                  {/* 상품명 */}
                  <Typography
                    variant={
                      windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    noWrap={true}
                    fontWeight="700"
                    marginBottom={
                      windowSize.width >= BREAK_POINT_PC ? "0.4rem" : "0.4rem"
                    }
                  >
                    {item.item_name}
                  </Typography>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={
                      windowSize.width >= BREAK_POINT_PC ? "2rem" : "2rem"
                    }
                  >
                    <Box>
                      {item.item_price !== item.sale_price && (
                        <Typography
                          variant={
                            windowSize.width >= BREAK_POINT_PC
                              ? "h6"
                              : "subtitle2"
                          }
                          fontWeight="700"
                          component="span"
                          color="primary"
                          marginRight={
                            windowSize.width >= BREAK_POINT_PC
                              ? "1rem"
                              : "0.5rem"
                          }
                        >
                          {Math.round(
                            100 - (item.sale_price / item.item_price) * 100
                          )}
                          %
                        </Typography>
                      )}
                      <Typography
                        variant={
                          windowSize.width >= BREAK_POINT_PC
                            ? "h6"
                            : "subtitle2"
                        }
                        fontWeight="700"
                        component="span"
                      >
                        {inputNumberWithComma(
                          item.item_price !== item.sale_price
                            ? item.sale_price
                            : item.item_price
                        ) + "원"}
                      </Typography>
                    </Box>
                    {/* 좋아요 표시 */}
                    {item.curation_type === 1
                      ? !pathname.includes("likes") &&
                        (likeItemList.includes(item.item_option_id) ? (
                          <Box
                            onClick={(event) => {
                              handleChangeLikeItemList(
                                event,
                                "delete",
                                item.item_option_id
                              );
                            }}
                          >
                            <FavoriteIcon
                              color="primary"
                              style={{
                                fontSize:
                                  windowSize.width >= BREAK_POINT_PC
                                    ? "2rem"
                                    : "1.2rem",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            onClick={(event) => {
                              handleChangeLikeItemList(
                                event,
                                "add",
                                item.item_option_id
                              );
                            }}
                          >
                            <FavoriteBorderIcon
                              color="primary"
                              style={{
                                fontSize:
                                  windowSize.width >= BREAK_POINT_PC
                                    ? "2rem"
                                    : "1.2rem",
                              }}
                            />
                          </Box>
                        ))
                      : null}
                  </Box>
                </Box>
              </StyledLink>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box width="100%">
          <Box margin="auto 0" textAlign="center" padding="20% 0">
            상품이 존재하지 않습니다.
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default ItemList;
