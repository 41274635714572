import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { borderColor, BREAK_POINT_PC, grey } from "../style/styles";
import { windowSizeState } from "../state/state";
import { useRecoilState } from "recoil";

// 회원가입, 정보수정 페이지의 로그인 정보 입력 양식
const LoginForm = ({
  data,
  handleChangeInput,
  isEditing,
  handleIsDuplicated,
}) => {
  const regEmail =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
  const regPassword =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,12}$/;
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConFirmPassword, setShowConFirmPassword] = useState(false);
  const handleClickShowConFirmPassword = () =>
    setShowConFirmPassword((show) => !show);
  const handleMouseDownConFirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {windowSize.width >= BREAK_POINT_PC ? (
        <Typography
          fontWeight="600"
          variant="h5"
          component="p"
          margin="2rem 0 1.5rem 0"
        >
          로그인 정보
        </Typography>
      ) : (
        <Box>
          <Typography
            fontWeight="500"
            variant="subtitle2"
            component="p"
            margin="1rem"
          >
            로그인 정보
          </Typography>
          <Divider />
        </Box>
      )}
      {/* 이메일 주소 */}
      <Box
        margin={
          windowSize.width >= BREAK_POINT_PC ? "0 0 1rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          component="p"
          padding="8px 0"
          fontWeight="500"
        >
          이메일 주소
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <FormControl sx={{ flex: 5, borderRadius: "0" }} variant="outlined">
            <OutlinedInput
              id="outlined-id"
              placeholder="이메일 형식에 맞게 입력"
              sx={{ borderRadius: "0" }}
              value={data.id}
              name="login.id"
              onChange={handleChangeInput}
              disabled={isEditing}
              size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
              onOpen={(object) => {
                console.log(object);
              }}
            />
            {data.id && regEmail.test(data.id) === false ? (
              <FormHelperText
                style={{
                  color: "red",
                  fontSize: `${
                    windowSize.width >= BREAK_POINT_PC ? "10pt" : "7.5pt"
                  }`,
                }}
              >
                * 올바른 이메일 형식을 입력해주세요.
              </FormHelperText>
            ) : null}
          </FormControl>

          {!isEditing && (
            <Box
              flex="1"
              height="fit-content"
              marginLeft="4px"
              padding={
                windowSize.width >= BREAK_POINT_PC ? "0.9rem 0" : "0.7rem 1rem"
              }
              sx={{
                minWidth: "max-content",
                background: `${data.id ? "black" : borderColor}`,
              }}
              onClick={handleIsDuplicated}
            >
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                textAlign="center"
                color="white"
              >
                중복 확인
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* 비밀번호 */}
      <Box
        margin={
          windowSize.width >= BREAK_POINT_PC ? "0 0 1rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          component="p"
          padding="8px 0"
        >
          {isEditing && "변경할"} 비밀번호
        </Typography>
        <FormControl
          sx={{ width: "100%", borderRadius: "0" }}
          variant="outlined"
        >
          <OutlinedInput
            id="outlined-password"
            placeholder="영문, 특수문자, 숫자 포함 6~12자 이내"
            size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
            type={showConFirmPassword ? "text" : "password"}
            sx={{ borderRadius: "0" }}
            name="login.pw"
            value={data.pw}
            onChange={handleChangeInput}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConFirmPassword}
                  onMouseDown={handleMouseDownConFirmPassword}
                  edge="end"
                >
                  {showConFirmPassword ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            style={{
              color: "red",
              fontSize: `${
                windowSize.width >= BREAK_POINT_PC ? "10pt" : "7.5pt"
              }`,
            }}
          >
            *특수문자는 @$!%*#?& 만 허용됩니다.
          </FormHelperText>
          {data.pw && regPassword.test(data.pw) === false ? (
            <FormHelperText
              style={{
                color: "red",
                fontSize: `${
                  windowSize.width >= BREAK_POINT_PC ? "10pt" : "7.5pt"
                }`,
              }}
            >
              *비밀번호 형식이 올바르지 않습니다.
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>

      {/* 비밀번호 확인 */}
      <Box
        margin={
          windowSize.width >= BREAK_POINT_PC ? "0 0 1rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          component="p"
          padding="8px 0"
        >
          {isEditing && "변경할"} 비밀번호 확인
        </Typography>
        <FormControl
          sx={{ width: "100%", borderRadius: "0" }}
          variant="outlined"
        >
          <OutlinedInput
            placeholder="영문, 특수문자, 숫자 포함 6~12자 이내"
            id="outlined-password"
            type={showPassword ? "text" : "password"}
            sx={{ borderRadius: "0" }}
            size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
            name="login.confirmPw"
            value={data.confirmPw}
            onChange={handleChangeInput}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {data.pw !== data.confirmPw ? (
            <FormHelperText
              style={{
                color: "red",
                fontSize: `${
                  windowSize.width >= BREAK_POINT_PC ? "10pt" : "7.5pt"
                }`,
              }}
            >
              * 비밀번호가 일치하지 않습니다.
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
    </Box>
  );
};

export default LoginForm;
