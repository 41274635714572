import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { borderColor } from "../../../common/style/styles";
import styled from "styled-components";
import { primary } from "../../../common/style/styles";
import { TailSpin } from "react-loader-spinner";

const StyledTypography = styled.p`
  font-size: 1rem;
  line-height: 1.75;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const ItemStatus = ({
  itemStatusLoding,
  status,
  countOfItemStatus,
  handleStatusOfParamsChange,
}) => {
  return (
    <Box display="flex" border={"1px solid " + borderColor} padding="8px">
      <Box
        display="flex"
        color={status === "total" && primary}
        alignItems="center"
      >
        <Typography variant="subtitle2" padding="0 16px">
          전체
        </Typography>
        <Box margin="0 16px">
          {itemStatusLoding ? (
            <TailSpin
              height="25"
              width="25"
              color={status === "total" ? primary : "black"}
              radius="1"
              wrapperStyle={{
                position: "relative",
                zIndex: 200,
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <StyledTypography
              onClick={() => handleStatusOfParamsChange("total")}
            >
              {countOfItemStatus.total}건
            </StyledTypography>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        display="flex"
        color={status === "for_sale" && primary}
        alignItems="center"
      >
        <Typography variant="subtitle2" padding="0px 16px">
          판매중
        </Typography>
        <Box margin="0px 16px">
          {itemStatusLoding ? (
            <TailSpin
              height="25"
              width="25"
              color={status === "for_sale" ? primary : "black"}
              radius="1"
              wrapperStyle={{
                position: "relative",
                zIndex: 200,
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <StyledTypography
              onClick={() => handleStatusOfParamsChange("for_sale")}
            >
              {countOfItemStatus.for_sale}건
            </StyledTypography>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        display="flex"
        color={status === "on_disable" && primary}
        alignItems="center"
      >
        <Typography variant="subtitle2" padding="0px 16px">
          판매 대기
        </Typography>
        <Box margin="0px 16px">
          {itemStatusLoding ? (
            <TailSpin
              height="25"
              width="25"
              color={status === "on_disable" ? primary : "black"}
              radius="1"
              wrapperStyle={{
                position: "relative",
                zIndex: 200,
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <StyledTypography
              onClick={() => handleStatusOfParamsChange("on_disable")}
            >
              {countOfItemStatus.on_disable}건
            </StyledTypography>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        display="flex"
        color={status === "on_sale" && primary}
        alignItems="center"
      >
        <Typography variant="subtitle2" padding="0 16px">
          할인중
        </Typography>
        <Box margin="0 16px">
          {itemStatusLoding ? (
            <TailSpin
              height="25"
              width="25"
              color={status === "on_sale" ? primary : "black"}
              radius="1"
              wrapperStyle={{
                position: "relative",
                zIndex: 200,
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <StyledTypography
              onClick={() => handleStatusOfParamsChange("on_sale")}
            >
              {countOfItemStatus.on_sale}건
            </StyledTypography>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        display="flex"
        color={status === "sold_out" && primary}
        alignItems="center"
      >
        <Typography variant="subtitle2" padding="0 16px">
          품절
        </Typography>
        <Box margin="0 16px">
          {itemStatusLoding ? (
            <TailSpin
              height="25"
              width="25"
              color={status === "sold_out" ? primary : "black"}
              radius="1"
              wrapperStyle={{
                position: "relative",
                zIndex: 200,
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <StyledTypography
              onClick={() => handleStatusOfParamsChange("sold_out")}
            >
              {countOfItemStatus.sold_out}건
            </StyledTypography>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        display="flex"
        color={status === "discontinued" && primary}
        alignItems="center"
      >
        <Typography variant="subtitle2" padding="0 16px">
          판매종료
        </Typography>
        <Box margin="0 16px">
          {itemStatusLoding ? (
            <TailSpin
              height="25"
              width="25"
              color={status === "discontinued" ? primary : "black"}
              radius="1"
              wrapperStyle={{
                position: "relative",
                zIndex: 200,
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <StyledTypography
              onClick={() => handleStatusOfParamsChange("discontinued")}
            >
              {countOfItemStatus.discontinued}건
            </StyledTypography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ItemStatus;
