import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  borderColor,
  info,
  lightcoral,
  grey,
} from "../../../common/style/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";

const CountryInfoForm = ({ newColumns, inputData, handleInputDataChange }) => {
  const countryList = ["스페인", "프랑스", "영국"];
  const [tempCountry, setTempCountry] = useState("");

  // tempColor 입력값 변경
  const handleChange = (event) => {
    const { name, value } = event.target;

    setTempCountry(value);
  };
  return (
    <Box>
      <ListItem style={{ backgroundColor: `${lightcoral}` }}>
        국가별 정보
      </ListItem>
      <ListItem>
        <FormControl variant="standard" sx={{ minWidth: "200px" }}>
          <InputLabel>선택</InputLabel>
          <Select
            name={"country"}
            value={tempCountry}
            onChange={handleChange}
            label="filter"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {countryList.map((country, index) => (
              <MenuItem key={index} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={() => {
            handleInputDataChange(
              { target: { name: "country", value: tempCountry } },
              "countryInfo",
              "add"
            );
          }}
        >
          국가 추가
        </Button>
      </ListItem>
      <Divider />
      {/* 국가별 정보 리스트 */}
      {inputData.country.map((country) => (
        <ListItem key={country} sx={{ flexDirection: "column" }} width="100%">
          <Box display="flex" width="100%">
            <Accordion sx={{ width: "90%" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{country}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* 국가별 기본 정보 */}
                {newColumns.countryInfo.basicInfo.map((value) => (
                  <Box width="100%" key={value.column}>
                    <Box display="flex" width="100%" margin="16px 8px">
                      <Typography component="p" minWidth="150px">
                        {value.name}
                      </Typography>
                      {value.type === "string" ? (
                        <TextField
                          variant="standard"
                          fullWidth={true}
                          name={value.column}
                          value={inputData.basicInfo[country][value.column]}
                          onChange={(event) => {
                            handleInputDataChange(
                              event,
                              "basicInfo",
                              null,
                              country
                            );
                          }}
                        />
                      ) : null}
                      {value.type === "switch" ? (
                        <Switch
                          name={value.column}
                          checked={inputData.basicInfo[country][value.column]}
                          onChange={(event) => {
                            handleInputDataChange(
                              event,
                              "basicInfo",
                              null,
                              country
                            );
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      ) : null}
                    </Box>
                    <Divider />
                  </Box>
                ))}
                {/* 국가별 가격 정보 */}
                {newColumns.countryInfo.priceInfo.map((value) => (
                  <Box key={value.column} width="100%">
                    <Box display="flex" width="100%" margin="16px 8px">
                      <Typography component="p" minWidth="150px">
                        {value.name}
                      </Typography>
                      {value.type === "string" || value.type === "number" ? (
                        <TextField
                          variant="standard"
                          fullWidth={true}
                          type={value.type}
                          name={value.column}
                          value={inputData.priceInfo[country][value.column]}
                          onChange={(event) => {
                            handleInputDataChange(
                              event,
                              "priceInfo",
                              null,
                              country
                            );
                          }}
                        />
                      ) : null}
                      {value.type === "select" && (
                        <FormControl variant="standard" fullWidth={true}>
                          <InputLabel>선택</InputLabel>
                          <Select
                            name={value.column}
                            value={inputData.priceInfo[country][value.column]}
                            label="filter"
                            onChange={(event) => {
                              handleInputDataChange(
                                event,
                                "priceInfo",
                                null,
                                country
                              );
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {value.option.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Box>
                    <Divider />
                  </Box>
                ))}
                {/* 국가별 사이즈 정보 */}
                <Box>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "unset",
                      margin: "8px",
                      width: "150px",
                    }}
                    onClick={() => {
                      handleInputDataChange(
                        { target: { name: "", value: "" } },
                        "sizeInfo",
                        "add",
                        country
                      );
                    }}
                  >
                    사이즈 추가
                  </Button>
                </Box>
                <Box
                  width="100%"
                  border={`1px solid ${borderColor}`}
                  padding="8px 32px"
                  display="flex"
                  overflow="scroll"
                >
                  {/* 사이즈 리스트 */}
                  {inputData.sizeInfo[country].map((data) => (
                    <Box
                      key={data.id}
                      width="30%"
                      padding="8px 16px"
                      position="relative"
                      margin="0 8px"
                      border="1px black dashed"
                    >
                      {newColumns.countryInfo.sizeInfo.map((value) => (
                        <Box key={value.column}>
                          <Box
                            display="flex"
                            margin="16px 8px"
                            alignItems="center"
                          >
                            <Typography component="p" minWidth="100px">
                              {value.name}
                            </Typography>
                            {value.type === "select" && (
                              <FormControl variant="standard" fullWidth={true}>
                                <InputLabel>선택</InputLabel>
                                <Select
                                  name={value.column}
                                  value={data[value.column]}
                                  label="filter"
                                  onChange={(event) => {
                                    handleInputDataChange(
                                      event,
                                      "sizeInfo",
                                      null,
                                      country,
                                      data.id
                                    );
                                  }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {inputData.commonInfo.color.map(
                                    (option, index) => (
                                      <MenuItem key={index} value={option}>
                                        {option}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                            {value.type === "switch" ? (
                              <Switch
                                name={value.column}
                                checked={data[value.column]}
                                onChange={(event) => {
                                  handleInputDataChange(
                                    event,
                                    "sizeInfo",
                                    null,
                                    country,
                                    data.id
                                  );
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            ) : null}
                            {(value.type === "string" ||
                              value.type === "number") && (
                              <TextField
                                variant="standard"
                                fullWidth={true}
                                type={value.type}
                                name={value.column}
                                value={
                                  data[value.column] ? data[value.column] : null
                                }
                                onChange={(event) => {
                                  handleInputDataChange(
                                    event,
                                    "sizeInfo",
                                    null,
                                    country,
                                    data.id
                                  );
                                }}
                              />
                            )}
                          </Box>
                          <Divider />
                        </Box>
                      ))}
                      <ClearIcon
                        fontSize="small"
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: 10,
                          top: 10,
                        }}
                        onClick={() => {
                          handleInputDataChange(
                            { target: { name: "id", value: data.id } },
                            "sizeInfo",
                            "delete",
                            country
                          );
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Button
              variant="outlined"
              style={{
                borderRadius: "unset",
                margin: "0 8px",
                height: "50px",
                width: "100px",
              }}
              onClick={() => {
                handleInputDataChange(
                  { target: { name: "country", value: country } },
                  "countryInfo",
                  "delete"
                );
              }}
            >
              국가 삭제
            </Button>
          </Box>
          <Divider />
        </ListItem>
      ))}
    </Box>
  );
};

export default CountryInfoForm;
