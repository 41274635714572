import React from "react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {
  borderColor,
  lightgrey,
  lightcoral,
  StyledLink,
} from "../../../common/style/styles";
import { useLocation } from "react-router-dom";

const MainMenuDataList = ({ columns, itemInfo, handleDeleteData }) => {
  const { pathname } = useLocation();

  return (
    <Box margin="16px">
      <Box margin="8px 0">
        <StyledLink to={pathname.replace("show", "edit")}>
          <Button variant="outlined" style={{ borderRadius: "unset" }}>
            데이터 수정
          </Button>
        </StyledLink>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleDeleteData}
        >
          데이터 삭제
        </Button>
      </Box>
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          데이터 상세보기
        </ListItem>
        <Divider />
        {columns.map((value) => (
          <ListItem
            key={value.field}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Box display="flex" width="100%">
                <Typography component="p" flex="1">
                  {value.name}
                </Typography>
                {value.field === "image" ? (
                  itemInfo[value.field] === "NULL" ? (
                    <Typography flex="5">없음</Typography>
                  ) : (
                    <img src={itemInfo[value.field]} style={{ flex: "5" }} />
                  )
                ) : (
                  <Typography component="p" flex="5">
                    {itemInfo[value.field]}
                  </Typography>
                )}
              </Box>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MainMenuDataList;
