import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CenterModalStyle } from "../../../common/style/styles";

const reasonList = [
  "상품 품절",
  "배송 지연",
  "서비스 및 상품 불만족",
  "구매 의사 취소",
  "색상 및 사이즈 변경",
  "다른 상품 잘못 주문",
  "상품정보 상이",
];

// 판매취소 모달
const CancelModal = ({
  StateName,
  isOpened,
  handleModalChange,
  handleCancelSale,
}) => {
  const [selectedReason, setSelectedReason] = useState("선택");
  const [message, setMessage] = useState("");
  const [cancelFee, setCancelFee] = useState(0);
  // 중복 클릭 방지
  const [hasClick, setHasClick] = useState(false);

  const handleChangeSelect = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleChangeCancelFee = (event) => {
    if (parseInt(event.target.value) < 0) {
      return;
    }
    setCancelFee(event.target.value);
  };

  const handleSubmit = () => {
    if (hasClick) {
      return;
    }
    setHasClick(true);
    // 판매취소 사유 선택했는지 확인
    if (selectedReason === "선택") {
      alert(`${StateName} 사유를 선택해주세요.`);
      setHasClick(false);
      return;
    }
    handleModalClose();
    handleCancelSale(selectedReason, message, cancelFee);
  };

  const handleModalClose = () => {
    setHasClick(false);
    setSelectedReason("선택");
    setMessage("");
    setCancelFee(0);
    handleModalChange();
  };

  return (
    <Modal open={isOpened} onClose={handleModalClose}>
      <Box sx={CenterModalStyle} width="70%">
        <Box margin="16px">
          <Typography variant="h6">{StateName}</Typography>
          <Divider />
          <Box margin="8px 0 16px 0">
            <Typography>{StateName} 사유</Typography>
            <FormControl variant="standard" fullWidth={true}>
              <Select
                label="curation"
                value={selectedReason}
                onChange={handleChangeSelect}
                placeholder={`${StateName} 사유 선택`}
              >
                <MenuItem value="선택">선택</MenuItem>
                {reasonList.map((reason) => (
                  <MenuItem value={reason}>{reason}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box margin="8px 0 16px 0">
            <Typography>구매자에게 전달할 메세지</Typography>
            <TextField
              multiline
              rows={4}
              placeholder="메세지를 입력해주세요."
              fullWidth={true}
              value={message}
              onChange={handleChangeMessage}
            />
          </Box>
          <Box margin="8px 0 16px 0">
            <Typography>취소 수수료</Typography>
            <TextField
              placeholder="취소 수수료를 입력해주세요."
              fullWidth={true}
              value={cancelFee}
              onChange={handleChangeCancelFee}
            />
          </Box>
          <Box>
            <Button
              variant="outlined"
              sx={{ margin: "0 8px 0 0" }}
              onClick={handleSubmit}
            >
              {StateName} 처리
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleModalClose}
            >
              닫기
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CancelModal;
