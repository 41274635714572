import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { addDashToPhoneNumber } from "../js/common";
import { windowSizeState } from "../state/state";
import {
  primary,
  borderColor,
  grey4,
  grey3,
  BREAK_POINT_PC,
} from "../style/styles";

// 배송지 정보
const Address = ({ address, handleChangeAddress, handleClickEdit }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  return (
    <Box border={"1px solid " + borderColor} padding="0.8rem 1rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          fontWeight="500"
          padding="0.3rem 1rem"
          sx={{ background: grey4, borderRadius: "20px" }}
        >
          {address.title}
        </Typography>

        {address.is_default && (
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
            fontWeight="500"
            padding="0.3rem 0.4rem"
            color="primary"
            sx={{ border: `1px solid ${primary}`, borderRadius: "4px" }}
            marginLeft="0.2rem"
          >
            기본 배송지
          </Typography>
        )}
      </Box>

      <Box marginTop="1rem" display="flex">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          color={grey3}
          flex="1"
        >
          받으실 분
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          fontWeight="500"
          flex="4"
        >
          {address.name}
        </Typography>
      </Box>
      <Box marginTop="0.3rem" display="flex">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          color={grey3}
          flex="1"
        >
          주소
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          fontWeight="500"
          flex="4"
        >
          {address.main_address} {address.detail_address} ({address.zip_code})
        </Typography>
      </Box>
      <Box marginTop="0.3rem" display="flex">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          color={grey3}
          flex="1"
        >
          전화번호
        </Typography>
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          fontWeight="500"
          flex="4"
        >
          {addDashToPhoneNumber(address.cellphone)}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" marginTop="1rem">
        {handleClickEdit ? (
          <Button
            variant="outlined"
            sx={{
              borderRadius: "0",
              borderColor: "black",
              color: "black",
              marginRight:
                windowSize.width >= BREAK_POINT_PC ? "1rem" : "0.5rem",
            }}
            onClick={() => {
              handleClickEdit(address.id);
            }}
          >
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              fontWeight="500"
            >
              수정
            </Typography>
          </Button>
        ) : null}
        {handleChangeAddress ? (
          <Button
            variant="contained"
            sx={{ borderRadius: "0", background: "black", color: "white" }}
            onClick={() => {
              handleChangeAddress(address.id);
            }}
          >
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              fontWeight="500"
            >
              선택
            </Typography>
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default Address;
