import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { primary, CenterModalStyle } from "../../../common/style/styles";
import { putDataFromServer } from "../../../common/network/network";

// 상품 상태 변경 모달
const StatusModal = ({
  selectedIDs,
  isOpened,
  handleModalChange,
  handleChangeItemInfo,
}) => {
  const [selectedStaus, setSelectedStaus] = useState("");
  const [statusList, setStatusList] = useState([
    { name: "판매 대기", value: "is_show=false" },
    { name: "판매중", value: "is_show=true" },
    { name: "판매종료", value: "is_discontinued=true" },
  ]);

  const handleStausChange = (event) => {
    setSelectedStaus(event.target.value);
  };

  const handleButtonClick = () => {
    const newData = selectedIDs.filter((value) => value !== -1);
    handleChangeItemInfo(newData.join(","), selectedStaus);
    setSelectedStaus("");
    handleModalChange("status");
  };

  return (
    <Modal
      open={isOpened}
      onClose={() => {
        handleModalChange("status");
      }}
    >
      <Box sx={CenterModalStyle} width="50%">
        <Box padding="16px">
          <Typography variant="h6">상품 상태 변경</Typography>
          <Divider />
          <Typography>
            * 한 컬러의 상품 상태를 변경해도 전체 컬러에 모두 적용됩니다.
          </Typography>
          <FormControl
            variant="standard"
            sx={{ width: "100%", margin: "8px 0" }}
          >
            <InputLabel>상품 상태 목록</InputLabel>
            <Select
              label="curation"
              value={selectedStaus}
              onChange={handleStausChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {statusList.map((status) => (
                <MenuItem value={status.value}>{status.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          padding="16px"
          sx={{
            backgroundColor: `${primary}`,
            textAlign: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleButtonClick}
        >
          선택한 아이템의 상태 변경하기
        </Box>
      </Box>
    </Modal>
  );
};

export default StatusModal;
