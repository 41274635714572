import {
  Box,
  Divider,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC } from "../../../common/style/styles";

const MenuList = ({ categoryList, categoryID, handleChangeCategory }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <Box>
      <Divider />
      <Tabs
        value={categoryID}
        onChange={handleChangeCategory}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        textColor="black"
        indicatorColor="secondary"
      >
        {categoryList.map((category) => {
          return (
            <Tab
              key={category.id}
              value={category.id}
              label={category.name}
              style={{
                fontSize: windowsize.width >= BREAK_POINT_PC ? "24px" : "14px",
                padding:
                  windowsize.width >= BREAK_POINT_PC ? "1.5rem" : "0.7rem",
                minWidth: "fit-content",
              }}
            />
          );
        })}
      </Tabs>
      {/* <Box display="flex" flexWrap="wrap" margin="1rem 2rem">
        {categoryList.map((category) => (
          <Typography width="50%" margin="0.4rem 0" sx={{ cursor: "pointer" }}>
            {category.name}
          </Typography>
        ))}
      </Box> */}
      <Divider />
    </Box>
  );
};

export default MenuList;
