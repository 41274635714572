import { Button, Modal, Typography, Box } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../state/state";
import { BREAK_POINT_PC, CenterModalStyle } from "../style/styles";
import ReactHtmlParser from "react-html-parser";

// 알림 모달
const AlertModal = ({
  title,
  content,
  isOpened,
  handleAlertModal,
  handleSubmit,
}) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <Modal open={isOpened}>
      <Box
        sx={CenterModalStyle}
        width={windowsize.width >= BREAK_POINT_PC ? "600px" : "300px"}
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="1rem"
      >
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "body1"}
          fontWeight="700"
          margin="1rem 0"
        >
          {title}
        </Typography>
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
        >
          {ReactHtmlParser(content)}
        </Typography>
        <Box display="flex" width="100%">
          <Button
            variant="outlined"
            sx={{
              flex: "1",
              margin: "1.5rem 0.5rem 0.5rem 0",
              color: "black",
              borderColor: "black",
            }}
            onClick={handleAlertModal}
          >
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2}"
              }
              padding={windowsize.width >= BREAK_POINT_PC ? "0.5rem 0" : "0"}
            >
              취소
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              flex: "1",
              margin: "1.5rem 0 0.5rem 0.5rem",
              background: "black",
            }}
            onClick={handleSubmit}
          >
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2}"
              }
              padding={windowsize.width >= BREAK_POINT_PC ? "0.5rem 0" : "0"}
            >
              확인
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AlertModal;
