import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  borderColor,
  bottomModalStyle,
  BREAK_POINT_PC,
  Column,
  primary,
} from "../style/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { inputNumberWithComma, roundNumber } from "../js/common";
import { getDataFromServer, postDataFromServer } from "../network/network";
import { useRecoilState } from "recoil";
import { cartItemNumState, windowSizeState } from "../state/state";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import AlertModal from "./alert_modal";
import LoginModal from "./login_modal";

const BuyModal = ({ isOpened, handleClickModal, sizeList, itemInfo }) => {
  const navigate = useNavigate();
  const [windowsize, setWindowSize] = useRecoilState(windowSizeState);
  // 사이즈 드롭다운 open 여부
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 선택된 사이즈 정보 저장
  const [selectedSize, setSelectedSize] = useState([]);
  // 헤더의 장바구니 아이템 갯수
  const [cartItemNum, setCartItemNum] = useRecoilState(cartItemNumState);
  const [isCartAlertModalOpen, SetIsCartAlertModalOpen] = useState(false);
  const [loginIsOpened, setLoginIsOpened] = useState(false);

  const handleLoginModal = () => {
    setLoginIsOpened((prev) => !prev);
  };

  // 장바구니 추가 모달에서 확인을 누른경우
  const handleAlertSubmit = () => {
    navigate("/carts");
  };

  // 장바구니 추가 모달 열고 닫는 함수
  const handleCartAlertModal = () => {
    if (isCartAlertModalOpen) {
      // 지금 구매하기 모달 닫기
      handleClickModal();
      // 선택된 사이즈 초기화
      setSelectedSize([]);
    }

    SetIsCartAlertModalOpen((prev) => !prev);
  };

  // 사이즈 드롭다운 열고 닫는 함수
  const handleClickDropdown = () => {
    setIsDropDownOpen((prev) => !prev);
  };

  // console.log(itemInfo, sizeList);
  // 아이템 사이즈 선택, 삭제, 수량 증가, 수량 감소 관련 함수
  const handleClickSize = (state, value) => {
    // 이미 선택된 사이즈인지 확인
    const isSelected = selectedSize.filter(
      (size) => size.item_option_id == value.item_option_id
    );

    if (state === "add" && isSelected.length !== 0) {
      alert("이미 선택한 옵션입니다.");
      return;
    }

    // 사이즈 추가
    if (state === "add") {
      setSelectedSize([...selectedSize, value]);
      // 사이즈 드롭다운 닫기
      handleClickDropdown();
      // 사이즈 삭제
    } else if (state === "delete") {
      const newSelectedSize = selectedSize.filter(
        (size) => size.item_option_id !== value.item_option_id
      );
      setSelectedSize([...newSelectedSize]);
      // 사이즈 수량 증가
    } else if (state === "increase") {
      const newSelectedSize = selectedSize.map((size) => {
        if (size.item_option_id == value.item_option_id) {
          size.quantity += 1;
        }
        return size;
      });

      setSelectedSize([...newSelectedSize]);
      // 사이즈 수량 감소
    } else if (state === "decrease") {
      const newSelectedSize = selectedSize.map((size) => {
        if (size.item_option_id == value.item_option_id) {
          size.quantity -= 1;
        }
        return size;
      });

      setSelectedSize([...newSelectedSize]);
    }
  };

  // 아이템 장바구니 담기 클릭
  const handleClickAddCarts = () => {
    // 선택된 사이즈가 없으면 실행하지 않기
    if (!selectedSize.length) {
      alert("사이즈를 선택해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);

    const nonMemberToken = localStorage.getItem("nonMemberToken");

    const cartItemList = selectedSize.map((value) => {
      return {
        item_id: itemInfo.item_id,
        detail_id: value.item_detail_id,
        option_id: value.item_option_id,
        quantity: value.quantity,
      };
    });

    postDataFromServer("/service/carts", {
      token: nonMemberToken === "undefined" ? null : nonMemberToken,
      item_list: cartItemList,
    }).then((response) => {
      // 비회원 토큰 정보가 없을 경우 토큰정보 로컬에 저장
      if (response["token"] !== "null") {
        localStorage.setItem("nonMemberToken", response["token"]);
      }
      setCartItemNum((prev) => (prev += 1));

      // 장바구니 추가 알림 모달 열기
      handleCartAlertModal();
    });

    setLoading(false);
    setProcessing(false);
  };

  //로그인 후 구매 클릭
  const handleLogin = () => {
    //  현재 선택한 상품 정보 로컬에 저장
    const newIteminfo = {
      sizeList: sizeList,
      itemInfo: itemInfo,
      selectedSize: selectedSize,
    };
    localStorage.setItem("nonmemberOrderItem", JSON.stringify(newIteminfo));
    navigate("/login");
  };

  // 바로 구매 버튼 클릭
  const handleClickBuyNow = async () => {
    // 선택한 사이즈가 없으면 return
    if (!selectedSize.length) {
      alert("사이즈를 선택해주세요.");
      return;
    }

    const newItemInfo = selectedSize.map((value) => {
      return {
        brand_name: itemInfo.brand_name,
        brand_id: itemInfo.brand_id,
        category_id: itemInfo.category_id,
        color: itemInfo.color,
        size: value.size,
        country: value.country,
        id: null,
        images:
          typeof itemInfo.images === "string"
            ? itemInfo.images
            : itemInfo.images[0],
        is_selected: true,
        is_show: true,
        is_stock: true,
        item_detail_id: value.item_detail_id,
        item_id: itemInfo.item_id,
        item_name: itemInfo.item_name,
        item_option_id: value.item_option_id,
        item_show_option_id: itemInfo.item_option_id,
        sale_price: parseInt(itemInfo.sale_price) + value.option_price,
        item_price: parseInt(itemInfo.item_price) + value.option_price,
        option_name: "사이즈",
        quantity: value.quantity,
      };
    });

    // 구매 목록 로컬에 저장
    localStorage.setItem("orderItemListData", JSON.stringify(newItemInfo));

    // 로그인 했는지 확인
    getDataFromServer("/service/users/name")
      .then((response) => {
        // 구매정보 입력 페이지로 이동
        navigate("/orderSheet");
      })
      .catch((error) => {
        // 로그인, 비로그인 선택
        handleLoginModal();
      });
  };

  return (
    <Modal
      open={isOpened}
      onClose={handleClickModal}
      sx={{ pointerEvents: processing ? "none" : "auto" }}
    >
      <Box sx={bottomModalStyle}>
        <Column xs={12} sm={12} md={12} lg={9}>
          <List
            disablePadding
            sx={{
              height: "360px",
              overflowY: "scroll",
              margin: "1.5rem",
            }}
          >
            <Box border={"1px solid " + borderColor} borderRadius="4px">
              {/*  사이즈 버튼 */}
              <ListItem sx={{ margin: 0, padding: 0 }}>
                <Box width="100%">
                  <Button
                    fullWidth
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "black",
                      padding: "0.8rem 1rem",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    endIcon={
                      isDropDownOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                    onClick={handleClickDropdown}
                  >
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle1"
                          : "body1"
                      }
                      fontWeight="700"
                    >
                      사이즈
                    </Typography>
                  </Button>
                </Box>
              </ListItem>
              {isDropDownOpen && <Divider />}
              {/* 사이즈 드롭다운 */}
              {isDropDownOpen &&
                (sizeList.length !== 0 ? (
                  sizeList.map(
                    (value) =>
                      value.is_show && (
                        <ListItem
                          key={value.id}
                          sx={{
                            backgroundColor: "white",
                            cursor: "pointer",
                            margin: 0,
                            padding: 0,
                          }}
                          onClick={() => {
                            value.is_stock &&
                              handleClickSize("add", {
                                item_detail_id: value.item_detail_id,
                                item_option_id: value.item_option_id,
                                country: value.country,
                                option_price: value.option_price,
                                size: value.size,
                                quantity: 1,
                              });
                          }}
                        >
                          <Box width="100%">
                            <Typography
                              key={value.id}
                              variant={
                                windowsize.width >= BREAK_POINT_PC
                                  ? "subtitle1"
                                  : "body1"
                              }
                              sx={{
                                textDecoration: `${
                                  value.is_stock ? "none" : "line-through"
                                }`,
                                color: `${
                                  value.is_stock ? "black" : "#aeaeae"
                                }`,
                              }}
                              padding="0.8rem 1rem"
                            >
                              {value.size}{" "}
                              {value.option_price !== 0 &&
                                `(+${inputNumberWithComma(
                                  value.option_price
                                )}원)`}
                            </Typography>
                            <Divider />
                          </Box>
                        </ListItem>
                      )
                  )
                ) : (
                  <ListItem
                    sx={{ backgroundColor: "white", cursor: "pointer" }}
                    onClick={() => {
                      itemInfo.is_stock &&
                        handleClickSize("add", {
                          item_detail_id: itemInfo.item_detail_id,
                          item_option_id: itemInfo.item_option_id,
                          country: itemInfo.country,
                          option_price: 0,
                          size: "One Size",
                          quantity: 1,
                        });
                    }}
                  >
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle1"
                          : "body1"
                      }
                      component="p"
                      sx={{
                        textDecoration: `${
                          itemInfo.is_stock ? "none" : "line-through"
                        }`,
                        color: `${itemInfo.is_stock ? "black" : "#aeaeae"}`,
                      }}
                    >
                      One Size
                    </Typography>
                  </ListItem>
                ))}
            </Box>
            {/* 선택된 사이즈 창 */}
            {selectedSize.length !== 0 &&
              selectedSize.map((value) => (
                <ListItem
                  disablePadding
                  key={value.id}
                  sx={{
                    marginTop: "0.8rem",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    width="100%"
                    padding="8px 16px"
                    border="1px solid black"
                    borderRadius="4px"
                  >
                    {/* 선택 정보 */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                      marginBottom="1rem"
                    >
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "subtitle1"
                            : "body1"
                        }
                        component="p"
                        fontWeight="700"
                      >
                        {itemInfo.color} / {value.size}
                      </Typography>
                      <CloseIcon
                        sx={{ fontSize: "1.2rem" }}
                        onClick={() => {
                          handleClickSize("delete", {
                            item_option_id: value.item_option_id,
                          });
                        }}
                      />
                    </Box>
                    {/* 수량 수정 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",

                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{ padding: "4px" }}
                          onClick={() => {
                            value.quantity > 1 &&
                              handleClickSize("decrease", {
                                item_option_id: value.item_option_id,
                              });
                          }}
                        >
                          <RemoveIcon
                            sx={{
                              fontSize:
                                windowsize.width >= BREAK_POINT_PC
                                  ? "1.5rem"
                                  : "1.2rem",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            padding: "5px 16px",
                          }}
                          border={"1px solid " + borderColor}
                          borderRadius="4px"
                        >
                          <Typography
                            variant={
                              windowsize.width >= BREAK_POINT_PC
                                ? "subtitle1"
                                : "body1"
                            }
                            component="p"
                            fontWeight="700"
                          >
                            {value.quantity}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ padding: "4px" }}
                          onClick={() => {
                            handleClickSize("increase", {
                              item_option_id: value.item_option_id,
                            });
                          }}
                        >
                          <AddIcon
                            sx={{
                              fontSize:
                                windowsize.width >= BREAK_POINT_PC
                                  ? "1.5rem"
                                  : "1.2rem",
                            }}
                          />
                        </Box>
                      </Box>
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "subtitle1"
                            : "body1"
                        }
                        component="p"
                        fontWeight="700"
                      >
                        {inputNumberWithComma(
                          (roundNumber(itemInfo.sale_price) +
                            value.option_price) *
                            value.quantity
                        )}
                        원
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
          </List>
          {/* 장바구니, 구매 버튼 */}
          <Box display="flex">
            <Button
              variant="outlined"
              sx={{
                flex: 1,
                borderRadius: "40px",
                margin: "0.5rem 0.1rem 0.5rem 1rem",
                borderColor: "black",
                color: "black",
              }}
              onClick={handleClickAddCarts}
            >
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                }
                fontWeight="500"
                padding="0.3rem"
              >
                장바구니
              </Typography>
            </Button>
            <Button
              variant="containd"
              sx={{
                flex: 1,
                background: "black",
                borderRadius: "40px",
                color: "white",
                margin: "0.5rem 1rem 0.5rem 0.1rem",
              }}
              onClick={handleClickBuyNow}
            >
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                }
                fontWeight="500"
                padding="0.3rem"
              >
                구매하기
              </Typography>
            </Button>
          </Box>
        </Column>
        <AlertModal
          title="장바구니"
          content="선택한 상품이 장바구니에 담겼습니다. <br/>장바구니로 이동하시겠습니까?"
          isOpened={isCartAlertModalOpen}
          handleAlertModal={handleCartAlertModal}
          handleSubmit={handleAlertSubmit}
        />
        <LoginModal
          isOpened={loginIsOpened}
          handleLogin={handleLogin}
          handleNotLogin={() => navigate("/orderSheet")}
        />
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Modal>
  );
};

export default BuyModal;
