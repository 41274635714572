import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { CenterModalStyle } from "../../../common/style/styles";

// 결제취소&반품&교환 거부 모달
const RefuseModal = ({
  name,
  status,
  isOpened,
  handleModalChange,
  handleCancelAndReturn,
  handleExchangeItem,
}) => {
  const [message, setMessage] = useState("");
  // 중복 클릭 방지
  const [hasClick, setHasClick] = useState(false);

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    if (hasClick) {
      return;
    }
    setHasClick(true);

    //교환
    if (status === 10) {
      handleModalChange("refuse");
      handleExchangeItem(false, message);
    }
    //취소&반품
    else {
      handleModalChange("refuse");
      handleCancelAndReturn(false, message, 0);
    }
  };

  const handleModalClose = () => {
    setHasClick(false);
    setMessage("");
    handleModalChange("refuse");
  };

  return (
    <Modal open={isOpened} onClose={handleModalClose}>
      <Box sx={CenterModalStyle} width="50%">
        <Box margin="16px">
          <Typography margin="8px 0">{name} 거부 사유 기록 (선택)</Typography>
          <TextField
            multiline
            rows={4}
            placeholder={`${name} 거부 사유를 입력해주세요.`}
            fullWidth={true}
            value={message}
            onChange={handleChangeMessage}
          />
          <Box margin="8px 0">
            <Button
              variant="outlined"
              sx={{ margin: "0 8px 0 0" }}
              onClick={handleSubmit}
            >
              {name} 거부 처리
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleModalClose}
            >
              닫기
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RefuseModal;
