import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { useRecoilState } from "recoil";
import MenuTab from "../../../common/components/menu_tab";
import { findHangulIndex } from "../../../common/js/common";
import { getDataFromServer } from "../../../common/network/network";
import { windowSizeState } from "../../../common/state/state";
import {
  BREAK_POINT_PC,
  Column,
  grey4,
  StyledLink,
} from "../../../common/style/styles";

const indexList = [
  "ㄱ",
  "ㄲ",
  "ㄴ",
  "ㄷ",
  "ㄸ",
  "ㄹ",
  "ㅁ",
  "ㅂ",
  "ㅃ",
  "ㅅ",
  "ㅆ",
  "ㅇ",
  "ㅈ",
  "ㅉ",
  "ㅊ",
  "ㅋ",
  "ㅌ",
  "ㅍ",
  "ㅎ",
];

export const Index = () => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [menu, setMenu] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [likeBrandList, setLikeBrandList] = useState([]);
  // 한글 인덱스 리스트중 브랜드 모음이 존재하는 인덱스 리스트
  const [existedIndexList, setExistedIndexList] = useState([]);

  // 탭 변경
  const handleChange = (event, newValue) => {
    setSelectedMenu(newValue);
  };

  useEffect(() => {
    // 브랜드 메뉴 리스트 데이터 가져오기
    getDataFromServer("/service/menu?name=brands").then((response) => {
      setMenu([...response.menu]);
      setSelectedMenu(response.menu[0].id);
    });

    const nonMemberToken = localStorage.getItem("nonMemberToken");

    // 브랜드 리스트 데이터 가져오기
    getDataFromServer(
      `/service/brands?token=${
        nonMemberToken == "undefined" ? null : nonMemberToken
      }`
    ).then((response) => {
      setBrandList(response.brand_list);
      setLikeBrandList(response.like_items);

      // 한글 인덱스 중 브랜드 모음이 없는 인덱스 제외하기

      for (const brand of response.brand_list) {
        setExistedIndexList((prev) => {
          return [
            ...new Set([
              ...prev,
              indexList[findHangulIndex(brand.name.charAt(0))],
            ]),
          ];
        });
      }
    });
  }, []);

  const handleClickIndex = (hangul) => {
    const indexBrand = document.getElementById(hangul);

    indexBrand.scrollIntoView({
      behavior: "auto",
      block: "start",
    });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {brandList.length ? (
        <Box display="flex" overflow="hidden">
          {/* 브랜드 목록 */}
          <Box flex="9" maxWidth="90%">
            <Box height="100%">
              <Box overflow="hidden">
                <MenuTab
                  data={menu}
                  menu={selectedMenu}
                  handleChange={handleChange}
                  style={{
                    pc: { fontSize: "18pt" },
                    mobile: { fontSize: "10.5pt" },
                  }}
                />
              </Box>

              <Box
                overflow="auto"
                maxHeight={
                  windowsize.width < BREAK_POINT_PC
                    ? windowsize.height * 0.74
                    : windowsize.height * 9
                }
              >
                {existedIndexList.map((hangul) => (
                  <Box kex={hangul}>
                    <Typography
                      id={hangul}
                      variant={
                        windowsize.width >= BREAK_POINT_PC ? "h5" : "subtitle1"
                      }
                      padding="1rem 0 0.3rem 1rem"
                      fontWeight="600"
                      sx={{ background: grey4 }}
                    >
                      {hangul}
                    </Typography>
                    {brandList.map((brand) =>
                      (brand.category_id == selectedMenu ||
                        selectedMenu == -1) &&
                      hangul ===
                        indexList[findHangulIndex(brand.name.charAt(0))] ? (
                        <StyledLink to={"/brands/" + brand.id}>
                          <Typography
                            variant={
                              windowsize.width >= BREAK_POINT_PC
                                ? "h6"
                                : "subtitle2"
                            }
                            fontWeight="700"
                            margin="1rem"
                          >
                            {brand.name}
                          </Typography>
                        </StyledLink>
                      ) : null
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          {/* 브랜드 목록 가나다 인덱스 */}
          <Box
            flex="1"
            maxHeight={windowsize.height * 0.9}
            sx={{ overflow: "auto" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
            >
              {existedIndexList.map((hangul) => (
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                  padding="0.5rem"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClickIndex(hangul);
                  }}
                >
                  {hangul}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      ) : null}
    </Column>
  );
};
