import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  borderColor,
  info,
  lightcoral,
  lightgrey,
  primary,
  StyledLink,
} from "../../../common/style/styles";
import { useLocation, useNavigate } from "react-router-dom";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import { inputNumberWithComma } from "../../../common/js/common";
import { Checkbox } from "@material-ui/core";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import MenuTab from "../../../common/components/menu_tab";
import { getDataFromServer } from "../../../common/network/network";

const CategoryEditData = ({
  id,
  columns,
  itemInfo,
  items,
  selectedIDs,
  handleClickCheckBox,
  handleDeleteCurationItems,
  handleModalChange,
  handleChangeData,
  handleSaveData,
  selectedMenu,
  handleChangeMenu,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [infoColumns, setInfoColumns] = useState(columns);

  // 카테고리 생성인 경우 소카테고리, 대카테고리 선택 메뉴 만들기
  const [categoryList, setCategoryList] = useState([]);
  const [menuList, setMenuList] = useState([
    { id: 1, name: "대카테고리" },
    { id: 2, name: "소카테고리" },
  ]);

  // 입력/보기 정보 변경됐을 때 리렌더링
  useEffect(() => {
    let newColumns = columns;
    if (
      columns.find((value) => value.field === "one") &&
      pathname.includes("category/create")
    ) {
      newColumns = columns.map((value) => {
        if (value.field === "zero_id") {
          return { ...value, option: categoryList };
        }
        return value;
      });
    }
    setInfoColumns([...newColumns]);
  }, [columns, itemInfo]);

  useEffect(() => {
    getDataFromServer("/service/categories").then((response) => {
      setCategoryList([...response.category_list.zero]);
    });
  }, []);

  return (
    <Box margin="16px">
      <Box margin="8px 0" display="flex">
        <Button
          variant="outlined"
          style={{
            borderRadius: "unset",
            margin: "0 8px",
            border: `1px solid ${primary}`,
            padding: "4px",
            color: primary,
          }}
          onClick={handleSaveData}
        >
          카테고리 정보 저장
        </Button>
      </Box>
      {pathname.includes("category/create") && (
        <MenuTab
          data={menuList}
          menu={selectedMenu}
          handleChange={handleChangeMenu}
          style={{
            pc: { fontSize: "14pt" },
            mobile: { fontSize: "14pt" },
          }}
        />
      )}
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          데이터 {pathname.includes("create") ? "생성" : "수정"}
        </ListItem>
        <Divider />
        {infoColumns.map((value) => (
          <ListItem
            key={value.field}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Typography component="p" flex="1">
                {value.name}
              </Typography>
              {value.type === "select" ? (
                <FormControl
                  variant="standard"
                  sx={{ flex: "5", margin: "8px 0" }}
                >
                  <InputLabel>선택</InputLabel>
                  <Select
                    name={value.field}
                    value={
                      itemInfo[value.field] !== "" ? itemInfo[value.field] : ""
                    }
                    onChange={handleChangeData}
                    label="filter"
                  >
                    {value.option.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.id ? option.id : option.value}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          padding: "8px",
                        }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : value.type === "string" || value.type === "number" ? (
                <TextField
                  sx={{ flex: "5", margin: "8px 0" }}
                  size="small"
                  name={value.field}
                  value={itemInfo[value.field] ? itemInfo[value.field] : ""}
                  onChange={handleChangeData}
                  type={value.type}
                  step="0.01"
                />
              ) : (
                <Typography component="p" flex="5">
                  {itemInfo[value.field]}
                </Typography>
              )}
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CategoryEditData;
