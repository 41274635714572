import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDataFromServer } from "../../../common/network/network";
import {
  BREAK_POINT_PC,
  Column,
  primary,
  StyledLink,
} from "../../../common/style/styles";
import MenuTab from "../../../common/components/menu_tab";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import QnA from "../../../common/components/qna";

// 문의하기 리스트 페이지
const Inquiry = () => {
  const navigate = useNavigate();
  const [windowsize, setWindoesize] = useRecoilState(windowSizeState);
  const [data, setData] = useState([]);
  const [isLogin, setIsLogion] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState(0);
  const [QnAList, setQnAList] = useState([]);
  const menu = [
    { id: 0, name: "상품문의" },
    { id: 1, name: "일반문의" },
  ];

  useEffect(() => {
    getDataFromServer("/service/qna")
      .then((response) => {
        setData([...response.qna_list]);
        setIsLogion(true);
        if (selectedMenu === 0) {
          const newQnAList = response.qna_list.filter(
            (qna) => qna.qna_type === 0
          );
          setQnAList([...newQnAList]);
        } else {
          const newQnAList = response.qna_list.filter(
            (qna) => qna.qna_type === 1
          );
          setQnAList([...newQnAList]);
        }
      })
      .catch((error) => {
        // 회원정보 없을 시 로그인 페이지로 이동
        alert("로그인 후 이용해주세요.");
        navigate("/login");
      });
  }, []);

  // 메뉴 탭 변경
  const hadleChangeSelectedMenu = (event, newValue) => {
    setSelectedMenu(newValue);
  };

  useEffect(() => {
    if (selectedMenu === 0) {
      const newQnAList = data.filter((qna) => qna.qna_type === 0);
      setQnAList([...newQnAList]);
    } else {
      const newQnAList = data.filter((qna) => qna.qna_type === 1);
      setQnAList([...newQnAList]);
    }
  }, [selectedMenu]);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {isLogin ? (
        <Box margin="8px">
          <Box display="flex" margin="1rem 1rem 1rem 6.3rem">
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h5" : "subtitle1"}
              component="p"
              width="100%"
              flex="5"
              fontWeight="500"
              textAlign="center"
            >
              나의 문의내역
            </Typography>
            <StyledLink to="/contact/write">
              <Button
                variant="contained"
                sx={{
                  minWidth: "max-content",
                  color: "white",
                  background: "black",
                  borderRadius: "0",
                }}
              >
                문의 작성
              </Button>
            </StyledLink>
          </Box>

          {data.length ? (
            <Box>
              <MenuTab
                data={menu}
                menu={selectedMenu}
                handleChange={hadleChangeSelectedMenu}
                style={{
                  pc: { fontSize: "18pt" },
                  mobile: { fontSize: "10.5pt" },
                }}
              />
              {QnAList.length ? (
                QnAList.map((qna, index) => (
                  <Box>
                    <QnA qna={qna} />
                    {QnAList.length - 1 !== index && <Divider />}
                  </Box>
                ))
              ) : (
                <Box
                  height={windowsize.height / 2}
                  display="flex"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle1"
                    component="p"
                    textAlign="center"
                    width="100%"
                  >
                    작성된 문의가 없습니다.
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              height={windowsize.height / 2}
              display="flex"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                component="p"
                textAlign="center"
                width="100%"
              >
                작성된 문의가 없습니다.
              </Typography>
            </Box>
          )}
        </Box>
      ) : null}
    </Column>
  );
};

export default Inquiry;
