import { Box } from "@mui/material";
import React from "react";

// 개인정보 수집 및 이용
const PrivacyInformation = () => {
  return (
    <Box margin="64px 16px">
      <div>
        <div>
          <h2>개인정보 취급방침</h2>
          <div>
            <br />
            <p>1. 총칙</p>
            <br />
            <p>
              주식회사 밀리언픽 (밀리언픽)(이하 “밀리언픽” 또는 “회사”라 함)는
              이용자들의 개인정보보호를 매우 중요시하며, 이용자가 회사의
              서비스를 이용함과 동시에 온라인상에서 회사에 제공한 개인정보가
              보호 받을 수 있도록 최선을 다하고 있습니다. 이에 회사는
              통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호
              등에 관한 법률 등 정보통신서비스 제공자가 준수하여야 할 관련
              법규상의 개인정보보호 규정 및 정보통신부가 제정한
              개인정보보호지침을 준수하고 있습니다.
            </p>
            <br />
            <p>
              회사는 개인정보 취급방침을 통하여 이용자들이 제공하는 개인정보가
              어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한
              조치가 취해지고 있는지 알려 드립니다.
            </p>
            <br />
            <p>
              회사의 개인정보 취급방침은 정부의 법률 및 지침 변경이나 회사의
              내부 방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른
              개인정보 취급방침의 지속적인 개선을 위하여 필요한 절차를 정하고
              있습니다. 그리고 개인정보 취급방침을 개정하는 경우나 개인정보
              취급방침 변경될 경우 쇼핑몰의 첫 페이지의 개인정보취급방침을 통해
              고지하고 있습니다. 이용자들께서는 사이트 방문 시 수시로 확인하시기
              바랍니다.
            </p>
            <br />
            <p>2. 개인정보 수집에 대한 동의</p>
            <br />
            <p>
              회사는 귀하께서 회사의 개인정보보호방침 또는 이용약관의 내용에
              대해 「동의합니다」버튼 또는 「동의하지 않습니다」버튼을 클릭할 수
              있는 절차를 마련하여, 「동의합니다」버튼을 클릭하면 개인정보
              수집에 대해 동의한 것으로 봅니다.
            </p>
            <br />
            <p>3. 개인정보의 수집목적 및 이용목적</p>
            <br />
            <p>
              "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해 정보에
              포함되어 있는 성명, 휴대폰번호 등의 사항에 의하여 당해 개인을
              식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수
              없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를
              말합니다.
            </p>
            <br />
            <p>
              대부분의 서비스는 별도의 사용자 등록이 없이 언제든지 사용할 수
              있습니다. 그러나 회사는 회원서비스를 통하여 이용자들에게 맞춤식
              서비스를 비롯한 보다 더 향상된 양질의 서비스를 제공하기 위하여
              다음과 같은 목적으로 이용자 개인의 정보를 수집 · 이용하고
              있습니다.
            </p>
            <br />
            <p>
              1) 성명, 아이디, 비밀번호, 닉네임 : 회원제 서비스 이용에 따른 본인
              확인 절차에 이용
            </p>
            <p>
              2) 이메일, 휴대폰번호 : 고지사항 전달, 불만처리 등을 위한 원활한
              의사소통 경로의 확보, 새로운 서비스 및 신상품이나 이벤트 정보 등의
              안내
            </p>
            <p>
              3) 주소, 전화번호, 개인통관고유번호 : 청구서, 물품배송 시 정확한
              배송지의 확보
            </p>
            <p>
              4) 기타 선택항목(직업, 관심분야, 관심분류) : 개인맞춤 서비스를
              제공하기 위한 자료
            </p>
            <p>5) IP Address : 불량회원의 부정 이용 방지와 비인가 사용 방지</p>
            <p>
              기타 위 수집된 정보를 이용하여 서비스 제공에 관한 계약이행 및
              요금정산, 회원관리, 마케팅 및 광고에 활용하고 있습니다.
            </p>
            <br />
            <p>4. 수집하는 개인정보 항목 및 수집방법</p>
            <br />
            <p>
              회사는 이용자들이 회원서비스를 이용하기 위해 회원으로 가입하실 때
              서비스 제공을 위한 필수적인 정보들을 온라인상에서 입력 받고
              있습니다.
            </p>
            <br />
            <p>1) 수집하는 개인정보의 항목</p>
            <br />
            <p>
              가. 회원 가입 : 아이디, 비밀번호, 이름, 닉네임, 성별, 생년월일,
              이메일, 주소, 핸드폰번호, 전화번호 또한
            </p>
            <p>
              쇼핑몰 내에서의 설문조사나 이벤트 행사 시 통계분석이나 경품제공
              등을 위해 선별적으로 개인정보 입력을 요청할 수 있습니다.
            </p>
            <p>
              나. 물품 배송 : 배송의뢰인 및 수령인의 이름, 전화번호,
              휴대전화번호, 주소, 개인통관고유번호
            </p>
            <p>다. 결제 : 신용카드정보, 은행계좌정보, 상품권정보</p>
            <p>
              라. 서비스 이용 또는 업무처리과정에서 자동으로 생성되어 수집되는
              정보 : 접속 IP 정보, 쿠키,서비스 이용 기록, 접속 로그, 결제 기록
            </p>
            <p>
              입력하신 정보는 이용자들에게 사전에 밝힌 목적 이외에 다른
              목적으로는 사용하지 않으며 이용자의 사전 동의 없이는 이용자의 개인
              정보를 외부에 공개 · 유출하지 않습니다.
            </p>
            <br />
            <p>2) 개인정보의 수집방법</p>
            <br />
            <p>
              홈페이지, 어플리케이션을 통한 회원가입, 전화/팩스/상담게시판을
              통한 이용자의 자발적 정보 제공, 이메일, 경품/배송 행사 응모,
              매장에서의 태블릿 PC, 서면양식 등을 통한 오프라인에서의 수집 등
              본인확인기관으로부터의 제공, 제휴사로부터 제공 등 생성정보 수집
              툴을 통한 수집
            </p>
            <br />
            <p>5. 개인정보의 보유 및 이용 기간</p>
            <br />
            <p>
              이용자가 쇼핑몰 회원으로서 회사에 제공하는 서비스를 이용하는 동안
              회사는 이용자들의 개인정보를 계속적으로 보유하며 서비스 제공 등을
              위해 이용합니다. 다만, 개인정보의 열람, 정정, 삭제" 에서 설명한
              절차와 방법에 따라 이용자 본인이 직접 삭제하거나 수정한 정보,
              가입해지를 요청한 경우에는 재생할 수 없는 방법에 의하여 디스크에서
              완전히 삭제하며 추후 열람이나 이용이 불가능한 상태로 처리됩니다.
            </p>
            <br />
            <p>
              그리고 수집하는 개인정보 항목 및 수집방법" 에서와 같이 일시적인
              목적 (설문조사, 이벤트, 본인확인 등)으로 입력 받은 개인정보는 그
              목적이 달성된 이후에는 동일한 방법으로 사후 재생이 불가능한 상태로
              처리됩니다.
            </p>
            <br />
            <p>
              이용자의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은
              목적이 달성되면 파기하는 것을 원칙으로 합니다.
            </p>
            <p>
              그리고 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등
              관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는
              관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우
              회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은
              아래와 같습니다.
            </p>
            <br />
            <p>
              1) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래
              등에서의 소비자보호에 관한 법률)
            </p>
            <p>
              2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래
              등에서의 소비자보호에 관한 법률)
            </p>
            <p>
              3) 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의
              소비자보호에 관한 법률)
            </p>
            <p>
              4) 표시/광고에 관한 기록 : 6개월 (전자상거래 등에서의 소비자보호에
              관한 법률)
            </p>
            <p>5) 접속에 관한 기록 : 3개월 (통신비밀보호법 시행령)</p>
            <p>
              6) 건당 1만원 초과 전자금융거래에 관한 기록 : 5년 (전자금융거래법)
            </p>
            <p>
              7) 건당 1만원 이하 전자금융거래에 관한 기록 : 1년 (전자금융거래법)
            </p>
            <br />
            <p>
              관련 법령에 그 근거가 없더라도, 회사의 중대한 손실을 예방하거나,
              범죄 및 소송 등을 위해 보관 해야하는 경우 회사방침에 따라 보관할
              수 있습니다.
            </p>
            <p>
              단, 그 목적을 달성하기 위한 최소한의 기간 및 항목만 보관합니다.
            </p>
            <br />
            <p>6. 개인정보 제3자 제공</p>
            <br />
            <p>
              회사는 이용자들의 개인정보를 개인정보의 수집목적 및 이용목적 에서
              고지한 범위 내에서만 사용하며, 이용자의 사전 동의 없이는 동 범위를
              초과하여 이용하거나 이용자의 개인정보를 외부에 공개하지 않습니다.
            </p>
            <p>다만, 아래의 경우에는 예외로 합니다.</p>
            <br />
            <p>1) 이용자들이 사전에 공개에 동의한 경우</p>
            <p>
              2) 서비스 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서
              경제적 · 기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한
              경우
            </p>
            <p>3) 서비스 제공에 따른 요금정산을 위하여 필요한 경우</p>
            <p>
              4) 자사 서비스를 이용하여 타인에게 정신적, 물질적 피해를 줌으로써
              그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고
              판단되는 충분한 근거가 있는 경우
            </p>
            <p>
              기타 법에 의해 요구된다고 판단되는 경우 (ex. 관련법에 의거 적법한
              절차에 의한 정부/수사기관의 요청이 있는 경우 등)
            </p>
            <p>
              5) 통계작성, 학술연구나 시장조사를 위하여 특정개인을 식별할 수
              없는 형태로 광고주, 협력업체나 연구단체 등에 제공하는 경우
            </p>
            <br />
            <p>7. 개인정보의 취급위탁</p>
            <br />
            <p>
              회사는 서비스 향상을 위해서 귀하의 개인정보를 필요한 경우 동의 등
              법률상의 요건을 구비하여 외부에 수집 · 취급 · 관리 등을 위탁하여
              처리할 있으며, 제 3자에게 제공할 수 있습니다.
            </p>
            <br />
            <p>
              회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고
              있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수
              있도록 필요한 사항을 규정하고 있습니다. 또한 공유하는 정보는 당해
              목적을 달성하기 위하여 필요한 최소한의 정보에 국한됩니다.
            </p>
            <br />
            <p>1) (주)에이씨아이 월드와이드, 우체국택배, 기타택배 : 배송</p>
            <br />
            <p>
              회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무
              수행목적 외 개인정보 처리금지, 기술적 관리적 보호조치, 재위탁
              제한, 수탁자에 대한 관리 및 감독, 손해배상 등 책임에 관한 사항을
              계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게
              처리하는지를 감독하고 있습니다.
            </p>
            <p>
              위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
              처리방침을 통하여 공개하도록 하겠습니다.
            </p>
            <br />
            <p>8. 개인정보의 열람, 정정, 삭제</p>
            <br />
            <p>
              이용자가 원할 경우 언제라도 회사에서 개인정보를 열람하실 수 있으며
              보관된 필수 정보를 수정하실 수 있습니다. 또한 회원 가입 시 요구된
              필수 정보 외의 추가 정보는 언제나 열람, 수정, 삭제할 수 있습니다.
            </p>
            <p>
              이용자의 개인정보 변경 및 삭제와 회원탈퇴는 당사의 고객센터에서
              로그인(Login) 후 이용하실 수 있습니다.
            </p>
            <br />
            <p>9. 개인정보의 파기</p>
            <br />
            <p>
              회사는 개인정보의 수집 및 이용목적이 달성되거나 보유기간 만료 시
              해당 개인정보를 파기합니다. 파기절차 및 방법은 다음과 같습니다.
              단, 다른 법령에 의하여 해당 개인정보를 보존하여야 하는 경우는
              예외로 합니다.
            </p>
            <br />
            <p>
              이용자가 입력한 정보는 목적 달성 후 별도의 데이터베이스에
              옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라
              일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, 데이터베이스로
              옮겨진 개인정보는 법령에 의한 경우가 아니고서는 다른 목적으로
              이용되지 않습니다.
            </p>
            <br />
            <p>
              이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는
              보유기간의 종료일로부터 5영업일 이내에, 개인정보의 처리 목적 달성,
              해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을
              때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5영업일
              이내에 그 개인정보를 파기합니다. 만 14세미만 아동에 대한
              법정대리인의 거부가 있거나 동의 의사가 확인되지 않는 경우
              수집일로부터 5영업일 이내에 해당 개인정보를 파기합니다.
            </p>
            <br />
            <p>
              전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
              사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
              통하여 파기합니다.
            </p>
            <br />
            <p>10. 이용자의 권리와 의무</p>
            <br />
            <p>
              이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를
              예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해
              발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등
              허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
            </p>
            <br />
            <p>
              이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의
              정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한
              이용자의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한
              타인의 개인정보를 훼손하지 않도록 유의해 주십시오.
            </p>
            <br />
            <p>
              만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할
              시에는 『정보통신망이용촉진 및 정보보호 등에 관한 법률』등에 의해
              처벌 받을 수 있습니다.
            </p>
            <br />
            <p>11. 개인정보관리 책임자 및 담당자</p>
            <br />
            <p>
              회사는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을
              다하고 있습니다. 개인정보를 보호하는데 있어 이용자에게 고지한
              사항들에 반하는 사고가 발생할 경우 개인정보관리책임자가 책임을
              집니다.
            </p>
            <br />
            <p>
              그러나 기술적 · 관리적 보완조치를 했음에도 불구하고, 해킹 등
              기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로
              인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에
              관해서는 회사의 책임이 없습니다. 또한 이용자 개인정보와 관련한
              아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 이용자 자신에게
              있습니다. 회사는 비밀번호에 대해 어떠한 방법으로도 이용자에게
              직접적으로 질문하는 경우는 없으므로 타인에게 비밀번호가 유출되지
              않도록 각별히 주의하시기 바랍니다. 특히 공공장소의 온라인상에서
              접속해 있을 경우에는 더욱 유의하셔야 합니다.
            </p>
            <br />
            <p>
              회사는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보
              관리책임자 및 담당자를 지정하고 있고, 연락처는 아래와 같습니다.
            </p>
            <br />
            <p>이름 : 심민규</p>
            <p>직위 : 대표이사</p>
            <p>e-mail : admin@million-pick.com</p>
            <br />
            <p>12. 이용자 및 법정대리인의 권리와 그 행사방법</p>
            <br />
            <p>
              이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만
              14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를
              요청할 수도 있습니다.
            </p>
            <br />
            <p>
              이용자 혹은 만 14세 미만 아동의 개인정보 조회,수정을 위해서는
              ‘개인정보변경’(또는 ‘회원정보수정’ 등)을 가입해지(동의철회)를
              위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접
              열람, 정정 또는 탈퇴가 가능합니다.
            </p>
            <p>
              혹은 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면
              지체없이 조치하겠습니다.
            </p>
            <br />
            <p>
              이용자가 개인정보의 오류에 대한 정정을 요청한 경우 정정을 완료하기
              전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
              개인정보를 제3자 에게 이미 제공한 경우에는 정정 처리결과를
              제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
            </p>
            <br />
            <p>
              회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
              개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된
              바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
              처리하고 있습니다
            </p>
            <p>
              개인정보보호 관련 권리행사는 정보주체의 법정대리인이나 위임을
              받은자 등 대리인을 통하여 하실수 있습니다 이경우 개인정보 보호법
              시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
            </p>
            <p>
              정보주체는 개인정보보호법등 관계법령을 위반하여 회사가 처리하고
              있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해해서는 아니
              됩니다.
            </p>
            <br />
            <p>이름 : 심민규</p>
            <p>직위 : 대표이사</p>
            <p>e-mail : admin@million-pick.co.kr</p>
            <br />
            <p>13. 고지의 의무</p>
            <br />
            <p>
              현 개인정보취급방침의 내용은 정부의 정책 또는 보안기술의 변경에
              따라 내용의 추가 삭제 및 수정이 있을 시에는 홈페이지의
              '공지사항'을 통해 고지할 것입니다.
            </p>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PrivacyInformation;
