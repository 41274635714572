import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import MainMenu from "../../../common/components/main_menu";
import columnData from "../../../common/json/columns.json";
import DataTable from "../../../common/components/data_table";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../../../common/style/styles";
import is from "date-fns/esm/locale/is/index.js";

const Index = () => {
  const { pathname } = useLocation();
  const [isAdmin, setIsAdmin] = useState(true);
  // 테이블 행 데이터
  const [columns, setColumns] = useState([...columnData[pathname]]);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  //더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);

        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 환율 입력값 변경
  const handleInputInfoChange = (event, id) => {
    console.log(event.target.value, id);

    const newRows = rows.map((data) => {
      if (data.id === id) {
        return { ...data, price: event.target.value };
      } else {
        return data;
      }
    });

    setRows([...newRows]);
  };

  useEffect(() => {
    setLoading(true);
    getDataFromServer("/admin/etc/exchange")
      .then((response) => {
        setIsAdmin(true);

        setRows([...response.data]);
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // 환율 수정 버튼 클릭
  const handleSubmit = () => {
    if (hasClick) {
      return;
    }
    // 선택한 체크박스가 있는지 확인
    if (!selectedIDs.length) {
      alert("수정할 환율 정보를 선택해주세요.");
      return;
    }

    const newData = rows.filter((data) => selectedIDs.includes(data.id));

    // 숫자외의 문자가 있는지 확인
    const hasChar = newData.find((data) => data.price.toString().match(/\D/));
    if (hasChar) {
      alert("환율에는 숫자만 기입해주세요.");
      return;
    }
    setLoading(true);
    setProcessing(true);
    setHasClick(true);
    // 서버로 데이터 전송
    putDataFromServer("/admin/etc/exchange", { data: newData })
      .then((response) => {
        alert("환율이 수정되었습니다.");
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      });
    setLoading(false);
    setProcessing(false);
    setHasClick(false);
  };

  return (
    <>
      {isAdmin && (
        <Box display="flex">
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {rows.length > 0 ? (
              <Box sx={{ overflow: "auto" }}>
                <Box margin="8px 0">
                  <Button
                    variant="outlined"
                    style={{ borderRadius: "unset", margin: "0 8px" }}
                    onClick={handleSubmit}
                  >
                    환율 수정
                  </Button>
                </Box>
                <DataTable
                  columns={columns}
                  rows={rows}
                  selectedIDs={selectedIDs}
                  // handleChangeOrderby={handleChangeOrderby}
                  handleClickCheckBox={handleClickCheckBox}
                  handleInputInfoChange={handleInputInfoChange}
                />
              </Box>
            ) : (
              <Typography textAlign="center" variant="h4" margin="32px 0">
                데이터가 없습니다.
              </Typography>
            )}
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { Index as ExchangeManagement };
