import { Box, Button, InputBase, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainMenu from "../../../common/components/main_menu";
import SearchHeader from "../components/search_header";
import OptionHeader from "../components/option_header";
import OrderStatus from "../components/order_status";
import columnData from "../../../common/json/columns.json";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { borderColor } from "@mui/system";
import DataTable from "../../../common/components/data_table";
import { beforePageState, windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import {
  convertDateTime,
  createXlsxData,
  preproccessingForDataTable,
} from "../../../common/js/common";
import { useMutation } from "react-query";
import {
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { lightgrey, primary } from "../../../common/style/styles";
import ChangeStatusButton from "../components/change_status_button";
import RefuseAndAccecptanceButton from "../components/refuse_and_acceptance_button";
import { TailSpin } from "react-loader-spinner";
import deleveryCode from "../../../common/json/delivery_code.json";

const Index = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [beforePage, setBeforePage] = useRecoilState(beforePageState);
  // 조회조건 배열
  const [condition, setCondition] = useState({
    filter: [],
    date: [],
  });
  // 총 데이터 갯수
  const [count, setCount] = useState(0);
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  // 현재페이지, 마지막페이지 정보
  const [page, setPage] = useState({ total: 1, current: 1 });
  // url 파라미터
  const [params, setParams] = useState({
    limit: 15,
    offset: 0,
    orderby: "order_items.id desc",
    filter: null,
    status: state ? state.status : -1,
  });

  // 테이블 행 데이터
  const tempColumns = columnData[pathname]["배송"].map((value) => {
    if (value.field === "deliver_company") {
      value.option = deleveryCode.data;
    }
    return value;
  });
  const [columns, setColumns] = useState(tempColumns);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // 엑셀 일괄 발송처리를 위해 서버에 엑셀 업로드
  const handleUploadExcel = (excelFile) => {
    if (!excelFile) {
      return;
    }

    setLoading(true);
    setProcessing(true);
    const formData = new FormData();
    formData.append("excel", excelFile);

    postDataFromServer("/admin/orders/excel", formData)
      .then((response) => {
        alert(
          response.error_item.length
            ? `${response.error_item.join(",")} 외에 발송처리가 완료되었습니다.`
            : "발송처리가 완료되었습니다."
        );
        window.location.reload();
      })
      .catch((error) => {
        alert("발송처리에 실패했습니다. 엑셀을 확인해주세요.");
        return;
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  // 배송정보 입력 정보 변경
  const handleInputInfoChange = (event, order_item_id) => {
    const { name, value } = event.target;

    const newRows = rows.map((row) => {
      if (order_item_id === row.id) {
        return { ...row, [name]: value };
      } else {
        return row;
      }
    });

    setRows([...newRows]);
  };

  // 교환 승인, 거부 처리
  const handleExchangeItem = (isAccept, message) => {
    // console.log(rows, isAccept, message);
    if (isAccept) {
      if (!window.confirm("교환상품 발송처리를 진행하시겠습니까?")) {
        return;
      }
    }
    setLoading(true);
    setProcessing(true);
    const selectedItems = rows
      .filter((value) => selectedIDs.includes(value.id))
      .map((value) => {
        return {
          order_id: parseInt(String(value.order_id).slice(-10)),
          order_item_id: parseInt(String(value.order_item_id).slice(-10)),
          cancel_history_id: value.cancel_history_id,
          shipment_number: value.shipment_number,
          deliver_company: value.deliver_company,
        };
      });

    // 주문 상품의 택배사, 송장번호를 입력 안했으면 return
    if (
      selectedItems.find(
        (value) => !value.deliver_company || !value.shipment_number
      )
    ) {
      alert("선택한 교환 상품의 택배사, 송장번호를 입력해주세요.");
      return;
    }

    const newData = {
      items: selectedItems,
      is_accept: isAccept,
      message: message ? message : null,
    };

    postDataFromServer("/admin/orders/exchange", newData)
      .then((response) => {
        alert(`선택한 주문이 ${isAccept ? "발송" : "거부"} 처리되었습니다.`);

        window.location.reload();
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  // 결제취소&반품 승인, 거부 처리
  const handleCancelAndReturn = (isAccept, message, cancelFee) => {
    // 선택한 상품주문번호가 없으면 return
    if (!selectedIDs.length) {
      alert("처리할 주문번호를 선택해주세요.");
      return;
    }

    if (params.status === 6 && isAccept) {
      if (!window.confirm("취소 요청을 승인하시겠습니까?")) {
        return;
      }
    }

    setLoading(true);
    setProcessing(true);

    const selectedItems = rows
      .filter((value) => selectedIDs.includes(value.id))
      .map((value) => {
        return {
          order_id: parseInt(String(value.order_id).slice(-10)),
          order_item_id: parseInt(String(value.order_item_id).slice(-10)),
          cancel_history_id: value.cancel_history_id,
        };
      });
    const newData = {
      items: selectedItems,
      is_accept: isAccept,
      message: message ? message : null,
      state: params.status,
      admin_cancel_fee: cancelFee ? cancelFee : 0,
    };

    putDataFromServer("/admin/orders/cancel", newData)
      .then((response) => {
        const name = `${params.status === 6 ? "취소" : "반품"}${
          isAccept ? "완료" : "거부"
        }`;
        alert(`선택한 주문이 ${name} 처리되었습니다.`);

        mutation.mutate(params);
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  // 판매 취소 처리
  const handleCancelSale = (selectedReason, message, cancelFee) => {
    // 선택한 상품주문번호가 없으면 return
    if (!selectedIDs.length) {
      alert("취소할 주문번호를 선택해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);

    const selectedItems = rows
      .filter((value) =>
        selectedIDs.includes(parseInt(String(value.order_item_id).slice(-10)))
      )
      .map((value) => {
        return {
          order_id: parseInt(String(value.order_id).slice(-10)),
          order_item_id: parseInt(String(value.order_item_id).slice(-10)),
          deliver_company: value.deliver_company,
          shipment_number: value.shipment_number,
          state_record: {
            ...value.state_record,
          },
          state: parseInt(
            Object.keys(value.state_record)[
              Object.keys(value.state_record).length - 1
            ]
          ),
        };
      });

    // console.log(selectedItems);

    postDataFromServer("/admin/orders/cancel", {
      cancel_items: selectedItems,
      content: selectedReason,
      message: message,
      admin_cancel_fee: cancelFee,
    })
      .then((response) => {
        alert("선택한 주문이 판매 취소 처리되었습니다.");

        window.location.reload();
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  // 주문 상품의 상태 변경 (발주 확인, 발송 처리)
  const handleChangeStatus = (status) => {
    // 선택한 상품주문번호가 없으면 return
    if (!selectedIDs.length) {
      alert("주문 상품을 선택해주세요.");
      return;
    }

    const selectedItems = rows.filter((value) =>
      selectedIDs.includes(parseInt(String(value.order_item_id).slice(-10)))
    );

    // 발주 확인에서 상품 상태가 구매 완료 후의 상태가 있으면 return
    if (
      status === 2 &&
      selectedItems.find((value) => value.state !== "신규주문")
    ) {
      console.log(status, 1);
      alert("주문 상품의 상태를 확인해주세요.");
      return;
    }

    // 발송 처리에서 상품 상태가 구매 완료나 배송준비 후의 상태가 있으면 return
    if (
      status === 3 &&
      selectedItems.find(
        (value) =>
          value.state !== "신규주문" &&
          value.state !== "배송준비" &&
          value.state !== "배송중"
      )
    ) {
      console.log(status, 2);
      alert("주문 상품의 상태를 확인해주세요.");
      return;
    }

    // 발송 처리에서 주문 상품의 택배사, 송장번호를 입력 안했으면 return
    if (
      status === 3 &&
      selectedItems.find(
        (value) => !value.deliver_company || !value.shipment_number
      )
    ) {
      alert("선택한 주문 상품의 택배사, 송장번호를 입력해주세요.");
      return;
    }

    // 발송 처리에서 주문 상품의 출고국가를 선택 안했으면 return
    if (
      status === 3 &&
      selectedItems.find((value) => !value.shipment_country)
    ) {
      alert("선택한 주문 상품의 출고국가를 선택해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);

    const filterdItems = selectedItems.map((value) => {
      return {
        order_id: parseInt(String(value.order_id).slice(-10)),
        order_item_id: parseInt(String(value.order_item_id).slice(-10)),
        deliver_company: value.deliver_company,
        shipment_number: value.shipment_number,
        country: value.shipment_country,
        state_record: {
          ...value.state_record,
          [status]: convertDateTime(new Date(Date.now())),
        },
      };
    });

    putDataFromServer(`/admin/orders/-1?status=${status}`, {
      data: filterdItems,
    })
      .then((response) => {
        if (response.error_item.length) {
          alert(
            `${response.error_item.join(
              ","
            )} 상품주문번호를 제외한 선택한 제품의 주문 상태가 변경되었습니다.`
          );
        } else {
          alert("선택한 제품의 주문 상태가 변경되었습니다.");
        }

        // mutation.mutate(params);
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  // 특정한 상태를 기준으로 상품 필터링  ( params 중 status 변경 => 조회조건 초기화 )
  const handleStatusOfParamsChange = (status) => {
    setParams({
      limit: 15,
      offset: 0,
      orderby: "order_items.id desc",
      filter: null,
      status: status,
    });
    setSelectedIDs([]);
    navigate(pathname, { replace: true });
    // 배송
    if (status < 6) {
      const tempColumns = columnData[pathname]["배송"].map((value) => {
        if (value.field === "deliver_company") {
          value.option = deleveryCode.data;
        }
        return value;
      });
      setColumns([...columnData[pathname]["배송"]]);
    }
    // 취소
    if (status === 6 || status === 7) {
      setColumns([...columnData[pathname]["취소"]]);
    }
    // 반품
    if (status === 8 || status === 9) {
      setColumns([...columnData[pathname]["반품"]]);
    }
    // 교환
    if (status === 10) {
      setColumns([...columnData[pathname]["교환"]]);
    }
  };

  // 조회조건 추가, 삭제
  const handleChangeInquiry = (state, type, data, id) => {
    if (state === "add") {
      setCondition({ ...condition, [type]: [...condition[type], data] });
    } else if (state === "delete") {
      const newCondition = condition[type].filter((value) => value.id !== id);
      setCondition({ ...condition, [type]: newCondition });
    }
  };

  // 조회하기 버튼 클릭했을때 조회조건을 기준으로 필터링하기
  const handleClickInquiry = () => {
    if (!condition.filter.length && !condition.date.length) {
      alert("조회조건이 없습니다.");
      return;
    }

    setParams({ ...params, filter: JSON.stringify(condition) });
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);

        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 특정 컬럼 기준으로 정렬
  const handleChangeOrderby = (fieldName, orderby) => {
    if (params.status > 6) {
      return;
    }

    const newColumns = columns.map((value) => {
      const newValue = value;

      if (value.standard) {
        // 같은 컬럼 선택 > 기존 정렬방식의 반대방식으로 정렬
        if (fieldName === value.field) {
          newValue.standard = true;
          orderby === "desc"
            ? (newValue.orderby = "asc")
            : (newValue.orderby = "desc");
          // 다른 컬럼 선택시 기존컬럼 선택 해지
        } else {
          newValue.standard = false;
        }
        // 다른 컬럼 선택 > 기존 정렬방식대로 정렬
      } else {
        if (fieldName === value.field) {
          newValue.standard = true;
        }
      }
      return { ...newValue };
    });

    setColumns([...newColumns]);

    setParams({
      ...params,
      orderby: `${fieldName} ${
        newColumns.filter((value) => value.standard)[0].orderby
      }`,
    });
  };

  // 엑셀 다운로드
  const handleExportExcel = () => {
    const newRows = rows.filter((data) => selectedIDs.includes(data.id));
    createXlsxData(columns, newRows, `order_list`);
  };

  // 페이징 숫자 변경 (100개, 500개씩 보기)
  const handleChangePaging = (paging) => {
    setParams({ ...params, limit: paging });
  };

  // 특정 페이지 이동
  const handleChangeCurrentPage = () => {
    // 이동하려는 페이지 숫자가 전체 페이지를 넘어섰을 경우 리턴
    if (page.current > page.total) {
      return;
    }
    setParams({
      ...params,
      offset: params.limit * (page.current - 1),
    });
  };

  // 페이지 변경
  const handlePageInputChange = (event) => {
    // 페이지 입력이 숫자가 아니거나 0보다 작으면 리턴
    if (!event.target.value || event.target.value <= 0) {
      return;
    }
    setPage({ ...page, current: event.target.value });
  };

  // prams가 변경되었을 때 서버로부터 데이터 가져오기
  const mutation = useMutation("OrderItemDataList", {
    mutationFn: () => {
      return getDataFromServer(
        `/admin/orders?limit=${params.limit}&offset=${params.offset}&orderby=${params.orderby}&filter=${params.filter}&status=${params.status}`
      );
    },
    onSuccess: (data) => {
      setIsAdmin(true);

      const newRows = preproccessingForDataTable(pathname, {
        status: params.status,
        data: data.rows,
      });

      console.log(newRows);
      setRows([...newRows]);
      setCount(data.count);
      setPage({
        ...page,
        total: Math.ceil(data.count / params.limit),
      });
    },
    onError: (error) => {
      setIsAdmin(false);
    },
  });

  useEffect(() => {
    // 상세페이지에서 리스트로 뒤로가기 해서 이동 안했을 시 local에 있는 데이터 삭제
    if (!beforePage.includes("orders")) {
      localStorage.setItem("adminItemListData", "null");
    } else {
      // 상세페에지 -> 리스트 이동시 이전 리스트 데이터 세팅
      const adminItemListData = localStorage.getItem("adminItemListData");

      if (adminItemListData && adminItemListData !== "null") {
        const newAdminItemListData = JSON.parse(adminItemListData);
        setCondition({ ...newAdminItemListData.listData.condition });
        setParams({ ...newAdminItemListData.listData.params });
        localStorage.setItem("adminItemListData", "null");
        return;
      }
    }

    mutation.mutate(params);
  }, [params]);

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <OrderStatus
              status={params.status}
              handleStatusOfParamsChange={handleStatusOfParamsChange}
            />
            {params.status < 6 && (
              <SearchHeader
                condition={condition}
                handleChangeInquiry={handleChangeInquiry}
                handleClickInquiry={handleClickInquiry}
              />
            )}
            <OptionHeader
              handleChangePaging={handleChangePaging}
              handleExportExcel={handleExportExcel}
            />
            {count > 0 ? (
              <Box
                border={"1px solid " + borderColor}
                sx={{ overflow: "auto" }}
                maxHeight={(windowSize.height * 8) / 13}
              >
                <DataTable
                  listData={{
                    params: params,
                    condition: condition,
                  }}
                  columns={columns}
                  rows={rows}
                  selectedIDs={selectedIDs}
                  handleChangeOrderby={handleChangeOrderby}
                  handleClickCheckBox={handleClickCheckBox}
                  handleInputInfoChange={handleInputInfoChange}
                />
                <Box
                  style={{
                    backgroundColor: `${lightgrey}`,
                    bottom: "0px",
                    left: "0px",
                    position: "sticky",
                    zIndex: 110,
                    padding: "8px",
                  }}
                >
                  {count}개 중 {params.offset + 1} -{" "}
                  {params.offset + rows.length} 데이터 표시중 ({" "}
                  <InputBase
                    type="number"
                    style={{
                      margin: "2px",
                      border: `2px solid ${primary}`,
                      borderRadius: "4px",
                      width: "50px",
                    }}
                    value={page.current}
                    onChange={handlePageInputChange}
                  />
                  / {page.total} )
                  <Button
                    variant="outlined"
                    style={{ borderRadius: "unset", margin: "0 8px" }}
                    onClick={handleChangeCurrentPage}
                  >
                    페이지 이동
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography textAlign="center" variant="h4" margin="32px 0">
                데이터가 없습니다.
              </Typography>
            )}
            {params.status < 6 && (
              <ChangeStatusButton
                selectedIDs={selectedIDs}
                handleChangeStatus={handleChangeStatus}
                handleUploadExcel={handleUploadExcel}
                handleCancelSale={handleCancelSale}
              />
            )}
            {(params.status === 6 ||
              params.status === 8 ||
              params.status === 10) && (
              <RefuseAndAccecptanceButton
                status={params.status}
                selectedIDs={selectedIDs}
                handleCancelAndReturn={handleCancelAndReturn}
                handleExchangeItem={handleExchangeItem}
              />
            )}
          </Box>
        </Box>
      )}
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={loadling || mutation.isLoading}
      />
    </>
  );
};

export { Index as OrderManagement };
