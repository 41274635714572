import { Box } from "@mui/material";
import React from "react";

// 서비스 이용 약관
const TermsOfService = () => {
  return (
    <Box margin="64px 16px">
      <div>
        <div>
          <div>
            <h2>이용약관</h2>
            <div></div>
            <div>
              <p>제 1 조 (목적)</p>
              <br />
              <p>
                이 약관은 “주식회사 밀리언픽”에서(이하 "회사"라 함) 운영하는
                밀리언픽 웹사이트(http://million-pick.com 이하 "사이트" 또는
                “밀리언픽”)에서 제공하는 수입대행 서비스를 이용함에 있어 회사와
                회원의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
              </p>
              <br />
              <p>제 2 조 (정의)</p>
              <br />
              <p>
                1. "사이트"라 함은 회사가 본 약관에 의하여 재화나 용역을
                회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여
                재화나 용역을 거래할 수 있도록 설정한 가상의 영업장을
                의미합니다.
              </p>
              <p>
                2. “회원"이라 함은 밀리언픽에 개인정보를 제공하여 회원등록을 한
                개인 이용자로서, 밀리언픽의 정보를 지속적으로 제공받으며
                밀리언픽이 제공하는 서비스를 계속적으로 이용할 수 있는 자를
                말합니다.{" "}
              </p>
              <p>
                3. 밀리언픽과 회원간에 발생하는 거래계약 유형은 “수입대행형”이며
                거래계약유형에 대한 정의는 다음과 같습니다.
              </p>
              <p>
                가. “수입대행형” 거래계약 유형은 밀리언픽이 고객을 대신하여 해외
                인터넷 쇼핑몰등에서 상품을 선정하고 밀리언픽 사이트에 게시한
                상품에 대하여 회원이 밀리언픽에 수입대행을 의뢰하면 밀리언픽이
                해당 상품을 판매하는 해외 인터넷 쇼핑몰 등에서 회원을 대신해
                구매 및 결제를 하여 이후 국제운송 및 수입통관을 거쳐 회원의 국내
                수취처까지 운송을 하여 회원이 수령할 수 있도록 하는 것을
                의미합니다.
              </p>
              <br />
              <p>제 3 조 (약관의 명시와 설명 및 개정)</p>
              <br />
              <p>
                1. 밀리언픽은 이 약관의 내용과 상호 및 대표자 성명, 영업소
                소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
                전화번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호,
                개인정보 관리책임자 등을 회원이 쉽게 알 수 있도록 밀리언픽의
                초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 회원이
                연결화면을 통하여 볼 수 있도록 할 수 있습니다.
              </p>
              <p>
                2. 밀리언픽은 회원이 약관에 동의하기에 앞서 약관에 정하여져 있는
                내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을
                회원이 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
                제공하여 회원의 확인을 구하여야 합니다.
              </p>
              <p>
                3. 밀리언픽은 전자상거래 등에서의 소비자보호에 관한 법률, 약관의
                규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진
                등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등
                관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
              </p>
              <p>
                4. 밀리언픽이 약관을 개정할 경우에는 적용일자 및 개정사유를
                명시하여 현행약관과 함께 밀리언픽의 초기화면에 그 적용일자 7일
                이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게
                약관내용을 변경하는 경우에는 최소한 15일 이상의 사전 유예기간을
                두고 공지합니다. 이 경우 밀리언픽은 개정 전 내용과 개정 후
                내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
              </p>
              <p>
                5. 밀리언픽이 약관을 개정할 경우에는 그 개정약관은 그 적용일자
                이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에
                대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을
                체결한 회원이 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에
                의한 개정약관의 공지기간 내에 밀리언픽에 송신하여 밀리언픽의
                동의를 받은 경우에는 개정약관 조항이 적용됩니다.
              </p>
              <p>
                6. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
                전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에
                관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의
                소비자보호 지침 및 관계법령 또는 상관례에 따릅니다.
              </p>
              <br />
              <p>제 4 조 (서비스의 제공 및 변경)</p>
              <br />
              <p>1. 밀리언픽은 다음과 같은 업무를 수행합니다.</p>
              <p>가. 재화 또는 용역에 대한 정보제공 및 수입대행계약의 체결</p>
              <p>
                나. 회원이 해외에서 구매 또는 수입대행을 의뢰한 물건에 대한
                운송계약의 체결
              </p>
              <p>다. 회원이 구매 또는 수입대행을 의뢰한 재화의 배송</p>
              <p>라. 통관대행 서비스</p>
              <p>마. 기타 밀리언픽이 정하는 업무</p>
              <p>
                2. 밀리언픽은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의
                경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의
                내용을 변경할 수 있습니다.{" "}
              </p>
              <p>
                3. 밀리언픽이 제공하기로 회원과 계약을 체결한 서비스의 내용을
                재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할
                경우에는 그 사유를 회원에게 통지합니다.
              </p>
              <br />
              <p>제 5 조 (서비스의 중단)</p>
              <br />
              <p>
                1. 밀리언픽은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
                통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을
                일시적으로 중단할 수 있습니다.
              </p>
              <p>
                2. 밀리언픽은 제1항의 사유로 서비스의 제공이 일시적으로
                중단됨으로 인하여 회원 또는 제3자가 입은 손해에 대하여
                배상합니다. 단, 밀리언픽이 고의 또는 과실이 없음을 입증하는
                경우에는 그러하지 아니합니다.
              </p>
              <br />
              <p>제 6 조 (회원가입)</p>
              <br />
              <p>
                1. 회원은 밀리언픽이 정한 가입양식에 따라 회원정보를 기입한 후
                이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
              </p>
              <p>
                2. 밀리언픽은 제1항과 같이 회원으로 가입할 것을 신청한 회원 중
                다음 각호에 해당하지 않는 한 회원으로 등록합니다.
              </p>
              <p>
                가. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을
                상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후
                3년이 경과한 자로서 밀리언픽의 회원 재가입 승낙을 얻은 경우에는
                예외로 합니다.
              </p>
              <p>나. 등록 내용에 허위, 기재누락, 오기가 있는 경우</p>
              <p>다. 법인정보를 이용하여 법인명의로 등록하는 경우</p>
              <p>
                라. 기타 회원으로 등록하는 것이 밀리언픽의 기술상 현저히 지장이
                있다고 판단되는 경우
              </p>
              <p>마. 가입신청자의 연령이 만 14세 미만인 경우</p>
              <p>
                3. 회원가입계약의 성립시기는 밀리언픽의 회원가입 승낙이 회원에게
                도달한 시점으로 합니다.
              </p>
              <p>
                4. 회원은 회원가입 시 기입한 회원정보에 변경이 있는 경우, 즉시
                전자우편이나 기타 방법으로 밀리언픽에 그 변경사항을 알려야
                합니다.
              </p>
              <p>
                5. 회원가입 정보를 받은 경우도 본 조 2항 가, 나, 다, 라, 마
                항목에 해당될 시에는 회원가입이 승인되지 않은 것으로 합니다.
              </p>
              <br />
              <p>제 7 조 (회원탈퇴 및 자격상실 등)</p>
              <br />
              <p>
                1. 회원은 밀리언픽에 언제든지 회원탈퇴를 요청할 수 있으며
                밀리언픽는 즉시 회원탈퇴를 처리합니다.
              </p>
              <p>
                2. 회원이 다음 각 호의 사유에 해당하는 경우, 밀리언픽는
                회원자격을 제한 및 정지시킬 수 있습니다.
              </p>
              <p>가. 가입신청 내역에 허위내용이 발견된 경우</p>
              <p>
                나. 밀리언픽 사용과 관련하여 서비스요금 미납 등 회원이 부담하는
                채무를 기일 내에 이행하지 않는 경우
              </p>
              <p>
                다. 다른 사람의 사이트 이용을 방해하거나 그 정보를 도용하는 등
                전자상거래 질서를 위협하는 경우
              </p>
              <p>
                라. 회원이 제출한 주소 또는 연락처의 변경통지를 하지 않는 등
                회원의 귀책사유로 인해 회원이 소재불명 되어 밀리언픽이 회원에게
                통지, 연락을 할 수 없다고 판단되는 경우
              </p>
              <p>
                마. 밀리언픽을 이용하여 관련법령과 이 약관이 금지하거나
                공서양속에 반하는 행위를 하는 경우
              </p>
              <p>
                3. 밀리언픽을 다음 각 호의 경우에 회원자격을 상실시킬 수
                있습니다.
              </p>
              <p>
                가. 밀리언픽이 본 조2항에 의해 회원자격을 제한 또는 정지시킨 후,
                동일한 행위가 2회 이상 반복 되거나 30일 이내에 그 사유가
                시정되지 아니하는 경우
              </p>
              <p>
                나. 회원이 구매한 물품에 대한 서비스요금 결제를 30일 이내에
                이행하지 않을 경우
              </p>
              <p>
                다. 회원이 위법, 불법 혹은 부정한 목적으로 본 서비스를
                사용하였다고 밀리언픽이 객관적 자료에 의거 합리적으로 판단했을
                경우
              </p>
              <p>
                4. 밀리언픽이 회원자격을 상실시키는 경우에는 회원등록을
                말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에
                최소한 30일 이상의 기간을 정하여 소명할 기회를
                부여합니다.(회원의 소재불명 등으로 인해 소명 기회의 제공이
                어려운 경우는 밀리언픽의 판단으로 회원등록을 말소할 수
                있습니다.)
              </p>
              <br />
              <p>제 8 조 (회원에 대한 통지)</p>
              <br />
              <p>
                1. 밀리언픽이 회원에 대한 통지를 하는 경우, 회원이 밀리언픽과
                미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
              </p>
              <p>
                2. 밀리언픽은 불특정다수 회원에 대한 통지의 경우 1주일 이상
                밀리언픽 사이트에 게시함으로서 개별 통지에 갈음할 수 있습니다.
                다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
                대하여는 개별통지를 합니다.
              </p>
              <br />
              <p>제 9 조 (계약의 성립)</p>
              <br />
              <p>
                밀리언픽은 수입대행형 서비스 이용과 관련하여 회원과 아래의
                절차를 통해 수입대행 계약을 체결합니다.
              </p>
              <p>1. 수입대행형 서비스</p>
              <p>
                가. 회원이 밀리언픽 사이트 내에 있는 상품에 대하여 수입대행에
                따르는 비용결제를 통해 수입대행을 의뢰하고 이에 대해 밀리언픽이
                결제확인 메일을 회원에게 송부 또는 밀리언픽 사이트에서 이를
                통보하여 회원에게 도착된 시점에 회원과 밀리언픽 간에
                수입대행계약이 성립됩니다.
              </p>
              <p>
                나. 밀리언픽과 회원의 계약성립은 해외 사이트에서의 구매 및 결제,
                국내 수취처 인도까지의 전 과정을 밀리언픽에게 일임하였음을
                의미합니다.
              </p>
              <p>
                2. 밀리언픽은 회원의 수입대행 계약 요청에 대해 다음 각 호에
                해당하면 승낙하지 않을 수 있습니다.
              </p>
              <p>가. 신청내용에 허위, 기재누락, 오기가 있는 경우</p>
              <p>
                나. 회원이 관련법령 및 동 약관에서 금지하는 재화에 대해 수입대행
                또는 운송을 요청하는 경우
              </p>
              <p>
                다. 기타 수입대행 신청을 승낙하는 것이 밀리언픽 기술상 현저히
                지장이 있다고 판단하는 경우
              </p>
              <br />
              <p>제 10 조 (서비스 대상 물품)</p>
              <br />
              <p>
                밀리언픽은 회원이 자가 사용의 목적으로 구매하여 수입하는
                경우에만 서비스를 제공하며, 한편 아래의 경우 고객의 동의를 구한
                후 한국으로 발송하거나 해당 발송자에게로 반송할 수 있으며 회원의
                귀책사유로 인해 회원의 소재가 불명확한 경우 밀리언픽은 당해
                물품을 발송인에게 반송하고 이를 회원에게 통보하며, 소요비용은
                회원으로부터 정산하거나 밀리언픽의 임의대로 물품을 처리하여
                충당할 수 있습니다
              </p>
              <p>
                가. 동물, 금,은괴, 화폐, 의약품, 무기류, 인체의 일부,
                포르노그래피, 정밀금속, 석재류, 냉장보관물품, 냉동 또는 냉장을
                요하는 물품 및 폭발물, 가연성 위험물 등
              </p>
              <p>나. 수입, 수출 불가 / 항공운송 불가 품목일 경우</p>
              <p>
                다. 송장(Invoice) 부실기재, 포장불량 등으로 본 약관에 따른
                서비스를 제공하기에 적절하지 않다고 밀리언픽이 판단하는 경우
              </p>
              <p>
                라. 기타 관련법령에 의하여 수출입이 제한되는 물품을 대상으로 한
                경우
              </p>
              <p>
                회사는 다음 각 호를 포함하여 관계 법령에 따라 수출입이 금지된
                물품을 취급하지 않습니다
              </p>
              <p>
                가. 헌법질서를 문란하게 하거나 공공의 안녕질서 또는 풍속을
                해치는서적 간행물, 도화, 영화, 음반, 비디오물, 조각물 기타 이에
                준 하는 물품
              </p>
              <p>나. 정부의 기밀을 누설하거나 첩보활동에 사용되는 물품</p>
              <p>다. 화폐, 채권 기타 유가증권의 위조품,변조품 또는 모조품</p>
              <br />
              <p>제 11 조 (지급방법)</p>
              <br />
              <p>
                “밀리언픽” 서비스 이용에 대한 대금지급방법은 다음 각 호의 방법
                중 가용한 방법으로 할 수 있습니다.
              </p>
              <p>가. 폰뱅킹, 인터넷 뱅킹 등의 계좌이체</p>
              <p>나. 신용카드 결제</p>
              <p>다. 온라인 무통장 입금</p>
              <p>라. 쿠폰 및 회사가 정하는 마일리지</p>
              <p>마. 기타 밀리언픽이 인정하는 결제수단</p>
              <br />
              <p>제 12 조(수신확인통지, 배송신청 변경 및 취소)</p>
              <br />
              <p>
                1. “밀리언픽”은 회원의 운송 및 수입대행신청이 있는 경우 회원에게
                수신확인통지를 합니다.
              </p>
              <p>
                2. 수신확인통지를 받은 회원은 의사표시의 불일치 등이 있는 경우에
                수신확인통지를 받은 후 즉시 배송신청 변경 및 취소를 요청할 수
                있습니다.
              </p>
              <p>
                3. 밀리언픽 주소에 도달한 물품이 제10조 각호에 해당하는 경우에는
                당해 물품에 대한 배송 계약을 해지할 수 있으며, 이 경우 물품의
                반송 등 사후조치를 위해 소요되는 일체의 비용은 회원의 부담으로
                합니다.
              </p>
              <br />
              <p>제 13 조 (서비스별 요금 결제, 재화등의 공급 및 보관)</p>
              <br />
              <p>1. 수입대행형 서비스</p>
              <p>
                가. 회원이 밀리언픽에서 상품의 수입대행을 의뢰할 경우에는
                밀리언픽 내 각 상품에 구현된 물품의 가격을 제11조 각 항의 형태로
                결제해야 합니다.
              </p>
              <p>
                나. 밀리언픽과 회원간의 수입대행계약에 의해 회사 주소에 입고된
                물품에 대해 검수 후 국내의 수취처까지 배송하여 회원이 수령할 수
                있도록 합니다.
              </p>
              <p>
                다. 밀리언픽은 밀리언픽 상품의 수입대행을 수행함에 따른 전체
                금액을 회원이 결제하기 전에 각각의 거래계약 유형별로 총지불가격,
                운송료형태로 구분하여 회원에게 고지합니다.
              </p>
              <p>
                라. 밀리언픽에 명기된 가격은 해외 인터넷쇼핑몰 등에서의 물품
                구입가격과 해외 밀리언픽 물류센터까지의 운송료, Sales Tax,
                국제운송료, 수입관세, 수입부가세, 기타세금에 밀리언픽의
                수입대행수수료가 모두 포함된 가격이며 회원이 이외에 추가적으로
                부담하는 금액은 없습니다. 다만 수입관부가세가 포함되지 않음으로
                명기된 개별상품에 대해서는 수입통관시 회원이 관부가세를 추가
                납부해야 할 가능성이 있으며 이 경우 회사는 관세사를 통해서
                회원에게 미리 고지하도록 합니다.
              </p>
              <p>
                마. 밀리언픽은 회원이 밀리언픽에서 수입대행을 의뢰한 상품에
                대해서 회원의 대금 결제단위를 기준으로 포장하여 배송함을
                원칙으로 합니다. 다만 결제 단위당 복수의 상품의 경우 각 상품의
                해외 인터넷쇼핑몰 등 공급자가 다를 경우 혹은 공급자가 같더라도
                물품도착일이 다를 경우에는 이를 밀리언픽 해외 물류센터에
                도착하는 순서대로 배송할 수 있습니다.
              </p>
              <br />
              <p>제 14 조 (운송 및 통관)</p>
              <br />
              <p>1. 운송</p>
              <p>
                가. 밀리언픽은 운송계약대행자로서 밀리언픽의 운송제휴사가
                해외물류센터에서부터 대한민국까지의 항공운송, 수입통관, 회원이
                지정한 수취처까지의 국내배송의 용역을 제공합니다.
              </p>
              <p>
                나. 상기 “가”항에서의 해외물류센터에서 회원이 지정한
                수취처까지의 운송구간에서 물품의 파손 등 하자가 발생하였을 때,
                수입대행형 거래계약 유형에 해당하는 수입대행형 서비스에서는
                수입대행가격 및 국제운송료를 포함한 총 금액을 보상합니다.
              </p>
              <p>2. 통관</p>
              <p>
                가. 밀리언픽은 개인이 자가사용 목적으로 수입하는
                개인수입통관원칙에 의거, 회원을 납세의무자로 하고 수입요건을
                구비하여 밀리언픽의 운송제휴사를 통하여 통관절차를 수행합니다.
              </p>
              <br />
              <p>제 15 조 (취소)</p>
              <br />
              <p>
                1. 회원이 밀리언픽에 구매취소(청약철회)를 요청할 경우 주문하신
                당일에 밀리언픽에 그 취소를 요청하시면 취소가 가능하며 이 때
                회원은 어떠한 비용도 부담하지 않습니다.
              </p>
              <p>
                2. 구매 익일부터 해외물류센터에 입고되기 전까지 회원이
                취소요청을 하면 밀리언픽에서 해외 수급처 에서 물품을 발송했는지
                여부를 확인하여 회원의 취소가능여부를 알려드립니다. 이 때 물품이
                해외 수급처에서 발송되기 이전이면 취소가 가능하고 그 이후이면 본
                조 제3항에 의해 처리됩니다.
              </p>
              <p>
                3. 주문하신 물품이 해외 사이트에서 배송이 시작되고
                해외물류센터에 입고되어 한국으로 발송되기 전에도 취소는 가능하나
                이 때 해외사이트로부터 해외물류센터까지의 물류비, 창고비,
                작업비, 해외사이트로의 반품물류비용은 회원이 부담합니다.
              </p>
              <p>
                4. 물품이 해외물류센터에서 한국으로 발송된 이후에는 회원의
                취소가 불가능합니다.
              </p>
              <p>
                5. 밀리언픽 사이트의 상품설명 상세페이지 상에 특정사유에 의하여
                “구매 후 취소불가”로 표시되 물품은 구매 후 취소가 불가능합니다.
              </p>
              <br />
              <p>제 16 조 (반품 및 교환)</p>
              <br />
              <p>
                1. 회사가 회원이 수입대행을 신청한 재화를 품절 등의 사유로
                인도할 수 없을 때에는 그 사유를 회원에게 통지하고 사전에 재화
                대금을 받은 경우에는 대금을 받은 날로부터 3 영업일 이내에 환불에
                필요한 조치를 합니다.
              </p>
              <p>
                2. 회원이 구매한 재화 등에 대하여 회사와 해외공급업체 사이에
                매매계약이 체결되어 해당 재화가 회사의 해외 현지 수령 장소로
                발송된 이후 회원이 반품 또는 교환을 한 경우, 해외 현지 운송료,
                해외 현지 반송료는 회원이 부담합니다.{" "}
              </p>
              <p>
                3. 회원이 구매한 재화 등에 대하여 회사가 회원의 국내 수령 장소로
                발송한 이후 회원이 반품 또는 교환을 신청하는 경우 최초배송비 및
                회사가 지정하는 반품(교환)수수료 및 회원을 위하여 회사가 대납한
                관부가세는 회원이 부담합니다.
              </p>
              <p>
                4. 회원이 구매한 재화 등에 대하여 회사가 한국에 재고를 보유하여
                상품을 출고한 이후, 회원이 청약철회 등을 하는 경우, 반송비용은
                회원이 부담합니다.
              </p>
              <p>
                5. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과
                다르게 이행되어 청약철회 등을 하는 경우 재화 반품 및 교환에
                필요한 비용은 회사가 부담합니다.
              </p>
              <p>
                6. 회사는 회원의 청약철회 등에 따라 재화 등을 반품 받은 경우
                3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 다만,
                환급액에서 회원이 부담해야 하는 반송 비용 등을 공제할 수
                있습니다.
              </p>
              <p>
                7. 반품 및 교환의 경우 회원은 회사가 지정하는 주소로 반품 및
                교환신청상품을 발송하며 회사는 해당 상품에 대한 검수 이후
                하자여부를 판단합니다.
              </p>
              <p>8. 반품, 교환이 불가능한 경우</p>
              <p>가. 상품배송 완료 후 7일이 지난 상품</p>
              <p>나. 고객부주의로 인한 상품훼손, 오염</p>
              <p>다. 상품사용으로 인하여 상품의 재판매가 불가능한 경우</p>
              <p>
                라. 모니터상의 색상과 실제 상품의 색상의 작은 불일치로 인한 사유
              </p>
              <p>
                마. 상품 특성 또는 구매처의 특성상 미세한 주름, 스크래치, 올풀림
                등이 있을 수 있으며 상품상세페이지에 해당 내용이 이미 고지된
                경우
              </p>
              <p>
                바. 회원에게 최초 배송시에 포함되었던 구성품이 누락 또는 훼손된
                경우
              </p>
              <br />
              <p>제 17 조 (긴급조치)</p>
              <br />
              <p>
                1. 회원이 위법, 불법 또는 부당한 목적을 위해 서비스를 이용한다고
                밀리언픽이 판단하는 때에는 밀리언픽은 관련 물품의 수취나 배송을
                거절할 권리를 가집니다.
              </p>
              <p>
                2. 관할관청 또는 당국에 의해 밀리언픽에 의해 서비스되는 물품에
                대해 제재를 받았을 때 밀리언픽은 해당 물품을 관할관청 또는
                당국에 인도하는 것을 원칙으로 합니다. 이로 인하여 회원이 손해를
                입었다고 할지라도 해당 손해에 대해서 밀리언픽은 일체의 책임을
                지지 않으며 또한 회원은 해당 물품에 대한 서비스 이용요금 및
                관련비용 등의 지급의무를 면하지 아니합니다.
              </p>
              <p>
                3. 밀리언픽 주소로 배송된 물품에 악취, 액체누수 그 외 이상이
                있다고 인정됐을 경우 및 기타 긴급을 필요로 하고 정당한 이유가
                있다고 인정이 됐을 경우 밀리언픽은 회원에게 해당사실을 통지하고
                해당 물품을 별도 장소로 이동 보관하는 등 임시조치를 취할 수
                있습니다. 이로 인해 발생하는 추가비용은 회원이 부담하여야 하며
                또한 회원에게 손해가 발생하더라도 해당 손해에 대하여 밀리언픽는
                일체의 책임을 지지 않습니다.
              </p>
              <br />
              <p>제 18 조 (개인정보보호)</p>
              <br />
              <p>
                1. 밀리언픽은 회원의 정보수집 시 구매계약 이행에 필요한 최소한의
                정보를 수집합니다. 다음 사항을 필수 사항으로 하며 그 외 사항은
                선택사항으로 합니다.
              </p>
              <p>가. 성명</p>
              <p>
                나. 개인통관고유부호(회원의 경우) 또는 외국인등록번호(관세법
                근거, 주문서 작성 시)
              </p>
              <p>다. 주소</p>
              <p>라. 전화번호</p>
              <p>마. 희망ID(회원의 경우)</p>
              <p>바. 비밀번호(회원의 경우)</p>
              <p>사. 전자우편주소(또는 이동전화번호)</p>
              <p>
                2. 밀리언픽은 회원의 개인식별이 가능한 개인정보를 수집하는
                때에는 반드시 당해 회원의 동의를 받습니다.
              </p>
              <p>
                3. 제공된 개인정보는 당해 회원의 동의없이 목적 외의 이용이나
                제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 밀리언픽이
                집니다. 다만, 다음의 경우에는 예외로 합니다.
              </p>
              <p>
                가. 배송업무상 배송업체에게 배송 및 통관에 필요한 최소한의
                회원의 정보(성명, 주소, 전화번호, 주민등록번호 등)를 알려주는
                경우
              </p>
              <p>
                나. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서
                특정 개인을 식별할 수 없는 형태로 제공하는 경우
              </p>
              <p>다. 재화 등의 거래에 따른 대금정산을 위하여 필요한 경우</p>
              <p>라. 도용방지를 위하여 본인확인에 필요한 경우</p>
              <p>
                마. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는
                경우
              </p>
              <p>
                4. 밀리언픽이 본 조 제2항과 제3항에 의해 회원의 동의를 받아야
                하는 경우에는 개인정보관리 책임자의 신원 (소속, 성명 및
                전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에
                대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의
                내용) 등 정보통신망이용촉진등에관한법률 제22조 제2항이 규정한
                사항을 미리 명시하거나 고지해야 하며 회원은 언제든지 이 동의를
                철회할 수 있습니다.
              </p>
              <p>
                5. 회원은 언제든지 밀리언픽이 가지고 있는 자신의 개인정보에 대해
                열람 및 오류정정을 요구할 수 있으며 ”밀리언픽”는 이에 대해
                지체없이 필요한 조치를 취할 의무를 집니다. 회원이 오류의 정정을
                요구한 경우에는 밀리언픽은 그 오류를 정정할 때까지 당해
                개인정보를 이용하지 않습니다.
              </p>
              <p>
                6. 밀리언픽은 개인정보 보호를 위하여 관리자를 한정하여 그 수를
                최소화하며 신용카드, 은행계좌 등을 포함한 회원의 개인정보의
                분실, 도난, 유출, 변조 등으로 인한 회원의 손해에 대하여 모든
                책임을 집니다.
              </p>
              <p>
                7. 밀리언픽 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의
                수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를
                지체없이 파기합니다.
              </p>
              <p>
                8. 밀리언픽은 회원에게 밀리언픽이 제공하는 다양한 서비스의
                질적향상 및 신규 서비스등을 위하여 회원의 개인식별이 가능한
                개인정보를 회원의 동의를 득하여 이을 수집하여
                판촉활동(이메일광고, 모바일광고, 텔레마케팅광고 등)에 이용할 수
                있습니다.
              </p>
              <br />
              <p>제 19 조 (밀리언픽의 의무)</p>
              <br />
              <p>
                1. 밀리언픽은 법령과 이 약관이 금지하거나 미풍양속에 반하는
                행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고,
                안정적으로 재화 용역을 제공하는데 최선을 다하여야 합니다.
              </p>
              <p>
                2. 밀리언픽은 회원이 안전하게 인터넷 서비스를 이용할 수 있도록
                회원의 개인정보 (신용정보 포함) 보호를 위한 보안 시스템을
                갖추어야 합니다.
              </p>
              <p>
                3. 밀리언픽은 상품이나 용역에 대하여 「표시 광고의 공정화에 관한
                법률」 제3조 소정의 부당한 표시 광고 행위를 함으로써 회원이
                손해를 입은 때에는 이를 배상할 책임을 집니다.
              </p>
              <p>
                4. 밀리언픽은 회원이 원하지 않는 영리목적의 광고성 전자우편을
                발송하지 않습니다.
              </p>
              <br />
              <p>제 20 조 (회원의 ID 및 비밀번호에 대한 의무)</p>
              <br />
              <p>
                1. 제18조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은
                회원에게 있습니다.
              </p>
              <p>
                2. 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
                안됩니다.
              </p>
              <p>
                3. 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
                있음을 인지한 경우에는 바로 밀리언픽에 통보하고 밀리언픽의
                안내가 있는 경우에는 그에 따라야 합니다.
              </p>
              <br />
              <p>제 21 조 (회원의 의무)</p>
              <br />
              <p>회원은 다음 행위를 하여서는 안됩니다.</p>
              <p>1. 신청 또는 변경 시 허위 내용의 등록</p>
              <p>2. 타인의 정보 도용</p>
              <p>3. 밀리언픽에 게시된 정보의 변경</p>
              <p>
                4. 밀리언픽이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의
                송신 또는 게시
              </p>
              <p>5. 밀리언픽 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
              <p>
                6. 밀리언픽 기타 제3자의 명예를 손상시키거나 업무를 방해하는
                행위
              </p>
              <p>
                7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 미풍양속에 반하는
                정보를 몰에 공개 또는 게시하는 행위 또는 타인을 비방하는 게시
                행위(단, 이 경우 밀리언픽은 임의적으로 게시물을 삭제할 수
                있습니다)
              </p>
              <br />
              <p>제 22 조 (저작권의 귀속 및 이용제한)</p>
              <br />
              <p>
                1. 밀리언픽이 작성한 저작물에 대한 저작권 기타 지적재산권은
                밀리언픽에 귀속합니다.
              </p>
              <p>
                2. 회원은 밀리언픽을 이용함으로써 얻은 정보 중 밀리언픽에게
                지적재산권이 귀속된 정보를 밀리언픽의 사전 승낙없이 복제, 송신,
                출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나
                제3자에게 이용하게 하여서는 안됩니다.
              </p>
              <p>
                3. 밀리언픽은 약정에 따라 회원에게 귀속된 저작권을 사용하는 경우
                당해 회원에게 통보하여야 합니다.
              </p>
              <br />
              <p>제 23 조 (분쟁해결)</p>
              <br />
              <p>
                1. 밀리언픽은 회원이 제기하는 정당한 의견이나 불만을 반영하고 그
                피해를 보상처리하기 위하여 피해보상 처리기구를 설치, 운영합니다.
              </p>
              <p>
                2. 밀리언픽은 회원으로부터 제출되는 불만사항 및 의견은
                우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한
                경우에는 회원에게 그 사유와 처리일정을 즉시 통보합니다.
              </p>
              <p>
                3. 밀리언픽과 회원간에 발생한 전자상거래 분쟁과 관련하여 회원의
                피해구제신청이 있는 경우에는 공정거래위원회 또는 시,도지사가
                의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
              </p>
              <br />
              <p>제 24 조 (재판권 및 준거법)</p>
              <br />
              <p>
                1. 밀리언픽과 회원간에 발생한 전자상거래 분쟁에 관한 소송은 제소
                당시의 회원의 주소에 의하고, 주소가 없는 경우에는 거소를
                관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 회원의
                주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
                민사소송법상의 관할법원에 제기합니다.
              </p>
              <p>
                2. 밀리언픽과 회원간에 제기된 전자상거래 소송에는 한국법을
                적용합니다.
              </p>
              <p>본 약관은 2021년 1월 1일부터 시행합니다.</p>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default TermsOfService;
