import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { inputNumberWithComma } from "../../../common/js/common";
import { BREAK_POINT_PC, StyledLink } from "../../../common/style/styles";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

// 주문, 주문상세에서 아이템 정보
const OrderItem = ({ items, country, pointRate }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <>
      {items.map(
        (item, index) =>
          item.country === country && (
            <Box key={index}>
              {/* 상품 정보 */}
              <Box display="flex" margin="1rem 1.2rem">
                <StyledLink
                  to={`/items/${item.item_id}/${item.item_detail_id}/${item.item_show_option_id}`}
                >
                  <img
                    style={{
                      backgroundImage: `url(${defaultImg})`,
                      width:
                        windowsize.width >= BREAK_POINT_PC ? "110px" : "62px",
                      height:
                        windowsize.width >= BREAK_POINT_PC ? "160px" : "90px",
                    }}
                    src={item.images}
                    srcSet={item.images}
                    // loading="lazy"
                  />
                </StyledLink>
                {/* 상품 옵션정보 */}
                <Box
                  flex="1"
                  display="flex"
                  flexDirection="column"
                  margin={
                    windowsize.width >= BREAK_POINT_PC ? "0 2rem" : "0 0.7rem"
                  }
                >
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                    }
                    fontWeight="600"
                    marginBottom="0.2rem"
                  >
                    {item.brand_name}
                  </Typography>
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    marginBottom="0.2rem"
                    noWrap={true}
                    width={
                      windowsize.width >= BREAK_POINT_PC
                        ? ((windowsize.width * 9) / 12) * 0.7
                        : windowsize.width * 0.7
                    }
                  >
                    {item.item_name}
                  </Typography>
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                    }
                    fontWeight="500"
                    marginBottom="0.2rem"
                  >
                    {item.color} /{" "}
                    {item.option_name == "컬러" ? "one size" : item.size} /{" "}
                    {item.quantity}개
                  </Typography>

                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="600"
                  >
                    {inputNumberWithComma(item.sale_price)}원
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </Box>
          )
      )}
    </>
  );
};

export default OrderItem;
