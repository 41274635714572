import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import {
  inputNumberWithComma,
  selectImageThumbnail,
  sumOfItems,
} from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import {
  borderColor,
  BREAK_POINT_PC,
  grey4,
  grey5,
  primary,
} from "../../../common/style/styles";
import OrderItem from "./order_item";

// 주문, 주문상세에서 아이템 정보
const OrderItemList = ({ country, items, pointRate }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);

  return (
    <Box>
      <Typography
        variant={windowsize.width >= BREAK_POINT_PC ? "h5" : "subtitle2"}
        fontWeight="600"
        margin={
          windowsize.width >= BREAK_POINT_PC ? "2rem 1rem" : "1.2rem 1rem"
        }
      >
        주문상품({items.length})
      </Typography>
      {country.map((value, index) => (
        <Box key={index}>
          <Box sx={{ backgroundColor: grey4 }}>
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "body1"}
              fontWeight="500"
              padding="0.8rem 1rem"
            >
              {value === "스페인" ? "🇪🇸" : value === "영국" ? "🇬🇧" : "🇫🇷"}{" "}
              {value} 출고
            </Typography>
          </Box>
          <Box border={`1px solid ${borderColor}`}>
            <OrderItem
              items={selectImageThumbnail(items)}
              country={value}
              pointRate={pointRate}
            />
            {/* 나라별 가격 계산 */}
            <Box
              margin={windowsize.width >= BREAK_POINT_PC ? "1rem" : "0.8rem"}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom={
                  windowsize.width >= BREAK_POINT_PC ? "1rem" : "0.5rem"
                }
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                >
                  상품가격
                </Typography>
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                  fontWeight="500"
                >
                  {inputNumberWithComma(
                    sumOfItems(items.filter((item) => item.country == value))
                  )}
                  원
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                >
                  배송비
                </Typography>
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                  fontWeight="500"
                >
                  10,000원
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default OrderItemList;
