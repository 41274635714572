import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import { primary, borderColor } from "../../../common/style/styles";
// import ClassicEditor from "./editor";

const EditData = ({
  submenu,
  data,
  handleChange,
  handleUploadImage,
  handleSubmit,
}) => {
  // 새로 추가한 이미지를 처리하기 위한 어댑터
  const customUploadAdapter = (loader) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then((file) => {
            data.append("name", file.name);
            data.append("file", file);

            handleUploadImage(file).then((name) => {
              resolve({
                default: name,
              });
            });
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    };
  }

  return (
    <>
      <Box
        margin="8px 0"
        display="flex"
        justifyContent="space-between"
        border={`1px solid ${borderColor}`}
        padding="4px"
      >
        <Box display="flex">
          <Box margin="0 8px" padding="0 8px">
            <Checkbox
              checked={data.is_banner}
              onChange={() => handleChange("is_banner", !data.is_banner)}
            />
            배너로 지정
          </Box>
          <Box margin="0 8px" padding="0 8px">
            <Checkbox
              checked={!data.is_show}
              onChange={() => handleChange("is_show", !data.is_show)}
            />
            비공개
          </Box>
        </Box>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleSubmit}
        >
          {submenu === "faq" ? "FAQ" : "공지사항"} 저장
        </Button>
      </Box>
      {data.is_banner ? (
        <Box border={`1px solid ${borderColor}`} padding="4px" margin="8px 0">
          <Typography variant="h6" margin="0 0 0 8px">
            배너 이미지 지정
          </Typography>
          <Box margin="8px 0">
            <Button variant="outlined" style={{ borderRadius: "unset" }}>
              <label htmlFor="banner">이미지 파일 선택</label>
              <input
                type="file"
                accept="image/*"
                id="banner"
                multiple
                onChange={(event) => {
                  handleChange("image", event.target.files[0]);
                }}
              />
            </Button>
            <img
              name="홈 배너 이미지"
              src={
                data.image &&
                (typeof data.image === "object"
                  ? URL.createObjectURL(data.image)
                  : data.image)
              }
              style={{ width: "300px" }}
            />
          </Box>
        </Box>
      ) : null}
      <TextField
        value={data.title}
        name="title"
        onChange={(event) => {
          handleChange("title", event.target.value);
        }}
        placeholder="제목을 입력해주세요."
        style={{ width: "100%", margin: "8px 0" }}
      />
      <CKEditor
        editor={ClassicEditor}
        data={data.content}
        config={{
          extraPlugins: [uploadPlugin],
        }}
        onChange={(event, editor) => {
          handleChange("content", editor.getData());
        }}
      />
    </>
  );
};

export default EditData;
