import { Box, Button } from "@mui/material";
import React from "react";
import { StyledLink } from "../../../common/style/styles";
import { borderColor } from "../../../common/style/styles";

const OptionHeader = ({ handleChangePaging, handleExportExcel }) => {
  return (
    <Box display="flex" border={"1px solid " + borderColor} padding="8px">
      <Box>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={() => handleChangePaging(100)}
        >
          100건씩 보기
        </Button>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={() => handleChangePaging(500)}
        >
          500건씩 보기
        </Button>
      </Box>
      <Button
        variant="outlined"
        style={{ borderRadius: "unset", margin: "0 8px" }}
        onClick={handleExportExcel}
      >
        엑셀 내려받기
      </Button>
    </Box>
  );
};

export default OptionHeader;
