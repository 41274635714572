import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getDataFromServer } from "../../../common/network/network";
import DataList from "../components/data_list";
import EditData from "../components/edit_data";
import MainMenu from "../../../common/components/main_menu";
import { preproccessingForDataTable } from "../../../common/js/common";
import columnData from "../../../common/json/detail_data_columns.json";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../../../common/style/styles";

// 사용자 데이터 상세보기, 추가, 수정
const Show = () => {
  const { subMenu, mode, id } = useParams();
  const { pathname } = useLocation();
  const columns = columnData[`/admin/users/${subMenu}/${mode}/`];
  const [rows, setRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadling, setLoading] = useState(false);

  useEffect(() => {
    if (mode == "create") {
      return;
    }
    setLoading(true);
    // 로그인 정보 확인
    getDataFromServer(`/admin/users/${subMenu}/${id}`)
      .then((response) => {
        setIsAdmin(true);
        setRows([...response.rows]);
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {isAdmin && (
        <Box display="flex">
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {mode === "show" && id && (
              <DataList columns={columns} rows={rows} />
            )}
            {(mode === "edit" || mode === "create") && id && (
              <EditData columns={columns} />
            )}
          </Box>
        </Box>
      )}
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={loadling}
      />
    </>
  );
};

export { Show as UserDetail };
