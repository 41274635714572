import { Box, Modal, Typography, Button, Divider } from "@mui/material";
import React, { useState } from "react";
import {
  BREAK_POINT_PC,
  CenterModalStyle,
  grey4,
} from "../../../common/style/styles";
import CloseIcon from "@mui/icons-material/Close";
import Address from "../../../common/components/address";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AddressForm from "../../../common/components/address_form";
import AddIcon from "@mui/icons-material/Add";

const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

const AddressModal = ({
  isOpened,
  handleClickModal,
  addressList,
  handleChangeAddress,
  handleEditAddress,
}) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [isEditing, setIsEditing] = useState(false);
  //추가, 수정하기 위해 선택된 주소
  const [selectedAddress, setSelectedAddress] = useState({ is_defalut: false });

  //  배송지 추가, 수정으로 변경
  const handleClickEdit = (id) => {
    setIsEditing((prev) => !prev);
    if (id && id !== -1) {
      setSelectedAddress(addressList.find((address) => address.id === id));
    } else {
      setSelectedAddress({});
    }
  };

  // 배송지 추가, 수정에서 배송지 정보 입력값 변경
  const handleChangeInput = (event) => {
    const { name, value, checked } = event.target;
    // console.log(name, value, checked);
    setSelectedAddress((prev) => {
      return {
        ...prev,
        [name]: name === "is_default" ? checked : value,
      };
    });
  };

  // 배송지 추가, 수정에서 주소 수정
  const handleChangeInputAddress = (data) => {
    setSelectedAddress((prev) => {
      return {
        ...prev,
        [data[0]["name"]]: data[0]["value"],
        [data[1]["name"]]: data[1]["value"],
      };
    });
  };

  // 추가,수정버튼 클릭
  const handleSubmit = () => {
    // 주소 정보를 모두 작성했는지 확인
    const hasEmptyTextFiled = Object.keys(selectedAddress).length < 7;
    // console.log(hasEmptyTextFiled);
    if (hasEmptyTextFiled) {
      alert("주소 정보를 모두 입력해주세요.");
      return;
    }
    // 핸드폰번호 형식이 맞는지 확인
    if (!regPhone.test(selectedAddress.cellphone)) {
      alert("올바른 전화번호 형식으로 입력해주세요.");
      return;
    }

    handleEditAddress(selectedAddress);
    handleClickEdit();
  };

  return (
    <Modal
      open={isOpened}
      onClose={handleClickModal}
      // sx={{ pointerEvents: processing ? "none" : "auto" }}
    >
      <Box
        sx={CenterModalStyle}
        width={windowSize.width >= BREAK_POINT_PC ? "500px" : "350px"}
        padding="0.6rem 1.2rem"
        height={windowSize.height * 0.6}
        overflow="scroll"
      >
        {!isEditing ? (
          <Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
                flex="9"
                textAlign="center"
              >
                배송지 선택
              </Typography>
              <CloseIcon
                sx={{ flex: "1", cursor: "pointer" }}
                onClick={handleClickModal}
              />
            </Box>
            {addressList.map((address) => (
              <Box key={address.id} margin="0.5rem 0">
                <Address
                  address={address}
                  handleChangeAddress={handleChangeAddress}
                  handleClickEdit={handleClickEdit}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            <Box display="flex" alignItems="center">
              <KeyboardArrowLeftIcon
                sx={{ flex: "1", cursor: "pointer" }}
                onClick={handleClickEdit}
              />
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
                flex="9"
                textAlign="center"
              >
                배송지 {selectedAddress.id ? "수정" : "추가"}
              </Typography>
            </Box>
            <Box margin="3rem 0">
              <AddressForm
                address={selectedAddress}
                handleChangeInput={handleChangeInput}
                handleChangeAddress={handleChangeInputAddress}
                showIsDefalt={true}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                variant="outlined"
                sx={{ borderRadius: 0, borderColor: "black", color: "black" }}
                onClick={handleSubmit}
              >
                <Typography
                  variant={
                    windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                  padding="0.2rem 0.5rem"
                >
                  {selectedAddress.id ? "수정" : "추가"}하기
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
        {!isEditing ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ background: grey4, cursor: "pointer" }}
            margin="1.2rem 0"
            padding="0.5rem 0"
            onClick={() => {
              handleClickEdit(-1);
            }}
          >
            <AddIcon
              sx={{
                fontSize: windowSize.width >= BREAK_POINT_PC ? "20px" : "12px",
              }}
            />
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              fontWeight="500"
            >
              배송지 추가
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};

export default AddressModal;
