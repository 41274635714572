import {
  Box,
  Button,
  Divider,
  InputBase,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import columnData from "../../../common/json/columns.json";
import {
  getDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import {
  convertDateTime,
  preproccessingForDataTable,
} from "../../../common/js/common";
import { useMutation } from "react-query";
import DataTable from "../../../common/components/data_table";
import {
  lightgrey,
  primary,
  CenterModalStyle,
  borderColor,
  secondary,
} from "../../../common/style/styles";
import {
  itemListTimeStampState,
  windowSizeState,
} from "../../../common/state/state";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

// 이벤트 상품 추가 모딜
const CurationItemAddModal = ({
  id,
  isOpened,
  handleModalChange,
  handleAddSelectedItems,
}) => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );
  // 테이블 행 데이터
  const [columns, setColumns] = useState([...columnData["/admin/items/item"]]);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  // url 파라미터
  const [params, setParams] = useState({
    limit: 15,
    offset: 0,
    orderby: "item_id desc",
    filter: null,
    status: "total",
  });
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  // 검색한 데이터의 총 갯수
  const [count, setCount] = useState(0);
  // 현재페이지, 마지막페이지 정보
  const [page, setPage] = useState({ total: 1, current: 1 });
  const [isAdmin, setIsAdmin] = useState(false);
  //더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 페이징 숫자 변경 (100개, 500개씩 보기)
  const handleChangePaging = (paging) => {
    setParams({ ...params, limit: paging });
  };

  // 특정 페이지 이동
  const handleChangeCurrentPage = () => {
    // 이동하려는 페이지 숫자가 전체 페이지를 넘어섰을 경우 리턴
    if (page.current > page.total) {
      return;
    }
    setParams({
      ...params,
      offset: params.limit * (page.current - 1),
    });
  };

  // 페이지 변경
  const handlePageInputChange = (event) => {
    // 페이지 입력이 숫자가 아니거나 0보다 작으면 리턴
    if (!event.target.value || event.target.value <= 0) {
      return;
    }
    setPage({ ...page, current: event.target.value });
  };

  // params가 변경되었을 때 서버로부터 데이터 가져오기
  const paramsMutation = useMutation("AdminPageItemList", () => {
    return getDataFromServer(
      `/admin/items?limit=${params.limit}&offset=${params.offset}&orderby=${params.orderby}
            &filter=${params.filter}&status=${params.status}&timestamp=${itemListTimeStamp}`
    );
  });

  // params 에 따라 테이블 데이터 변경
  useEffect(() => {
    if (!isOpened) {
      return;
    }

    setLoading(true);

    paramsMutation.mutate(params, {
      onSuccess: (data, variables, context) => {
        setIsAdmin(true);

        const newRows = preproccessingForDataTable(
          "/admin/items/item",
          data.rows
        );

        setRows([...newRows]);
        setCount(data.count);
        setPage({
          ...page,
          total: Math.ceil(data.count / params.limit),
        });
        setSelectedIDs([]);
      },
      onError: (error, variable, context) => {
        setIsAdmin(false);
      },
    });

    setLoading(false);
  }, [isOpened, params]);

  const hadleModalClose = () => {
    setSelectedIDs([]);
    handleModalChange();
  };

  // 이벤트 상품 추가
  const handleAddCurationItems = () => {
    if (hasClick) {
      return;
    }
    if (!selectedIDs.length) {
      alert("추가할 이벤트 상품을 선택해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);
    setHasClick(true);

    handleAddSelectedItems(
      rows.filter((value) => selectedIDs.includes(value.id))
    );
    handleModalChange();
    setSelectedIDs([]);

    setLoading(false);
    setProcessing(false);
    setHasClick(false);
  };

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <>
      {isAdmin && (
        <Modal
          open={isOpened}
          onClose={hadleModalClose}
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <Box sx={CenterModalStyle} width="70%">
            <Box padding="16px">
              <Box margin="16px 0">
                <Typography>이벤트 상품 추가</Typography>
                <Divider />
              </Box>
              <Box
                border={"1px solid " + borderColor}
                sx={{ overflow: "auto" }}
                maxHeight={(windowSize.height * 8) / 13}
              >
                <DataTable
                  columns={columns}
                  rows={rows}
                  selectedIDs={selectedIDs}
                  //   handleChangeOrderby={handleChangeOrderby}
                  handleClickCheckBox={handleClickCheckBox}
                />
                <Box
                  style={{
                    backgroundColor: `${lightgrey}`,
                    bottom: "0px",
                    left: "0px",
                    position: "sticky",
                    zIndex: 110,
                    padding: "8px",
                  }}
                >
                  {count}개 중 {params.offset + 1} -{" "}
                  {params.offset + rows.length} 데이터 표시중 ({" "}
                  <InputBase
                    type="number"
                    style={{
                      margin: "2px",
                      border: `2px solid ${primary}`,
                      borderRadius: "4px",
                      width: "50px",
                    }}
                    value={page.current}
                    onChange={handlePageInputChange}
                  />
                  / {page.total} )
                  <Button
                    variant="outlined"
                    style={{ borderRadius: "unset", margin: "0 8px" }}
                    onClick={handleChangeCurrentPage}
                  >
                    페이지 이동
                  </Button>
                </Box>
              </Box>
              <Box margin="16px 0">
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "unset",
                    margin: "0 8px",
                    border: `1px solid ${primary}`,
                    padding: "4px",
                    color: primary,
                  }}
                  onClick={handleAddCurationItems}
                >
                  이벤트 상품 추가
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{
                    borderRadius: "unset",
                    margin: "0 8px",
                    border: `1px solid ${secondary}`,
                    padding: "4px",
                    color: secondary,
                  }}
                  onClick={hadleModalClose}
                >
                  닫기
                </Button>
              </Box>
            </Box>
            <TailSpin
              height="80"
              width="80"
              color={primary}
              radius="1"
              wrapperStyle={{
                position: "absolute",
                top: "50%",
                left: "50%",
                zIndex: 200,
              }}
              wrapperClass=""
              visible={loadling}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CurationItemAddModal;
