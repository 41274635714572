import { Box, Typography, Divider } from "@mui/material";
import { sub } from "date-fns";
import React from "react";
import { useRecoilState } from "recoil";
import { inputNumberWithComma } from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import {
  BREAK_POINT_PC,
  info,
  grey5,
  primary,
  borderColor,
} from "../../../common/style/styles";
import itemPriceIcon from "../../../media_assets/image/item-price.svg";
import PriceDropdown from "./price_dropdown";

const PriceInfo = ({ itemInfo }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <Box>
      {windowSize.width >= BREAK_POINT_PC ? (
        <Box>
          <Box display="flex">
            <img src={itemPriceIcon} />
            <Typography variant="subtitle1" marginLeft="0.5rem">
              가격정보
            </Typography>
          </Box>
          <Divider />
        </Box>
      ) : null}

      <Box margin={windowSize.width >= BREAK_POINT_PC ? "1rem 0" : "1rem"}>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" marginRight="1rem">
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
              }
              color={info}
            >
              최대 혜택가
            </Typography>
            <Typography
              variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle1"}
              fontWeight="700"
              marginLeft="1rem"
            >
              {inputNumberWithComma(itemInfo.item_info.sale_price)}원
            </Typography>
          </Box>
          <PriceDropdown priceList={itemInfo.price_list} />
        </Box>
        {itemInfo.item_info.sale_price !== itemInfo.item_info.item_price ? (
          <Box display="flex" alignItems="center" marginLeft="2rem">
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
              }
              fontWeight="700"
              color={primary}
            >
              {Math.round(
                100 -
                  (itemInfo.item_info.sale_price /
                    itemInfo.item_info.item_price) *
                    100
              )}
              %
            </Typography>
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
              }
              fontWeight="500"
              color={borderColor}
              marginLeft="1.5rem"
              sx={{ textDecoration: "line-through" }}
            >
              {inputNumberWithComma(itemInfo.item_info.item_price)}원
            </Typography>
          </Box>
        ) : null}
      </Box>
      {windowSize.width >= BREAK_POINT_PC ? <Divider /> : null}
    </Box>
  );
};

export default PriceInfo;
