import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  borderColor,
  BREAK_POINT_PC,
  Column,
  grey4,
  StyledLink,
} from "../../../common/style/styles";
import Address from "../../../common/components/address";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import AddressForm from "../../../common/components/address_form";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  putDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import AddIcon from "@mui/icons-material/Add";

const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

// 내정보 페이지의 주소 정보
const AddressInfo = () => {
  const { state } = useLocation();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [addressList, setAddressList] = useState([
    ...(state ? state.addressInfo : []),
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({ is_defalut: false });

  const handleClickEdit = (id) => {
    //  배송지 추가, 수정으로 변경
    setIsEditing((prev) => !prev);
    if (id && id !== -1) {
      setSelectedAddress(addressList.find((address) => address.id === id));
    }
  };

  // 배송지 추가, 수정에서 배송지 정보 입력값 변경
  const handleChangeInput = (event) => {
    const { name, value } = event.target;

    setSelectedAddress((prev) => {
      return {
        ...prev,
        [name]: name === "is_default" ? !JSON.parse(value) : value,
      };
    });
  };

  // 배송지 추가, 수정에서 주소 수정
  const handleChangeAddress = (data) => {
    setSelectedAddress((prev) => {
      return {
        ...prev,
        [data[0]["name"]]: data[0]["value"],
        [data[1]["name"]]: data[1]["value"],
      };
    });
  };

  // 수정, 추가버튼 클릭
  const handleSubmit = () => {
    // console.log(selectedAddress);
    // 주소 정보를 모두 작성했는지 확인
    const hasEmptyTextFiled = Object.keys(selectedAddress).length < 7;

    // console.log(hasEmptyTextFiled, selectedAddress);
    if (hasEmptyTextFiled) {
      alert("주소 정보를 모두 입력해주세요.");
      return;
    }
    // 핸드폰번호 형식이 맞는지 확인
    if (!regPhone.test(selectedAddress.cellphone)) {
      alert("올바른 전화번호 형식으로 입력해주세요.");
      return;
    }

    if (selectedAddress.id) {
      putDataFromServer(
        `/service/address/${selectedAddress.id}`,
        selectedAddress
      )
        .then((response) => {
          const newAddressList = addressList.map((data) => {
            if (data.id === selectedAddress.id) {
              return selectedAddress;
            } else {
              return data;
            }
          });
          setAddressList([...newAddressList]);
          handleClickEdit();
          setSelectedAddress({});
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    } else {
      postDataFromServer("/service/address", selectedAddress)
        .then((response) => {
          setAddressList((prev) => {
            return [...prev, selectedAddress];
          });
          handleClickEdit();
          setSelectedAddress({});
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    }
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box display="flex" alignItems="center">
        {isEditing ? (
          <Box
            onClick={() => {
              handleClickEdit();
            }}
          >
            <KeyboardArrowLeftIcon sx={{ flex: "1", cursor: "pointer" }} />
          </Box>
        ) : null}
        <Typography
          flex="9"
          variant="subtitle1"
          textAlign="center"
          margin="1rem 0"
          fontWeight="600"
        >
          배송지 정보{" "}
          {!isEditing ? "관리" : selectedAddress.id ? "수정" : "추가"}
        </Typography>
      </Box>
      {!isEditing ? (
        <Box margin="1rem 2rem">
          {addressList.map((address) => (
            <Box margin="1rem 0">
              <Address address={address} handleClickEdit={handleClickEdit} />
            </Box>
          ))}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ background: grey4, cursor: "pointer" }}
            padding="0.5rem 0"
            onClick={() => {
              handleClickEdit(-1);
            }}
          >
            <AddIcon
              sx={{
                fontSize: windowSize.width >= BREAK_POINT_PC ? "20px" : "10px",
              }}
            />
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
              }
              fontWeight="500"
            >
              배송지 추가
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box margin="2rem">
          <AddressForm
            address={selectedAddress}
            handleChangeInput={handleChangeInput}
            handleChangeAddress={handleChangeAddress}
            showIsDefalt={true}
          />
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              sx={{ borderRadius: 0, borderColor: "black", color: "black" }}
              onClick={handleSubmit}
            >
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                }
                padding="0.2rem 0.5rem"
              >
                {selectedAddress.id ? "수정" : "추가"}하기
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Column>
  );
};

export default AddressInfo;
