import { Box, List, ListItem } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { borderColor, StyledLink } from "../../../common/style/styles";
const StyledTypography = styled.p`
  font-size: 12pt;
  flex: 1;
  margin: 0;
  text-decoration: ${({ link }) => link && "underline"};
  cursor: pointer;
`;

const CSProcess = ({ orderCount }) => {
  return (
    <Box flex="1" border={"1px solid " + borderColor} margin="4px">
      <List>
        <ListItem>
          <StyledTypography>취소 요청</StyledTypography>
          <StyledTypography link={true}>
            <StyledLink to={`/admin/orders/order`} state={{ status: 6 }}>
              {orderCount.refound} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
        <ListItem>
          <StyledTypography>반품 요청</StyledTypography>
          <StyledTypography link={true}>
            <StyledLink to={`/admin/orders/order`} state={{ status: 8 }}>
              {orderCount.return} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
        <ListItem>
          <StyledTypography>교환 요청</StyledTypography>
          <StyledTypography link={true}>
            <StyledLink to={`/admin/orders/order`} state={{ status: 10 }}>
              {orderCount.exchange} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
        <ListItem>
          <StyledTypography>미답변 문의</StyledTypography>
          <StyledTypography link={true}>
            <StyledLink to={`/admin/reply/qna`} state={{ status: false }}>
              {orderCount.unanswered} 건
            </StyledLink>
          </StyledTypography>
        </ListItem>
      </List>
    </Box>
  );
};

export default CSProcess;
