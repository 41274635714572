import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SizeGuide from "./size_guide";
import TaxInfo from "./tax_info";
import ReturnExchangeInfo from "./return_exchange_info";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import { BREAK_POINT_PC, grey4 } from "../../../common/style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import ReactHtmlParser from "react-html-parser";

const ItemInfo = ({ itemInfo }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <Box margin="2.6rem">
      {/* 상품 상세 이미지 */}
      <Box id="detail-images" component="section">
        {itemInfo.item_info.images.map((value, index) => (
          <img
            key={index}
            style={{
              backgroundImage: `url(${defaultImg})`,
              width: "100%",
              marginBottom: "5rem",
            }}
            src={value}
            srcSet={value}
            // loading="lazy"
          />
        ))}
      </Box>
      {/* 상품 추가 정보 */}
      <Box id="detail-info" component="section">
        <Accordion sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            sx={{ background: grey4 }}
          >
            <Typography variant="subtitle2" component="p" fontWeight="500">
              상품 추가정보
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ReactHtmlParser(itemInfo.item_info.description)}
          </AccordionDetails>
        </Accordion>
      </Box>
      {/* 사이즈 가이드 */}
      <Box
        id="size-guide"
        marginTop={windowSize.width >= BREAK_POINT_PC ? "1rem" : "0.3rem"}
        component="section"
      >
        <Accordion sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            sx={{ background: grey4 }}
          >
            <Typography variant="subtitle2" component="p" fontWeight="500">
              사이즈 가이드
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SizeGuide sizeImages={itemInfo.item_info.size_images} />
          </AccordionDetails>
        </Accordion>
      </Box>
      {/* 관부가세 */}
      <Box
        id="tax-info"
        marginTop={windowSize.width >= BREAK_POINT_PC ? "1rem" : "0.3rem"}
        component="section"
      >
        <Accordion sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            sx={{ background: grey4 }}
          >
            <Typography variant="subtitle2" component="p" fontWeight="500">
              관부가세
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TaxInfo />
          </AccordionDetails>
        </Accordion>
      </Box>
      {/* 반품/교환 안내 */}
      <Box
        id="return-exchange-info"
        marginTop={windowSize.width >= BREAK_POINT_PC ? "1rem" : "0.3rem"}
        component="section"
      >
        <Accordion sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            sx={{ background: grey4 }}
          >
            <Typography variant="subtitle2" component="p" fontWeight="500">
              반품/교환 안내
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReturnExchangeInfo />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default ItemInfo;
