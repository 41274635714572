import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  convertDateTime,
  selectImageThumbnail,
} from "../../../common/js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { itemListTimeStampState } from "../../../common/state/state";
import MainMenu from "../../../common/components/main_menu";
import columnData from "../../../common/json/detail_data_columns.json";
import CurationItemAddModal from "../components/curation_item_add_modal";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../../../common/style/styles";
import MainMenuEditData from "../components/main_menu_edit_data";
import MainMenuDataList from "../components/main_menu_data_list";

const MainMenuShow = () => {
  const { mode, id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [columns, setColumns] = useState(
    columnData[`/admin/menu/main/${mode}/`]
  );
  const [info, setInfo] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );
  //더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 메뉴 데이터 정보 수정
  const handleChangeData = (event) => {
    const { name, value, files } = event.target;
    if (name === "reset") {
      setInfo({});
    } else {
      setInfo({ ...info, [name]: files ? files[0] : value });
    }
  };

  // 메뉴 데이터 정보 저장
  const handleSaveData = () => {
    if (hasClick) {
      return;
    }
    //  메뉴 데이터 생성
    if (id === "-1") {
      const isEmpty = columns.find(
        (value) => !info.hasOwnProperty(value.field) && !value.null
      );
      if (isEmpty) {
        alert("정보를 입력해주세요.");
        return;
      }

      setLoading(true);
      setProcessing(true);
      setHasClick(true);

      postDataFromServer("/admin/menu/main", info)
        .then((response) => {
          alert("데이터가 저장되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });

      setLoading(false);
      setProcessing(false);
      setHasClick(false);
      // 메뉴 데이터 업데이트
    } else {
      setLoading(true);
      setProcessing(true);
      setHasClick(true);
      putDataFromServer(`/admin/menu/main/${id}`, info)
        .then((response) => {
          alert("데이터가 저장되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
      setLoading(false);
      setProcessing(false);
      setHasClick(false);
    }
  };

  //  메뉴 데이터 삭제
  const handleDeleteData = () => {
    if (hasClick) {
      return;
    }
    if (window.confirm("데이터를 삭제하시겠습니까?")) {
      setLoading(true);
      setProcessing(true);
      setHasClick(true);
      deleteDataFromServer(`/admin/menu/main/${id}`)
        .then((response) => {
          alert("데이터가 삭제되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
      setLoading(false);
      setProcessing(false);
      setHasClick(false);
    }
  };

  useEffect(() => {
    // 로그인 정보 확인
    getDataFromServer("/admin/users/email")
      .then((response) => {
        setIsAdmin(true);
      })
      .catch((error) => {
        setIsAdmin(false);
      });
  }, []);

  useEffect(() => {
    if (mode === "create") {
      return;
    }
    setLoading(true);

    getDataFromServer(`/admin/menu/main/${id}?timestamp=${itemListTimeStamp}`)
      .then((response) => {
        setIsAdmin(true);

        const newColumns = columnData[`/admin/menu/main/${mode}/`];
        setColumns([...newColumns]);

        setInfo({
          ...response.info,
          is_show:
            mode === "show"
              ? response.info.is_show
                ? "O"
                : "X"
              : response.info.is_show,
        });
      })
      .catch((error) => {
        setIsAdmin(false);
      });

    setLoading(false);
  }, [pathname]);

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {mode === "show" && id && Object.keys(info).length && (
              <MainMenuDataList
                columns={columns}
                itemInfo={info}
                handleDeleteData={handleDeleteData}
              />
            )}
            {mode === "create" ||
            (mode === "edit" && Object.keys(info).length) ? (
              <MainMenuEditData
                id={id}
                columns={columns}
                itemInfo={info}
                handleChangeData={handleChangeData}
                handleSaveData={handleSaveData}
              />
            ) : null}
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { MainMenuShow as MainMenuDetail };
