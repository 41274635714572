import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import AgreeForm from "../../../common/components/agree_form";
import LoginForm from "../../../common/components/login_form";
import PersonalForm from "../../../common/components/personal_form";
import { addDashToPhoneNumber } from "../../../common/js/common";
import { putDataFromServer } from "../../../common/network/network";
import { BREAK_POINT_PC, Column, primary } from "../../../common/style/styles";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import CustomsNumberInfo from "../../order/components/customs_number_info";

// 회원 정보 수정 페이지
const EditUserInfo = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const regCustomsNumber = /^(P|p)([0-9]{12})$/;
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  // 로그인 정보
  const [loginData, setLoginData] = useState({
    id: state ? state.userInfo.email : "",
    pw: "",
    confirmPw: "",
    currentPw: "",
  });
  // 개인 정보
  const [personalData, setPersonalData] = useState({
    name: state ? state.userInfo.name : "",
    phone: state ? addDashToPhoneNumber(state.userInfo.cellphone) : "",
    customs_number: state ? state.userInfo.customs_number : "",
  });
  // 동의하기
  const [agreeData, setAgreeData] = useState({
    PP: state ? state.userInfo.receive_sms : false,
  });
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // url로 그냥 접근했을 때 홈으로 리다이력트
  useEffect(() => {
    if (!state) {
      alert("잘못된 접근입니다.");
      navigate("/");
    }
  }, []);

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    const [variableName, dataName] = name.split(".");

    switch (variableName) {
      case "login":
        setLoginData({ ...loginData, [dataName]: value });
        break;
      case "personal":
        setPersonalData({ ...personalData, [dataName]: value });
        break;
      case "agree":
        setAgreeData({
          ...agreeData,
          [dataName]: value === "false" ? true : false,
        });
        break;
      default:
    }
  };

  const handleSubmit = () => {
    if (processing) {
      return;
    }
    // 비어 있는 정보가 있는지 체크
    if (!loginData.currentPw || !personalData.name || !personalData.phone) {
      alert("비어 있는 항목이 있는지 확인해주세요.");
      return;
    }
    // 비밀번호와 비밀번호 확인이 같은지 확인
    if (loginData.pw !== loginData.confirmPw) {
      alert("비밀번호와 비밀번호 확인이 일차하지 않습니다.");
      return;
    }
    // 핸드폰번호 형식이 맞는지 확인
    if (!regPhone.test(personalData.phone)) {
      alert("올바른 전화번호 형식으로 입력해주세요.");
      return;
    }

    // 개인통관고유번호가 양식에 맞는지 확인
    if (!regCustomsNumber.test(personalData.customs_number)) {
      alert("올바른 개인통관고유번호 형식으로 입력해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);

    // 서버로 수정할 회원가입 정보 전송
    const userData = {
      pw: loginData.pw ? loginData.pw : null,
      current_pw: loginData.currentPw,
      name: personalData.name,
      cellphone: personalData.phone,
      customs_number: personalData.customs_number,
      receive_sms: agreeData.PP,
      receive_email: agreeData.PP,
    };

    putDataFromServer(`/service/users/${state.userInfo.id}`, userData)
      .then((response) => {
        alert("회원정보 수정이 완료되었습니다.");
        navigate("/myinfo");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("현재 비밀번호가 일치하지 않습니다.");
        } else {
          alert("잠시 후 다시 시도해주세요.");
        }
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box
        style={{
          pointerEvents: processing ? "none" : "auto",
        }}
      >
        <LoginForm
          data={loginData}
          handleChangeInput={handleChangeInput}
          isEditing={true}
        />
        <PersonalForm
          data={personalData}
          handleChangeInput={handleChangeInput}
          isEditing={true}
        />
        <AgreeForm
          data={agreeData}
          handleChangeInput={handleChangeInput}
          isEditing={true}
        />

        <Box width="100%">
          <Divider sx={{ borderWidth: "2px" }} />
          <Typography
            fontWeight="500"
            variant="subtitle2"
            component="p"
            margin="1rem"
          >
            현재 비밀번호
          </Typography>
          <Divider />
          <Box margin="1rem">
            <FormControl
              fullWidth
              sx={{ borderRadius: "0" }}
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-password"
                placeholder="영문, 특수문자, 숫자 포함 6~12자 이내"
                size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
                type={showPassword ? "text" : "password"}
                sx={{ borderRadius: "0" }}
                name="login.currentPw"
                value={loginData.currentPw}
                onChange={handleChangeInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <RemoveRedEyeOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "0",
            width: "100%",
            color: "white",
            margin: "2rem 0",
            padding: "0.5rem 0",
          }}
          onClick={handleSubmit}
        >
          <Typography variant="subtitle1">저장하기</Typography>
        </Button>

        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Column>
  );
};

export default EditUserInfo;
