import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { borderColor, StyledLink } from "../../../common/style/styles";
import ClearIcon from "@mui/icons-material/Clear";
import inquiryData from "../../../common/json/inquiry_condition.json";
import { useLocation } from "react-router-dom";

const OptionHeader = ({
  condition,
  filter,
  search,
  handleFilterChange,
  handleSearchInputChange,
  handleChangePaging,
  handleChangeInquiry,
  handleClickInquiry,
  handleDelete,
  handleExportExcel,
}) => {
  const { pathname } = useLocation();

  return (
    <Box border={"1px solid " + borderColor} padding="8px">
      {/* 조회 조건 추가, 조회 */}
      <Box padding="8px">
        <Box display="flex" alignItems="flex-end">
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel>조회조건</InputLabel>
            <Select value={filter} onChange={handleFilterChange} label="filter">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {inquiryData[pathname].map((value) => (
                <MenuItem
                  key={value.field}
                  value={value.field + ":" + value.headerName}
                >
                  {value.headerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {inquiryData[pathname].map(
            (value) =>
              value.field + ":" + value.headerName === filter &&
              (value.type === "string" ? (
                <TextField
                  label="조회값"
                  variant="standard"
                  name="search"
                  value={search}
                  sx={{ margin: "0 16px", minWidth: 200 }}
                  onChange={handleSearchInputChange}
                />
              ) : (
                <Box sx={{ margin: "0 16px", minWidth: 200 }}>
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: 200, minWidth: 200 }}
                  >
                    <InputLabel>선택</InputLabel>
                    <Select
                      label="filter"
                      value={search}
                      onChange={handleSearchInputChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {value.option.map((select) => (
                        <MenuItem value={select.value + ":" + select.name}>
                          {select.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ))
          )}

          <Button
            variant="outlined"
            style={{ borderRadius: "unset", margin: "0 8px" }}
            onClick={() => handleChangeInquiry("add")}
          >
            조회 조건 추가
          </Button>
          <Button
            variant="contained"
            style={{ borderRadius: "unset", color: "white", margin: "0 8px" }}
            onClick={handleClickInquiry}
          >
            조회하기
          </Button>
        </Box>
        <Box padding="8px">
          <Box display="flex">
            {condition.length ? (
              condition.map((value) => (
                <Box
                  key={value.id}
                  padding="8px"
                  margin="8px"
                  border={"1px solid " + borderColor}
                  borderRadius="8px"
                  width="max-content"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="caption">
                    {value.filter.split(":")[1]} :
                  </Typography>
                  <Typography variant="caption">
                    {value.search.includes(":")
                      ? value.search.split(":")[1]
                      : value.search}
                  </Typography>
                  <ClearIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleChangeInquiry("delete", value.id)}
                  />
                </Box>
              ))
            ) : (
              <Box margin="8px"></Box>
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
      {/* 데이터 대량으로 보기, 엑셀 다운 */}
      <Box display="flex" margin="8px 0">
        <Box>
          <Button
            variant="outlined"
            style={{ borderRadius: "unset", margin: "0 8px" }}
            onClick={() => handleChangePaging(100)}
          >
            100건씩 보기
          </Button>
          <Button
            variant="outlined"
            style={{ borderRadius: "unset", margin: "0 8px" }}
            onClick={() => handleChangePaging(500)}
          >
            500건씩 보기
          </Button>
        </Box>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleExportExcel}
        >
          엑셀 내려받기
        </Button>
      </Box>
    </Box>
  );
};

export default OptionHeader;
