import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Badge from "@mui/material/Badge";
import {
  BREAK_POINT_PC,
  BREAK_POINT_TABLET,
  Column,
  StyledLink,
} from "../style/styles";

import { useMutation, useQuery } from "react-query";
import { getDataFromServer } from "../network/network";
import { useRecoilState } from "recoil";
import { cartItemNumState, windowSizeState } from "../state/state";
import logoImg from "../../media_assets/image/millionpick_logo.png";
import { Divider, Typography } from "@mui/material";

const menuStyle = {
  padding: "2rem 0 1rem 0",
  maxHeight: "105px",
  fontSize: "24px",
  border: "7px",
  selectedColor: "black",
  justifyContent: "space-evenly",
};

// 서비스 페이지 헤더
const ServiceHeader = () => {
  const [cartItemNum, setCartItemNum] = useRecoilState(cartItemNumState);
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  // 맨처음 사이트에 접속했을 때 서버로부터 데이터 가져오기
  const { isLoading, error, data } = useQuery("cartItemNum", () => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");
    return getDataFromServer(
      `/service/carts?token=${
        nonMemberToken === "undefined" ? null : nonMemberToken
      }&num=true`
    );
  });

  useEffect(() => {
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    getDataFromServer(
      `/service/carts?token=${
        nonMemberToken === "undefined" ? null : nonMemberToken
      }&num=true`
    ).then((response) => {
      setCartItemNum(response.item_num);
    });
  }, []);

  return (
    <Box
      overflow="hidden"
      flexGrow="1"
      id="back-to-top-anchor"
      maxHeight="105px"
    >
      <AppBar
        component="nav"
        position="fixed"
        color="inherit"
        style={{
          top: 0,
          boxShadow: "none",
          maxHeight: "105px",
          minHeight: "65px",
        }}
      >
        <Toolbar
          style={{
            maxHeight: "105px",
            minHeight: "65px",
            minWidth: "100%",
            width: "100%",
            padding: 0,
          }}
        >
          <Column xs={12} sm={12} md={12} lg={9}>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              padding={windowSize.width >= BREAK_POINT_PC ? "1rem" : "0 1rem"}
            >
              <Box display="flex" alignItems="center" width="100%" padding="0">
                {/* 로고 */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flex="9"
                  padding="0 0 0 1px"
                >
                  <StyledLink to="/">
                    <img
                      src={logoImg}
                      alt="밀리언픽 로고"
                      width={
                        windowSize.width >= BREAK_POINT_PC ? "207px" : "94px"
                      }
                      height="auto"
                      style={{ cursor: "pointer", marginLeft: "80px" }}
                    />
                  </StyledLink>
                </Box>
                <Box display="flex" flex={1} margin="0 4px 0 0">
                  <StyledLink to="/search">
                    <IconButton>
                      <SearchRoundedIcon
                        style={{
                          fontSize: `${
                            windowSize.width >= BREAK_POINT_PC
                              ? "2.2rem"
                              : "1.5rem"
                          }`,
                          color: "black",
                        }}
                      />
                    </IconButton>
                  </StyledLink>
                  <StyledLink to="/carts">
                    <IconButton>
                      <Badge badgeContent={cartItemNum} color="error">
                        <ShoppingCartRoundedIcon
                          style={{
                            fontSize: `${
                              windowSize.width >= BREAK_POINT_PC
                                ? "2.2rem"
                                : "1.5rem"
                            }`,
                            color: "black",
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </StyledLink>
                </Box>
              </Box>
              {windowSize.width >= BREAK_POINT_PC ? null : <Divider />}
            </Box>
          </Column>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default ServiceHeader;
