import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  convertDate,
  fillZero,
  selectImageThumbnail,
} from "../../../common/js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import {
  Column,
  borderColor,
  primary,
  BREAK_POINT_PC,
  grey6,
} from "../../../common/style/styles";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import deleveryCode from "../../../common/json/delivery_code.json";
import { TailSpin } from "react-loader-spinner";
import MenuTab from "../../../common/components/menu_tab";
import DeliveryStateItemList from "../components/delivery_state_item_list";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import ReviewItem from "../../../common/components/review_item";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [offset, setOffset] = useState({ review: 0, order: 0 });
  const [orderItems, setOrderItems] = useState([]);
  const [reviewItems, setReviewItems] = useState([]);
  const [isLogin, setIsLogion] = useState(false);
  const [totalItemNum, setTotalItemNum] = useState({ review: 0, order: 0 });
  // 주문번호 배열
  const [orderIds, setOrderIds] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(1);

  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  const menu = [
    { id: 1, name: "주문내역" },
    { id: 2, name: "상품리뷰" },
  ];

  // 주문 상품 상태 변경
  const handleChangeState = (
    orderItemId,
    currentState,
    stateToChange,
    buttonName
  ) => {
    if (processing) {
      return;
    }
    // 취소철회 ( 6 : 취소요청 -> 2 : 배송준비 ) or 교환철회, 반품철회 ( 10 : 교환요청 , 8 : 반품요청 -> 4 : 배송완료 로 변경 ) 제외하고는 현재 state 숫자가 변경될 state 숫자보다 작아야함
    if (
      currentState != 6 &&
      currentState != 10 &&
      currentState != 8 &&
      currentState > stateToChange
    ) {
      alert("올바른 요청이 아닙니다.");
      return;
    }

    setLoading(true);
    setProcessing(true);

    putDataFromServer(`/service/orders/${orderItemId}?state=${stateToChange}`)
      .then((response) => {
        alert(`${buttonName} 성공적으로 처리되었습니다.`);
        const new_items = orderItems.map((item) => {
          if (orderItemId === item.order_item_id) {
            return {
              ...item,
              state: stateToChange,
              state_record: response.state_record,
            };
          } else {
            return item;
          }
        });
        setOrderItems([...new_items]);
      })
      .catch((error) => {
        alert("잠시후 다시 시도해주세요.");
      });

    setLoading(false);
    setProcessing(false);
  };

  // 서버로부터 주문내역 데이터 가져오기
  const orderItemMutation = useMutation("OrderItemListData", {
    mutationFn: () => {
      return getDataFromServer(`/service/orders?offset=${offset.order}`);
    },
    onSuccess: (data) => {
      setTotalItemNum((prev) => {
        return { ...prev, order: data.total_num };
      });
      setIsLogion(true);

      const tempItemList = data.item_list.map((value) => {
        if (value.t_code) {
          return {
            ...value,
            t_code: deleveryCode.data.find((data) => data.name === value.t_code)
              .sweettracker,
          };
        } else {
          return value;
        }
      });

      if (offset.order === 0) {
        setOrderItems([...selectImageThumbnail(tempItemList)]);

        setOrderIds([
          ...new Set(
            data.item_list.map((value) => {
              return (
                convertDate(new Date(value.order_date)).replaceAll("-", "") +
                fillZero(10, value.order_id)
              );
            })
          ),
        ]);
      } else {
        setOrderItems((prev) => {
          return [...prev, ...selectImageThumbnail(tempItemList)];
        });
        setOrderIds((prev) => {
          return [
            ...prev,
            ...new Set(
              data.item_list.map((value) => {
                return (
                  convertDate(new Date(value.order_date)).replaceAll("-", "") +
                  fillZero(10, value.order_id)
                );
              })
            ),
          ];
        });
      }
    },
    onError: (error) => {
      // 로그인 안했을 경우 리다이렉트
      alert("로그인 후 사용해주세요.");
      navigate("/login");
      return;
    },
  });

  // 서버로부터 상품리뷰 데이터 갸져오기
  const reviewItemMutation = useMutation("reviewItemListData", {
    mutationFn: () => {
      return getDataFromServer(`/service/reviews/user?offset=${offset.review}`);
    },
    onSuccess: (data) => {
      setTotalItemNum((prev) => {
        return { ...prev, review: data.total_num };
      });
      setIsLogion(true);
      if (offset.review === 0) {
        setReviewItems([...data.item_list]);
      } else {
        setReviewItems((prev) => {
          return [...prev, ...data.item_list];
        });
      }
    },
    onError: (error) => {
      // 로그인 안했을 경우 리다이렉트
      // console.log(error);
      alert("로그인 후 사용해주세요.");
      navigate("/login");
      return;
    },
  });

  useEffect(() => {
    if (selectedMenu === 1) {
      orderItemMutation.mutate(offset);
    } else {
      reviewItemMutation.mutate(offset);
    }
  }, [selectedMenu, offset]);

  //  더 보기 버튼 클릭
  const handleClick = () => {
    if (selectedMenu === 1) {
      orderItems.length !== totalItemNum.order &&
        setOffset((prev) => {
          return { ...prev, order: prev.order + 5 };
        });
    } else {
      reviewItems.length !== totalItemNum.review &&
        setOffset((prev) => {
          return { ...prev, review: prev.review + 5 };
        });
    }
  };

  // 메뉴 탭 변경
  const hadleChangeSelectedMenu = (event, newValue) => {
    setSelectedMenu(newValue);
    if (newValue === 1) {
      setOffset((prev) => {
        return { ...prev, order: 0 };
      });
    } else {
      setOffset((prev) => {
        return { ...prev, review: 0 };
      });
    }
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box sx={{ pointerEvents: processing ? "none" : "auto" }}>
        {isLogin && (
          <Box minHeight="100vh">
            {/* 주문 내역 , 상품 리뷰 탭 */}
            <Box>
              <MenuTab
                data={menu}
                menu={selectedMenu}
                handleChange={hadleChangeSelectedMenu}
                style={{
                  pc: { fontSize: "18pt" },
                  mobile: { fontSize: "10.5pt" },
                }}
              />
              <Divider />
            </Box>
            {/* 주문내역 탭일 때 */}
            {selectedMenu === 1 &&
              !orderItemMutation.isLoading &&
              (orderItems.length ? (
                <DeliveryStateItemList
                  orderIds={orderIds}
                  items={orderItems}
                  handleChangeState={handleChangeState}
                />
              ) : (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  flexDirection="column"
                  height={windowsize.height / 2}
                >
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle1"
                    }
                    fontWeight="700"
                    margin="1rem 0"
                  >
                    주문 내역이 없습니다.
                  </Typography>
                </Box>
              ))}
            {/* 상품 리뷰 탭일 때 */}
            {selectedMenu === 2 &&
              !reviewItemMutation.isLoading &&
              (reviewItems.length ? (
                reviewItems.map((review) => (
                  <Box key={review.id}>
                    <ReviewItem review={review} canEdit={true} />
                    <Box height="5px" sx={{ background: grey6 }}></Box>
                  </Box>
                ))
              ) : (
                <Box
                  height={windowsize.height / 2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle1"
                    }
                    fontWeight="700"
                  >
                    작성한 리뷰가 없습니다.
                  </Typography>
                </Box>
              ))}
          </Box>
        )}

        {(selectedMenu === 1
          ? orderItems.length < totalItemNum.order
          : reviewItems.length < totalItemNum.review) && (
          <Box
            padding="8px 0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            border={`1px solid ${borderColor}`}
            onClick={handleClick}
          >
            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="700"
            >
              {selectedMenu === 1 ? "주문 내역" : "리뷰"} 더 보기
            </Typography>
            <ExpandMoreOutlinedIcon />
          </Box>
        )}
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Column>
  );
};

export { Index as OrderList };
