import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postDataFromServer } from "../../../common/network/network";
import AgreeForm from "../../../common/components/agree_form";
import LoginForm from "../../../common/components/login_form";
import PersonalForm from "../../../common/components/personal_form";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import { BREAK_POINT_PC, Column } from "../../../common/style/styles";

export const Index = () => {
  const navigate = useNavigate();
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const regEmail =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
  const regPassword =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,12}$/;
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  // 로그인 정보
  const [loginData, setLoginData] = useState({ id: "", pw: "", confirmPw: "" });
  // 개인 정보
  const [personalData, setPersonalData] = useState({ name: "", phone: "" });
  // 동의하기
  const [agreeData, setAgreeData] = useState({
    totalTerms: false,
    termsOfUse: false,
    personalInfo: false,
    marketing: false,
  });
  // 이메일 중복인지 체크
  const [isDuplicated, setIsDuplicated] = useState(true);

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    const [variableName, dataName] = name.split(".");
    // console.log(name, value);

    switch (variableName) {
      case "login":
        setLoginData({ ...loginData, [dataName]: value });
        // 이메일 변경시 중복확인 다시하기
        if (dataName === "id") {
          setIsDuplicated(true);
        }
        break;
      case "personal":
        setPersonalData({ ...personalData, [dataName]: value });
        break;
      case "agree":
        setAgreeData({
          ...agreeData,
          [dataName]: value === "false" ? true : false,
        });
        break;
      default:
      // console.log(variableName);
    }
  };

  // 이메일 중복 확인
  const handleIsDuplicated = () => {
    // 이메일 입력했는지 확인
    if (!loginData.id) {
      alert("이메일을 입력해주세요.");
      return;
    }
    // 이메일 형식이 맞는지 확인
    if (!regEmail.test(loginData.id)) {
      alert("올바른 이메일 형식으로 입력해주세요.");
      return;
    }

    postDataFromServer("/service/join/email", { email: loginData.id }).then(
      (response) => {
        if (response.is_duplicated) {
          alert("사용할 수 없는 이메일입니다.");
          return;
        }
        alert("사용 가능한 이메일입니다.");
        setIsDuplicated(response.is_duplicated);
      }
    );
  };

  const handleSubmit = () => {
    // 비어 있는 정보가 있는지 체크
    if (
      !loginData.id ||
      !loginData.pw ||
      !personalData.name ||
      !personalData.phone
    ) {
      alert("비어 있는 항목이 있는지 확인해주세요.");
      return;
    }
    // 이메일이 중복인지 확인
    if (isDuplicated) {
      alert("이메일이 중복인지 확인해주세요.");
      return;
    }
    // 비밀번호 형식에 맞는지 확인
    if (!regPassword.test(loginData.pw)) {
      alert("올바른 비밀번호 형식으로 입력해주세요.");
      return;
    }
    // 비밀번호와 비밀번호 확인이 같은지 확인
    if (loginData.pw !== loginData.confirmPw) {
      alert("비밀번호와 비밀번호 확인이 일차하지 않습니다.");
      return;
    }
    // 이메일 형식이 맞는지 확인
    if (!regEmail.test(loginData.id)) {
      alert("올바른 이메일 형식으로 입력해주세요.");
      return;
    }
    // 핸드폰번호 형식이 맞는지 확인
    if (!regPhone.test(personalData.phone)) {
      alert("올바른 전화번호 형식으로 입력해주세요.");
      return;
    }
    // 이용약관, 개인정보취급방침 동의 했는지 확인
    if (
      !agreeData.totalTerms ||
      !agreeData.termsOfUse ||
      !agreeData.personalInfo
    ) {
      console.log(agreeData);
      alert("필수 동의사항에 체크해주세요.");
      return;
    }

    // 서버로 회원가입 정보 전송
    const joinData = {
      email: loginData.id,
      pw: loginData.pw,
      name: personalData.name,
      phone: personalData.phone,
      marketing: agreeData.marketing,
    };
    postDataFromServer("/service/join", joinData).then((response) => {
      alert("회원가입이 완료되었습니다.");
      navigate("/login");
    });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box
        padding={windowSize.width >= BREAK_POINT_PC && "16px"}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <LoginForm
          data={loginData}
          handleChangeInput={handleChangeInput}
          isEdit={false}
          handleIsDuplicated={handleIsDuplicated}
        />
        <PersonalForm
          data={personalData}
          handleChangeInput={handleChangeInput}
          isEdit={false}
        />
        <AgreeForm
          data={agreeData}
          handleChangeInput={handleChangeInput}
          isEdit={false}
        />

        <Button
          variant="contained"
          color="primary"
          style={{
            width: "100%",
            color: "white",
            margin: `${
              windowSize.width >= BREAK_POINT_PC ? "2rem 0" : "1rem 1rem"
            }`,
            borderRadius: "0",
            height: `${windowSize.width >= BREAK_POINT_PC ? "80px" : "54px"}`,
          }}
          onClick={handleSubmit}
        >
          <Typography
            variant={
              windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
            }
            fontWeight="700"
          >
            회원가입
          </Typography>
        </Button>
      </Box>
    </Column>
  );
};
