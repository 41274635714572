import { Box, Divider, Typography } from "@mui/material";

import React from "react";
import { useRecoilState } from "recoil";
import { convertDate } from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, StyledLink } from "../../../common/style/styles";

// 공지사항 리스트
const NoticeList = ({ data }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <Box margin="8px">
      {data.map((value) => (
        <StyledLink
          to={"/notice/" + value.id}
          state={{
            title: value.title,
            content: value.content,
            date: convertDate(new Date(value.created_at)),
          }}
          style={{ width: "100%" }}
        >
          <Box>
            <Box margin="0.5rem 0 1rem 0">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h5" : "subtitle1"
                }
                fontWeight="500"
              >
                {value.title}
              </Typography>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                }
              >
                {convertDate(new Date(value.created_at))}
              </Typography>
            </Box>
          </Box>
          <Divider />
        </StyledLink>
      ))}
    </Box>
  );
};

export default NoticeList;
