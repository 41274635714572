import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getDataFromServer } from "../../../common/network/network";
import { windowSizeState } from "../../../common/state/state";
import {
  BREAK_POINT_PC,
  BREAK_POINT_TABLET,
  Column,
  grey4,
  StyledLink,
} from "../../../common/style/styles";

import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import Banner from "../../../common/components/banner";
export const Index = () => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [categoryList, setCategoryList] = useState({});
  // 선택된 대카테고리
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    // 카테고리 데이터 가져오기
    getDataFromServer("/service/categories").then((response) => {
      setCategoryList({ ...response.category_list });
      setSelectedCategory(response.category_list.zero[0].id);
    });
  }, []);

  // 선택된 대카테고리 변경
  const handleChangeCategory = (id) => {
    setSelectedCategory(id);
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {Object.keys(categoryList).length ? (
        <Box display="flex">
          {/* 사이드 카테고리 (대카테고리) */}
          <Box
            flex="3"
            minHeight={
              windowsize.width >= BREAK_POINT_TABLET
                ? windowsize.height * 0.9
                : windowsize.height * 0.82
            }
            maxHeight={windowsize.height * 0.82}
            sx={{ overflow: "auto", background: grey4 }}
          >
            <Box height="100%">
              {categoryList.zero.map((category) => (
                <Typography
                  variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "body1"}
                  fontWeight="500"
                  padding={
                    windowsize.width >= BREAK_POINT_PC
                      ? "1rem 0 1rem 1rem"
                      : "1rem 0 1rem 0.5rem"
                  }
                  minWidth="max-content"
                  sx={{
                    cursor: "pointer",
                    background:
                      selectedCategory === category.id ? "white" : grey4,
                  }}
                  onClick={() => {
                    handleChangeCategory(category.id);
                  }}
                >
                  {category.name}
                </Typography>
              ))}
            </Box>
          </Box>
          {/* 메인 카테고리(소카테고리) */}
          <Box
            flex="7"
            maxHeight={
              windowsize.width >= BREAK_POINT_TABLET
                ? windowsize.height * 0.9
                : windowsize.height * 0.82
            }
            sx={{ overflow: "auto" }}
          >
            <Box height="100%">
              {categoryList.one.map((category) =>
                category.ancestry === selectedCategory ? (
                  <StyledLink to={"/categories/" + category.id}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      margin={
                        windowsize.width >= BREAK_POINT_PC
                          ? "1.5rem 1.5rem 1.5rem 2rem"
                          : "1.5rem 0.5rem 1.5rem 1rem"
                      }
                    >
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "h6"
                            : "subtitle2"
                        }
                        fontWeight="700"
                        minWidth="max-content"
                      >
                        {category.name}
                      </Typography>
                      <NavigateNextOutlinedIcon />
                    </Box>
                  </StyledLink>
                ) : null
              )}
            </Box>
          </Box>
        </Box>
      ) : null}
    </Column>
  );
};
