import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  primary,
  grey6,
  BREAK_POINT_PC,
  grey3,
  Column,
} from "../../../common/style/styles";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import Address from "../components/address";
import OrderInfo from "../components/order_info";
import {
  convertDate,
  fillZero,
  selectImageThumbnail,
} from "../../../common/js/common";
import deleveryCode from "../../../common/json/delivery_code.json";
import { TailSpin } from "react-loader-spinner";
import DeliveryStateItem from "../components/delivery_state_item";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Show = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  // 구매 아이템 목록
  const [items, setItems] = useState([]);
  // 아이템별 국가 리스트
  const [country, setCountry] = useState([]);
  // 로그인했는지 확인
  const [isLogin, setIsLogion] = useState(false);

  // 배송지 정보
  const [addressInfo, setAddressInfo] = useState({});
  // 주문 정보
  const [orderInfo, setOrderInfo] = useState({});
  const [cancelInfo, setCancelInfo] = useState({});
  const [cancelCharge, setCancelCharge] = useState(0);
  const [promotionCode, setPromotionCode] = useState(0);

  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  // 주문 상품 상태 변경
  const handleChangeState = (
    orderItemId,
    currentState,
    stateToChange,
    buttonName
  ) => {
    if (processing) {
      return;
    }

    // console.log(orderItemId, currentState, stateToChange, buttonName);
    // 취소철회 ( 6 : 취소요청 -> 2 : 배송준비 ) or 교환철회, 반품철회 ( 10 : 교환요청 , 8 : 반품요청 -> 4 : 배송완료 로 변경 ) 제외하고는 현재 state 숫자가 변경될 state 숫자보다 작아야함
    if (
      currentState != 6 &&
      currentState != 10 &&
      currentState != 8 &&
      currentState > stateToChange
    ) {
      alert("올바른 요청이 아닙니다.");
      return;
    }

    setLoading(true);
    setProcessing(true);

    putDataFromServer(`/service/orders/${orderItemId}?state=${stateToChange}`)
      .then((response) => {
        alert(`${buttonName} 성공적으로 처리되었습니다.`);
        const new_items = items.map((item) => {
          if (orderItemId === item.order_item_id) {
            return {
              ...item,
              state: stateToChange,
              state_record: response.state_record,
            };
          } else {
            return item;
          }
        });
        setItems([...new_items]);
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      });
    setLoading(false);
    setProcessing(false);
  };

  useEffect(() => {
    setLoading(true);
    getDataFromServer(`/service/orders/${id}`)
      .then((response) => {
        setIsLogion(true);
        setAddressInfo({
          ...response.address_info,
        });
        setOrderInfo({ ...response.order_info });
        setCancelInfo({ ...response.cancel_info });
        setCancelCharge(
          response.cancel_charge ? response.cancel_charge[0].charge : 0
        );

        const promotionCodeDiscountPrice = response.order_item_list.find(
          (item) => item.benefits_type === "item"
        )
          ? response.order_item_list.reduce((accumulator, currentValue) => {
              return (
                accumulator +
                (currentValue.discount_price ? currentValue.discount_price : 0)
              );
            }, 0)
          : response.order_item_list[0].discount_price;
        setPromotionCode(-promotionCodeDiscountPrice);

        const tempItemList = response.order_item_list.map((value) => {
          if (value.t_code) {
            return {
              ...value,
              t_code: deleveryCode.data.find(
                (data) => data.name === value.t_code
              ).sweettracker,
            };
          } else {
            return value;
          }
        });

        setItems([...selectImageThumbnail(tempItemList)]);
        setCountry([
          ...new Set(
            response.order_item_list.map((item) => {
              return item.country;
            })
          ),
        ]);
      })
      .catch((error) => {
        // 로그인 안했을 경우 리다이렉트
        alert("로그인 후 사용해주세요.");
        navigate("/login");
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box sx={{ pointerEvents: processing ? "none" : "auto" }}>
        {isLogin ? (
          <Box minHeight="100vh">
            <Box margin="1rem">
              {/* 주문번호 : 날짜+order_id */}
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h5" : "subtitle1"
                }
                fontWeight="700"
              >
                주문 번호{" "}
                {convertDate(new Date(orderInfo.created_at)).replaceAll(
                  "-",
                  ""
                ) + fillZero(10, orderInfo.id)}
              </Typography>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                }
              >
                결제일 : {items[0].state_record["1"]}
              </Typography>
            </Box>
            <Box height="5px" sx={{ background: grey6 }}></Box>
            {/* 주문상품 */}
            <Box>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                }
                fontWeight="700"
                margin="0.8rem 1rem"
                component="p"
              >
                주문상품
              </Typography>
              <Divider />
              <Box margin="0 1rem">
                {items.map((item, index) => (
                  <DeliveryStateItem
                    item={item}
                    handleChangeState={handleChangeState}
                  />
                ))}
              </Box>
            </Box>
            <Box height="5px" sx={{ background: grey6 }}></Box>
            {/* 취소사유 */}
            {Object.keys(cancelInfo).length ? (
              <Box>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ margin: "0", padding: "0 0.5rem 0 0" }}
                  >
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                      }
                      fontWeight="700"
                      margin="0.8rem 1rem"
                      component="p"
                    >
                      취소사유
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" marginBottom="1rem">
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "subtitle1"
                            : "body1"
                        }
                        fontWeight="500"
                        color={grey3}
                        flex="1"
                      >
                        취소사유
                      </Typography>
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "subtitle1"
                            : "body1"
                        }
                        fontWeight="500"
                        flex="4"
                      >
                        {cancelInfo.content}
                      </Typography>
                    </Box>
                    <Box display="flex" marginBottom="1rem">
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "subtitle1"
                            : "body1"
                        }
                        fontWeight="500"
                        color={grey3}
                        flex="1"
                      >
                        메세지
                      </Typography>
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "subtitle1"
                            : "body1"
                        }
                        fontWeight="500"
                        flex="4"
                        whiteSpace="pre-wrap"
                      >
                        {cancelInfo.message}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ) : null}
            {Object.keys(cancelInfo).length ? (
              <Box height="5px" sx={{ background: grey6 }}></Box>
            ) : null}
            {/* 배송지정보 */}
            <Box>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                }
                fontWeight="700"
                margin="0.8rem 1rem"
                component="p"
              >
                배송지정보
              </Typography>
              <Divider />
              <Box margin="1rem">
                <Address selectedAddress={addressInfo} />
                <Box display="flex" marginBottom="1rem">
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    color={grey3}
                    flex="1"
                  >
                    배송메모
                  </Typography>
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    flex="4"
                  >
                    {orderInfo.delivery_message
                      ? orderInfo.delivery_message
                      : "-"}
                  </Typography>
                </Box>
                <Box display="flex" marginBottom="1rem">
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    color={grey3}
                    flex="1"
                    // minWidth="max-content"
                    // marginRight="0.2rem"
                  >
                    통관번호
                  </Typography>
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="500"
                    flex="4"
                  >
                    {orderInfo.customs_number}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box height="5px" sx={{ background: grey6 }}></Box>
            {/* 결제 정보 */}
            <OrderInfo
              orderInfo={orderInfo}
              items={items}
              cancelCharge={cancelCharge}
              promotionCode={promotionCode}
            />
          </Box>
        ) : null}
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Column>
  );
};

export { Show as OrderItemDetail };
