import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { itemListTimeStampState } from "../../../common/state/state";
import MainMenu from "../../../common/components/main_menu";
import columnData from "../../../common/json/detail_data_columns.json";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../../../common/style/styles";
import CategoryDataList from "../components/category_data_list";
import CategoryEditData from "../components/category_edit_data";
import { convertDateTime } from "../../../common/js/common";

const CategoryShow = () => {
  const { mode, id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [columns, setColumns] = useState(
    columnData[`/admin/menu/category/${mode}/`]
  );
  const [info, setInfo] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );
  // 카테고리 생성시 어느 메뉴를 선택했는지
  const [selectedMenu, setSelectedMenu] = useState(1);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  const handleChangeMenu = (event, newValue) => {
    setSelectedMenu(newValue);
  };

  // 메뉴 데이터 정보 수정
  const handleChangeData = (event) => {
    const { name, value } = event.target;

    if (name === "reset") {
      setInfo({});
    } else {
      setInfo({ ...info, [name]: value });
    }
  };

  // 메뉴 데이터 정보 저장
  const handleSaveData = () => {
    if (processing) {
      return;
    }
    //  메뉴 데이터 생성
    if (id === "-1") {
      let isEmpty;
      // 대카테고리
      if (typeof info.zero_id == "string") {
        isEmpty = columns.find(
          (value) =>
            !info.hasOwnProperty(value.field) &&
            !value.null &&
            value.field !== "one"
        );
        // 소카테고리
      } else {
        isEmpty = columns.find(
          (value) => !info.hasOwnProperty(value.field) && !value.null
        );
      }
      if (isEmpty) {
        alert("정보를 입력해주세요.");
        return;
      }

      setLoading(true);
      setProcessing(true);

      postDataFromServer(`/admin/menu/category`, info)
        .then((response) => {
          alert("데이터가 저장되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
        });

      // 메뉴 데이터 업데이트
    } else {
      setLoading(true);
      setProcessing(true);

      putDataFromServer(`/admin/menu/category/${id}`, info)
        .then((response) => {
          alert("데이터가 저장되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
        });
    }
  };

  //  메뉴 데이터 삭제
  const handleDeleteData = () => {
    if (processing) {
      return;
    }
    if (window.confirm("데이터를 삭제하시겠습니까?")) {
      setLoading(true);
      setProcessing(true);
      deleteDataFromServer(`/admin/menu/category/${id}`)
        .then((response) => {
          alert("데이터가 삭제되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
        });
    }
  };

  useEffect(() => {
    // 로그인 정보 확인
    getDataFromServer("/admin/users/email")
      .then((response) => {
        setIsAdmin(true);
      })
      .catch((error) => {
        setIsAdmin(false);
      });
  }, []);

  useEffect(() => {
    if (mode === "create") {
      return;
    }
    setLoading(true);

    getDataFromServer(
      `/admin/menu/category/${id}?timestamp=${itemListTimeStamp}`
    )
      .then((response) => {
        setIsAdmin(true);

        // 소카테고리 수정, 보기
        if (response.ancestry) {
          const newColumns = columnData[`/admin/menu/category/${mode}/`].map(
            (value) => {
              if (value.field === "zero_id") {
                return {
                  ...value,
                  type: "select",
                  option: response.ancestry,
                };
              }
              return value;
            }
          );

          if (mode === "edit") {
            const categoryId = response.ancestry.find(
              (value) => value.name === response.info["zero"]
            )["id"];

            response.info.zero = categoryId;
          }

          setColumns([...newColumns]);
          // 대카테고리 수정, 보기
        } else {
          const newColumns = columnData[`/admin/menu/category/${mode}/`].filter(
            (value) => value.field !== "one"
          );

          setColumns([...newColumns]);

          if (mode === "edit") {
            response.info.zero_id = response.info.zero;
          }
        }

        setInfo({
          ...response.info,
          is_show:
            mode === "show"
              ? response.info.is_show
                ? "O"
                : "X"
              : response.info.is_show,
        });
      })
      .catch((error) => {
        setIsAdmin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pathname]);

  // 카테고리 관리 생성일 때 대카테고리, 소카테고리에 맞는 입력정보 생성
  useEffect(() => {
    if (!pathname.includes("category/create")) {
      return;
    }
    let newColumns = null;
    // 대카테고리 생성
    if (selectedMenu === 1) {
      newColumns = columns
        .filter((value) => value.field !== "one")
        .map((value) => {
          if (value.field === "zero_id") {
            return { ...value, type: "string" };
          }
          if (value.field === "image") {
            return { ...value, null: true };
          }
          return value;
        });
      handleChangeData({ target: { name: "reset" } });
      // 소카테고리 생성
    } else {
      newColumns = columns.map((value) => {
        if (value.field === "zero_id") {
          return { ...value, type: "select", option: [] };
        }
        return value;
      });
      newColumns.splice(1, 0, {
        field: "one",
        name: "소 카테고리",
        null: false,
        type: "string",
      });
      handleChangeData({ target: { name: "reset" } });
    }
    setColumns([...newColumns]);
  }, [selectedMenu]);

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {mode === "show" && id && Object.keys(info).length ? (
              <CategoryDataList
                columns={columns}
                itemInfo={info}
                handleDeleteData={handleDeleteData}
              />
            ) : null}
            {mode === "edit" && Object.keys(info).length ? (
              <CategoryEditData
                id={id}
                columns={columns}
                itemInfo={info}
                handleChangeData={handleChangeData}
                handleSaveData={handleSaveData}
              />
            ) : null}
            {mode === "create" ? (
              <CategoryEditData
                id={id}
                columns={columns}
                itemInfo={info}
                handleChangeData={handleChangeData}
                handleSaveData={handleSaveData}
                selectedMenu={selectedMenu}
                handleChangeMenu={handleChangeMenu}
              />
            ) : null}
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { CategoryShow as CategoryDetail };
