import {
  Box,
  Divider,
  List,
  ListItem,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  Chip,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useState } from "react";
import {
  borderColor,
  grey,
  info,
  secondary,
} from "../../../common/style/styles";

const EditData = ({
  columns,
  rows,
  handleChange,
  handleSaveData,
  handleDeleteCountry,
  handleDeleteImage,
  categoryList,
}) => {
  // 관부가세율 추가할 나라
  const [newCountry, setNewCountry] = useState("");

  // 관부가세율 나라 추가
  const handleChangeCountry = (event) => {
    setNewCountry(event.target.value);
  };

  return (
    <Box margin="16px">
      <Box margin="8px 0" display="flex">
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleSaveData}
        >
          브랜드 정보 저장
        </Button>
      </Box>
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${grey}` }}>데이터 수정</ListItem>
        <Divider />
        {columns.map((value) => (
          <ListItem
            key={value.column}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Typography variant="subtitle2" component="p" flex="1">
                {value.name}
              </Typography>
              {value.type === "multiple" && (
                <Box flex="5" margin="8px 0">
                  {/* 새로운 나라 추가 */}
                  {
                    <Box margin="0 0 8px 0">
                      <Select
                        value={newCountry}
                        onChange={handleChangeCountry}
                        label="filter"
                        style={{ minWidth: "100px" }}
                      >
                        {value.option.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <Button
                        variant="outlined"
                        style={{ borderRadius: "unset", margin: "0 8px" }}
                        onClick={() => {
                          handleChange({
                            target: { name: newCountry, value: 0 },
                          });
                        }}
                      >
                        나라 추가
                      </Button>
                    </Box>
                  }
                  {/* 나라별 관부가세율  */}
                  {rows.hasOwnProperty(value.column) &&
                    Object.keys(rows[value.column]).map((country) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        margin="0 0 8px 0"
                      >
                        <Typography>{country}</Typography>
                        <TextField
                          size="small"
                          value={rows[value.column][country]}
                          name={country}
                          type="number"
                          step="0.01"
                          onChange={handleChange}
                          style={{ margin: "0 8px" }}
                        />
                        <Button
                          variant="outlined"
                          style={{ borderRadius: "unset" }}
                          onClick={() => {
                            handleDeleteCountry(country);
                          }}
                        >
                          삭제
                        </Button>
                      </Box>
                    ))}
                </Box>
              )}
              {value.type === "select" && (
                <Select
                  name={value.column}
                  value={rows[value.column]}
                  onChange={handleChange}
                  label="filter"
                  sx={{ flex: "5" }}
                >
                  {value.option.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id ? option.id : option.value}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {(value.type === "string" || value.type === "number") && (
                <TextField
                  sx={{ flex: "5" }}
                  value={rows[value.column]}
                  name={value.column}
                  type={value.type}
                  onChange={handleChange}
                />
              )}
              {value.type === "multiselect" && (
                <Select
                  name={value.column}
                  value={rows[value.column] ? rows[value.column] : []}
                  onChange={handleChange}
                  label="filter"
                  sx={{ flex: "5" }}
                  multiple
                >
                  {categoryList.map((data) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.category_name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
        <ListItem>
          <Typography variant="subtitle1" component="p" flex="1">
            사이즈 이미지 (선택사항)
          </Typography>
          <Box flex="5">
            <Box margin="8px 0">
              <Button variant="outlined" style={{ borderRadius: "unset" }}>
                <label
                  htmlFor="size"
                  style={{
                    cursor: "pointer",
                    padding: "8px",
                  }}
                >
                  이미지 파일 선택
                </label>
              </Button>
              <input
                type="file"
                accept="image/*"
                name="images"
                id="size"
                multiple
                onChange={handleChange}
              />
            </Box>
            <Box display="flex" flexWrap="wrap">
              {rows.hasOwnProperty("images") &&
                rows.images.map((img) => (
                  <Box position="relative" margin="0 4px 0 0">
                    <img
                      src={
                        typeof img === "object" ? URL.createObjectURL(img) : img
                      }
                      style={{ width: "300px" }}
                    />
                    <ClearIcon
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 10,
                        top: 10,
                      }}
                      onClick={() => {
                        handleDeleteImage(
                          typeof img === "object" ? img.name : img
                        );
                      }}
                    />
                  </Box>
                ))}
            </Box>
          </Box>
        </ListItem>
      </List>
    </Box>
  );
};

export default EditData;
