import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import Slider from "react-slick";
import {
  borderColor,
  BREAK_POINT_PC,
  BREAK_POINT_TABLET,
  StyledLink,
} from "../../../common/style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { inputNumberWithComma } from "../../../common/js/common";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <NavigateNextIcon
        style={{
          color: "black",
          fontSize: "4rem",
          transform: "translate(100px,-60px)",
        }}
      />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <NavigateBeforeIcon
        style={{
          color: "black",
          fontSize: "4rem",
          transform: "translate(-140px,-60px)",
        }}
      />
    </div>
  );
}

// 모바일, pc 이벤트 유형 3
const LookBookItemList = ({ items, lookbookImages }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [selectedImage, setSelectedImage] = useState([
    ...lookbookImages[Object.keys(lookbookImages)[0]],
  ]);

  const pcSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (index) => {
      setSelectedImage([...lookbookImages[Object.keys(lookbookImages)[index]]]);
    },
  };

  const mobileSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    appendDots: (dots) => (
      <div
        style={{
          bottom: "25px",
        }}
      >
        <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
      </div>
    ),
    afterChange: (index) => {
      setSelectedImage([...lookbookImages[Object.keys(lookbookImages)[index]]]);
    },
  };

  return (
    <Box>
      {/* pc 버전 */}
      {windowSize.width >= BREAK_POINT_PC ? (
        <Box padding="5rem" border={`1px solid ${borderColor}`}>
          <Slider {...pcSettings}>
            {Object.keys(lookbookImages).map((img) => (
              <Box>
                <img
                  src={img}
                  alt="룩북 이미지"
                  width="100%"
                  height={windowSize.width * 0.6}
                  style={{
                    backgroundImage: !img ? `url(${defaultImg})` : "none",
                    objectFit: "contain",
                  }}
                />
              </Box>
            ))}
          </Slider>
          <Box display="flex" overflow="scroll">
            {selectedImage.map((id) => (
              <Box
                margin="2rem 2rem 2rem 0"
                sx={{ minWidth: "33%", maxWidth: "33%" }}
                key={id}
              >
                {items.map((info, index) =>
                  info.item_option_id === id ? (
                    <Box key={info.item_option_id}>
                      <StyledLink
                        to={`items/${info.item_id}/${info.item_detail_id}/${info.item_option_id}`}
                      >
                        <img
                          src={info.images}
                          alt="제품 이미지"
                          width="100%"
                          height={
                            (((windowSize.width * 0.75 - 80) / 3 - 32) * 100) /
                            66
                          }
                          style={{
                            backgroundImage: !info.images
                              ? `url(${defaultImg})`
                              : "none",
                            objectFit: "contain",
                          }}
                        />
                        <Box padding="0.8rem 0">
                          <Typography
                            noWrap={true}
                            variant="body1"
                            fontWeight="500"
                          >
                            {info.item_name}
                          </Typography>
                          <Box display="flex">
                            {info.sale_price !== info.item_price ? (
                              <Typography
                                variant="subtitle2"
                                fontWeight="700"
                                component="span"
                                color="primary"
                                marginRight="0.3rem"
                              >
                                {Math.round(
                                  100 -
                                    (info.sale_price / info.item_price) * 100
                                )}
                                %
                              </Typography>
                            ) : null}
                            <Typography variant="subtitle2" fontWeight="700">
                              {inputNumberWithComma(info.sale_price)}원
                            </Typography>
                          </Box>
                        </Box>
                      </StyledLink>
                    </Box>
                  ) : null
                )}
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        // 모바일 버전
        <Box padding="1.2rem">
          <Slider {...mobileSettings}>
            {Object.keys(lookbookImages).map((img) => (
              <Box>
                <img
                  src={img}
                  alt="룩북 이미지"
                  width="100%"
                  height={windowSize.width * 0.9}
                  style={{
                    backgroundImage: !img ? `url(${defaultImg})` : "none",
                    objectFit: "contain",
                  }}
                />
              </Box>
            ))}
          </Slider>
          <Box display="flex" overflow="scroll">
            {selectedImage.map((id) => (
              <Box
                margin="2rem 0.5rem 2rem 0"
                sx={{ minWidth: "33%", maxWidth: "33%" }}
                key={id}
              >
                {items.map((info, index) =>
                  info.item_option_id === id ? (
                    <Box key={info.item_option_id}>
                      <StyledLink
                        to={`items/${info.item_id}/${info.item_detail_id}/${info.item_option_id}`}
                      >
                        <img
                          src={info.images}
                          alt="제품 이미지"
                          width="100%"
                          height={
                            (((windowSize.width - 20) / 3 - 8) * 100) / 66
                          }
                          style={{
                            backgroundImage: !info.images
                              ? `url(${defaultImg})`
                              : "none",
                            objectFit: "contain",
                          }}
                        />
                        <Box padding="0.8rem 0">
                          <Typography
                            noWrap={true}
                            variant="body1"
                            fontWeight="500"
                          >
                            {info.item_name}
                          </Typography>
                          <Box display="flex">
                            {info.sale_price !== info.item_price ? (
                              <Typography
                                variant="subtitle2"
                                fontWeight="700"
                                component="span"
                                color="primary"
                                marginRight="0.3rem"
                              >
                                {Math.round(
                                  100 -
                                    (info.sale_price / info.item_price) * 100
                                )}
                                %
                              </Typography>
                            ) : null}
                            <Typography variant="subtitle2" fontWeight="700">
                              {inputNumberWithComma(info.sale_price)}원
                            </Typography>
                          </Box>
                        </Box>
                      </StyledLink>
                    </Box>
                  ) : null
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LookBookItemList;
