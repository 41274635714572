import {
  Box,
  Checkbox,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { padding } from "@mui/system";
import React, { useState } from "react";
import { convertDateTime } from "../../../common/js/common";
import { primary, secondary, StyledLink } from "../../../common/style/styles";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const DataTable = ({
  submenu,
  dataList,
  selectedIDs,
  handleClickCheckBox,
  handleDrag,
}) => {
  const [dragBtn, setDragBtn] = useState(false);

  const onClickDragBtn = () => {
    setDragBtn((prev) => !prev);
  };
  return (
    <List>
      {dataList.map((data) => (
        <ListItem sx={{ justifyContent: "space-between" }}>
          <Box
            display="flex"
            alignItems="center"
            draggable={dragBtn}
            onDragStart={(event) => {
              handleDrag(event, data.id);
            }}
            onDragEnd={(event) => {
              handleDrag(event, data.id);
              onClickDragBtn();
            }}
            onDragOver={(event) => {
              handleDrag(event, data.id);
            }}
          >
            <Box sx={{ cursor: "pointer" }} onMouseDown={onClickDragBtn}>
              <DragHandleIcon />
            </Box>
            <Checkbox
              checked={selectedIDs.includes(data.id) ? true : false}
              onChange={() => handleClickCheckBox(data.id)}
            />

            <StyledLink to={`/admin/etc/${submenu}/show/${data.id}`}>
              <Box display="flex">
                <Typography>{data.title}</Typography>
                {data.is_banner ? (
                  <Typography
                    margin="0 4px"
                    border={`1px solid ${primary}`}
                    borderRadius="4px"
                    padding="0 4px"
                    color={primary}
                  >
                    배너
                  </Typography>
                ) : null}
                {!data.is_show ? (
                  <Typography
                    margin="0 4px"
                    border={`1px solid ${secondary}`}
                    borderRadius="4px"
                    padding="0 4px"
                    color={secondary}
                  >
                    비공개
                  </Typography>
                ) : null}
              </Box>
            </StyledLink>
          </Box>
          <Typography>{convertDateTime(new Date(data.created_at))}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default DataTable;
