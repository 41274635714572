import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReviewItem from "../../../common/components/review_item";
import {
  primary,
  borderColor,
  BREAK_POINT_PC,
} from "../../../common/style/styles";
import { getDataFromServer } from "../../../common/network/network";
import { useMutation } from "react-query";
import PagingButton from "./paging_button";
import { Reviews } from "@mui/icons-material";
import { TailSpin } from "react-loader-spinner";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

// 상품 상세페이지의 리뷰 페이지
const ItemReview = ({ itemInfo, reviewCount }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [reviews, setReviews] = useState([]);
  const [reviewRate, setReviewRate] = useState(0);
  const [offset, setOffset] = useState(0);

  // prams가 변경되었을 때 서버로부터 데이터 가져오기
  const mutation = useMutation("ReviewDataList", () => {
    return getDataFromServer(
      `/service/reviews/${itemInfo.item_info.item_id}?reference=${itemInfo.item_info.reference}&offset=${offset}`
    );
  });

  useEffect(() => {
    if (reviewCount == 0) {
      return;
    }

    mutation.mutate(offset, {
      onSuccess: (data) => {
        if (offset === 0) {
          setReviews([...data.review_list]);
        } else {
          setReviews([...reviews, ...data.review_list]);
        }
        setReviewRate(data.review_rating);
      },
    });
  }, [offset]);

  // 상품리뷰 리스트 더 가져오기
  const handleClickButton = () => {
    setOffset(offset + 20);
  };

  return (
    <Box margin={windowsize.width >= BREAK_POINT_PC ? "3.7rem 0" : "1.5rem 0"}>
      {!mutation.isLoading && reviewCount === 0 ? (
        <Box
          margin="15rem 0"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <NoteAltOutlinedIcon
            style={{
              color: borderColor,
              fontSize: `${
                windowsize.width >= BREAK_POINT_PC ? "4.4rem" : "3rem"
              }`,
            }}
          />
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
            fontWeight="700"
            width="max-content"
          >
            아직 작성된 리뷰가 없습니다.
          </Typography>
        </Box>
      ) : (
        <Box padding="0 0 4px 0">
          {/* <Box sx={{ backgroundColor: "white" }} padding="8px 16px">
            <Typography variant="h6">
              <b>{reviewRate}%가 만족한 상품이에요.</b>
            </Typography>
          </Box>
          <Divider /> */}
          {reviews.map((review, index) => (
            <Box margin="8px 0">
              <ReviewItem review={review} />
              {reviews.length - 1 !== index && (
                <Divider
                  style={{
                    borderWidth:
                      windowsize.width >= BREAK_POINT_PC ? "0" : "2px",
                  }}
                />
              )}
            </Box>
          ))}
          {reviewCount < Reviews.length && (
            <Box display="flex" justifyContent="center">
              <PagingButton
                color={"white"}
                handleClickButton={handleClickButton}
              />
            </Box>
          )}
        </Box>
      )}
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "relative",
          top: "50%",
          left: "50%",
          zIndex: 200,
          padding: "16px",
        }}
        wrapperClass=""
        visible={mutation.isLoading}
      />
    </Box>
  );
};

export default ItemReview;
