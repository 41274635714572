import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import {
  borderColor,
  StyledLink,
  grey3,
  BREAK_POINT_PC,
  Column,
} from "../../../common/style/styles";
import { useRecoilState } from "recoil";
import { cartItemNumState, windowSizeState } from "../../../common/state/state";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

export const Index = () => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("userEmail");
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [cartItemNum, setCartItemNum] = useRecoilState(cartItemNumState);

  // 로그인 정보
  const [loginData, setLoginData] = useState({ email: "", pw: "" });
  const [showPassword, setShowPassword] = useState(false);
  // 로그인 버튼 활성화
  const [activate, setActivate] = useState(false);
  const [rememberEmail, setRememberEmail] = useState(false);

  useEffect(() => {
    // 로그인을 한 상태일 경우 홈으로 리디렉션
    getDataFromServer("/service/login/confirm").then((response) => {
      if (response.is_login === true) {
        navigate("/");
      }
    });

    // 저장된 아이디가 있을경우 저장된 아이디 불러오기
    if (userEmail && userEmail !== "null") {
      const info = JSON.parse(userEmail);
      if (info.remember) {
        setLoginData((prev) => {
          return { ...prev, email: info.email };
        });
        setRememberEmail(true);
      }
    }
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleClickRememberEmail = () =>
    setRememberEmail((remember) => !remember);

  // 아이디, 비밀번호를 입력하면 loginData 버튼 활성화
  useEffect(() => {
    if (loginData.email && loginData.pw) {
      setActivate(true);
    }
  }, [loginData]);

  const handleSubmit = () => {
    // 아이디, 비밀번호가 있는지 확인
    if (!loginData.email || !loginData.pw) {
      alert("로그인 정보를 입력해주세요.");
      return;
    }

    // 아이디 저장 여부 확인
    if (rememberEmail) {
      localStorage.setItem(
        "userEmail",
        JSON.stringify({
          remember: true,
          email: loginData.email,
        })
      );
    } else {
      localStorage.setItem("userEmail", JSON.stringify({ remember: false }));
    }

    postDataFromServer("/service/login", loginData)
      .then((response) => {
        // 로그인시 장바구니 뱃지 갯수 업데이트
        getDataFromServer(`/service/carts?token=null&num=true`).then(
          (response) => {
            setCartItemNum(response.item_num);
          }
        );

        // 비로그인 상태로 아이템 구매하기 버튼을 눌렀을 때 로그인 후에 그 아이템 페이지로 이동
        const itemInfo = localStorage.getItem("nonmemberOrderItem");
        if (itemInfo && itemInfo !== "null") {
          const data = JSON.parse(itemInfo);
          navigate(
            `/items/${data.itemInfo.item_id}/${data.itemInfo.item_detail_id}/${data.itemInfo.item_option_id}`
          );
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        if (error.response.status == 410) {
          alert("탈퇴한 회원입니다.");
        } else {
          alert("아이디 또는 비밀번호를 확인해주세요.");
        }

        return;
      });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box display="flex">
        <Box
          marginTop={windowSize.height / 10 + "px"}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "h5" : "subtitle1"}
            marginBottom={windowSize.width >= BREAK_POINT_PC ? "2rem" : "1rem"}
          >
            로그인
          </Typography>
          <Box padding="16px" maxWidth="600px" width="100%">
            {/* 이메일 */}
            <OutlinedInput
              variant="outlined"
              placeholder="이메일 주소"
              id="outlined-id"
              style={{ borderRadius: "0", width: "100%", margin: "0 0 1rem 0" }}
              name="email"
              value={loginData.email}
              onChange={handleChangeInput}
            />

            {/* 비밀번호 */}
            <OutlinedInput
              id="outlined-password"
              placeholder="비밀번호"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              sx={{ borderRadius: "0", width: "100%", margin: "0 0 1rem 0" }}
              name="pw"
              value={loginData.pw}
              onChange={handleChangeInput}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <RemoveRedEyeOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            {/* 아이디 저장 */}
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={rememberEmail}
                onChange={handleClickRememberEmail}
                inputProps={{ "aria-label": "controlled" }}
                style={{
                  padding: `${
                    windowSize.width >= BREAK_POINT_PC
                      ? "9px 9px 9px 0"
                      : "6px 6px 6px 0"
                  } `,
                }}
                size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
              />
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                fontWeight="700"
                color={grey3}
              >
                아이디 저장
              </Typography>
            </Box>
            {/* 로그인 버튼 */}
            <Button
              variant="contained"
              color={activate ? "primary" : "grey"}
              style={{
                width: "100%",
                color: "white",
                margin: `${
                  windowSize.width >= BREAK_POINT_PC ? "2rem 0" : "1rem 0"
                }`,
                borderRadius: "0",
                height: `${
                  windowSize.width >= BREAK_POINT_PC ? "80px" : "54px"
                }`,
              }}
              onClick={handleSubmit}
            >
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight={activate ? "700" : "400"}
              >
                로그인
              </Typography>
            </Button>
            {/* 비밀번호 찾기, 회원가입 */}
            <Box
              display="flex"
              justifyContent="center"
              margin={windowSize.width >= BREAK_POINT_PC ? "1rem 0" : "0"}
            >
              <StyledLink to="/myinfo/password">
                <Typography
                  variant={
                    windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                  }
                  fontWeight="500"
                  color={grey3}
                >
                  비밀번호 찾기
                </Typography>
              </StyledLink>
              <Divider
                flexItem
                orientation="vertical"
                variant="middle"
                style={{
                  margin: "0.3rem 1rem",
                  border: `1px solid ${borderColor}`,
                }}
              />
              <StyledLink to="/join">
                <Typography
                  color={grey3}
                  variant={
                    windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                  }
                  fontWeight="500"
                >
                  회원가입
                </Typography>
              </StyledLink>
              <Divider
                flexItem
                orientation="vertical"
                variant="middle"
                style={{
                  margin: "0.3rem 1rem",
                  border: `1px solid ${borderColor}`,
                }}
              />
              <StyledLink to="/nonmember">
                <Typography
                  color={grey3}
                  variant={
                    windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                  }
                  fontWeight="500"
                >
                  비회원 주문조회
                </Typography>
              </StyledLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </Column>
  );
};
