import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import React, { useState } from "react";
import { BREAK_POINT_PC } from "../../../common/style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

// 비회원 구매 비밀번호 설정
const TempPassword = ({ password, handleChangeOrderInfo }) => {
  const regPassword =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,12}$/;
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConFirmPassword, setShowConFirmPassword] = useState(false);
  const handleClickShowConFirmPassword = () =>
    setShowConFirmPassword((show) => !show);
  const handleMouseDownConFirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
        fontWeight="600"
        margin="0.8rem 1rem"
      >
        비회원 주문조회 비밀번호
      </Typography>
      <Divider />
      <Box margin="0.8rem 1rem">
        <FormControl
          sx={{ width: "100%", borderRadius: "0" }}
          variant="outlined"
        >
          <OutlinedInput
            id="outlined-password"
            placeholder="영문, 특수문자, 숫자 포함 6~12자 이내"
            size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
            type={showConFirmPassword ? "text" : "password"}
            sx={{ borderRadius: "0" }}
            name="temp_pw"
            value={password}
            onChange={(event) =>
              handleChangeOrderInfo("temp_pw", event.target.value)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConFirmPassword}
                  onMouseDown={handleMouseDownConFirmPassword}
                  edge="end"
                >
                  {showConFirmPassword ? (
                    <RemoveRedEyeOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            style={{
              color: "red",
              fontSize: `${
                windowSize.width >= BREAK_POINT_PC ? "10pt" : "7.5pt"
              }`,
            }}
          >
            *특수문자는 @$!%*#?& 만 허용됩니다.
          </FormHelperText>
          {password && regPassword.test(password) === false ? (
            <FormHelperText
              style={{
                color: "red",
                fontSize: `${
                  windowSize.width >= BREAK_POINT_PC ? "10pt" : "7.5pt"
                }`,
              }}
            >
              *비밀번호 형식이 올바르지 않습니다.
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
    </Box>
  );
};

export default TempPassword;
