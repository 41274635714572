import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { convertDate } from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, primary, blue } from "../../../common/style/styles";

// 포인트 적립, 사용 기록
const PointHistory = ({ pointHistories }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <Box margin="1rem">
      {pointHistories.length !== 0 ? (
        pointHistories.map((value) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            margin="0.5rem 0"
          >
            <Box display="flex" alignItems="center">
              <Box minWidth="max-content">
                {value.point > 0 ? (
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="600"
                    color={blue}
                    border="1px solid"
                    padding="0.2rem 0.5rem"
                  >
                    적립
                  </Typography>
                ) : (
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="600"
                    color={primary}
                    border="1px solid"
                    padding="0.2rem 0.5rem"
                  >
                    사용
                  </Typography>
                )}
              </Box>
              <Box margin="0 0.5rem">
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                  component="p"
                >
                  {convertDate(new Date(value.created_at))} {value.content}
                </Typography>
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                  }
                  component="p"
                >
                  {value.item_name}
                </Typography>
              </Box>
            </Box>

            <Typography
              variant={
                windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
              }
              fontWeight="700"
              color={value.point > 0 ? blue : primary}
              minWidth="max-content"
            >
              {value.point}p
            </Typography>
          </Box>
        ))
      ) : (
        <Box
          height={windowsize.height / 2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle1"}
            component="p"
            textAlign="center"
            fontWeight="500"
          >
            포인트 적립, 사용 내역이 없습니다.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PointHistory;
