import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { primary, CenterModalStyle } from "../../../common/style/styles";
import {
  getDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import { TailSpin } from "react-loader-spinner";
import { el } from "date-fns/locale";

// 이벤트 추가 모달
const CurationModal = ({ selectedIDs, isOpened, handleModalChange }) => {
  const [selectedCuration, setSelectedCuration] = useState("");
  const [curationList, setCurationList] = useState([]);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 룩북 이미지 배열
  const [lookbookImages, setLookbookImages] = useState([]);
  // 체크된 룩북 이미지
  const [checkedImages, setCheckedImages] = useState([]);

  // 룩북이미지 선택
  const handleCheckedImageChange = (event) => {
    const { name, checked } = event.target;

    console.log(name, checked);

    if (checked) {
      setCheckedImages((prev) => {
        return [...prev, name];
      });
    } else {
      const newArray = checkedImages.filter((img) => name !== img);
      setCheckedImages([...newArray]);
    }
  };

  // 이벤트 선택
  const handleCuraionChange = (event) => {
    setSelectedCuration(event.target.value);

    const selectedCurationList = curationList.find(
      (curation) => curation.id === event.target.value
    );

    // 이벤트 유형 3이면 해당하는 룩북이미지 불러오기
    if (selectedCurationList.curation_type === 3) {
      getDataFromServer(
        `/admin/items/lookbook/${selectedCurationList.id}`
      ).then((response) => {
        setLookbookImages([...response.images]);
      });
    }
  };

  // 이벤트 목록 불러오기
  useEffect(() => {
    if (isOpened) {
      getDataFromServer("/service/curations").then((response) => {
        setCurationList([...response.curation_list]);
      });
    }
  }, [isOpened]);

  // 이벤트 추가 버튼 클릭
  const handleButtonClick = () => {
    if (!selectedCuration) {
      alert("이벤트를 선택해주세요.");
      return;
    }
    // setLoading(true);
    // setProcessing(true);
    const newData = selectedIDs.filter((value) => value !== -1);
    console.log(checkedImages);
    postDataFromServer("/admin/menu/curation/item", {
      curation_id: selectedCuration,
      images: checkedImages,
      ids: newData.join(","),
    })
      .then((response) => {
        alert("선택한 아이템이 이벤트에 추가되었습니다.");
        setSelectedCuration("");
        handleModalChange("curation");
        setCheckedImages([]);
        setLookbookImages([]);
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  return (
    <Modal
      open={isOpened}
      onClose={() => {
        handleModalChange("curation");
      }}
      sx={{ pointerEvents: processing ? "none" : "auto" }}
    >
      <Box sx={CenterModalStyle} width="50%">
        {/* 이벤트 목록 */}
        <Box padding="16px">
          <Typography variant="h6">이벤트 추가</Typography>
          <Divider />
          <FormControl
            variant="standard"
            sx={{ width: "100%", margin: "8px 0" }}
          >
            <InputLabel>이벤트 목록</InputLabel>
            <Select
              label="curation"
              value={selectedCuration}
              onChange={handleCuraionChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {curationList.map((curation) => (
                <MenuItem value={curation.id}>{curation.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* 룩북 이미지 */}
        {lookbookImages.length ? (
          <Box padding="16px">
            <Divider />
            <Typography variant="subtitle1">룩북 이미지</Typography>
            <Box display="flex" overflow="scroll">
              {lookbookImages.map((img) => (
                <Box padding="4px">
                  <Checkbox
                    name={img.image}
                    checked={checkedImages[img.image]}
                    onChange={handleCheckedImageChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <img width="150px" src={img.image} />
                </Box>
              ))}
            </Box>
            <Divider />
          </Box>
        ) : null}
        {/* 이벤트 추가 버튼 */}
        <Box
          padding="16px"
          sx={{
            backgroundColor: `${primary}`,
            textAlign: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleButtonClick}
        >
          선택한 아이템을 해당 이벤트에 추가
        </Box>
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Modal>
  );
};

export default CurationModal;
