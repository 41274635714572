import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDataFromServer } from "../../../common/network/network";
import CommonMenu from "../components/common_menu";
import PersonalMenu from "../components/personal_menu";
import { cartItemNumState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import PersonalInfo from "../components/personal_info";
import { Column } from "../../../common/style/styles";

export const Index = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [cartItemNum, setCartItemNum] = useRecoilState(cartItemNumState);

  // 회원 정보 가져오기
  useEffect(() => {
    getDataFromServer("/service/users/info")
      .then((response) => {
        setUserInfo({ ...response.info });
      })
      .catch((error) => navigate("/login"));
  }, []);

  // 로그아웃시 저장한 정보 삭제
  const handleLogout = () => {
    localStorage.removeItem("itemListData");
    localStorage.removeItem("orderItemListData");
    localStorage.removeItem("nonmemberOrderItem");

    getDataFromServer("/service/logout").then((response) => {
      const nonMemberToken = localStorage.getItem("nonMemberToken");
      getDataFromServer(
        `/service/carts?token=${
          nonMemberToken === "undefined" ? null : nonMemberToken
        }&num=true`
      ).then((response) => {
        setCartItemNum(response.item_num);
      });
      navigate("/login");
    });
  };

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box
        margin="8px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <PersonalInfo userInfo={userInfo} />
        <PersonalMenu />
        <CommonMenu handleLogout={handleLogout} />
      </Box>
    </Column>
  );
};
