import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import AddressForm from "../../../common/components/address_form";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC } from "../../../common/style/styles";

// 비회원 구매시 주소 작성
const WriteAddress = ({ handleChangeOrderInfo }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [address, setAddress] = useState({});

  // 배송지 추가, 수정에서 배송지 정보 입력값 변경
  const handleChangeInput = (event) => {
    const { name, value, checked } = event.target;
    // console.log(name, value, checked);
    setAddress((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // 배송지 추가, 수정에서 주소 수정
  const handleChangeAddress = (data) => {
    setAddress((prev) => {
      return {
        ...prev,
        [data[0]["name"]]: data[0]["value"],
        [data[1]["name"]]: data[1]["value"],
      };
    });
  };

  useEffect(() => {
    handleChangeOrderInfo("userInfo", {
      address: address,
      info: { name: address.name, cellphone: address.cellphone },
    });
  }, [address]);

  return (
    <Box>
      <Typography
        variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
        fontWeight="600"
        margin="0.8rem 1rem"
      >
        배송지
      </Typography>
      <Divider />
      <Box margin="0.8rem 1rem">
        <AddressForm
          address={address}
          handleChangeInput={handleChangeInput}
          handleChangeAddress={handleChangeAddress}
          showIsDefalt={false}
        />
      </Box>
    </Box>
  );
};

export default WriteAddress;
