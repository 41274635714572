import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BREAK_POINT_PC,
  grey3,
  grey6,
  StyledLink,
} from "../../../common/style/styles";
import DeliveryStateItem from "./delivery_state_item";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { convertDate, fillZero } from "../../../common/js/common";
import DeliveryState from "./delivery_state";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
// 주문내역 리스트에서 배송상태별 상품 묶음

const DeliveryStateItemList = ({ orderIds, items, handleChangeState }) => {
  const [orderItems, setOrderItems] = useState({});
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const navigate = useNavigate();
  useEffect(() => {
    const tempItems = {};
    for (const id of orderIds) {
      for (const item of items) {
        if (
          convertDate(new Date(item.order_date)).replaceAll("-", "") +
            fillZero(10, item.order_id) ===
          id
        ) {
          // obj에 주문상품 번호 있음
          if (Object.keys(tempItems).includes(id)) {
            // obj에 택배송장 있음
            if (
              Object.keys(tempItems[id]).includes(item.t_invoice) ||
              tempItems[id]["null"]
            ) {
              // 입력한 택배 송장 있음
              if (item.t_invoice) {
                tempItems[id][item.t_invoice].push(item);
                // 택배송장 입력 안함
              } else {
                tempItems[id]["null"].push(item);
              }
              // obj에 택배 송장 없음
            } else {
              // 입력한 택배 송장 있음
              if (item.t_invoice) {
                tempItems[id][item.t_invoice] = [item];
                // 택배송장 입력 안함
              } else {
                tempItems[id]["null"] = [item];
              }
            }

            // obj에 주문상품 번호 없음
          } else {
            // 입력한 택배 송장 있음
            if (item.t_invoice) {
              tempItems[id] = { [item.t_invoice]: [item] };
              // 택배송장 입력 안함
            } else {
              tempItems[id] = { null: [item] };
            }
          }
        }
      }
    }
    // console.log(tempItems);
    setOrderItems(tempItems);
  }, [items]);

  // console.log(orderItems);

  return Object.keys(orderItems).length ? (
    Object.keys(orderItems).map((orderId) => (
      <Box>
        <Box margin="0.5rem 1.5rem">
          {/* 주문번호 : 날짜 + 숫자 10자리수의 order_id */}
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="700"
              >
                주문 번호 {orderId}
              </Typography>
              <StyledLink to={`/orders/${parseInt(orderId.slice(8))}`}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                    }
                    fontWeight="500"
                    color={grey3}
                  >
                    주문 상세
                  </Typography>
                  <NavigateNextOutlinedIcon
                    sx={{
                      fontSize:
                        windowsize.width >= BREAK_POINT_PC ? "1.5rem" : "1rem",
                      color: grey3,
                    }}
                  />
                </Box>
              </StyledLink>
            </Box>
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              fontWeight="500"
              marginTop="0.5rem"
            >
              {convertDate(
                new Date(
                  items.find(
                    (item) => item.order_id === parseInt(orderId.slice(8))
                  ).order_date
                )
              )}{" "}
              결제완료
            </Typography>
            {Object.keys(orderItems[orderId]).find(
              (trackingNum) => trackingNum != "null"
            ) ? null : orderItems[orderId]["null"].find(
                (item) => item.state > 2
              ) ? null : (
              <Box
                marginTop="0.5rem"
                padding="0.5rem"
                color="white"
                sx={{ background: "black", cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/cancel/${parseInt(orderId.slice(8))}/-1/${
                      orderItems[orderId]["null"].find(
                        (item) => item.state == 2
                      )
                        ? "6"
                        : "7"
                    }`
                  );
                }}
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                  }
                  textAlign="center"
                  fontWeight="500"
                >
                  {" "}
                  전체 취소
                </Typography>
              </Box>
            )}
          </Box>
          {Object.keys(orderItems[orderId]).map((trackingNum, index) => (
            <Box margin="1rem 0">
              <DeliveryState items={orderItems[orderId][trackingNum]} />
              {orderItems[orderId][trackingNum].map((item) => (
                <DeliveryStateItem
                  item={item}
                  handleChangeState={handleChangeState}
                />
              ))}

              {index !== Object.keys(orderItems[orderId]).length - 1 && (
                <Divider />
              )}
            </Box>
          ))}
        </Box>

        <Box height="5px" sx={{ background: grey6 }}></Box>
      </Box>
    ))
  ) : (
    <></>
  );
};

export default DeliveryStateItemList;
