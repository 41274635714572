import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, grey3 } from "../../../common/style/styles";

const CouponInfo = () => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const navigate = useNavigate();

  return (
    <>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
        fontWeight="600"
        margin="0.8rem 1rem"
      >
        쿠폰
      </Typography>
      <Divider />
      <Box margin="0.8rem 1rem">
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            사용 가능한 쿠폰
          </Typography>
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            fontWeight="600"
          >
            0장
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="0.8rem"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            사용한 쿠폰 금액
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="600"
            >
              0원
            </Typography>
            <Button
              variant="contained"
              size="small"
              style={{
                margin: "0 0 0 0.5rem",
                background: "black",
                color: "white",
              }}
              onClick={() => {
                navigate("/coupon");
              }}
            >
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
                fontWeight="600"
              >
                쿠폰 선택
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CouponInfo;
