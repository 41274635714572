import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import {
  borderColor,
  lightgrey,
  StyledLink,
} from "../../../common/style/styles";
import defaultImg from "../../../media_assets/image/gallery1.jpg";

const DataList = ({ columns, rows, handleDelete }) => {
  const { pathname } = useLocation();

  return (
    <Box margin="16px">
      <Box margin="8px 0">
        <StyledLink to={pathname.replace("show", "edit")}>
          <Button variant="outlined" style={{ borderRadius: "unset" }}>
            데이터 수정
          </Button>
        </StyledLink>
        <Button
          variant="outlined"
          style={{ borderRadius: "unset", margin: "0 8px" }}
          onClick={handleDelete}
        >
          데이터 삭제
        </Button>
      </Box>
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${lightgrey}` }}>
          데이터 상세보기
        </ListItem>
        <Divider />

        {columns.map((value) => (
          <ListItem
            key={value.column}
            sx={{ flexDirection: "column" }}
            width="100%"
          >
            <Box display="flex" width="100%">
              <Typography component="p" flex="1">
                {value.name}
              </Typography>
              <Typography component="p" flex="5">
                {rows[value.column]}
              </Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
          </ListItem>
        ))}
        <ListItem>
          <Typography component="p" flex="1">
            사이즈 이미지
          </Typography>
          <Box flex="5" flexWrap={true}>
            {rows.images.map((img) => (
              <img
                src={img}
                style={{
                  backgroundImage: `url(${defaultImg})`,
                  width: "300px",
                }}
              />
            ))}
          </Box>
        </ListItem>
      </List>
    </Box>
  );
};

export default DataList;
