import React, { useEffect, useState } from "react";
import MainMenu from "../../../common/components/main_menu";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import { Box, Typography, Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import columnData from "../../../common/json/detail_data_columns.json";
import EditData from "../components/edit_data";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { convertDate } from "../../../common/js/common";
import DataList from "../components/data_list";

const Show = () => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [isAdmin, setIsAdmin] = useState(true);
  const [processing, setProcessing] = useState(false);
  const { mode, id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [columns, setColumns] = useState(
    columnData[`/admin/benefits/promotion-code/${mode}/`]
  );
  const [rows, setRows] = useState({ brands: [-1], categories: [-1] });

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    setRows({
      ...rows,
      [name]: name === "promotion_code" ? value.toUpperCase() : value,
    });
  };

  useEffect(() => {
    setColumns(columnData[`/admin/benefits/promotion-code/${mode}/`]);
    if (mode !== "create") {
      getDataFromServer(`/admin/benefits/promotion-code/${mode}/${id}`).then(
        (response) => {
          setRows({ ...response.data });
        }
      );
    }
  }, [mode]);

  const handleDelete = () => {
    if (processing) {
      return;
    }

    if (window.confirm("삭제하시겠습니까?")) {
      setProcessing(true);

      deleteDataFromServer(`/admin/benefits/promotion-code?id=${id}`)
        .then((response) => {
          alert("삭제가 완료되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  const handleSaveData = () => {
    /*
     {discount_rate : 할인율(할인코드, 쿠폰), discount_price : 할인 금액(할인코드, 쿠폰),  
        point_rate : 지급 포인트율 (포인트),
        point : 지급 포인트 (포인트),
        max_discount_price : 최대 할인 금액, 
        min_price : 최소 적용 금액, benefits_type: 혜택 종류, expired_at : 소멸시점,
        brands: 적용 브랜드.
        categories: 적용 카테고리,
        exclude_sale_item: 세일상품 포함,
        purchase_start_date: 구매 시작일,
        purchase_end_date: 구매 종료일, 
        purchase_min_count: 구매 최대 횟수, 
        purchase_max_count: 구매 최소 횟수 
        }
    */
    const newRows = {
      name: rows.name,
      event_type: 2,
      started_at: convertDate(new Date(rows.started_date)),
      ended_at: convertDate(new Date(rows.ended_date)),
      is_show: rows.is_show,
      content: {
        discount_rate: rows.discount_type === "rate" ? rows.discount : null,
        discount_price: rows.discount_type === "price" ? rows.discount : null,
        benefits_type: rows.benefits_type,
        brands: rows.brands,
        categories: rows.categories,
        exclude_sale_item: rows.exclude_sale_item,
        promotion_code: rows.promotion_code,
        is_reusable: rows.is_reusable,
        purchase_start_date: rows.purchase_start_date
          ? convertDate(new Date(rows.purchase_start_date))
          : null,
        purchase_end_date: rows.purchase_end_date
          ? convertDate(new Date(rows.purchase_end_date))
          : null,
        purchase_min_count: rows.purchase_min_count,
        purchase_max_count: rows.purchase_max_count,
      },
    };
    console.log(newRows);

    if (mode === "create" || mode === "copy") {
      postDataFromServer("/admin/benefits/promotion-code", newRows).then(
        (response) => {
          alert("정보가 저장되었습니다.");
          navigate(-1);
        }
      );
    } else {
      putDataFromServer(`/admin/benefits/promotion-code/${id}`, newRows).then(
        (response) => {
          alert("정보가 저장되었습니다.");
          navigate(-1);
        }
      );
    }
  };
  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {mode === "show" && Object.keys(rows).length && (
              <DataList
                columns={columns}
                rows={rows}
                handleDelete={handleDelete}
              />
            )}
            {mode !== "show" && (
              <EditData
                columns={columns}
                rows={rows}
                handleChange={handleChange}
                handleSaveData={handleSaveData}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export { Show as BenefitsDetail };
