import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import MainMenu from "../../../common/components/main_menu";
import { preproccessingForDataTable } from "../../../common/js/common";
import columnData from "../../../common/json/detail_data_columns.json";
import DataList from "../components/data_list";
import EditData from "../components/edit_data";
import { TailSpin } from "react-loader-spinner";
import { primary } from "../../../common/style/styles";

const Show = () => {
  const { mode, id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [columns, setColumns] = useState(
    columnData[`/admin/brands/brand/${mode}/`]
  );
  const [rows, setRows] = useState({});
  const country = ["영국", "프랑스", "스페인", "한국"];
  // 업로드된 이미지 중 삭제할 이미지 리스트
  const [deleteImages, setDeleteImages] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  //더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 카테고리 리스트
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    setLoading(true);

    getDataFromServer("/admin/brands/categories").then((response) => {
      setCategoryList([...response.category_list]);
    });

    if (mode === "create") {
      getDataFromServer("/admin/menu/brand")
        .then((response) => {
          setIsAdmin(true);
          const newColumns = columns.map((data) => {
            if (data.column === "menu_id") {
              return {
                ...data,
                option: response.menu,
              };
            }
            return data;
          });
          setColumns([...newColumns]);
        })
        .catch((error) => {
          setIsAdmin(false);
        });
    } else {
      getDataFromServer(`/admin/brands/${id}`)
        .then((response) => {
          setIsAdmin(true);
          let newData;
          if (mode === "show") {
            const epCategories = response.category_list
              .filter((data) =>
                response.brand_info.ep_categories.includes(data.id)
              )
              .map((data) => {
                return data.category_name;
              });
            newData = {
              ...preproccessingForDataTable(
                `/admin/brands/brand/show`,
                response.brand_info
              )[0],
              ep_categories: epCategories.join(", "),
            };
          } else {
            newData = response.brand_info;
            const newColumns = columnData[`/admin/brands/brand/${mode}/`].map(
              (data) => {
                if (data.column === "menu_id") {
                  return { ...data, option: response.menu };
                }
                return data;
              }
            );
            setColumns([...newColumns]);
          }
          setRows({ ...newData });
        })
        .catch((error) => {
          setIsAdmin(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [pathname]);

  // 브랜드 삭제
  const handleDelete = () => {
    if (hasClick) {
      return;
    }

    if (window.confirm("삭제하시겠습니까?")) {
      setLoading(true);
      setProcessing(true);
      setHasClick(true);
      deleteDataFromServer(`/admin/brands/${id}`)
        .then((response) => {
          alert("삭제가 완료되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    }

    setLoading(false);
    setProcessing(false);
    setHasClick(false);
  };

  const handleSaveData = () => {
    if (hasClick) {
      return;
    }
    // 비어있는 정보가 없는지 확인
    const tempColumns = columns.filter((value) => !value.null);
    const isEmpty = tempColumns.find(
      (value) => !rows.hasOwnProperty(value.column)
    );

    if (isEmpty) {
      alert("정보를 입력해주세요.");
      return;
    }

    if (!Object.keys(rows.tax_rate).length) {
      alert("관부가세 대상 나라를 1개 이상 선택해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);
    setHasClick(true);

    const brandName = rows.name.replaceAll(/[ㄱ-ㅎ|가-힣]/g, "").trim();
    const formData = new FormData();
    const newData = {
      ...rows,
      images: rows.images
        ? rows.images.filter((img) => typeof img === "object")
        : [],
    };

    for (const [key, value] of Object.entries(newData["tax_rate"])) {
      if (parseFloat(value) < 1.0) {
        alert("관부가세율은 1.0 이상이어야 합니다.");
        return;
      }
    }

    // 이미지 중 새로 추가한 이미지만 업로드 하기
    newData.images &&
      newData.images.map((img, index) => {
        typeof img === "object" &&
          formData.append(`${brandName}_${index}`, img);
      });
    formData.append("info", JSON.stringify(newData));

    if (mode === "edit") {
      formData.append("deleteImages", JSON.stringify(deleteImages));
      putDataFromServer(`/admin/brands/${id}`, formData)
        .then((response) => {
          alert("브랜드 정보가 수정되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
          setHasClick(false);
        });
    } else if (mode === "create") {
      postDataFromServer("/admin/brands", formData)
        .then((response) => {
          alert("브랜드 정보가 저장되었습니다.");
          navigate(-1);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        })
        .finally(() => {
          setLoading(false);
          setProcessing(false);
          setHasClick(false);
        });
    }
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    // 부가세 수정
    if (country.includes(name)) {
      setRows({
        ...rows,
        tax_rate: { ...rows.tax_rate, [name]: value },
      });
      return;
    }
    // 이미지 추가
    if (files) {
      setRows({
        ...rows,
        images: [...rows.images, ...files],
      });
      return;
    }
    setRows({ ...rows, [name]: value });
  };

  // 관부가세율 나라 삭제
  const handleDeleteCountry = (name) => {
    let newTaxRate = rows.tax_rate;
    delete newTaxRate[name];
    setRows({ ...rows, tax_rate: newTaxRate });
  };

  // 이미지 파일 삭제
  const handleDeleteImage = (name) => {
    const deleteImg = rows.images.find((data) =>
      typeof img === "object" ? data.name === name : data === name
    );
    const newImgArray = rows.images.filter((data) =>
      typeof img == "object" ? data.name !== name : data !== name
    );

    if (!window.confirm("이미지를 삭제하시겠습니까?")) {
      return;
    }

    if (typeof deleteImg === "string") {
      setDeleteImages([...deleteImages, deleteImg]);
    }

    setRows({ ...rows, images: newImgArray });
  };

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            {mode === "show" && Object.keys(rows).length && (
              <DataList
                columns={columns}
                rows={rows}
                handleDelete={handleDelete}
              />
            )}
            {mode === "edit" && Object.keys(rows).length > 0 && (
              <EditData
                columns={columns}
                rows={rows}
                handleChange={handleChange}
                handleSaveData={handleSaveData}
                handleDeleteCountry={handleDeleteCountry}
                handleDeleteImage={handleDeleteImage}
                categoryList={categoryList}
              />
            )}
            {mode === "create" && (
              <EditData
                columns={columns}
                rows={rows}
                handleChange={handleChange}
                handleSaveData={handleSaveData}
                handleDeleteCountry={handleDeleteCountry}
                handleDeleteImage={handleDeleteImage}
                categoryList={categoryList}
              />
            )}
          </Box>
          <TailSpin
            height="80"
            width="80"
            color={primary}
            radius="1"
            wrapperStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 200,
            }}
            wrapperClass=""
            visible={loadling}
          />
        </Box>
      )}
    </>
  );
};

export { Show as BrandDetail };
