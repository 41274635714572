import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { inputNumberWithComma, sumOfItems } from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, grey3 } from "../../../common/style/styles";

const OrderInfo = ({
  orderInfo,
  items,
  point,
  cancelCharge,
  promotionCode,
}) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  //구매시 사용 포인트
  const usedPoint = point
    ? point
    : items.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.point ? currentValue.point : 0);
    }, 0);

  //구매 국가 리스트
  const country = [
    ...new Set(
      items.map((item) => {
        return item.country ? item.country : item.order_country;
      })
    ),
  ];
  //구매 국가별 아이템 갯수
  const itemCountByCountry = items
    .map((item) => {
      return item.country ? item.country : item.order_country;
    })
    .reduce((cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {});
  //국가별 취소 아이템 갯수
  const cancelItemCountByCountry = items
    .filter((value) => value.state === 7 || value.state === 9)
    .map((item) => {
      return item.country ? item.country : item.order_country;
    })
    .reduce((cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {});
  //취소 금액
  const cancelPrice = sumOfItems(
    items.filter((value) => value.state === 7 || value.state === 9)
  );
  // 취소 배송비
  const cacelShipmentFee =
    country.filter(
      (value) =>
        value && itemCountByCountry[value] === cancelItemCountByCountry[value]
    ).length * 10000;
  // 취소 포인트
  const cancelPoint = items.reduce((accumulator, currentValue) => {
    if (currentValue.state === 7 || currentValue.state === 9) {
      return accumulator + currentValue.point;
    }
    return accumulator;
  }, 0);

  return (
    <Box>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
        fontWeight="600"
        margin="0.8rem 1rem"
      >
        결제 정보
      </Typography>
      <Divider />
      <Box margin="0.8rem 1rem">
        {orderInfo && (
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="0.8rem"
          >
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              color={grey3}
              fontWeight="500"
            >
              결제 방법
            </Typography>
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="600"
            >
              {orderInfo.payment_method === "kakaopay" && "카카오페이"}
              {orderInfo.payment_method === "naverpay" && "네이버페이"}
              {orderInfo.payment_method === "html5_inicis" &&
                "신용/체크카드 결제"}
              {orderInfo.payment_method === "tosspayments" &&
                "신용/체크카드 결제"}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="0.8rem"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            총 상품 금액
          </Typography>
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            fontWeight="600"
          >
            {inputNumberWithComma(sumOfItems(items))}원
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="0.8rem"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            쿠폰 할인
          </Typography>
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            fontWeight="600"
          >
            0원
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="0.8rem"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            프로모션 할인
          </Typography>
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            fontWeight="600"
          >
            {inputNumberWithComma(promotionCode, true)}원
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="0.8rem"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            포인트 사용
          </Typography>
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            fontWeight="600"
          >
            {inputNumberWithComma(usedPoint, true)}원
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="0.8rem"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            color={grey3}
            fontWeight="500"
          >
            배송비
          </Typography>
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            fontWeight="600"
          >
            {inputNumberWithComma(
              country.filter((value) => value).length * 10000
            )}
            원
          </Typography>
        </Box>
        {cancelCharge > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="0.8rem"
          >
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              color={grey3}
              fontWeight="500"
            >
              취소수수료
            </Typography>
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="600"
            >
              -{inputNumberWithComma(cancelCharge, true)}원
            </Typography>
          </Box>
        )}
        {cancelPrice > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="0.8rem"
          >
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              color={grey3}
              fontWeight="500"
            >
              취소금액
            </Typography>
            <Typography
              variant={
                windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
              }
              fontWeight="600"
            >
              -
              {inputNumberWithComma(
                cancelPrice + cacelShipmentFee + cancelPoint - cancelCharge,
                true
              )}
              원
            </Typography>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom="0.8rem"
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "body1"}
            fontWeight="700"
          >
            총 결제 금액
          </Typography>
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "body1"}
            fontWeight="700"
            color="red"
          >
            {inputNumberWithComma(
              sumOfItems(items) +
              usedPoint +
              promotionCode -
              (cancelPrice + cacelShipmentFee + cancelPoint - cancelCharge
                ? cancelCharge
                : 0) +
              country.filter((value) => value).length * 10000,
              true
            )}
            원
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderInfo;
