import { Box, Button, Typography } from "@mui/material";
import React from "react";
import deliveryColde from "../../../common/json/delivery_code.json";
import {
  blue,
  BREAK_POINT_PC,
  grey3,
  grey4,
} from "../../../common/style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

//주문내역의  배송 상태, 배송 조회
/*
1 : 구매완료 & 신규주문
2 : 배송준비
3 : 배송중
4 : 배송완료
*/
const DeliveryState = ({ items }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const deliveryCompany = items[0].t_code
    ? deliveryColde.data.find((code) => items[0].t_code === code.sweettracker)[
        "name"
      ]
    : null;
  const deliveryCode = items[0].t_code;

  const deliveryNumber = items[0].t_invoice;
  const country = items[0].shipment_country;

  const stateArray = items.map((item) => {
    return item.state;
  });

  let state = null;

  for (const item of items) {
    // 결제완료
    if (item.state == 1 || !item.state_record.hasOwnProperty(2)) {
      state = 1;
    }

    // 배송준비
    if (
      item.state == 2 ||
      (item.state_record.hasOwnProperty(2) &&
        !item.state_record.hasOwnProperty(3))
    ) {
      state = 2;
    }

    //배송중
    if (
      item.state == 3 ||
      (item.state_record.hasOwnProperty(3) &&
        !item.state_record.hasOwnProperty(4))
    ) {
      state = 3;
    }

    //배송완료
    if (item.state == 4 || item.state_record.hasOwnProperty(4)) {
      state = 4;
    }
  }

  const cancelState = stateArray.find((state) => state === 6 || state === 7);
  return (
    <Box>
      {/* 배송상태 */}
      <Box display="flex">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
          fontWeight="500"
          padding="0.3rem 0.8rem"
          borderRadius="2px"
          border="1px solid white"
          borderColor={
            state == 1
              ? cancelState
                ? grey3
                : "white"
              : cancelState
              ? grey3
              : blue
          }
          sx={{
            background: state == 1 ? (cancelState ? grey3 : blue) : "white",
            color: state == 1 ? "white" : cancelState ? grey3 : blue,
          }}
        >
          결제완료
        </Typography>
        <Box
          marginTop={windowsize.width >= BREAK_POINT_PC ? "0.8rem" : "0.6rem"}
          width={windowsize.width >= BREAK_POINT_PC ? "20px" : "12px"}
          height="2px"
          border="1px solid white"
          borderColor={state < 2 ? grey4 : blue}
        />
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
          fontWeight="500"
          padding="0.3rem 0.8rem"
          borderRadius="2px"
          border="1px solid white"
          borderColor={state < 2 ? grey4 : blue}
          sx={{
            background:
              state == 2
                ? cancelState
                  ? grey3
                  : blue
                : state < 2
                ? "white"
                : "white",
            color: state == 2 ? "white" : state < 2 ? grey4 : blue,
          }}
        >
          배송준비
        </Typography>
        <Box
          marginTop={windowsize.width >= BREAK_POINT_PC ? "0.8rem" : "0.6rem"}
          width={windowsize.width >= BREAK_POINT_PC ? "20px" : "12px"}
          height="2px"
          border="1px solid white"
          borderColor={state < 3 ? grey4 : blue}
        />
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
          fontWeight="500"
          padding="0.3rem 0.8rem"
          borderRadius="2px"
          border="1px solid white"
          borderColor={state < 3 ? grey4 : blue}
          sx={{
            background: state < 3 ? "white" : state === 3 ? blue : "white",
            color: state < 3 ? grey4 : state === 3 ? "white" : blue,
          }}
        >
          배송중
        </Typography>
        <Box
          marginTop={windowsize.width >= BREAK_POINT_PC ? "0.8rem" : "0.6rem"}
          width={windowsize.width >= BREAK_POINT_PC ? "20px" : "12px"}
          height="2px"
          border="1px solid white"
          borderColor={state < 4 ? grey4 : blue}
        />

        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
          fontWeight="500"
          padding="0.3rem 0.8rem"
          borderRadius="2px"
          border="1px solid white"
          borderColor={state === 4 ? blue : grey4}
          sx={{
            background: state === 4 ? blue : "white",
            color: state === 4 ? "white" : grey4,
          }}
        >
          배송완료
        </Typography>
      </Box>
      {/* 배송조회 */}
      {country ? (
        <Box
          // display="flex"
          // alignItems={
          //   windowsize.width >= BREAK_POINT_PC ? "center" : "flex-start"
          // }
          margin="1rem 0"
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            // alignItems="baseline"
          >
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              color={grey3}
            >
              출고국가
            </Typography>
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              margin="0 0.4rem"
            >
              {country}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            margin={
              windowsize.width >= BREAK_POINT_PC
                ? "1rem 0"
                : "0.5rem 0 0.3rem 0"
            }
          >
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              color={grey3}
            >
              택배사
            </Typography>
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              margin="0 0.4rem"
            >
              {deliveryCompany}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            // marginRight={windowsize.width >= BREAK_POINT_PC ? "1rem" : "0.5rem"}
          >
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              color={grey3}
            >
              송장번호
            </Typography>
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"}
              margin="0 0.4rem"
            >
              {deliveryNumber}
            </Typography>
            <a
              target="_blank"
              href={
                deliveryCompany === "LX판토스"
                  ? `https://www.epantos.com/ecp/web/pr/dt/popup/dlvChaseInqPopup.do?locale=ko&quickNo=${deliveryNumber}`
                  : `https://www.acieshop.com/pod.php?OrderNo=${deliveryNumber}`
              }
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  color: "white",
                  background: "black",
                  margin: "0 0.4rem",
                }}
                type="submit"
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                  }
                >
                  배송조회
                </Typography>
              </Button>
            </a>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DeliveryState;
