import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BREAK_POINT_PC,
  Column,
  grey3,
  grey4,
  primary,
} from "../../../common/style/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useRecoilState } from "recoil";
import {
  convertDate,
  inputNumberWithComma,
  selectImageThumbnail,
  selectItemImages,
  sumOfItems,
} from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import {
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import Address from "../components/address";
import BottomButton from "../../../common/components/bottom_button";
import AlertModal from "../../../common/modal/alert_modal";

// 취소, 반품, 교환
const Cancel = () => {
  const { orderId, orderItemId, state } = useParams();
  const navigate = useNavigate();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [selectedReason, setSelectedReason] = useState("");
  const [message, setMessage] = useState("");
  const [selectedReturn, setSelectedReturn] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  // 로그인했는지 확인
  const [isLogin, setIsLogion] = useState(false);
  // 배송지 정보
  const [addressInfo, setAddressInfo] = useState({});
  // 주문 정보
  const [orderInfo, setOrderInfo] = useState({});
  // 상품 정보
  const [itemInfo, setItemInfo] = useState([]);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  const [isOpened, setIsOpend] = useState(false);
  const [items, setItems] = useState([]);

  // 같이 배송된 상품 반품시 취소 수수료 한번만 부과
  const cancelItemInfo = items.find(
    (item) => orderItemId == item.order_item_id
  );
  const deliveryItemCheck = items.filter(
    (item) =>
      orderItemId !== item.order_item_id &&
      item.state == state &&
      item.t_invoice === cancelItemInfo.t_invoice
  );

  const usedPont = itemInfo.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.point ? currentValue.point : 0);
  }, 0);
  const discountPrice = itemInfo.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.point ? currentValue.discount_price : 0);
  }, 0);

  const cancelFee =
    state == 6
      ? 10000
      : state == 8 && deliveryItemCheck.length === 0
      ? 40000
      : state == 10 && deliveryItemCheck.length === 0
      ? 50000
      : 0;

  // 취소 배송비
  const leftItems =
    orderItemId == -1
      ? []
      : items.filter((item) => orderItemId != item.order_item_id);

  const cacelShipmentFee =
    orderItemId != -1
      ? leftItems.find(
          (item) =>
            itemInfo.order_country === item.order_country &&
            item.state !== 7 &&
            item.state !== 9
        )
        ? 0
        : 10000
      : [
          ...new Set(
            itemInfo.map((item) => {
              return item.order_country;
            })
          ),
        ].length * 10000;

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "reason") {
      setSelectedReason(value);
    } else if (name === "message") {
      setMessage(value);
    } else if (name === "return") {
      setSelectedReturn(value);
    } else if (name === "method") {
      setSelectedMethod(value);
    }
  };

  const handleAlertModal = () => {
    setIsOpend((prev) => !prev);
  };

  // 취소 신청 클릭
  const handleClick = () => {
    if (processing) {
      return;
    }

    if (!selectedReason) {
      alert("취소 사유를 선택해주세요.");
      return;
    }

    setProcessing(true);

    // 취소, 반품, 교환 기록 저장
    const newData = {
      order_id: orderId,
      order_item_id: orderItemId,
      content: selectedReason,
      message: message,
      state: state,
      return: selectedReturn,
    };

    // 전체취소
    if (orderItemId == -1) {
      if (!itemInfo.find((item) => item.state === 2)) {
        putDataFromServer(`/service/orders/total-refund/${orderId}`, newData)
          .then((response) => {
            navigate(-1);
          })
          .catch((error) => {
            alert("결제취소에 실패했습니다. 관리자에게 문의해주세요.");
            return;
          });
      } else {
        postDataFromServer("/service/orders/cancel", newData)
          .then((response) => {
            navigate(-1);
          })
          .catch((error) => {
            alert("잠시 후 다시 시도해주세요.");
          });
      }

      // 부분취소
    } else {
      // 결제취소인경우 서버에 취소요청 전달
      if (itemInfo[0].state === 1) {
        putDataFromServer(
          `/service/orders/refund/${orderId}/${orderItemId}`,
          newData
        )
          .then((response) => {
            navigate(-1);
          })
          .catch((error) => {
            alert("결제취소에 실패했습니다. 관리자에게 문의해주세요.");
            return;
          });
      } else {
        postDataFromServer("/service/orders/cancel", newData)
          .then((response) => {
            navigate(-1);
          })
          .catch((error) => {
            alert("잠시 후 다시 시도해주세요.");
          });
      }
    }

    setProcessing(false);
  };

  useEffect(() => {
    getDataFromServer(`/service/orders/${orderId}`)
      .then((response) => {
        // console.log(response);
        setIsLogion(true);
        setItems([...response.order_item_list]);
        setAddressInfo({
          ...response.address_info,
        });
        setOrderInfo({ ...response.order_info });
        if (orderItemId != -1) {
          setItemInfo([
            {
              ...selectImageThumbnail(response.order_item_list).find(
                (item) => item.order_item_id == orderItemId
              ),
            },
          ]);
        } else {
          setItemInfo([...selectImageThumbnail(response.order_item_list)]);
        }
      })
      .catch((error) => {
        // 로그인 안했을 경우 리다이렉트
        alert("로그인 후 사용해주세요.");
        navigate("/login");
        return;
      });
  }, []);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {isLogin ? (
        <Box
          minHeight="100vh"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <Box display="flex" margin="1rem 0" alignItems="center">
            <KeyboardArrowLeftIcon sx={{ flex: "1", cursor: "pointer" }} />
            <Typography
              flex="9"
              textAlign="center"
              marginRight="1.8rem"
              variant="subtitle1"
              fontWeight="700"
            >
              {state == 6 && "취소"} {state == 8 && "반품"}{" "}
              {state == 10 && "교환"} 요청
            </Typography>
          </Box>
          {/* 상품 정보 */}
          <Box>
            <Box
              margin="1rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {itemInfo.length == 1 && itemInfo[0].shipment_country ? (
                <Typography variant="subtitle2" fontWeight="700">
                  {itemInfo[0].shipment_country} 출고
                </Typography>
              ) : null}

              <Typography variant="body1" fontWeight="500">
                {convertDate(new Date(itemInfo[0].order_date))} 결제완료
              </Typography>
            </Box>
            <Divider />
            {itemInfo.map((item) => (
              <Box margin="1rem" display="flex">
                <img
                  src={item.images}
                  width={windowsize.width >= BREAK_POINT_PC ? "115px" : "97px"}
                  height={
                    windowsize.width >= BREAK_POINT_PC ? "160px" : "140px"
                  }
                />
                <Box
                  marginLeft={
                    windowsize.width >= BREAK_POINT_PC ? "1.5rem" : "0.8rem"
                  }
                  width="90%"
                >
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                    fontWeight="700"
                    margin="0.5rem 0 0.2rem 0"
                  >
                    {item.brand_name}
                  </Typography>
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                    fontWeight="700"
                    noWrap={true}
                    width={
                      windowsize.width >= BREAK_POINT_PC
                        ? ((windowsize.width * 9) / 12 - 115) * 0.9
                        : (windowsize.width - 137) * 0.8
                    }
                  >
                    {item.item_name}
                  </Typography>
                  <Box display="flex" margin="0.5rem 0">
                    <Typography
                      color={grey3}
                      variant={
                        windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                      }
                      fontWeight="500"
                    >
                      옵션: {item.color}/{item.size}
                    </Typography>
                    <Typography
                      marginLeft="1rem"
                      color={grey3}
                      variant={
                        windowsize.width >= BREAK_POINT_PC ? "body1" : "body2"
                      }
                      fontWeight="500"
                    >
                      수량: {item.quantity}
                    </Typography>
                  </Box>
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                    }
                    fontWeight="700"
                  >
                    {inputNumberWithComma(item.item_price)}원
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box height="6px" sx={{ background: grey4 }}></Box>
          {/* 반품 사유 */}
          <Box>
            <Typography
              margin="0.5rem 1rem"
              variant="subtitle2"
              fontWeight="700"
            >
              {state == 6 && "취소"} {state == 7 && "취소"}{" "}
              {state == 8 && "반품"} {state == 10 && "교환"}
              사유
            </Typography>
            <Divider />
            <Box margin="1rem">
              <FormControl
                fullWidth
                size={windowsize.width >= BREAK_POINT_PC ? "medium" : "small"}
                style={{ marginBottom: "0.5rem" }}
              >
                <InputLabel
                  style={{
                    display: selectedReason ? "none" : "block",
                    top: "-2px",
                  }}
                  shrink={false}
                >
                  {state == 6 && "취소"} {state == 7 && "취소"}{" "}
                  {state == 8 && "반품"} {state == 10 && "교환"} 사유를
                  선택해주세요
                </InputLabel>
                <Select
                  name="reason"
                  size={windowsize.width >= BREAK_POINT_PC ? "medium" : "small"}
                  value={selectedReason}
                  onChange={handleChange}
                >
                  <MenuItem value="구매의사 취소">
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                    >
                      구매의사 취소
                    </Typography>
                  </MenuItem>
                  <MenuItem value="색상 및 사이즈 변경">
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                    >
                      색상 및 사이즈 변경
                    </Typography>
                  </MenuItem>
                  <MenuItem value="다른 상품 잘못 주문">
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                    >
                      다른 상품 잘못 주문
                    </Typography>
                  </MenuItem>
                  <MenuItem value="서비스 불만족">
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                    >
                      서비스 불만족
                    </Typography>
                  </MenuItem>
                  <MenuItem value="배송지연">
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                    >
                      배송지연
                    </Typography>
                  </MenuItem>
                  <MenuItem value="배송누락">
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC
                          ? "subtitle2"
                          : "body1"
                      }
                    >
                      배송누락
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                placeholder={
                  state == 6
                    ? "취소사유를 입력해주세요"
                    : state == 8
                    ? "반품사유를 입력해주세요"
                    : state == 10 && "교환사유를 입력해주세요"
                }
                multiline
                rows={7}
                inputProps={{
                  style: {
                    fontSize:
                      windowsize.width >= BREAK_POINT_PC ? "16px" : "14px",
                  },
                }}
                fullWidth={true}
                // size="small"
                name="message"
                value={message}
                onChange={handleChange}
              />
            </Box>
          </Box>

          {/* 수거 방법 */}
          {state == 8 || state == 10 ? (
            <Box height="6px" sx={{ background: grey4 }}></Box>
          ) : null}
          {state == 8 || state == 10 ? (
            <Box>
              <Typography
                margin="0.5rem 1rem"
                variant="subtitle2"
                fontWeight="700"
              >
                {state == 8 && "반품"} {state == 10 && "교환"} 수거 방법
              </Typography>
              <Divider />
              <Box margin="1rem">
                <FormControl
                  fullWidth
                  size={windowsize.width >= BREAK_POINT_PC ? "medium" : "small"}
                  style={{ marginBottom: "0.5rem" }}
                >
                  <InputLabel
                    style={{
                      display: selectedReturn ? "none" : "block",
                      top: "-2px",
                    }}
                    shrink={false}
                  >
                    {state == 8 && "반품"} {state == 10 && "교환"} 수거 방법을
                    선택해주세요
                  </InputLabel>
                  <Select
                    name="return"
                    size={
                      windowsize.width >= BREAK_POINT_PC ? "medium" : "small"
                    }
                    value={selectedReturn}
                    onChange={handleChange}
                  >
                    <MenuItem value="지정 택배사 수거요청">
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "subtitle2"
                            : "body1"
                        }
                      >
                        지정 택배사 수거요청
                      </Typography>
                    </MenuItem>
                    <MenuItem value="직접 반품">
                      <Typography
                        variant={
                          windowsize.width >= BREAK_POINT_PC
                            ? "subtitle2"
                            : "body1"
                        }
                      >
                        직접 반품
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          ) : null}
          {/* 환불 정보 */}
          <Box>
            <Typography
              margin="0.5rem 1rem"
              variant="subtitle2"
              fontWeight="700"
            >
              환불 정보
            </Typography>
            <Divider />
            <Box margin="1rem" display="flex" justifyContent="space-between">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
                color={grey3}
              >
                환불 상품 금액
              </Typography>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
              >
                {inputNumberWithComma(sumOfItems(itemInfo))}원
              </Typography>
            </Box>
            <Box margin="1rem" display="flex" justifyContent="space-between">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
                color={grey3}
              >
                배송비
              </Typography>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
              >
                {cacelShipmentFee}원
              </Typography>
            </Box>
            <Box margin="1rem" display="flex" justifyContent="space-between">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
                color={grey3}
              >
                프로모션코드 사용
              </Typography>
              {itemInfo.discount_price}
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
              >
                {inputNumberWithComma(
                  discountPrice ? -discountPrice : "0",
                  true
                )}
                원
              </Typography>
            </Box>
            <Box margin="1rem" display="flex" justifyContent="space-between">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
                color={grey3}
              >
                포인트 사용
              </Typography>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
              >
                {inputNumberWithComma(usedPont ? usedPont : "0", true)}원
              </Typography>
            </Box>
            <Box margin="1rem" display="flex" justifyContent="space-between">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
                color={grey3}
              >
                {state == 6 && "취소"} {state == 8 && "반품"}
                {state == 10 && "교환"} 수수료
              </Typography>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="500"
              >
                {cancelFee > 0
                  ? `-${inputNumberWithComma(cancelFee)}원`
                  : "0원"}
              </Typography>
            </Box>
            <Box margin="1rem" display="flex" justifyContent="space-between">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                fontWeight="700"
              >
                {" "}
                총 환불 금액
              </Typography>
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "subtitle2"
                }
                color={primary}
                fontWeight="700"
              >
                {inputNumberWithComma(
                  parseInt(sumOfItems(itemInfo)) +
                    cacelShipmentFee +
                    (usedPont ? usedPont : 0) -
                    (discountPrice ? discountPrice : 0) -
                    cancelFee,
                  true
                )}
                원
              </Typography>
            </Box>
          </Box>
          <BottomButton
            phrase={
              state == 6
                ? "취소 신청"
                : state == 7
                ? "구매 취소"
                : state == 8
                ? "반품 신청"
                : state == 10 && "교환 신청"
            }
            handleClick={handleAlertModal}
          />
          <AlertModal
            title={
              state == 6 || state == 7
                ? "선택하신 주문을 취소하시겠습니까? "
                : state == 8
                ? "선택하신 주문을 반품하시겠습니까?"
                : state == 10 && "선택하신 주문을 교환하시겠습니까?"
            }
            content={
              state == 6 || state == 7
                ? "배송준비중 상태에서 취소완료시 별도의 수수료가 발생할 수 있으며 취소수수료는 결제금액에서 차감되어 환불될 예정입니다."
                : state == 8
                ? "소비자의 귀책사유로 반품신청시 반품수수료와 통관시 발생한 관부가세가 추가로 부과될 수 있사오니 참고해 주세요."
                : state == 10 &&
                  "소비자의 귀책사유로 반품신청시 교환수수료와 통관시 발생한 관부가세가 추가로 부과될 수 있사오니 참고해 주세요."
            }
            isOpened={isOpened}
            handleAlertModal={handleAlertModal}
            handleSubmit={handleClick}
          />
        </Box>
      ) : null}
    </Column>
  );
};

export default Cancel;
