import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import {
  BREAK_POINT_TABLET,
  primary,
  StyledLink,
  borderColor,
  BREAK_POINT_PC,
} from "../../../common/style/styles";
import Slider from "react-slick";
import {
  inputNumberWithComma,
  resizeImage,
  selectImageThumbnail,
  selectItemImages,
} from "../../../common/js/common";
import { Button, Typography, Box } from "@mui/material";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Divider } from "@material-ui/core";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <NavigateNextIcon
        style={{
          color: "black",
          fontSize: "4rem",
          transform: "translate(0,-60px)",
        }}
      />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <NavigateBeforeIcon
        style={{
          color: "black",
          fontSize: "4rem",
          transform: "translate(-40px,-60px)",
        }}
      />
    </div>
  );
}

const ItemSlider = ({ items, likeItems }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: windowSize.width >= BREAK_POINT_PC ? 4 : 2,
    slidesToScroll: 1,
    centerMode: windowSize.width >= BREAK_POINT_PC ? false : true,
    arrows: windowSize.width >= BREAK_POINT_PC ? true : false,
    draggable: windowSize.width >= BREAK_POINT_PC ? false : true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <Box key={index}>
          <StyledLink
            to={`/items/${item.item_id}/${item.item_detail_id}/${item.item_option_id}`}
          >
            <Box
              margin={
                windowSize.width >= BREAK_POINT_PC ? "0 0.5rem" : "0 0.3rem"
              }
            >
              <img
                src={selectItemImages(item.images, item.color)[0]}
                width="100%"
                height={
                  windowSize.width >= BREAK_POINT_PC
                    ? (((windowSize.width * 0.75) / 4 - 16) * 100) / 66
                    : ((windowSize.width / 3 - 30) * 100) / 66
                }
                style={{
                  backgroundImage: `url(${defaultImg})`,
                }}
              />
              <Box
                margin={
                  windowSize.width >= BREAK_POINT_PC
                    ? "1.5rem 0 1rem 0"
                    : "0.5rem 0"
                }
              >
                <Typography
                  variant={
                    windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                  }
                  marginRight="16px"
                  fontWeight="500"
                >
                  {item.brand_name}
                </Typography>
                <Typography
                  variant={
                    windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                  }
                  marginRight="16px"
                  noWrap={true}
                  fontWeight="700"
                  component="p"
                >
                  {item.item_name}
                </Typography>
              </Box>
              {windowSize.width >= BREAK_POINT_PC ? <Divider /> : null}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop={windowSize.width >= BREAK_POINT_PC ? "1rem" : "0"}
              >
                <Box display="flex">
                  {item.item_price !== item.sale_price ? (
                    <Typography
                      fontWeight="700"
                      variant={
                        windowSize.width >= BREAK_POINT_PC
                          ? "subtitle1"
                          : "body2"
                      }
                      color={primary}
                      marginRight={
                        windowSize.width >= BREAK_POINT_PC ? "1rem" : "0.3rem"
                      }
                    >
                      {parseInt((item.sale_price / item.item_price) * 100)}%
                    </Typography>
                  ) : null}
                  <Typography
                    fontWeight="700"
                    variant={
                      windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body2"
                    }
                  >
                    {inputNumberWithComma(item.sale_price)}원
                  </Typography>
                </Box>
                {likeItems.includes(item.item_option_id) ? (
                  <FavoriteIcon
                    color="primary"
                    style={{
                      fontSize: `${
                        windowSize.width >= BREAK_POINT_PC ? "2rem" : "1rem"
                      }`,
                    }}
                  />
                ) : (
                  <FavoriteBorderIcon
                    style={{
                      color: borderColor,
                      fontSize: `${
                        windowSize.width >= BREAK_POINT_PC ? "2rem" : "1rem"
                      }`,
                    }}
                  />
                )}
              </Box>
            </Box>
          </StyledLink>
        </Box>
      ))}
    </Slider>
  );
};

export default ItemSlider;
