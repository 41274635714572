import {
  Box,
  Divider,
  FormControl,
  TextField,
  Typography,
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC } from "../../../common/style/styles";
import { getDataFromServer } from "../../../common/network/network";

const regCustomsNumber = /^(P|p)([0-9]{12})$/;

const CustomsNumberInfo = ({ info, handleChange }) => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  return (
    <Box>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
        fontWeight="600"
        margin="0.8rem 1rem"
      >
        개인통관고유번호
      </Typography>
      <Divider />
      <Box margin="0.8rem 1rem">
        <FormControl variant="outlined" fullWidth={true}>
          <OutlinedInput
            placeholder="P로 시작하는 12자리 숫자를 입력해주세요."
            inputProps={{
              style: {
                fontSize: windowSize.width >= BREAK_POINT_PC ? "16px" : "12px",
                padding:
                  windowSize.width >= BREAK_POINT_PC
                    ? "0.5rem"
                    : "0.5rem 0.3rem",
              },
            }}
            name="customs_number"
            value={info.customs_number}
            onChange={(event) => {
              handleChange(event.target.name, event.target.value);
            }}
            size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
          />
          {info.customs_number &&
          regCustomsNumber.test(info.customs_number) === false ? (
            <FormHelperText style={{ color: "red", fontSize: "10px" }}>
              * 통관번호를 바르게 입력해주세요.
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
    </Box>
  );
};

export default CustomsNumberInfo;
