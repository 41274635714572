import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { postDataFromServer } from "../../../common/network/network";
import { CenterModalStyle, primary } from "../../../common/style/styles";

// 브랜드 태그 추가 모달
const BrandTagModal = ({ isOpened, handleChangeModal }) => {
  const [name, setName] = useState("");
  //더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleCloseModal = () => {
    setName("");
    handleChangeModal();
  };

  const handleSaveData = () => {
    if (hasClick) {
      return;
    }

    if (!name) {
      alert("태그명을 입력해주세요.");
      return;
    }

    setLoading(true);
    setProcessing(true);
    postDataFromServer("/admin/menu/brand", { name: name })
      .then((response) => {
        alert("브랜드 태그가 추가되었습니다.");
        handleCloseModal();
      })
      .catch((error) => {
        alert("잠시후 다시 시도해주세요.");
      })
      .finally(() => {
        setLoading(false);
        setProcessing(false);
      });
  };

  return (
    <Modal
      open={isOpened}
      onClose={handleCloseModal}
      sx={{ pointerEvents: processing ? "none" : "auto" }}
    >
      <Box sx={CenterModalStyle} width="50%">
        <Box margin="16px">
          <Typography variant="h6">브랜드 태그 추가</Typography>
          <Divider />
          <Box display="flex" alignItems="center" margin="16px 0">
            <Typography margin="0 8px 0 0">태그명 : </Typography>
            <TextField value={name} onChange={handleChangeName} />
          </Box>
          <Box>
            <Button
              variant="outlined"
              style={{ borderRadius: "unset", margin: "0 8px" }}
              onClick={handleSaveData}
            >
              브랜드 태그 추가
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              style={{ borderRadius: "unset", margin: "0 8px" }}
              onClick={handleCloseModal}
            >
              닫기
            </Button>
          </Box>
        </Box>
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Modal>
  );
};

export default BrandTagModal;
