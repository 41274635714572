import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";
import {
  borderColor,
  BREAK_POINT_PC,
  primary,
  secondary,
} from "../../../common/style/styles";

const CouponItem = () => {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  return (
    <Box
      border={"1px solid " + borderColor}
      padding="0.8rem"
      borderRadius="4px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="0.5rem 0"
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
          fontWeight="600"
        >
          새해 맞이 할인 쿠폰
        </Typography>
        <Button
          variant="contained"
          style={{ borderRadius: "0", background: "black" }}
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
            fontWeight="600"
          >
            적용하기
          </Typography>
        </Button>
      </Box>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "h5" : "h6"}
        fontWeight="600"
        color={primary}
      >
        1,000원
      </Typography>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
        fontWeight="400"
        color={secondary}
        margin="0.5rem 0 0.2rem 0"
      >
        총 상품금액 10,000원 이상 구매 시 사용 가능
      </Typography>
      <Typography
        variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
        fontWeight="400"
        color={secondary}
      >
        23년 2월 01일 - 23년 10월 31일
      </Typography>
    </Box>
  );
};

export default CouponItem;
