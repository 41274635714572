import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postDataFromServer } from "../../../common/network/network";
import { borderColor, info } from "../../../common/style/styles";

const reg = {
  phone: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
  email:
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
};

const EditData = ({ columns, rows }) => {
  const { pathname } = useLocation();
  const [data, setData] = useState({});
  // 이메일 중복인지 체크
  const [isDuplicated, setIsDuplicated] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes("create")) {
      let newData = {};
      columns.map((value) => {
        if (value.type === "boolean") {
          newData[value.column] = false;
        } else {
          newData[value.column] = "";
        }
      });
      setData({ ...newData });
    }
    if (pathname.includes("edit")) {
    }
  }, []);

  // 입력값 변경
  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    const dataType = columns.find((column) => name === column.column)["type"];

    setData({ ...data, [name]: dataType === "boolean" ? checked : value });
    const IsDuplicationCheckColumn = columns.find(
      (column) => name === column.column
    )["duplicationCheck"];
    IsDuplicationCheckColumn && setIsDuplicated(true);
  };

  // 이메일 중복 확인
  const handleIsDuplicated = () => {
    // 이메일 입력했는지 확인
    if (!data.email) {
      alert("이메일을 입력해주세요.");
      return;
    }
    // 이메일 형식이 맞는지 확인
    if (!reg.email.test(data.email)) {
      alert("올바른 이메일 형식으로 입력해주세요.");
      return;
    }

    postDataFromServer("/service/join/email", { email: data.email }).then(
      (response) => {
        if (response.is_duplicated) {
          alert("사용할 수 없는 이메일입니다.");
          return;
        }
        alert("사용 가능한 이메일입니다.");
        setIsDuplicated(response.is_duplicated);
      }
    );
  };

  // 데이터 저장
  const handleSaveData = () => {
    console.log(data);

    // 입력해야하는 값 중 입력이 안된 값이 있는지 확인
    const nullCheck = Object.keys(data).find((key) => data[key] === "");

    if (nullCheck) {
      alert("입력값을 모두 작성해주세요.");
      return;
    }
    // 중복 확인
    if (isDuplicated) {
      alert("중복 확인을 해주세요.");
      return;
    }
    // 정규 표현식 형식에 맞는지 확인
    const regCheck = columns.find((value) => {
      if (value.regularExpressionssionCheck) {
        return !reg[value.column].test(data[value.column]);
      }
    });
    if (regCheck) {
      alert("데이터를 형식에 맞게 입력해주세요.");
      return;
    }
    // 데이터 전송
    // console.log(data);
    const path = pathname.slice(0, pathname.lastIndexOf("/"));
    postDataFromServer(`${path}`, data).then((response) => {
      alert("데이터 생성이 완료되었습니다.");
      navigate(-1);
    });
  };

  return (
    <Box margin="16px">
      <Box margin="8px 0">
        <Button
          variant="outlined"
          style={{ borderRadius: "unset" }}
          onClick={handleSaveData}
        >
          데이터 저장
        </Button>
        {pathname.includes("update") && (
          <Button
            variant="outlined"
            style={{ borderRadius: "unset", margin: "0 8px" }}
          >
            데이터 삭제
          </Button>
        )}
      </Box>
      <List sx={{ border: `1px solid ${borderColor}`, padding: "0" }}>
        <ListItem style={{ backgroundColor: `${info}` }}>
          {pathname.includes("update") && "데이터 수정"}
          {pathname.includes("create") && "데이터 생성"}
        </ListItem>
        <Divider />
        {columns.map((value, index) => (
          <Box key={index}>
            <ListItem
              sx={{
                display: "flex",
              }}
            >
              <Typography variant="subtitle1" component="p" minWidth="150px">
                {value.name}
              </Typography>
              {value.type === "boolean" && (
                <Checkbox
                  name={value.column}
                  checked={data[value.column]}
                  onChange={handleChange}
                />
              )}
              {value.type === "string" && (
                <TextField
                  label={value.name}
                  variant="standard"
                  fullWidth={true}
                  name={value.column}
                  value={data[value.column]}
                  type={value.isSecret ? "password" : "text"}
                  onChange={handleChange}
                />
              )}
              {value.type === "select" && (
                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  <InputLabel>선택</InputLabel>
                  <Select
                    name={value.column}
                    value={data[value.column]}
                    onChange={handleChange}
                    label="filter"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {value.option.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {value.duplicationCheck && (
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "unset",
                    margin: "0 8px",
                    minWidth: "100px",
                  }}
                  onClick={handleIsDuplicated}
                >
                  중복 확인
                </Button>
              )}
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default EditData;
