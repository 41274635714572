import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useMutation } from "react-query";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
} from "../../../common/network/network";
import ItemList from "../../../common/components/item_list";
import {
  selectImageThumbnail,
  convertDateTime,
} from "../../../common/js/common";
import LoadingButton from "@mui/lab/LoadingButton";
import OrderAndFilterButton from "../../../common/components/order_and_filter_button";
import {
  beforePageState,
  itemListTimeStampState,
} from "../../../common/state/state";
import { useRecoilState } from "recoil";
import { TailSpin } from "react-loader-spinner";
import { Column, primary } from "../../../common/style/styles";

export const Index = () => {
  const [beforePage, setBeforePage] = useRecoilState(beforePageState);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );
  // 검색하기 전 키보드에 의해 바뀌는 검색어
  const [query, setQuery] = useState("");
  // params (검색어, 정렬 방식, 필터 조건, 아이템 시작점, 최초 로딩인지)
  const [params, setParams] = useState({
    query: "",
    orderby: "id",
    filter: null,
    offset: 0,
  });
  // 스크롤 하단 감지
  const [target, setTarget] = useState(null);
  // 무한 스크롤으로 데이터 가져올 때 로딩 스피너 보여주기
  const [isLoading, setIsLoading] = useState(false);
  // 아이템 리스트
  const [itemList, setItemList] = useState([]);
  // 아이템 마지막 id
  const [lastID, setLastID] = useState(null);
  // 검색, 정렬, 필터, 아이템 더 가져오기 중 어떤게 수행되었는지 확인
  const [changedValue, setChangedValue] = useState(null);
  // 아이템 찜 리스트
  const [likeItemList, setLikeItemList] = useState([]);
  // 이전페이지의 아이템 option id
  const [lastItem, setLastItem] = useState(null);

  // 검색어 검색
  const handleSubmit = () => {
    // console.log(params.query);
    setChangedValue("query");
    setParams({ ...params, query: query, offset: 0 });
    setItemListTimeStamp(convertDateTime(new Date()));
  };

  // 검색어 입력
  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  // 상품 더 요쳥
  const handleChangeOffset = (number) => {
    if (number > 0) {
      setChangedValue("offset");
      setParams((prevState) => ({
        ...prevState,
        offset: prevState["offset"] + number,
      }));
    } else {
      setParams({ ...params, offset: 0 });
      setItemListTimeStamp(convertDateTime(new Date()));
    }
  };

  // 정렬 변경
  const handleChangeOrderby = (value) => {
    setChangedValue("orderby");
    handleChangeOffset(0);
    setItemList([]);
    setParams((prevState) => ({
      ...prevState,
      orderby: value,
    }));
  };

  // 필터 변경
  const handleChangeFilter = (value) => {
    if (value) {
      setChangedValue("filter");
      handleChangeOffset(0);
      setItemList([]);
      setParams((prevState) => ({
        ...prevState,
        filter: value,
      }));
    }
  };

  // prams가 변경되었을 때 서버로부터 데이터 가져오기
  const mutation = useMutation("SearchItemListData", () => {
    // 비회원 & 토큰 정보 있을 경우
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    return getDataFromServer(
      `/service/search?offset=${params["offset"]}&query=${
        params["query"]
      }&token=${nonMemberToken == "undefined" ? null : nonMemberToken}&init=${
        params["isInit"]
      }&orderby=${params["orderby"]}&filter=${
        params["filter"]
      }&timestamp=${itemListTimeStamp}`
    );
  });

  // 검색어, 정렬방식 변경, 스크롤 제일 하단일때 데이터 요청
  useEffect(() => {
    // 아이템 상세페이지에서 리스트로 뒤로가기 해서 이동 안했을 시 local에 있는 데이터 삭제

    if (!beforePage.includes("search")) {
      localStorage.setItem("itemListData", "null");
    } else {
      // 아이템 상세페에지 -> 리스트 이동시 이전 리스트 데이터 세팅
      const itemListData = localStorage.getItem("itemListData");

      if (itemListData && itemListData !== "null") {
        const newItemListData = JSON.parse(itemListData);

        // 페이지에 해당하는 데이터가 아니면 삭제
        if (
          !newItemListData.hasOwnProperty("pageName") ||
          newItemListData.pageName !== "search"
        ) {
          localStorage.setItem("itemListData", "null");
          return;
        }
        setParams({ ...newItemListData.listData.params });
        setLastID(newItemListData.listData.lastID);
        setItemList([...newItemListData.items]);
        setLikeItemList([...newItemListData.likeItemList]);
        setLastItem(newItemListData.currentItem.split("/")[4]);
        setChangedValue(newItemListData.listData.changedValue);
        localStorage.setItem("itemListData", "null");
        return;
      }
    }

    if (lastItem) {
      return;
    }

    if (!params.query) {
      return;
    }

    mutation.mutate(params, {
      onSuccess: (data) => {
        // console.log(data);
        // 아이템 더 가져올 경우 itemList, likeItemList 배열에 가져온 값 추가
        if (changedValue == "offset" && itemList.length) {
          const last_item = document.getElementById(
            `${itemList[itemList.length - 1]["item_option_id"]}`
          );

          const { scrollHeight } = document.body;
          const myScroll = window.scrollY;
          myScroll / scrollHeight > 0.7 &&
            last_item.scrollIntoView({
              behavior: "auto",
              block: "end",
            });

          setItemList((itemList) => itemList.concat(data["item_list"]));
          setLikeItemList((itemList) => itemList.concat(data["like_items"]));

          // 검색어, 정렬이 바뀌었을 때 itemList, likeItemList 배열 비우고 다시 추가
        } else {
          setItemList([...data["item_list"]]);
          setLastID(data["last_item_id"]);
          setLikeItemList([...data["like_items"]]);
        }
      },
    });
  }, [params]);

  useEffect(() => {
    // 아이템 리스트 배열이 변경됐을 때 로딩 아이콘 종료
    if (changedValue === "offset") {
      setIsLoading(false);
    }
  }, [itemList]);

  // 아이템 상세페이지 -> 리스트페이지로 돌아왔을 때 클릭했던 아이템이 있는 곳으로 스크롤 이동
  useEffect(() => {
    if (itemList && lastItem) {
      const element = document.getElementById(lastItem);
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
      });

      setLastItem(null);
    }
  }, [lastItem]);

  // 좋아요 변경 적용
  const handleChangeLikeItemList = (event, state, item_option_id) => {
    event.preventDefault();
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    if (state == "add") {
      postDataFromServer("/service/likes", {
        token: nonMemberToken === "undefined" ? null : nonMemberToken,
        table_name: "item_options",
        table_id: item_option_id.toString(),
      }).then((response) => {
        if (response.status == 201) {
          setLikeItemList([...likeItemList, item_option_id]);
          // 비회원 토큰 정보가 없을경우 토큰정보 로컬에 저장
          if (response["token"] !== "null") {
            localStorage.setItem("nonMemberToken", response["token"]);
          }
        } else {
          alert("잠시 후 다시 시도해주세요.");
        }
      });
    } else if (state == "delete") {
      deleteDataFromServer(
        `/service/likes/-1?token=${nonMemberToken}&table_name=item_options&table_id=${item_option_id.toString()}`
      )
        .then((response) => {
          setLikeItemList([
            ...likeItemList.filter((value) => value !== item_option_id),
          ]);
        })
        .catch((error) => alert("잠시 후 다시 시도해주세요."));
    }
  };

  // 스크롤 제일 하단 감지하면 onIntersect 함수 실행
  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(onIntersect, {
        rootMargin: "100px",
        threshold: 0.6,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target]);

  // 스크롤 제일 하단일 때 데이터 요청
  const onIntersect = async ([entry], observer) => {
    // last item id가 itemList에 있기 전까지 데이터 가져오기

    if (
      entry.isIntersecting &&
      !isLoading &&
      lastID !== -1 &&
      itemList.length !== 0
    ) {
      const isLastId = itemList.filter((item) => item.item_id == lastID);
      if (isLastId.length == 0) {
        handleChangeOffset(10);
        setIsLoading(true);
      }
      if (!isLoading) {
        await new Promise((resolve) => setTimeout(resolve, 150));
        observer.observe(entry.target);
      }
    }
  };

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <TextField
        placeholder="상품명 또는 상품번호를 입력해주세요."
        sx={{ margin: "1rem", width: "90%" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton sx={{ p: "10px" }}>
                <SearchIcon onClick={handleSubmit} />
              </IconButton>
            </InputAdornment>
          ),
          style: { fontSize: "15pt" },
        }}
        variant="standard"
        onKeyPress={(event) => {
          event.key === "Enter" && handleSubmit();
        }}
        value={query}
        name="query"
        onChange={handleInputChange}
      />

      {params.query && (
        <div>
          <Box margin="8px 0">
            <OrderAndFilterButton
              orderBy={params["orderby"]}
              handleChangeOrderby={handleChangeOrderby}
              handleChangeFilter={handleChangeFilter}
            />
          </Box>
          <ItemList
            listData={{
              pageName: "search",
              params: params,
              lastID: lastID,
              titleName: "",
              categoryList: {},
              changedValue: changedValue,
            }}
            items={selectImageThumbnail(itemList)}
            likeItemList={likeItemList}
            handleChangeLikeItemList={handleChangeLikeItemList}
          />
          {!itemList.length && !mutation.isLoading ? (
            <Box width="100%">
              <Box margin="auto 0" textAlign="center" padding="20% 0">
                검색결과가 존재하지 않습니다.
              </Box>
            </Box>
          ) : null}
          {itemList.length > 0 && (
            <Box
              id="test-item"
              ref={setTarget}
              display="flex"
              justifyContent="center"
            >
              <TailSpin
                height="40"
                width="40"
                color={primary}
                radius="1"
                wrapperStyle={{
                  position: "relative",
                  margin: "16px",
                }}
                wrapperClass=""
                visible={isLoading}
              />
            </Box>
          )}
        </div>
      )}

      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={mutation.isLoading}
      />
    </Column>
  );
};
