import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { borderColor, info, lightcoral } from "../../../common/style/styles";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import { BreakfastDiningOutlined } from "@mui/icons-material";

const ImageListForm = ({ inputData, handleInputDataChange }) => {
  const [imgInfo, setImgInfo] = useState({ source: "", target: "" });

  // 이미지 순서 변경
  const handleDrag = (event, color) => {
    // console.log(event.target.name, event.type);

    switch (event.type) {
      // 드래그 시작
      case "dragstart":
        setImgInfo({ ...imgInfo, source: event.target.name });
        break;
      // 변경하고 싶은 순서에 있는 이미지 위로 드래그
      case "dragover":
        setImgInfo({ ...imgInfo, target: event.target.name });
        break;

      // 변경하고 싶은 위치로 이미지 옮김
      case "dragend":
        handleInputDataChange(
          { target: { name: "image", value: imgInfo } },
          "imageInfo",
          "orderby",
          color,
          null
        );
        break;
      default:
        break;
    }
  };
  return (
    <Box>
      <ListItem style={{ backgroundColor: `${lightcoral}` }}>
        이미지 리스트
      </ListItem>
      <ListItem style={{ flexDirection: "column" }}>
        {Object.keys(inputData.imageInfo).map((color) => (
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{color}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box margin="8px 0">
                <Button variant="outlined" style={{ borderRadius: "unset" }}>
                  <label htmlFor={color}>이미지 파일 선택</label>
                  <input
                    type="file"
                    accept="image/*"
                    id={color}
                    multiple
                    onChange={(event) => {
                      handleInputDataChange(
                        event,
                        "imageInfo",
                        "add",
                        color,
                        null
                      );
                    }}
                  />
                </Button>
              </Box>
              <Box display="flex" flexWrap="wrap">
                {inputData.imageInfo[color].map((data) => (
                  <Box
                    style={{ width: "300px" }}
                    margin="0 8px"
                    position="relative"
                    onDragStart={(event) => {
                      handleDrag(event, color);
                    }}
                    onDragOver={(event) => {
                      handleDrag(event, color);
                    }}
                    onDragEnd={(event) => {
                      handleDrag(event, color);
                    }}
                  >
                    <ClearIcon
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 10,
                        top: 10,
                      }}
                      onClick={() => {
                        handleInputDataChange(
                          {
                            target: {
                              name: "image",
                              value:
                                typeof data === "object" ? data.name : data,
                            },
                          },
                          "imageInfo",
                          "delete",
                          color
                        );
                      }}
                    />
                    <img
                      name={typeof data === "object" ? data.name : data}
                      src={
                        typeof data === "object"
                          ? URL.createObjectURL(data)
                          : data
                      }
                      style={{ width: "300px" }}
                      draggable="true"
                    />
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </ListItem>
    </Box>
  );
};

export default ImageListForm;
