import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ItemList from "../components/item_list";
import { selectImageThumbnail, convertDateTime } from "../js/common";
import {
  deleteDataFromServer,
  getDataFromServer,
  postDataFromServer,
} from "../network/network";
import MenuList from "../../service/brand/components/menu_list";
import { Title as BrandTitle } from "../../service/brand/components/title";
import { Title as CategoryTitle } from "../../service/category/components/title";
import OrderAndFilterButton from "../components/order_and_filter_button";
import { beforePageState, itemListTimeStampState } from "../state/state";
import { useRecoilState } from "recoil";
import { TailSpin } from "react-loader-spinner";
import { Column, primary } from "../style/styles";
import { Box } from "@mui/material";

// 브랜드, 카테고리 아이템 리스트 페이지
export const Show = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [beforePage, setBeforePage] = useRecoilState(beforePageState);
  const currentPage =
    (pathname.includes("categories") && "categories") ||
    (pathname.includes("brands") && "brands");
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );

  // params (카테고리 id, 정렬 방식, 필터 조건, 아이템 시작점, 최초 로딩인지)
  const [params, setParams] = useState({
    category: -1,
    orderby: "id",
    filter: null,
    offset: 0,
    isInit: true,
  });

  // 스크롤 하단 감지
  const [target, setTarget] = useState(null);
  // 무한 스크롤으로 데이터 가져올 때 로딩 스피너 보여주기
  const [isLoading, setIsLoading] = useState(false);
  // 아이템 리스트
  const [itemList, setItemList] = useState([]);
  // 아이템 마지막 id
  const [lastID, setLastID] = useState(null);
  // 타이틀 명
  const [titleName, setTitleName] = useState("");
  // 브랜드 카테고리 리스트
  const [categoryList, setCategoryList] = useState([]);
  // 카테고리 이동, 정렬, 필터, 아이템 더 가져오기 중 어떤게 수행되었는지 확인
  const [changedValue, setChangedValue] = useState(null);
  // 아이템 찜 리스트
  const [likeItemList, setLikeItemList] = useState([]);
  // 이전페이지의 아이템 option id
  const [lastItem, setLastItem] = useState(null);

  // 카테고리 변경
  const handleChangeCategory = (event, id) => {
    setChangedValue("category");
    handleChangeOffset(0);
    setItemList([]);
    setParams((prevState) => ({
      ...prevState,
      category: id,
    }));
  };

  // 정렬 변경
  const handleChangeOrderby = (event) => {
    setChangedValue("orderby");
    handleChangeOffset(0);
    setItemList([]);
    setParams((prevState) => ({
      ...prevState,
      orderby: event.target.value,
    }));
  };

  // 필터 변경
  const handleChangeFilter = (value) => {
    // console.log(value);
    if (value) {
      setChangedValue("filter");
      handleChangeOffset(0);
      setItemList([]);
      setParams((prevState) => ({
        ...prevState,
        filter: value,
      }));
    }
  };

  // 상품 더 요쳥
  const handleChangeOffset = (number) => {
    if (number > 0) {
      setChangedValue("offset");
      setParams((prevState) => ({
        ...prevState,
        offset: prevState["offset"] + number,
      }));
    } else {
      setParams({ ...params, offset: 0 });
      setItemListTimeStamp(convertDateTime(new Date()));
    }
  };

  // 좋아요 변경 적용
  const handleChangeLikeItemList = (event, state, item_option_id) => {
    event.preventDefault();
    // console.log("handleChangeLikeItemList", state, item_option_id);
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    if (state == "add") {
      postDataFromServer("/service/likes", {
        token: nonMemberToken === "undefined" ? null : nonMemberToken,
        table_name: "item_options",
        table_id: item_option_id.toString(),
      })
        .then((response) => {
          setLikeItemList([...likeItemList, item_option_id]);
          // 비회원 토큰 정보가 없을경우 토큰정보 로컬에 저장
          if (response["token"] !== "null") {
            localStorage.setItem("nonMemberToken", response["token"]);
          }
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    } else if (state == "delete") {
      deleteDataFromServer(
        `/service/likes/-1?token=${nonMemberToken}&table_name=item_options&table_id=${item_option_id.toString()}`
      )
        .then((response) => {
          setLikeItemList([
            ...likeItemList.filter((value) => value !== item_option_id),
          ]);
        })
        .catch((error) => {
          alert("잠시 후 다시 시도해주세요.");
        });
    }
  };

  // prams가 변경되었을 때 서버로부터 데이터 가져오기
  const mutation = useMutation("ItemListData", () => {
    // 비회원 & 토큰 정보 있을 경우
    const nonMemberToken = localStorage.getItem("nonMemberToken");

    return getDataFromServer(
      `/service${pathname}?offset=${params["offset"]}&category=${
        params["category"]
      }&token=${nonMemberToken == "undefined" ? null : nonMemberToken}&init=${
        params["isInit"]
      }&orderby=${params["orderby"]}&filter=${
        params["filter"]
      }&timestamp=${itemListTimeStamp}`
    );
  });

  //  prams가 변경되었을 때 서버로부터 last id 가져오기
  const lastIdMutation = useMutation("ItemLastIdData", () => {
    const pageName = pathname.includes("brands") ? "brands" : "categories";
    return getDataFromServer(
      `/service/${pageName}/last-id/${id}?category=${params["category"]}&orderby=${params["orderby"]}&filter=${params["filter"]}&timestamp=${itemListTimeStamp}`
    );
  });

  // 카테고리, 정렬방식 변경, 스크롤 제일 하단일때 데이터 요청
  useEffect(() => {
    // 아이템 상세페이지에서 리스트로 뒤로가기 해서 이동 안했을 시 local에 있는 데이터 삭제

    if (!beforePage.includes(currentPage)) {
      localStorage.setItem("itemListData", "null");
    } else {
      const itemListData = localStorage.getItem("itemListData");
      if (itemListData && itemListData !== "null") {
        const newItemListData = JSON.parse(itemListData);

        // 페이지에 해당하는 데이터가 아니면 삭제
        if (
          !newItemListData["listData"].hasOwnProperty("pageName") ||
          newItemListData.listData.pageName !== currentPage
        ) {
          localStorage.setItem("itemListData", "null");
          return;
        }

        // 아이템 상세페에지 -> 리스트 이동시 이전 리스트 데이터 세팅
        setTitleName(newItemListData.listData.titleName);
        setParams({ ...newItemListData.listData.params });
        setLastID(newItemListData.listData.lastID);
        setCategoryList([...newItemListData.listData.categoryList]);
        setItemList([...newItemListData.items]);
        setLikeItemList([...newItemListData.likeItemList]);
        setLastItem(newItemListData.currentItem.split("/")[4]);
        localStorage.setItem("itemListData", "null");
        return;
      }
    }

    if (lastItem) {
      return;
    }

    if (!changedValue && !params["isInit"]) {
      return;
    }

    if (params.offset === 0) {
      lastIdMutation.mutateAsync(params, {
        onSuccess: (data, variables, context) => {
          setLastID(data["last_item_id"]);
        },
        onError: (error, variable, context) => {
          console.log(error);
          // error
        },
      });
    }

    mutation.mutateAsync(params, {
      onSuccess: (data, variables, context) => {
        // 아이템 더 가져올 경우 itemList, likeItemList 배열에 가져온 값 추가
        if (changedValue == "offset" && itemList.length) {
          const last_item = document.getElementById(
            `${itemList[itemList.length - 1]["item_option_id"]}`
          );

          const { scrollHeight } = document.body;
          const myScroll = window.scrollY;
          myScroll / scrollHeight > 0.7 &&
            last_item.scrollIntoView({
              behavior: "auto",
              block: "end",
            });

          setItemList((itemList) => itemList.concat(data["item_list"]));
          setLikeItemList((itemList) => itemList.concat(data["like_items"]));

          // 카테고리, 정렬이 바뀌었을 때 itemList, likeItemList 배열 비우고 다시 추가
        } else {
          setItemList([...data["item_list"]]);
          setLikeItemList([...data["like_items"]]);
        }

        // 페이지 처음 로딩이 아닐경우 title name, category 정보 업데이트 하지 않기
        if (params["isInit"]) {
          if (currentPage === "brands") {
            setTitleName(data["brand_name"]);
            setCategoryList([...data["category_list"]]);
          } else if (currentPage === "categories") {
            setTitleName(data["category_name"]);
          }
          setParams((prevState) => ({
            ...prevState,
            isInit: false,
          }));
        }
      },
      onError: (error, variable, context) => {
        console.log(error);
        // error
      },
    });
  }, [params]);

  useEffect(() => {
    // 아이템 리스트 배열이 변경됐을 때 로딩 아이콘 종료
    if (changedValue === "offset") {
      setIsLoading(false);
    }
  }, [itemList]);

  // 아이템 상세페이지 -> 리스트페이지로 돌아왔을 때 클릭했던 아이템이 있는 곳으로 스크롤 이동
  useEffect(() => {
    if (itemList && lastItem) {
      const element = document.getElementById(lastItem);
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
      });

      setLastItem(null);
    }
  }, [lastItem]);

  // 카테고리 이동시 params 변경
  useEffect(() => {
    if (!params.isInit) {
      setChangedValue(null);
      handleChangeOffset(0);
      setItemList([]);
      setParams((prevState) => ({
        ...prevState,
        isInit: true,
      }));
    }
  }, [pathname]);

  // 스크롤 제일 하단 감지하면 onIntersect 함수 실행
  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(onIntersect, {
        rootMargin: "100px",
        threshold: 0.6,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target]);

  // 스크롤 제일 하단일 때 데이터 요청
  const onIntersect = async ([entry], observer) => {
    // last item id가 itemList에 있기 전까지 데이터 가져오기
    if (
      entry.isIntersecting &&
      !isLoading &&
      lastID !== -1 &&
      itemList.length !== 0 &&
      !params["isInit"]
    ) {
      const isLastId = itemList.filter((item) => item.item_id == lastID);
      if (isLastId.length == 0) {
        handleChangeOffset(10);
        setIsLoading(true);
      }
      if (!isLoading) {
        await new Promise((resolve) => setTimeout(resolve, 150));
        observer.observe(entry.target);
      }
    }
  };

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {titleName ? (
        <div>
          {currentPage === "brands" && <BrandTitle titleName={titleName} />}
          {currentPage === "categories" && (
            <CategoryTitle titleName={titleName} />
          )}
          {currentPage === "brands" && (
            <MenuList
              categoryList={categoryList}
              categoryID={params["category"]}
              handleChangeCategory={handleChangeCategory}
            />
          )}
          <OrderAndFilterButton
            orderBy={params["orderby"]}
            handleChangeOrderby={handleChangeOrderby}
            handleChangeFilter={handleChangeFilter}
          />

          <ItemList
            listData={{
              pageName: currentPage,
              params: params,
              lastID: lastID,
              titleName: titleName,
              categoryList: categoryList,
            }}
            items={selectImageThumbnail(itemList)}
            likeItemList={likeItemList}
            handleChangeLikeItemList={handleChangeLikeItemList}
          />
          {!itemList.length && !mutation.isLoading ? (
            <Box width="100%">
              <Box margin="auto 0" textAlign="center" padding="20% 0">
                상품이 존재하지 않습니다.
              </Box>
            </Box>
          ) : null}

          {itemList.length > 0 && (
            <Box
              id="test-item"
              ref={setTarget}
              display="flex"
              justifyContent="center"
            >
              <TailSpin
                height="30"
                width="30"
                color={primary}
                radius="1"
                wrapperStyle={{
                  position: "relative",
                  margin: "16px",
                }}
                wrapperClass=""
                visible={isLoading}
              />
            </Box>
          )}
        </div>
      ) : null}
    </Column>
  );
};
