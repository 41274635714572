import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import defaultImg from "../../../media_assets/image/gallery1.jpg";
import styled from "styled-components";
import {
  primary,
  borderColor,
  secondary,
  BREAK_POINT_PC,
  grey3,
  Column,
} from "../../../common/style/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  getDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { selectItemImages } from "../../../common/js/common";
import ClearIcon from "@mui/icons-material/Clear";
import { TailSpin } from "react-loader-spinner";
import { windowSizeState } from "../../../common/state/state";

const SelectButton = styled.div`
  flex: 1;
  padding: 1rem 0;
  margin: 4px 4px 8px 0;
  background-color: ${({ selected }) => (selected ? primary : "white")};
  border: ${({ selected }) =>
    selected ? `1px solid ${primary}` : `1px solid ${secondary}`};
  border-radius: 4px;
  color: ${({ selected }) => (selected ? "white" : secondary)};
  font-size: 13pt;
`;

const Review = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [windowsize, setWindowsize] = useState(windowSizeState);
  const [data, setData] = useState({
    itemRate: null,
    review: "",
    images: [],
  });
  const [itemInfo, setItemInfo] = useState({});
  const [isLogin, setIsLogin] = useState(false);
  // 리뷰 등록 클릭했는지 확인 - 더블클릭 방지
  const [hasClick, setHasClick] = useState(false);
  // 서버에서 삭제할 이미지
  const [deleteImages, setDeleteImages] = useState([]);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);

  // 리뷰 아이템에 해당하는 정보 가져오기
  useEffect(() => {
    setLoading(true);
    getDataFromServer(`/service/reviews/item/${id}`)
      .then((response) => {
        setItemInfo({ ...response.item_info });
        // 리뷰 수정일경우 이전 리뷰 작성 정보 세팅
        if (response.review_info && Object.keys(response.review_info).length) {
          setData({
            itemRate: response.review_info.rating,
            review: response.review_info.content,
            images: response.review_info.images
              ? response.review_info.images
              : [],
          });
        }
        setIsLogin(true);
      })
      .catch((error) => {
        alert("로그인 후 사용해주세요.");
        navigate("/login");
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleItemRateChange = (rate) => {
    setData({ ...data, itemRate: rate });
  };

  const handleReviewContentChange = (event) => {
    setData({ ...data, review: event.target.value });
  };

  const hadleReviewImagesChange = (event, state) => {
    if (state === "add") {
      const newFile = [...event.target.files];

      // 선택된 이미지가 없으면 return
      if (!newFile.length) {
        alert("후기 사진을 선택해주세요.");
        return;
      }

      // 이미 2장을 첨부한 상태에서 새로 첨부하면 return
      if (data.images.length === 2) {
        alert("후기 사진은 최대 2장까지 첨부할 수 있습니다.");
        return;
      }

      const imageList = newFile
        .map((value, index) => {
          if (index < 2 - data.images.length) {
            return value;
          }
        })
        .filter((value) => value);
      setData({ ...data, images: [...data.images, ...imageList] });
    } else if (state === "delete") {
      if (!window.confirm("이미지를 삭제하시겠습니까?")) {
        return;
      }

      const { name, value } = event.target;
      const newImageList = data.images.filter((img) =>
        typeof img === "object" ? img.name !== value : img !== value
      );
      setData({ ...data, images: [...newImageList] });

      const deleteImg = data.images.find((data) =>
        typeof img === "object" ? data.name === value : data === value
      );

      if (typeof deleteImg === "string") {
        setDeleteImages([...deleteImages, deleteImg]);
      }
    }
  };

  const handleSubmit = () => {
    // 더블클릭 방지
    if (hasClick) {
      return;
    }
    setHasClick(true);

    // 평점, 리뷰 작성했는지 확인
    if (data.itemRate === null) {
      alert("상품 만족도를 체크해주세요.");
      setHasClick(false);
      return;
    }
    if (data.review.length < 11) {
      alert("리뷰는 최소 10자 이상 입력해주세요.");
      setHasClick(false);
      return;
    }

    // 데이터 전송
    const formData = new FormData();
    formData.append("sku", itemInfo.sku);
    formData.append("orderItemId", id);
    formData.append("itemRate", data.itemRate);
    formData.append("reviewContent", data.review);

    const newImages = data.images.filter((value) => typeof value === "object");

    if (newImages.length) {
      const fileName = newImages.map((value, index) => {
        formData.append(`file_${index}`, value);
        return `file_${index}`;
      });
      formData.append("fileName", JSON.stringify(fileName));
    }

    // 리뷰 수정
    if (itemInfo.review_id) {
      formData.append("deleteImages", deleteImages.join(","));

      putDataFromServer(`/service/reviews/${itemInfo.review_id}`, formData)
        .then((response) => {
          alert("리뷰가 수정되었습니다.");
          navigate(-1);
        })
        .finally(() => {
          setHasClick(false);
        });
      // 리뷰 생성
    } else {
      postDataFromServer("/service/reviews", formData)
        .then((response) => {
          alert("리뷰가 등록되었습니다.");
          navigate(-1);
        })
        .finally(() => {
          setHasClick(false);
        });
    }
  };

  console.log(itemInfo);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      <Box sx={{ pointerEvents: hasClick ? "none" : "auto" }}>
        {isLogin ? (
          <Box margin="16px">
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h5" : "subtitle2"}
              fontWeight="500"
              textAlign="center"
              margin="8px 0"
            >
              리뷰 작성
            </Typography>
            <Divider />

            {/* 리뷰 아이템 정보 */}
            <Box display="flex" margin="8px 0">
              <img
                style={{
                  backgroundImage: `url(${defaultImg})`,
                }}
                src={selectItemImages(itemInfo.images, itemInfo.color)[0]}
                srcSet={selectItemImages(itemInfo.images, itemInfo.color)[0]}
                alt={itemInfo.item_name}
                loading="lazy"
                height="100px"
              />
              <Box marginLeft="1rem">
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                  }
                  fontWeight="500"
                  margin={
                    windowsize.width >= BREAK_POINT_PC ? "0 0.5rem 0 1rem" : "0"
                  }
                  flex="4"
                >
                  {itemInfo.brand_name}
                </Typography>
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                  }
                  fontWeight={
                    windowsize.width >= BREAK_POINT_PC ? "700" : "500"
                  }
                  margin={
                    windowsize.width >= BREAK_POINT_PC ? "0 0.5rem 0 1rem" : "0"
                  }
                  noWrap={true}
                  flex="4"
                >
                  {itemInfo.item_name}
                </Typography>
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                  }
                  fontWeight="500"
                  noWrap={true}
                  flex="4"
                >
                  {itemInfo.color} / {itemInfo.size}
                </Typography>
              </Box>
            </Box>
            <Divider />
            {/* 평점 */}
            <Box margin="1rem 0">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                }
                fontWeight="500"
              >
                구매하신 상품은 만족하셨나요?
              </Typography>
              <Box display="flex">
                <SelectButton
                  selected={data.itemRate == 0 ? true : false}
                  onClick={() => {
                    handleItemRateChange(0);
                  }}
                >
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                    fontWeight="700"
                    textAlign="center"
                  >
                    별로에요
                  </Typography>
                </SelectButton>
                <SelectButton
                  selected={data.itemRate == 1 ? true : false}
                  onClick={() => {
                    handleItemRateChange(1);
                  }}
                >
                  <Typography
                    variant={
                      windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"
                    }
                    fontWeight="700"
                    textAlign="center"
                  >
                    만족해요
                  </Typography>
                </SelectButton>
              </Box>
            </Box>
            {/* 리뷰 작성 */}
            <Box margin="1rem 0">
              <Typography
                variant={
                  windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                }
                fontWeight="500"
              >
                리뷰를 작성해주세요.
              </Typography>
              <TextField
                multiline
                rows={7}
                placeholder="최소 10자 이상 입력해주세요."
                fullWidth={true}
                value={data.review}
                onChange={handleReviewContentChange}
              />
            </Box>
            {/* 리뷰 이미지 첨부 */}
            <Box margin="1rem 0">
              <Button variant="outlined" fullWidth={true}>
                <label
                  htmlFor="reviewImage"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CameraAltIcon />
                  <Typography margin="0 4px">사진 첨부하기</Typography>
                </label>
              </Button>

              <Typography variant="caption" color="secondary">
                * 사진은 최대 2장까지 첨부할 수 있습니다.
              </Typography>
              <input
                type="file"
                accept="image/*"
                name="reviewImage"
                id="reviewImage"
                multiple
                onChange={(event) => {
                  hadleReviewImagesChange(event, "add");
                }}
              />
              <Box margin="1rem 0">
                {data.images && (
                  <Box>
                    <Typography
                      variant={
                        windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                      }
                    >
                      첨부된 리뷰 사진
                    </Typography>
                    <Box display="flex">
                      {data.images.map((img) => (
                        <Box margin="0px 4px 0 0" position="relative">
                          <img
                            src={
                              typeof img === "object"
                                ? URL.createObjectURL(img)
                                : img
                            }
                            style={{ height: "130px" }}
                          />
                          <ClearIcon
                            fontSize="small"
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: 5,
                              top: 5,
                            }}
                            onClick={() => {
                              hadleReviewImagesChange(
                                {
                                  target: {
                                    name: "image",
                                    value:
                                      typeof img === "object" ? img.name : img,
                                  },
                                },
                                "delete"
                              );
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {/* 등록, 취소 버튼 */}
            <Box margin="16px 0" display="flex">
              <Button
                variant="outlined"
                sx={{ flex: 1, margin: "0 4px 0 0", borderRadius: "0" }}
                color="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                  }
                  fontWeight="700"
                  padding="0.5rem 0"
                >
                  취소
                </Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{ flex: 1, margin: "0 4px 0 0", borderRadius: "0" }}
                onClick={handleSubmit}
              >
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"
                  }
                  fontWeight="700"
                  padding="0.5rem 0"
                >
                  등록
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : null}
        <TailSpin
          height="80"
          width="80"
          color={primary}
          radius="1"
          wrapperStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 200,
          }}
          wrapperClass=""
          visible={loadling}
        />
      </Box>
    </Column>
  );
};

export default Review;
