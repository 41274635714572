import { Box, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainMenu from "../../../common/components/main_menu";
import DataTable from "../../../common/components/data_table";
import columnData from "../../../common/json/columns.json";
import { useLocation, useNavigate } from "react-router-dom";
import { borderColor, StyledLink } from "../../../common/style/styles";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
import {
  deleteDataFromServer,
  getDataFromServer,
} from "../../../common/network/network";

const Index = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [isAdmin, setIsAdmin] = useState(false);
  const [processing, setProcessing] = useState(false);
  // 테이블 행 데이터
  const [columns, setColumns] = useState([...columnData[pathname]]);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  useEffect(() => {
    setIsAdmin(true);
    getDataFromServer("/admin/benefits/promotion-code").then((response) => {
      setRows([...response.data]);
    });
  }, []);

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  const handleEdit = () => {
    if (selectedIDs.length !== 1) {
      return;
    }

    navigate(`${pathname}/edit/${selectedIDs[0]}`);
  };

  const handleCopy = () => {
    if (selectedIDs.length !== 1) {
      return;
    }

    navigate(`${pathname}/copy/${selectedIDs[0]}`);
  };

  // 데이터 삭제
  const handleDelete = () => {
    // 선택된 데이터가 없을 때 return
    if (!selectedIDs.length) {
      alert("선택된 데이터 없음");
      return;
    }
    if (!window.confirm("데이터를 삭제하시겠습니까?")) {
      return;
    }

    setProcessing(true);
    deleteDataFromServer(
      `/admin/benefits/promotion-code?id=${selectedIDs
        .filter((id) => id !== -1)
        .join(",")}`
    )
      .then((response) => {
        alert("선택한 프로모션 코드가 삭제 처리되었습니다.");
        window.location.reload();
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
        setProcessing(false);
      });
  };

  return (
    <Box>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <Box margin="8px">
              <StyledLink to={`${pathname}/create/-1`}>
                <Button
                  variant="outlined"
                  style={{ borderRadius: "unset", margin: "0 8px" }}
                >
                  신규
                </Button>
              </StyledLink>
              <Button
                variant="outlined"
                style={{ borderRadius: "unset", margin: "0 8px" }}
                onClick={handleEdit}
              >
                수정
              </Button>
              <Button
                variant="outlined"
                style={{ borderRadius: "unset", margin: "0 8px" }}
                onClick={handleDelete}
              >
                삭제
              </Button>
              <Button
                variant="outlined"
                style={{ borderRadius: "unset", margin: "0 8px" }}
                onClick={handleCopy}
              >
                복사
              </Button>
            </Box>
            {/* {rows.length > 0 ? ( */}
            <Box
              border={"1px solid " + borderColor}
              sx={{ overflow: "auto" }}
              maxHeight={(windowSize.height * 8) / 13}
            >
              <DataTable
                columns={columns}
                rows={rows}
                selectedIDs={selectedIDs}
                handleChangeOrderby={null}
                handleClickCheckBox={handleClickCheckBox}
                handleInputInfoChange={null}
                handleDelete={handleDelete}
              />
            </Box>
            {/* ) : (
            <Typography textAlign="center" variant="h4" margin="32px 0">
              데이터가 없습니다.
            </Typography>
          )} */}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export { Index as Benefits };
