import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { BREAK_POINT_PC, grey } from "../style/styles";
import { IMaskInput } from "react-imask";
import { windowSizeState } from "../state/state";
import { useRecoilState } from "recoil";

// 회원가입, 정보수정 페이지의 개인 정보 입력 양식
const PersonalForm = ({ data, handleChangeInput, isEditing }) => {
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const regCustomsNumber = /^(P|p)([0-9]{12})$/;
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  return (
    <Box
      display="flex"
      flexDirection="column"
      margin={windowSize.width >= BREAK_POINT_PC ? "3rem 0 0 0" : "1rem 0 0 0"}
      width="100%"
    >
      {windowSize.width >= BREAK_POINT_PC ? (
        <Typography fontWeight="600" variant="h5" component="p">
          개인 정보
        </Typography>
      ) : (
        <Box>
          <Divider sx={{ borderWidth: "2px" }} />
          <Typography
            fontWeight="500"
            variant="subtitle2"
            component="p"
            margin="1rem"
          >
            개인 정보
          </Typography>
          <Divider />
        </Box>
      )}
      {/* 이름 */}
      <Box
        margin={
          windowSize.width >= BREAK_POINT_PC ? "0 0 1rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          component="p"
          padding="8px 0"
        >
          이름(실명)
        </Typography>
        <FormControl
          sx={{ width: "100%", borderRadius: "0" }}
          variant="outlined"
        >
          <OutlinedInput
            sx={{ borderRadius: "0" }}
            value={data.name}
            name="personal.name"
            onChange={handleChangeInput}
            size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
          />
        </FormControl>
      </Box>

      {/* 휴대폰번호 */}
      <Box
        margin={
          windowSize.width >= BREAK_POINT_PC ? "0 0 1rem 0" : "0.5rem 1rem"
        }
      >
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          component="p"
          padding="8px 0"
        >
          휴대폰 번호
        </Typography>
        <FormControl
          sx={{ width: "100%", borderRadius: "0" }}
          variant="outlined"
        >
          <OutlinedInput
            placeholder="- 없이 숫자만 입력"
            id="outlined-phone"
            sx={{ borderRadius: "0" }}
            value={data.phone}
            name="personal.phone"
            onChange={handleChangeInput}
            size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
          />
          {data.phone && regPhone.test(data.phone) === false ? (
            <FormHelperText style={{ color: "red", fontSize: "10pt" }}>
              * 연락처를 바르게 입력해주세요.
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
      {/*   개인통관고유번호 */}
      {isEditing ? (
        <Box
          margin={
            windowSize.width >= BREAK_POINT_PC ? "0 0 1rem 0" : "0.5rem 1rem"
          }
        >
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
            component="p"
            padding="8px 0"
          >
            개인통관고유번호
          </Typography>
          <FormControl
            sx={{ width: "100%", borderRadius: "0" }}
            variant="outlined"
          >
            <OutlinedInput
              placeholder="P로 시작하는 12자리 숫자를 입력해주세요."
              id="outlined-customs_number"
              sx={{ borderRadius: "0" }}
              value={data.customs_number}
              name="personal.customs_number"
              onChange={handleChangeInput}
              size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
            />
            {data.customs_number &&
            regCustomsNumber.test(data.customs_number) === false ? (
              <FormHelperText style={{ color: "red", fontSize: "10pt" }}>
                * 통관번호를 바르게 입력해주세요.
              </FormHelperText>
            ) : null}
          </FormControl>
        </Box>
      ) : null}
    </Box>
  );
};

export default PersonalForm;
