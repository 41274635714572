import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { BREAK_POINT_PC } from "../../../common/style/styles";
import ReactHtmlParser from "react-html-parser";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../../../common/state/state";

//  QnA 아코디언 리스트
const AccordionList = ({ data }) => {
  console.log(data);
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <div>
      {data.map((value) => (
        <Accordion key={value.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box>
              <Box display="flex" alignItems="center">
                <Typography
                  variant={
                    windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"
                  }
                >
                  {value.title}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Divider />
              <Box margin="1rem">
                {ReactHtmlParser(
                  value.content &&
                    value.content.replaceAll("<img", "<img width='100%'")
                )}{" "}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionList;
