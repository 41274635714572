import { Button, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  deleteDataFromServer,
  postDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import { windowSizeState } from "../../../common/state/state";
import {
  BREAK_POINT_PC,
  blue,
  lightgrey,
  grey,
} from "../../../common/style/styles";

const Paid = () => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const location = useLocation();
  const orderParams = decodeURIComponent(
    location.search.replaceAll(/\+/g, "%20")
  );
  const navigate = useNavigate();
  const [canShow, setCanShow] = useState(false);

  // 결제후 리다이렉션 페이지인지 확인
  useEffect(() => {
    if (!orderParams) {
      return;
    }

    const temp = orderParams.slice(1).split("&");

    let paymentResults = {};
    for (const data of temp) {
      paymentResults[data.split("=")[0]] = data.split("=")[1];
    }

    if (
      paymentResults.hasOwnProperty("error_code") ||
      paymentResults.imp_success === "false"
    ) {
      setCanShow(false);

      const error_msg = paymentResults["error_msg"];

      alert(error_msg);
      navigate("/orderSheet");
    } else {
      // 결제정보 서버에 저장

      postDataFromServer("/service/orders/payment", {
        imp_uid: paymentResults["imp_uid"],
        merchant_uid: paymentResults["merchant_uid"],
      })
        .then((response) => {
          // alert("결제가 완료되었습니다.");
          // 주문 목록으로 이동
          // navigate("/orders");
        })
        .catch((error) => {
          if (error.response.status == 409) {
            alert("결제금액이 일치하지 않아 결제가 취소되었습니다.");
            navigate("/orderSheet");
          }
        });
    }

    setCanShow(true);
  }, []);

  return (
    <>
      {canShow ? (
        <Box
          marginTop={windowsize.width >= BREAK_POINT_PC ? "10%" : "30%"}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            variant={windowsize.width >= BREAK_POINT_PC ? "h4" : "subtitle1"}
            fontWeight="700"
            flex="12"
            textAlign="center"
          >
            결제가 완료되었습니다.
          </Typography>

          <Button
            variant="outlined"
            style={{
              borderRadius: 0,
              borderColor: grey,
              color: blue,
              margin:
                windowsize.width >= BREAK_POINT_PC
                  ? "3rem 0 2rem 0"
                  : "2rem 0 1rem 0",
              width: windowsize.width >= BREAK_POINT_PC ? "300px" : "160px",
              padding: "0.5rem 0",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "body1"}
            >
              메인페이지로 돌아가기
            </Typography>
          </Button>
          <Button
            variant="containd"
            style={{
              borderRadius: 0,
              background: blue,
              color: "white",
              width: windowsize.width >= BREAK_POINT_PC ? "300px" : "160px",
              padding: "0.5rem 0",
            }}
            onClick={() => {
              navigate("/orders");
            }}
          >
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "body1"}
            >
              주문 목록으로 이동
            </Typography>
          </Button>
        </Box>
      ) : null}
    </>
  );
};

export default Paid;
