import { Box, Button, InputBase } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../common/components/data_table";
import MainMenu from "../../../common/components/main_menu";
import OptionHeader from "../components/option_header";
import SearchHeader from "../components/search_header";
import columnData from "../../../common/json/columns.json";
import {
  borderColor,
  info,
  lightgrey,
  primary,
} from "../../../common/style/styles";
import {
  beforePageState,
  itemListTimeStampState,
  windowSizeState,
} from "../../../common/state/state";
import { useRecoilState } from "recoil";
import ChangeStatus from "../components/change_status";
import { Mutation, useMutation } from "react-query";
import {
  deleteDataFromServer,
  getDataFromServer,
  putDataFromServer,
} from "../../../common/network/network";
import {
  convertDateTime,
  createXlsxData,
  preproccessingForDataTable,
  selectItemImages,
} from "../../../common/js/common";
import CurationModal from "../components/curation_modal";
import StatusModal from "../components/status_modal";
import { TailSpin } from "react-loader-spinner";
import SaleItemModal from "../components/sale_item_modal";

const Index = () => {
  const { pathname } = useLocation();
  const { navigate } = useNavigate();
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [itemListTimeStamp, setItemListTimeStamp] = useRecoilState(
    itemListTimeStampState
  );
  const [beforePage, setBeforePage] = useRecoilState(beforePageState);
  // 테이블 행 데이터
  const [columns, setColumns] = useState([...columnData[pathname]]);
  // 테이블 데이터
  const [rows, setRows] = useState([]);
  // url 파라미터
  const [params, setParams] = useState({
    limit: 15,
    offset: 0,
    orderby: "item_id desc",
    filter: null,
    status: "total",
  });
  // 검색한 데이터의 총 갯수
  const [count, setCount] = useState(0);
  // 현재페이지, 마지막페이지 정보
  const [page, setPage] = useState({ total: 1, current: 1 });
  // 아이템 상태별 총 갯수 (전체, 판매중, 판매 대기, 할인중, 품절, 판매종료)
  const [countOfItemStatus, setCountOfItemStatus] = useState({
    total: 0,
    for_sale: 0,
    on_disable: 0,
    sold_out: 0,
    discontinued: 0,
  });
  // 조회조건 배열
  const [condition, setCondition] = useState({
    filter: [],
    price: [],
    is_stock: [],
    curation: [],
  });
  // 선택된 행의 id 값 배열
  const [selectedIDs, setSelectedIDs] = useState([]);
  // 모달 열렸는지 닫혔는지 확인
  const [isOpenedModal, setIsOpenedModal] = useState({
    curation: false,
    status: false,
    saleItem: false,
  });
  const [isAdmin, setIsAdmin] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);
  // 데이터 처리중 화면 터치 금지시키기
  const [processing, setProcessing] = useState(false);
  // 아이템 상태별 갯수 로딩 따로 표시
  const [itemStatusLoding, setItemStatusLoading] = useState(true);

  // 어드민 할인 삭제
  const handelDeleteSaleItem = () => {
    setLoading(true);
    setProcessing(true);
    const ids = selectedIDs.filter((id) => id !== -1);

    deleteDataFromServer(`/admin/items/admin/sale?ids=${ids}`)
      .then((response) => {
        alert("선택한 아이템의 어드민 할인이 해제되었습니다.");
        window.location.reload();
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
        setLoading(false);
        setProcessing(false);
      });
  };

  // 상품 상태변경 (판매중, 판매대기)
  const handleChangeItemInfo = (ids, data) => {
    setLoading(true);
    setProcessing(true);
    putDataFromServer("/admin/items/status", {
      data: data,
      ids: ids,
    })
      .then((response) => {
        alert("선택한 아이템의 정보가 변경되었습니다.");
        window.location.reload();
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
        setLoading(false);
        setProcessing(false);
      });
  };

  // 모달 열고 닫기
  const handleModalChange = (name) => {
    // 선택된 아이템이 없을 때 return
    if (!selectedIDs.length) {
      alert("상품을 선택해주세요.");
      return;
    }
    setIsOpenedModal((prev) => ({ ...isOpenedModal, [name]: !prev[name] }));
  };

  // 체크박스 체크/해지
  const handleClickCheckBox = (id) => {
    const hasId = selectedIDs.filter((value) => value == id).length
      ? true
      : false;

    // 체크 해지
    if (hasId) {
      // 전체선택
      if (id === -1) {
        setSelectedIDs([]);
        // 개별 선택
      } else {
        const newSelectedIDs = selectedIDs.filter((value) => value !== id);
        setSelectedIDs([...newSelectedIDs]);
      }
      // 체크
    } else {
      // 전체선택
      if (id === -1) {
        const newSelectedIDs = rows.map((value) => {
          return value.id;
        });
        setSelectedIDs([id, ...newSelectedIDs]);
        // 개별 선택
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  // 데이터 삭제
  const handleDelete = (state, id) => {
    // 선택된 데이터가 없을 때 return
    if (state === "checkBox" && !selectedIDs.length) {
      alert("선택된 데이터 없음");
      return;
    }
    if (
      !window.confirm(
        "데이터를 삭제하시겠습니까? \n * 주문 내역이 있는 상품은 판매종료, 주문 내역이 없는 상품은 삭제처리 됩니다."
      )
    ) {
      return;
    }

    setLoading(true);
    setProcessing(true);
    const newData = selectedIDs.filter((value) => value !== -1);
    deleteDataFromServer(
      `/admin/items?id=${state === "checkBox" ? newData.join(",") : id}`
    )
      .then((response) => {
        alert("상품이 삭제 처리되었습니다.");
        window.location.reload();
      })
      .catch((error) => {
        alert("잠시 후 다시 시도해주세요.");
        setLoading(false);
        setProcessing(false);
      });
  };

  // 특정 컬럼 기준으로 정렬
  const handleChangeOrderby = (fieldName, orderby) => {
    const newColumns = columns.map((value) => {
      const newValue = value;

      if (value.standard) {
        // 같은 컬럼 선택 > 기존 정렬방식의 반대방식으로 정렬
        if (fieldName === value.field) {
          newValue.standard = true;
          orderby === "desc"
            ? (newValue.orderby = "asc")
            : (newValue.orderby = "desc");
          // 다른 컬럼 선택시 기존컬럼 선택 해지
        } else {
          newValue.standard = false;
        }
        // 다른 컬럼 선택 > 기존 정렬방식대로 정렬
      } else {
        if (fieldName === value.field) {
          newValue.standard = true;
        }
      }
      return { ...newValue };
    });

    setColumns([...newColumns]);

    setParams({
      ...params,
      orderby: `${fieldName} ${
        newColumns.filter((value) => value.standard)[0].orderby
      }`,
    });
  };

  // 엑셀 다운로드
  const handleExportExcel = () => {
    // 검색한 전체 아이템 엑셀 다운
    setProcessing(true);
    setLoading(true);
    getDataFromServer(
      `/admin/items?limit=null&offset=0&orderby=${params.orderby}&filter=${params.filter}&status=${params.status}&timestamp=${itemListTimeStamp}`
    )
      .then((response) => {
        const newRows = preproccessingForDataTable(pathname, response.rows);
        createXlsxData(columns, newRows, `item_list`);
      })
      .finally(() => {
        setProcessing(false);
        setLoading(false);
      });
  };

  // 페이징 숫자 변경 (100개, 500개씩 보기)
  const handleChangePaging = (paging) => {
    setParams({ ...params, limit: paging });
  };

  // 특정 페이지 이동
  const handleChangeCurrentPage = () => {
    // 이동하려는 페이지 숫자가 전체 페이지를 넘어섰을 경우 리턴
    if (page.current > page.total) {
      return;
    }
    setParams({
      ...params,
      offset: params.limit * (page.current - 1),
    });
  };

  // 페이지 변경
  const handlePageInputChange = (event) => {
    // 페이지 입력이 숫자가 아니거나 0보다 작으면 리턴
    if (!event.target.value || event.target.value <= 0) {
      return;
    }
    setPage({ ...page, current: event.target.value });
  };

  // 특정한 상태를 기준으로 상품 필터링  ( prams 중 status 변경 => 조회조건 초기화 )
  const handleStatusOfParamsChange = (status) => {
    setParams({
      status: status,
      orderby: "item_id desc",
      filter: null,
      offset: 0,
      limit: 15,
    });
    setItemListTimeStamp(convertDateTime(new Date()));
  };

  // 조회조건 추가, 삭제
  const handleChangeInquiry = (state, type, data, id) => {
    if (state === "add") {
      setCondition({ ...condition, [type]: [...condition[type], data] });
    } else if (state === "delete") {
      const newCondition = condition[type].filter((value) => value.id !== id);
      setCondition({ ...condition, [type]: newCondition });
    }
  };

  // 조회하기 버튼 클릭했을때 조회조건을 기준으로 필터링하기
  const handleClickInquiry = () => {
    if (
      !condition.filter.length &&
      !condition.price.length &&
      !condition.is_stock.length &&
      !condition.curation.length
    ) {
      alert("조회조건이 없습니다.");
      return;
    }

    setParams({ ...params, filter: JSON.stringify(condition) });
  };

  // 상태별 아이템 총 갯수가 변경되었을때 서버로부터 데이터 가져오기
  // prams가 변경되었을 때 서버로부터 데이터 가져오기
  const countOfItemStatusMutation = useMutation("countOfItemStatus", () => {
    return getDataFromServer(
      `/admin/items/count?timestamp=${itemListTimeStamp}`
    );
  });

  // 상태별 아이템 갯수 변경
  useEffect(() => {
    setItemStatusLoading(true);
    countOfItemStatusMutation.mutate(params, {
      onSuccess: (data) => {
        setCountOfItemStatus({ ...data });
        setItemStatusLoading(false);
      },
    });
  }, []);

  // params가 변경되었을 때 서버로부터 데이터 가져오기
  const paramsMutation = useMutation("AdminPageItemList", () => {
    return getDataFromServer(
      `/admin/items?limit=${params.limit}&offset=${params.offset}&orderby=${params.orderby}&filter=${params.filter}&status=${params.status}&timestamp=${itemListTimeStamp}`
    );
  });

  // params 에 따라 테이블 데이터 변경
  useEffect(() => {
    // 상세페이지에서 리스트로 뒤로가기 해서 이동 안했을 시 local에 있는 데이터 삭제
    if (!beforePage.includes("items")) {
      localStorage.setItem("adminItemListData", "null");
    } else {
      // 상세페에지 -> 리스트 이동시 이전 리스트 데이터 세팅
      const adminItemListData = localStorage.getItem("adminItemListData");

      if (adminItemListData && adminItemListData !== "null") {
        const newAdminItemListData = JSON.parse(adminItemListData);
        setCondition({ ...newAdminItemListData.listData.condition });
        setParams({ ...newAdminItemListData.listData.params });
        localStorage.setItem("adminItemListData", "null");
        return;
      }
    }

    paramsMutation.mutate(params, {
      onSuccess: (data) => {
        setIsAdmin(true);
        const newRows = preproccessingForDataTable(pathname, data.rows);

        setRows([...newRows]);
        setCount(data.count);
        setPage({
          ...page,
          total: Math.ceil(data.count / params.limit),
        });
        setSelectedIDs([]);
      },
      onError: (error) => {
        setIsAdmin(false);
      },
    });
  }, [params, pathname]);

  // 페이지시작할 때 timestamp 최신화
  useEffect(() => {
    setItemListTimeStamp(convertDateTime(new Date()));
  }, []);

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          sx={{ pointerEvents: processing ? "none" : "auto" }}
        >
          <MainMenu />
          <Box margin="8px" sx={{ overflow: "auto" }} width="100%">
            <SearchHeader
              itemStatusLoding={itemStatusLoding}
              condition={condition}
              status={params.status}
              countOfItemStatus={countOfItemStatus}
              handleStatusOfParamsChange={handleStatusOfParamsChange}
              handleChangeInquiry={handleChangeInquiry}
              handleClickInquiry={handleClickInquiry}
            />
            <OptionHeader
              handleChangePaging={handleChangePaging}
              handleExportExcel={handleExportExcel}
              handleDelete={handleDelete}
            />
            <Box
              border={"1px solid " + borderColor}
              sx={{ overflow: "auto" }}
              maxHeight={(windowSize.height * 8) / 13}
            >
              <DataTable
                listData={{
                  params: params,
                  condition: condition,
                }}
                columns={columns}
                rows={rows}
                selectedIDs={selectedIDs}
                handleChangeOrderby={handleChangeOrderby}
                handleClickCheckBox={handleClickCheckBox}
                handleDelete={handleDelete}
              />
              <Box
                style={{
                  backgroundColor: `${lightgrey}`,
                  bottom: "0px",
                  left: "0px",
                  position: "sticky",
                  zIndex: 110,
                  padding: "8px",
                }}
              >
                {count}개 중 {params.offset + 1} - {params.offset + rows.length}{" "}
                데이터 표시중 ({" "}
                <InputBase
                  type="number"
                  style={{
                    margin: "2px",
                    border: `2px solid ${primary}`,
                    borderRadius: "4px",
                    width: "50px",
                  }}
                  value={page.current}
                  onChange={handlePageInputChange}
                />
                / {page.total} )
                <Button
                  variant="outlined"
                  style={{ borderRadius: "unset", margin: "0 8px" }}
                  onClick={handleChangeCurrentPage}
                >
                  페이지 이동
                </Button>
              </Box>
            </Box>
            <ChangeStatus
              handleModalChange={handleModalChange}
              handelDeleteSaleItem={handelDeleteSaleItem}
            />
            <CurationModal
              selectedIDs={selectedIDs}
              isOpened={isOpenedModal.curation}
              handleModalChange={handleModalChange}
            />
            <StatusModal
              selectedIDs={selectedIDs}
              isOpened={isOpenedModal.status}
              handleModalChange={handleModalChange}
              handleChangeItemInfo={handleChangeItemInfo}
            />
            <SaleItemModal
              selectedIDs={selectedIDs}
              isOpened={isOpenedModal.saleItem}
              handleModalChange={handleModalChange}
            />
          </Box>
        </Box>
      )}
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={paramsMutation.isLoading || loadling}
      />
    </>
  );
};

export { Index as ItemManagement };
