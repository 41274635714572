import { Box, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { addDashToPhoneNumber } from "../../../common/js/common";
import { windowSizeState } from "../../../common/state/state";
import { BREAK_POINT_PC, grey3 } from "../../../common/style/styles";

const Address = ({ selectedAddress }) => {
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  return (
    <>
      <Box display="flex" marginBottom="1rem">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          color={grey3}
          flex="1"
        >
          수령인
        </Typography>
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          flex="4"
        >
          {selectedAddress.name}
        </Typography>
      </Box>
      <Box display="flex" marginBottom="1rem">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          color={grey3}
          flex="1"
        >
          주소
        </Typography>
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          flex="4"
        >
          {selectedAddress.main_address} {selectedAddress.detail_address}
        </Typography>
      </Box>
      <Box display="flex" marginBottom="1rem">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          color={grey3}
          flex="1"
        >
          우편번호
        </Typography>
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          flex="4"
        >
          {selectedAddress.zip_code}
        </Typography>
      </Box>
      <Box display="flex" marginBottom="1rem">
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          color={grey3}
          flex="1"
        >
          휴대폰번호
        </Typography>
        <Typography
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle1" : "body1"}
          fontWeight="500"
          flex="4"
        >
          {addDashToPhoneNumber(selectedAddress.cellphone)}
        </Typography>
      </Box>
    </>
  );
};

export default Address;
