import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Index as Main } from "./service/main/layout/index";
import { Index as Brands } from "./service/brand/layout/index";
import { Index as Categories } from "./service/category/layout/index";
import { Index as Likes } from "./service/like/layout/index";
import { Index as Carts } from "./service/cart/layout/index";
import { Index as Join } from "./service/join/layout/index";
import { Index as Login } from "./service/login/layout/index";
import { Index as MyPage } from "./service/mypage/layout/index";
import { Index as Search } from "./service/search/layout/index";
import { Show as ItemList } from "./common/layout/show";
import { Show as Item } from "./service/item/layout/show";
import { Show as Curation } from "./service/curation/layout/show";
import ServiceHeader from "./common/components/service_header";
import TabBar from "./common/components/tab_bar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BackToTop from "./common/components/back_to_top";
import { ThemeProvider } from "@mui/material/styles";
import {
  mainTheme,
  Pages,
  Column,
  BREAK_POINT_PC,
} from "./common/style/styles";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import "./App.css";
import { beforePageState, windowSizeState } from "./common/state/state";
import { useEffect } from "react";
import MyInfo from "./service/mypage/layout/my_info";
import EditUserInfo from "./service/mypage/layout/edit_user_info";
import AddressInfo from "./service/mypage/layout/address_info";
import TermsOfService from "./service/mypage/layout/terms_of_service";
import PrivacyInformation from "./service/mypage/layout/privacy_information";
import Point from "./service/mypage/layout/point";
import Notices from "./service/mypage/layout/notices";
import Notice from "./service/mypage/layout/notice";
import Inquiry from "./service/mypage/layout/inquiry";
import WriteQuestion from "./common/layout/write_question";
import ForgetPassword from "./service/login/layout/forget_password";
import { AdminLogin } from "./admin/login/layout";
import { DashBoard } from "./admin/dashbord/layout";
import AdminHeader from "./common/components/admin_header";
import { ItemManagement } from "./admin/item/layout/index";
import { ItemDetail } from "./admin/item/layout/show";
import { OrderManagement } from "./admin/order/layout/index";
import { OrderList } from "./service/order/layout/index";
import OrderSheet from "./service/order/layout/order_sheet";
import { OrderItemDetail } from "./service/order/layout/show";
import Review from "./service/order/layout/review";
import { OrderDetail } from "./admin/order/layout/show";
import { UserManagement } from "./admin/user/layout";
import { UserDetail } from "./admin/user/layout/show";
import { BrandManagement } from "./admin/brand/layout";
import { BrandDetail } from "./admin/brand/layout/show";
import { CrawlingInfo } from "./admin/crawling/layout";
import { ExchangeManagement } from "./admin/etc/layout/exchange_index";
import { Statistics } from "./admin/statistic/layout";
import { MainMenuDetail } from "./admin/menu/layout/main_menu_show";
import { MainMenuManagement } from "./admin/menu/layout/main_menu_index";
import { CurationManagement } from "./admin/menu/layout/curation_index";
import { CurationDetail } from "./admin/menu/layout/curation_show";
import { CategoryManagement } from "./admin/menu/layout/category_index";
import { CategoryDetail } from "./admin/menu/layout/category_show";
import { ReplyManagement } from "./admin/reply/layout";
import { NoticeManagement } from "./admin/etc/layout/notice_index";
import { NoticeDetail } from "./admin/etc/layout/notice_show";
import Coupon from "./service/order/layout/coupon";
import Cancel from "./service/order/layout/cancel";
import { Benefits } from "./admin/benefits/layout/index";
import { BenefitsDetail } from "./admin/benefits/layout/show";
import { Box } from "@mui/material";
import Paid from "./service/order/layout/paid";
import Nonmember from "./service/login/layout/nonmember";

function App(props) {
  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);
  const [beforePage, setBeforePage] = useRecoilState(beforePageState);
  const { pathname } = useLocation();

  // 화면 사이즈 변경 감지
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 이전페이지 url 정보 저장
  useEffect(() => {
    return () => {
      setBeforePage(pathname);
    };
  }, [pathname]);

  return (
    <div className="App">
      <ThemeProvider theme={mainTheme}>
        {pathname.includes("admin") ? (
          !pathname.includes("login") && <AdminHeader />
        ) : (
          <ServiceHeader />
        )}
        <Pages
          admin={pathname.includes("admin") ? true : false}
          device={windowSize.width >= BREAK_POINT_PC ? "PC" : "mobile"}
        >
          <Routes>
            {/* 존재하지 않는 페이지 리디렉션 */}
            {/* <Route path="*" exact={true} element={<Main />} /> */}
            {/* 서비스 페이지 */}
            <Route path="/" exact={true} element={<Main />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/brands/:id" element={<ItemList />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/:id" element={<ItemList />} />
            <Route path="/items/:id/:detailId/:optionId" element={<Item />} />
            <Route path="/likes" element={<Likes />} />
            <Route path="/carts" element={<Carts />} />
            <Route path="/join" element={<Join />} />
            <Route path="/login" element={<Login />} />
            <Route path="/nonmember" element={<Nonmember />} />
            <Route path="/mypage" element={<MyPage />} />
            <Route path="/myinfo" element={<MyInfo />} />
            <Route path="/userinfo/edit" element={<EditUserInfo />} />
            <Route path="/address" element={<AddressInfo />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyInformation />} />
            <Route path="/point" element={<Point />} />
            <Route path="/notices/:id" element={<Notices />} />
            <Route path="/notice/:id" element={<Notice />} />
            <Route path="/contact" element={<Inquiry />} />
            {/* 일반문의 */}
            <Route path="/contact/write" element={<WriteQuestion />} />
            {/* 상품문의 */}
            <Route
              path="/contact/write/:id/:optionId"
              element={<WriteQuestion />}
            />
            <Route path="/myinfo/password" element={<ForgetPassword />} />
            <Route path="/curation/:id" element={<Curation />} />
            <Route path="/search" element={<Search />} />
            <Route path="/orderSheet" element={<OrderSheet />} />
            <Route path="/orders" element={<OrderList />} />
            <Route path="/paid" element={<Paid />} />
            <Route path="/orders/:id" element={<OrderItemDetail />} />
            <Route path="/reviews/:id" element={<Review />} />
            <Route
              path="/cancel/:orderId/:orderItemId/:state"
              element={<Cancel />}
            />
            <Route path="/coupon" element={<Coupon />} />
            {/* 어드민 페이지 */}
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/login/password" element={<ForgetPassword />} />
            <Route
              path="/admin/dashboard/dashboard"
              exact={true}
              element={<DashBoard />}
            />
            <Route path="/admin/users/:subMenu" element={<UserManagement />} />
            <Route
              path="/admin/users/:subMenu/:mode/:id"
              element={<UserDetail />}
            />
            <Route
              path="/admin/items/item"
              exact={true}
              element={<ItemManagement />}
            />
            <Route
              path="/admin/items/item/:mode/:id/:detailId/:optionId"
              exact={true}
              element={<ItemDetail />}
            />
            <Route
              path="/admin/items/item/:mode/:id"
              exact={true}
              element={<ItemDetail />}
            />
            <Route
              path="/admin/orders/order"
              exact={true}
              element={<OrderManagement />}
            />
            <Route
              path="/admin/orders/order/show/:id"
              exact={true}
              element={<OrderDetail />}
            />
            <Route
              path="/admin/brands/brand"
              exact={true}
              element={<BrandManagement />}
            />
            <Route
              path="/admin/brands/brand/:mode/:id"
              exact={true}
              element={<BrandDetail />}
            />
            <Route
              path="/admin/brands/crawling"
              exact={true}
              element={<CrawlingInfo />}
            />
            <Route
              path="/admin/menu/main"
              exact={true}
              element={<MainMenuManagement />}
            />
            <Route
              path="/admin/menu/main/:mode/:id"
              exact={true}
              element={<MainMenuDetail />}
            />
            <Route
              path="/admin/menu/curation"
              exact={true}
              element={<CurationManagement />}
            />
            <Route
              path="/admin/menu/curation/:mode/:id"
              exact={true}
              element={<CurationDetail />}
            />
            <Route
              path="/admin/menu/category"
              exact={true}
              element={<CategoryManagement />}
            />
            <Route
              path="/admin/menu/category/:mode/:id"
              exact={true}
              element={<CategoryDetail />}
            />
            <Route
              path="/admin/etc/exchange"
              exact={true}
              element={<ExchangeManagement />}
            />
            <Route
              path="/admin/etc/:submenu"
              exact={true}
              element={<NoticeManagement />}
            />
            <Route
              path="/admin/etc/:submenu/:mode/:id"
              exact={true}
              element={<NoticeDetail />}
            />
            <Route
              path="/admin/reply/:submenu"
              exact={true}
              element={<ReplyManagement />}
            />
            <Route
              path="/admin/statistics/statistics"
              exact={true}
              element={<Statistics />}
            />
            <Route
              path="/admin/benefits/:submenu"
              exact={true}
              element={<Benefits />}
            />
            <Route
              path="/admin/benefits/:submenu/:mode/:id"
              exact={true}
              element={<BenefitsDetail />}
            />
          </Routes>
        </Pages>

        {!pathname.includes("admin") &&
          !pathname.includes("order") &&
          !pathname.includes("cart") &&
          !pathname.includes("ordersheet") &&
          !pathname.includes("cancel") && (
            <BackToTop {...props}>
              <Fab color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon sx={{ color: "white" }} />
              </Fab>
            </BackToTop>
          )}
        {!pathname.includes("carts") &&
          !pathname.includes("orderSheet") &&
          !pathname.includes("admin") &&
          !pathname.includes("items") &&
          !pathname.includes("cancel") && <TabBar />}
      </ThemeProvider>
    </div>
  );
}

export default App;
