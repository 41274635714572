import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OrderModal from "../modal/order_modal";
import FilterModal from "./filter_modal";
import TuneIcon from "@mui/icons-material/Tune";
import { borderColor, BREAK_POINT_PC, grey } from "../style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../state/state";
const OrderAndFilterButton = ({
  orderBy,
  handleChangeOrderby,
  handleChangeFilter,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const handleOpen = () => setOpenFilter(true);
  const handleClose = () => setOpenFilter(false);

  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      margin="1rem 1.2rem"
    >
      {/* 필터 */}
      <Box
        border={"1px solid " + grey}
        marginRight="0.5rem"
        display="flex"
        alignItems="center"
        padding="0.2rem"
        sx={{ cursor: "pointer" }}
        onClick={() => handleOpen("filter")}
      >
        <Typography
          margin="0.3rem"
          variant={windowsize.width >= BREAK_POINT_PC ? "subtitle2" : "body1"}
          fontWeight="500"
        >
          필터
        </Typography>
        <TuneIcon
          sx={{
            marginRight: "1rem",
            fontWeight: "2rem",
            margin: windowsize.width >= BREAK_POINT_PC ? "0.5rem" : "0.2rem",
          }}
        />
      </Box>

      <OrderModal orderBy={orderBy} handleChangeOrderby={handleChangeOrderby} />

      <FilterModal
        openFilter={openFilter}
        handleClose={handleClose}
        handleChangeFilter={handleChangeFilter}
      />
    </Box>
  );
};

export default OrderAndFilterButton;
