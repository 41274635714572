import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { BREAK_POINT_PC } from "../style/styles";
import { useRecoilState } from "recoil";
import { windowSizeState } from "../state/state";

// 배송지 추가, 수정 양식
const AddressForm = ({
  address,
  handleChangeInput,
  handleChangeAddress,
  showIsDefalt,
}) => {
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

  const [windowSize, setWindowSize] = useRecoilState(windowSizeState);

  const open = useDaumPostcodePopup(
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    handleChangeAddress([
      { name: "main_address", value: fullAddress },
      { name: "zip_code", value: data.zonecode },
    ]);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <Box>
      <Box display="flex" margin="0.7rem 0" alignItems="center">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          flex="1"
        >
          배송지 명
        </Typography>
        <TextField
          variant="outlined"
          size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
          name="title"
          sx={{
            flex: "4",
          }}
          inputProps={{
            style: {
              fontSize: windowSize.width >= BREAK_POINT_PC ? "16px" : "12px",
              padding:
                windowSize.width >= BREAK_POINT_PC ? "0.5rem" : "0.5rem 0.3rem",
            },
          }}
          value={address.title}
          onChange={handleChangeInput}
        />
      </Box>
      <Box display="flex" margin="0.7rem 0">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          flex="1"
          alignItems="center"
        >
          수령인
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          name="name"
          sx={{ flex: "4" }}
          inputProps={{
            style: {
              fontSize: windowSize.width >= BREAK_POINT_PC ? "16px" : "12px",
              padding:
                windowSize.width >= BREAK_POINT_PC ? "0.5rem" : "0.5rem 0.3rem",
            },
          }}
          value={address.name}
          onChange={handleChangeInput}
          fullWidth={true}
        />
      </Box>

      <Box display="flex" margin="0.7rem 0">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          flex="1"
        >
          주소
        </Typography>
        <Box flex="4">
          <Box display="flex" justifyContent="space-between">
            <TextField
              variant="outlined"
              size="small"
              inputProps={{
                style: {
                  fontSize:
                    windowSize.width >= BREAK_POINT_PC ? "16px" : "12px",
                  padding:
                    windowSize.width >= BREAK_POINT_PC
                      ? "0.5rem"
                      : "0.5rem 0.3rem",
                },
              }}
              name="zip_code"
              value={address.zip_code}
              onClick={handleClick}
              disabled
            />
            <Button variant="outlined" color="primary" onClick={handleClick}>
              <Typography
                variant={
                  windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"
                }
              >
                우편번호 찾기
              </Typography>
            </Button>
          </Box>
          <TextField
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                fontSize: windowSize.width >= BREAK_POINT_PC ? "16px" : "12px",
                padding:
                  windowSize.width >= BREAK_POINT_PC
                    ? "0.5rem"
                    : "0.5rem 0.3rem",
              },
            }}
            name="main_address"
            value={address.main_address}
            fullWidth={true}
            style={{ margin: "0.2rem 0" }}
            onClick={handleClick}
          />
          <TextField
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                fontSize: windowSize.width >= BREAK_POINT_PC ? "16px" : "12px",
                padding:
                  windowSize.width >= BREAK_POINT_PC
                    ? "0.5rem"
                    : "0.5rem 0.3rem",
              },
            }}
            name="detail_address"
            value={address.detail_address}
            onChange={handleChangeInput}
            fullWidth={true}
          />
        </Box>
      </Box>
      <Box display="flex" margin="0.7rem 0">
        <Typography
          variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
          flex="1"
        >
          연락처
        </Typography>
        <FormControl sx={{ width: "100%", flex: "4" }} variant="outlined">
          <OutlinedInput
            placeholder="- 없이 숫자만 입력"
            id="outlined-phone"
            inputProps={{
              style: {
                fontSize: windowSize.width >= BREAK_POINT_PC ? "16px" : "12px",
                padding:
                  windowSize.width >= BREAK_POINT_PC
                    ? "0.5rem"
                    : "0.5rem 0.3rem",
              },
            }}
            name="cellphone"
            value={address.cellphone}
            onChange={handleChangeInput}
            size={windowSize.width >= BREAK_POINT_PC ? "medium" : "small"}
          />
          {address.cellphone && regPhone.test(address.cellphone) === false ? (
            <FormHelperText style={{ color: "red", fontSize: "10px" }}>
              * 연락처를 바르게 입력해주세요.
            </FormHelperText>
          ) : null}
        </FormControl>
      </Box>
      {showIsDefalt ? (
        <Box display="flex" alignItems="center">
          <Checkbox
            sx={{ padding: "4px 4px 4px 0" }}
            checked={address.is_default}
            color="primary"
            onChange={handleChangeInput}
            value={address.is_default}
            name="is_default"
          />
          <Typography
            variant={windowSize.width >= BREAK_POINT_PC ? "subtitle2" : "body2"}
            fontWeight="500"
          >
            기본 배송지로 설정
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default AddressForm;
