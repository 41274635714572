import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PointHistory from "../components/point_history";
import {
  borderColor,
  BREAK_POINT_PC,
  Column,
  primary,
} from "../../../common/style/styles";
import { getDataFromServer } from "../../../common/network/network";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { windowSizeState } from "../../../common/state/state";
import { useRecoilState } from "recoil";
// 포인트 정보
const Point = () => {
  const navigate = useNavigate();
  const [windowsize, setWindowsize] = useRecoilState(windowSizeState);
  const [totalPoint, setTotalPoint] = useState(0);
  const [pointHistories, setPointHistories] = useState([]);
  // 로그인했는지 확인
  const [isLogin, setIsLogion] = useState(false);
  // 로딩스피너 표시
  const [loadling, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDataFromServer("/service/points")
      .then((response) => {
        setIsLogion(true);
        setTotalPoint(response.total_point);
        setPointHistories(response.point_histories);
      })
      .catch((error) => {
        // 회원정보 없을 시 로그인 페이지로 이동
        alert("로그인 후 이용해주세요.");
        navigate("/login");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Column xs={12} sm={12} md={12} lg={9}>
      {isLogin ? (
        <Box margin="8px">
          <Box
            display="flex"
            justifyContent="space-between"
            padding="16px"
            alignItems="center"
          >
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h6" : "subtitle2"}
              component="p"
              fontWeight="500"
            >
              사용가능한 포인트
            </Typography>
            <Typography
              variant={windowsize.width >= BREAK_POINT_PC ? "h5" : "subtitle1"}
              component="p"
              color="primary"
              fontWeight="700"
            >
              {totalPoint}p
            </Typography>
          </Box>
          <Divider />
          <PointHistory pointHistories={pointHistories} />
        </Box>
      ) : null}
      <TailSpin
        height="80"
        width="80"
        color={primary}
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 200,
        }}
        wrapperClass=""
        visible={loadling}
      />
    </Column>
  );
};

export default Point;
